import { TypedDocumentNode, gql } from '@apollo/client';

import { useCurrentUserContext } from 'contexts/currentUser';

import { useHasUnclaimedRefereeRewardsAsReferee_currentUser } from './__generated__/useHasUnclaimedRefereeRewardsAsReferee.graphql';
import { useReferralRewards } from './useReferralRewards';

export const useHasUnclaimedRefereeRewardsAsReferee = () => {
  const { currentUser } = useCurrentUserContext();
  const { referralAsReferee, refereeRewards } = currentUser || {};
  const { isClaimed } = useReferralRewards(refereeRewards || []);
  if (
    !referralAsReferee ||
    referralAsReferee.aasmState === 'expired' ||
    !refereeRewards
  )
    return false;

  return refereeRewards.length > 0 && !refereeRewards.every(isClaimed);
};

useHasUnclaimedRefereeRewardsAsReferee.fragments = {
  currentUser: gql`
    fragment useHasUnclaimedRefereeRewardsAsReferee_currentUser on CurrentUser {
      slug
      referralAsReferee {
        id
        aasmState
      }
      refereeRewards {
        id
        ...useReferralRewards_referralReward
      }
    }
    ${useReferralRewards.fragments.referralReward}
  ` as TypedDocumentNode<useHasUnclaimedRefereeRewardsAsReferee_currentUser>,
};
