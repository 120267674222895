import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import { earlyAccessEvents } from 'lib/earlyAccess';

import { useGetCardSpecialEventConfig_anyCard } from './__generated__/useGetCardSpecialEventConfig.graphql';

export const useGetCardSpecialEventConfig = ({
  card,
}: {
  card: useGetCardSpecialEventConfig_anyCard;
}) => {
  const { anyTeam } = card;
  const domesticLeague = isType(anyTeam, 'Club')
    ? anyTeam?.domesticLeague
    : undefined;
  if (!domesticLeague) return undefined;

  return earlyAccessEvents.find(event => {
    return event.leagues.includes(domesticLeague.slug);
  });
};

useGetCardSpecialEventConfig.fragments = {
  anyCard: gql`
    fragment useGetCardSpecialEventConfig_anyCard on AnyCardInterface {
      slug
      anyTeam {
        slug
        ... on Club {
          slug
          domesticLeague {
            slug
          }
        }
      }
    }
  ` as TypedDocumentNode<useGetCardSpecialEventConfig_anyCard>,
};
