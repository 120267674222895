import { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

import { Rarity, Sport } from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { getScarcityName } from 'lib/baseball';

import { DumbCardBack } from './DumbCardBack';
import commonBack from './assets/common.png';
import limitedBack from './assets/limited.png';
import rareBack from './assets/rare.png';
import superRareBack from './assets/super_rare.png';
import uniqueBack from './assets/unique.png';

type Props = {
  path?: string;
  sport?: Sport;
  tier?: number;
  rarity: Rarity;
  season?: string;
  radius?: string;
  shine?: boolean;
} & HTMLAttributes<HTMLElement>;

const pathBySport = {
  [Sport.BASEBALL]: 'mlb',
  [Sport.NBA]: 'nba',
};

export const getCardBackUrl = (
  sport: Sport | undefined,
  { rarity, season, tier }: { rarity: Rarity; season?: string; tier?: number }
) => {
  if (sport === Sport.BASEBALL || sport === Sport.NBA) {
    const urlParts = [FRONTEND_ASSET_HOST, 'cards/back', pathBySport[sport]];
    if (season && season !== '2022') {
      urlParts.push(season);
    }
    urlParts.push(`${rarity}_tier_${tier === undefined ? 'none' : tier}.png`);
    return urlParts.join('/');
  }
  if (sport === Sport.FOOTBALL) {
    const tokens: string[] = [Rarity[rarity]];
    if (tier !== undefined) {
      tokens.push(`tier_${tier}`);
    }
    return `${FRONTEND_ASSET_HOST}/cards/back/${tokens.join('-')}.svg`;
  }
  return (
    {
      [Rarity.common]: commonBack,
      [Rarity.limited]: limitedBack,
      [Rarity.rare]: rareBack,
      [Rarity.super_rare]: superRareBack,
      [Rarity.unique]: uniqueBack,
      [Rarity.custom_series]: commonBack,
    }[rarity] ?? commonBack
  );
};

export const CardBack = ({
  path: forcedPath,
  sport,
  rarity,
  tier,
  season,
  shine,
  radius,
  ...props
}: Props) => {
  const { formatMessage } = useIntl();

  const ariaLabel =
    tier !== undefined
      ? formatMessage(
          {
            id: 'CardBack.titleWithTier',
            defaultMessage: '{scarcity} Card, tier {tier}',
          },
          { scarcity: getScarcityName(rarity), tier }
        )
      : formatMessage(
          {
            id: 'CardBack.title',
            defaultMessage: '{scarcity} Card',
          },
          { scarcity: getScarcityName(rarity) }
        );

  const path = forcedPath ?? getCardBackUrl(sport, { rarity, season, tier });

  return (
    <DumbCardBack
      path={path}
      aria-label={ariaLabel}
      shine={shine}
      radius={radius}
      {...props}
    />
  );
};

export default CardBack;
