import styled from 'styled-components';

import defaultShield from 'assets/club/shield_none.png';
import ResponsiveImg from 'atoms/ui/ResponsiveImg';
import { unitMapping } from 'lib/style';

type Props = {
  shieldUrl?: string | null;
  clubName?: string | null;
  size?: keyof typeof unitMapping;
};

const Root = styled(ResponsiveImg)`
  object-fit: contain;
  aspect-ratio: 1/1;
`;

export const ClubShield = ({ shieldUrl, clubName, size = 4 }: Props) => {
  return (
    <Root
      style={{ width: unitMapping[size] }}
      cropWidth={40}
      src={shieldUrl || defaultShield}
      alt={clubName || ''}
      onError={e => {
        e.currentTarget.src = defaultShield;
      }}
    />
  );
};
