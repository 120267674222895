import { useEffect } from 'react';

import { AuthBroadCastChannel } from 'lib/broadcastChannel';
import { reloadPage } from 'routing/EnsureLatestAppVersion/reloadPage';

const useSyncAuthStateAcrossTabs = () => {
  useEffect(() => {
    AuthBroadCastChannel.on(message => {
      if (message === 'logged_out') {
        reloadPage();
      }
    });
  }, []);
};

export default useSyncAuthStateAcrossTabs;
