import { ReactNode, useCallback, useState } from 'react';

import { OnboardingHighlightBackdrop } from 'components/onboarding/OnboardingHighlightBackdrop';

import HighlightContext, {
  MeasureHighlightCallback,
  OnboardingHighlightConfig,
  OnboardingHighlightName,
} from '.';

type Props = {
  children: ReactNode;
};

const OnboardingHighlightProvider = ({ children }: Props) => {
  const [highlightsDimensions, setHighlightsDimensions] = useState<
    Record<OnboardingHighlightName, MeasureHighlightCallback>
  >({} as Record<OnboardingHighlightName, MeasureHighlightCallback>);

  const [highlightsConfig, setHighlightsConfig] = useState<
    | { name: OnboardingHighlightName; config?: OnboardingHighlightConfig }[]
    | null
  >(null);

  const registerHighlight = useCallback(
    (
      highlight: OnboardingHighlightName,
      measureCallback: MeasureHighlightCallback
    ) => {
      setHighlightsDimensions(old => {
        return { ...old, [highlight]: measureCallback };
      });
    },
    []
  );

  const clearHighlights = useCallback(() => {
    setHighlightsConfig(null);
  }, []);

  const currentHighlights =
    highlightsConfig?.map(({ name, config }) => ({
      name,
      config,
      measureCallback: highlightsDimensions[name],
    })) || null;

  return (
    <HighlightContext.Provider
      value={{
        registerHighlight,
        currentHighlights,
        setCurrentHighlights: setHighlightsConfig,
        clearHighlights,
      }}
    >
      {children}
      <OnboardingHighlightBackdrop />
    </HighlightContext.Provider>
  );
};

export default OnboardingHighlightProvider;
