import { useState } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';

import { useIsMobileApp } from './useIsMobileApp';
import { Lifecycle } from './useLifecycle';

export default () => {
  const { isMobileApp } = useIsMobileApp();
  const { currentUser } = useCurrentUserContext();
  const [disable3D, setDisable3D] = useState(
    (currentUser?.userSettings?.lifecycle as Lifecycle)?.disable3D ||
      isMobileApp
  );
  return { disable3D, setDisable3D };
};
