import { ReactNode } from 'react';
import styled from 'styled-components';

import Block from 'atoms/layout/Block';
import { Text16 } from 'atoms/typography';

interface Props {
  title: string;
  subtitle: string;
  children: ReactNode;
  link: string;
}

const Root = styled(Block)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 120px;
  padding: 0px 30px;
  width: 100%;
  & + & {
    margin-top: 0;
  }
`;
const Left = styled.div`
  text-align: left;
`;

export const RelatedPage = ({ title, subtitle, children, link }: Props) => {
  return (
    <Root to={link}>
      {children}
      <Left>
        <Text16 bold>{title}</Text16>
        <Text16 color="var(--c-neutral-600)">{subtitle}</Text16>
      </Left>
    </Root>
  );
};

export default RelatedPage;
