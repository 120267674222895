import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';
import { useMemo } from 'react';

import useMonetaryAmount from 'hooks/useMonetaryAmount';

import { useAuctionConversionCredit_auction } from './__generated__/useAuctionConversionCredit.graphql';
import {
  ConversionCreditWithAmounts,
  conversionCreditFragment,
} from './useConversionCredits';

export const useAuctionConversionCredit = (
  auction?: useAuctionConversionCredit_auction
): ConversionCreditWithAmounts | undefined => {
  const { toMonetaryAmount } = useMonetaryAmount();

  const conversionCredit = useMemo(() => {
    if (!auction) return undefined;

    const credit = auction.myBestBid?.conversionCredit;
    if (!credit || isPast(credit.endDate)) {
      return undefined;
    }

    const amounts = toMonetaryAmount(credit.maxDiscount);
    return {
      ...credit,
      maxDiscount: amounts,
    };
  }, [auction, toMonetaryAmount]);

  return conversionCredit as ConversionCreditWithAmounts;
};

useAuctionConversionCredit.fragments = {
  auction: gql`
    fragment useAuctionConversionCredit_auction on TokenAuction {
      id
      myBestBid {
        id
        conversionCredit {
          id
          ...useConversionCredits_conversionCredits
        }
      }
    }
    ${conversionCreditFragment}
  ` as TypedDocumentNode<useAuctionConversionCredit_auction>,
};
