import { TypedDocumentNode, gql } from '@apollo/client';

import idFromObject from '@sorare/core/src/gql/idFromObject';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';

import { PrimaryBuyConfirmationOptions } from 'contexts/buyingConfirmation';

import BuyPrimaryBuyConfirmation from '../BuyPrimaryBuyConfirmation';
import {
  BuyPrimaryBuyConfirmationQueryById,
  BuyPrimaryBuyConfirmationQueryByIdVariables,
} from './__generated__/index.graphql';

const BUY_PRIMARY_BUY_CONFIRMATION_QUERY = gql`
  query BuyPrimaryBuyConfirmationQueryById($id: String!) {
    tokens {
      primaryOffer(id: $id) {
        id
        anyCards {
          slug
          ...BuyPrimaryBuyConfirmation_anyCard
        }
        rewards {
          id
          ...BuyPrimaryBuyConfirmation_anyReward
        }
      }
    }
  }
  ${BuyPrimaryBuyConfirmation.fragments.anyCard}
  ${BuyPrimaryBuyConfirmation.fragments.anyReward}
` as TypedDocumentNode<
  BuyPrimaryBuyConfirmationQueryById,
  BuyPrimaryBuyConfirmationQueryByIdVariables
>;

export type Props = {
  primaryBuyId: string;
  options?: PrimaryBuyConfirmationOptions;
  onClose: () => void;
};

export const BuyPrimaryBuyConfirmationById = ({
  primaryBuyId,
  options,
  onClose,
}: Props) => {
  const { data, loading } = useQuery(BUY_PRIMARY_BUY_CONFIRMATION_QUERY, {
    variables: {
      id: idFromObject(primaryBuyId),
    },
    skip: !primaryBuyId,
  });

  if (!data || loading) return null;

  const {
    tokens: { primaryOffer },
  } = data;

  if (!primaryOffer) return null;

  const card = primaryOffer.anyCards?.[0];
  const { rewards } = primaryOffer;

  if (!card) return null;
  return (
    <BuyPrimaryBuyConfirmation
      card={card}
      rewards={rewards}
      options={options}
      onClose={onClose}
    />
  );
};

export default BuyPrimaryBuyConfirmationById;
