import { Backdrop } from '@material-ui/core';
import { Fragment, useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  CurrentHighlight,
  useOnboardingHighLightContext,
} from 'contexts/onboardingHighlight';
import { useResizeObserver } from 'hooks/ui/useResizeObserver';
import { theme } from 'style/theme';

const StyledBackdrop = styled(Backdrop)<{ blur?: number }>`
  z-index: ${theme.zIndex.backdrop};
  backdrop-filter: ${({ blur }) => `blur(${blur || 15}px)`};
`;

const AbsoluteSvg = styled.svg`
  position: absolute;
  inset: 0;
`;
const AboveBackdropDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.backdrop + 1};
  height: 100%;
  width: 100%;
`;
const AbsolutelyPositionedDiv = styled.div`
  position: absolute;
`;

const getDimensionsWithPadding = (highlight: CurrentHighlight) => {
  const { measureCallback, config } = highlight;
  const domRect = measureCallback?.();

  if (!domRect) {
    return {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    };
  }

  const { padding = 0, circle } = config || {};

  if (circle) {
    const { left, top, right, bottom } = domRect;
    const width = right - left;
    const height = bottom - top;
    const radius = Math.max(width / 2, height / 2);
    const beginY = top - radius + height / 2 - padding;
    const beginX = left - radius + width / 2 - padding;
    return {
      left: beginX,
      top: beginY,
      right: domRect.right + padding,
      bottom: domRect.bottom + padding,
    };
  }

  return {
    left: domRect.left - padding,
    top: domRect.top - padding,
    right: domRect.right + padding,
    bottom: domRect.bottom + padding,
  };
};

const getClipPathFromHighlight = (highlight: CurrentHighlight) => {
  const { left, top, right, bottom } = getDimensionsWithPadding(highlight);
  const { config } = highlight;
  const { borderRadius, circle } = config || {};

  if (circle) {
    const radius = (right - left) / 2;
    return `M${left + radius} ${top} A ${radius} ${radius} 180 0 1 ${
      left + radius
    } ${top + 2 * radius} A ${radius} ${radius} 180 0 1 ${
      left + radius
    } ${top}`;
  }

  if (borderRadius) {
    const borderArc = `${borderRadius} ${borderRadius} 90 0 1`;

    return `M${left} ${top + borderRadius} A ${borderArc} ${
      left + borderRadius
    } ${top} H${right - borderRadius} A ${borderArc} ${right} ${
      top + borderRadius
    } V${bottom - borderRadius} A ${borderArc} ${
      right - borderRadius
    } ${bottom} H${left + borderRadius} A ${borderArc} ${left} ${
      bottom - borderRadius
    } z`;
  }

  return `M${left} ${top} H${right} V${bottom} H${left} z`;
};
const getPathDefinition = (currentHighlights: CurrentHighlight[] | null) => {
  if (!currentHighlights) return '';

  const width = window.innerWidth;
  const height = window.innerHeight;

  return `M0 0 H${width} V${height} H0 z ${currentHighlights
    .map(getClipPathFromHighlight)
    .join(' ')}`;
};

type CurrentHighlightBackdropProps = {
  currentHighlights: CurrentHighlight[];
};
const CurrentHighlightBackdrop = ({
  currentHighlights,
}: CurrentHighlightBackdropProps) => {
  const id = useId();
  const [pathDefinition, setPathDefinition] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const newPathDefinition = getPathDefinition(currentHighlights);

  if (pathDefinition !== newPathDefinition) {
    setPathDefinition(newPathDefinition);
  }

  useResizeObserver(ref, () => {
    setPathDefinition(getPathDefinition(currentHighlights));
  });

  useEffect(() => {
    const recalculatePathDefinition = () => {
      setPathDefinition(getPathDefinition(currentHighlights));
    };
    window?.addEventListener('scroll', recalculatePathDefinition);
    return () => {
      window?.removeEventListener('scroll', recalculatePathDefinition);
    };
  }, [currentHighlights]);
  const blurValue = currentHighlights.find(highlight => highlight.config?.blur)
    ?.config?.blur;

  if (!currentHighlights?.length) {
    return null;
  }

  return (
    <div>
      <StyledBackdrop
        open
        style={{ clipPath: `url(#${id})` }}
        ref={ref}
        blur={blurValue}
      />
      <AbsoluteSvg width="0" height="0">
        <defs>
          <clipPath id={id}>
            <path clipRule="evenodd" d={pathDefinition} />
          </clipPath>
        </defs>
      </AbsoluteSvg>
      <AboveBackdropDiv
        onClick={() => {
          currentHighlights[
            currentHighlights.length - 1
          ].config?.onBackdropClick?.();
        }}
      >
        {currentHighlights.map((highlight, index) => {
          const { left, top, right, bottom } =
            getDimensionsWithPadding(highlight);
          const { config } = highlight;
          const { border, borderRadius, circle, renderSibling, onClick } =
            config || {};
          return (
            <Fragment key={highlight.name}>
              {(border || onClick) && (
                <AbsolutelyPositionedDiv
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    onClick?.();
                  }}
                  role={onClick ? 'button' : undefined}
                  aria-label={`Click ${highlight.name}`}
                  style={{
                    top,
                    left,
                    width: right - left,
                    height: circle ? right - left : bottom - top,
                    borderRadius: circle ? '50%' : borderRadius,
                    cursor: onClick ? 'pointer' : undefined,
                    border: border ? '2px solid var(--c-neutral-1000)' : 'none',
                  }}
                />
              )}
              {renderSibling?.({ left, top, right, bottom })}
            </Fragment>
          );
        })}
      </AboveBackdropDiv>
    </div>
  );
};

export const OnboardingHighlightBackdrop = () => {
  const { currentHighlights } = useOnboardingHighLightContext();

  if (!currentHighlights?.length) {
    return null;
  }

  return <CurrentHighlightBackdrop currentHighlights={currentHighlights} />;
};
