import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { Suspense, lazy, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import useScreenSize from 'hooks/device/useScreenSize';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { glossary } from 'lib/glossary';
import MenuIconButton from 'routing/MultiSportAppBar/MenuIconButton';

const MultiSportSearchBar = lazy(async () => import('../MultiSportSearchBar'));

const Top = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--unit);
  padding: var(--double-unit) var(--double-unit) 0;
  max-width: 100%;
`;

const Content = styled(Top)`
  position: relative;
  z-index: 100000;
  width: 300px;
  max-height: calc(
    var(--radix-popover-content-available-height, 100vh) - var(--double-unit)
  );
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--intermediate-unit);
  background-color: var(--c-neutral-200);
  margin-top: var(--unit);
  &:not(.isDesktop) {
    top: 0px !important;
    left: 0px !important;
    flex-shrink: unset;
    max-width: 100vw;
    max-height: var(--radix-popover-content-available-height, 100vh);
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    overflow: scroll;
  }
`;
export const ResponsiveSearchBar = () => {
  const { formatMessage } = useIntl();
  const { up: isDesktop } = useScreenSize('desktop');
  const [open, setOpen] = useState(false);
  const { postMessage, useNativeSearch } = useIsMobileApp();

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const toggleOpen = useCallback(() => {
    if (useNativeSearch) {
      postMessage('clickSearch');
    } else {
      setOpen(true);
    }
  }, [useNativeSearch, postMessage]);

  return (
    <Popover.Root
      open={open}
      onOpenChange={useNativeSearch ? undefined : setOpen}
      modal
    >
      <Popover.Trigger asChild>
        <MenuIconButton
          icon={faSearch}
          onClick={toggleOpen}
          active={open}
          aria-label={formatMessage(glossary.search)}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content style={{ zIndex: 30 }} asChild>
          <Content className={classNames({ isDesktop })}>
            {!isDesktop && (
              <Popover.Close asChild>
                <IconButton color="tertiary" icon={faCaretLeft} />
              </Popover.Close>
            )}
            <Suspense fallback={<LoadingIndicator />}>
              <MultiSportSearchBar onExit={!open} onSelect={onClose} />
            </Suspense>
          </Content>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ResponsiveSearchBar;
