import { ReactNode, createContext, useContext } from 'react';

export type ClaimRewardBoxComplete = {
  revealComplete: () => void;
};

const RevealCompleteContext = createContext<ClaimRewardBoxComplete | undefined>(
  undefined
);

export const useOnRevealComplete = () => {
  return useContext(RevealCompleteContext)!.revealComplete;
};

type Props = {
  children: ReactNode;
} & ClaimRewardBoxComplete;

export const RevealCompleteProvider = ({ children, revealComplete }: Props) => {
  return (
    <RevealCompleteContext.Provider value={{ revealComplete }}>
      {children}
    </RevealCompleteContext.Provider>
  );
};
