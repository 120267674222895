import { useCurrentUserContext } from 'contexts/currentUser';
import {
  useRedirectToSignin,
  useRedirectToSignup,
} from 'hooks/auth/useRedirectToLandingWithAction';

export const RequireAuth: React.FC<
  React.PropsWithChildren<{
    to?: string;
    redirectTo?: 'signin' | 'signup';
  }>
> = ({ children, to, redirectTo }) => {
  const { currentUser } = useCurrentUserContext();
  const redirectToSignin = useRedirectToSignin(to);
  const redirectToSignup = useRedirectToSignup(to);

  if (!currentUser) {
    return redirectTo === 'signup' ? redirectToSignup() : redirectToSignin();
  }

  return children;
};

export default RequireAuth;

export const requireAuth = (Component: React.FC) => {
  const RequireAuthWrapper: React.FC = () => (
    <RequireAuth>
      <Component />
    </RequireAuth>
  );
  return RequireAuthWrapper;
};
