import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, generatePath } from 'react-router-dom';

import {
  MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP,
  MLB_PLAY_PRO_FIXTURE_MY_LINEUPS,
} from '@sorare/core/src/constants/__generated__/routes';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import {
  PlayProChaseUserGroupRedirectionQuery,
  PlayProChaseUserGroupRedirectionQueryVariables,
} from './__generated__/page.graphql';
import { PageParams } from './__generated__/routeParams';

const query = gql`
  query PlayProChaseUserGroupRedirectionQuery($userGroupSlug: String!) {
    so5 {
      so5UserGroup(slug: $userGroupSlug) {
        slug
        latestSo5Leaderboard {
          slug
          so5Fixture {
            slug
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  PlayProChaseUserGroupRedirectionQuery,
  PlayProChaseUserGroupRedirectionQueryVariables
>;

const PlayProChaseUserGroupRedirectionPage = () => {
  const { userGroup: userGroupSlug } = useTypedParams<PageParams>();

  const { data } = useQuery(query, { variables: { userGroupSlug } });
  if (!data) {
    return null;
  }

  const userGroup = data?.so5?.so5UserGroup;
  const fixtureSlug = userGroup.latestSo5Leaderboard?.so5Fixture.slug;
  if (!userGroup || !fixtureSlug) {
    return (
      <Navigate
        to={generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS, {
          fixture: 'ongoing',
        })}
        replace
      />
    );
  }

  return (
    <Navigate
      to={generatePath(MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP, {
        fixture: fixtureSlug,
        userGroup: userGroupSlug,
      })}
      replace
    />
  );
};
export default PlayProChaseUserGroupRedirectionPage;
