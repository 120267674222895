import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  higherBid: {
    id: 'Notifications.higherBid',
    defaultMessage: 'Outbids',
  },
  cardAttached: {
    id: 'Notifications.cardAttached',
    defaultMessage: 'New Card in Collection',
  },
  shieldRewarded: {
    id: 'Notifications.shieldRewarded',
    defaultMessage: 'Club badge unlocked',
  },
  shieldDeprived: {
    id: 'Notifications.shieldDeprived',
    defaultMessage: 'Club badge lost',
  },
  cardBought: {
    id: 'Notifications.cardBought',
    defaultMessage: 'Cards bought',
  },
  cardWithdrawals: {
    id: 'Notifications.cardWithdrawals',
    defaultMessage: 'Card withdrawals',
  },
  externalDeposit: {
    id: 'Notifications.externalDeposit',
    defaultMessage: 'External deposit',
  },
  offerAccepted: {
    id: 'Notifications.offerAccepted',
    defaultMessage: 'Offers accepted',
  },
  offerRejected: {
    id: 'Notifications.offerRejected',
    defaultMessage: 'Offers rejected',
  },
  offerCancelled: {
    id: 'Notifications.offerCancelled',
    defaultMessage: 'Offers canceled',
  },
  offerWithdrawn: {
    id: 'Notifications.offerWithdrawn',
    defaultMessage: 'Offers withdrawn',
  },
  offerReceived: {
    id: 'Notifications.offerReceived',
    defaultMessage: 'Offers received',
  },
  offerCountered: {
    id: 'Notifications.offerCountered',
    defaultMessage: 'Counter offer received',
  },
  cardSold: {
    id: 'Notifications.cardSold',
    defaultMessage: 'Cards sold',
  },
  cardNotSold: {
    id: 'Notifications.cardsNotSold',
    defaultMessage: 'Cards not sold',
  },
  referralRewards: {
    id: 'Notifications.referralRewards',
    defaultMessage: 'Referral rewards',
  },
  so5LineupCancelled: {
    id: 'Notifications.so5LineupCancelled',
    defaultMessage: 'Lineup canceled',
  },
  kycRequests: {
    id: 'Notifications.kycRequests',
    defaultMessage: 'KYC requests',
  },
  rivalsGame: {
    id: 'Notifications.rivalsGame',
    defaultMessage: 'Rivals',
  },
  userRelation: {
    id: 'Notifications.userRelation',
    defaultMessage: 'Social relations',
  },
  managerProgression: {
    id: 'Notifications.managerProgression',
    defaultMessage: 'Manager progression',
  },
  rivalsManager: {
    id: 'Notifications.manager',
    defaultMessage: 'Rivals Manager',
  },
  auctionReminders: {
    id: 'Notifications.auctionReminders',
    defaultMessage: 'Auction Reminders',
  },
  shopItems: {
    id: 'Notifications.shopItems',
    defaultMessage: 'Shop Items',
  },
});

const notificationCategories = [
  'card_attached',
  'shield_rewarded',
  'shield_deprived',
  'card_bought',
  'card_not_sold',
  'card_sold',
  'card_withdrawal_started',
  'card_withdrawn',
  'eth_deposit_pending',
  'eth_deposit_finalized',
  'higher_bid',
  'offer_accepted',
  'offer_received',
  'offer_rejected',
  'offer_cancelled',
  'offer_withdrawn',
  'offer_countered',
  'referee_reward_ready_to_claim',
  'referrer_reward_ready_to_claim',
  'so5_lineup_cancelled',
  'appearance_destroyed',
  'kyc_request_validated',
  'kyc_request_refused',
  'invite_created',
  'invite_accepted',
  'starting_lineup_revealed',
  'friendly_challenges_started',
  'arena_challenges_started',
  'challenges_closed',
  'bot_invite_created',
  'user_relation_followed',
  'user_relation_revealed',
  'task_completed',
  'arena_unlocked',
  'auction_reminder_due',
  'auction_missed',
  'auction_ends_in_5_minutes',
  'auction_ending',
  'raffle_shop_item_won',
  'raffle_shop_item_lost',
] as const;

type NotificationCategory = (typeof notificationCategories)[number];

export const notificationCategoryTypes: {
  [category in NotificationCategory]: string;
} = {
  card_attached: 'CardCollectionNotification',
  shield_rewarded: 'CardCollectionNotification',
  shield_deprived: 'CardCollectionNotification',
  card_bought: 'AuctionNotification',
  card_not_sold: 'SaleNotification',
  card_sold: 'SaleNotification',
  card_withdrawal_started: 'CardNotification',
  card_withdrawn: 'CardNotification',
  eth_deposit_pending: 'ExternalDepositNotification',
  eth_deposit_finalized: 'ExternalDepositNotification',
  higher_bid: 'AuctionNotification',
  offer_accepted: 'OfferNotification',
  offer_received: 'OfferNotification',
  offer_rejected: 'OfferNotification',
  offer_cancelled: 'OfferNotification',
  offer_countered: 'OfferNotification',
  offer_withdrawn: 'OfferNotification',
  referee_reward_ready_to_claim: 'ReferralRewardNotification',
  referrer_reward_ready_to_claim: 'ReferralRewardNotification',
  so5_lineup_cancelled: 'So5LineupNotification',
  appearance_destroyed: 'So5LineupNotification',
  kyc_request_validated: 'KycRequestNotification',
  kyc_request_refused: 'KycRequestNotification',
  invite_created: 'Football::Rivals::GameNotification',
  invite_accepted: 'Football::Rivals::GameNotification',
  starting_lineup_revealed: 'Football::Rivals::GameNotification',
  friendly_challenges_started: 'Football::Rivals::GameNotification',
  arena_challenges_started: 'Football::Rivals::GameNotification',
  challenges_closed: 'Football::Rivals::GameNotification',
  bot_invite_created: 'Football::Rivals::GameNotification',
  user_relation_followed: 'UserRelationNotification',
  user_relation_revealed: 'UserRelationNotification',
  task_completed: 'ManagerProgressionNotification',
  arena_unlocked: 'Football::Rivals::ManagerNotification',
  auction_reminder_due: 'ReminderNotification',
  auction_missed: 'ReminderNotification',
  auction_ends_in_5_minutes: 'ReminderNotification',
  auction_ending: 'ReminderNotification',
  raffle_shop_item_won: 'ShopItemNotification',
  raffle_shop_item_lost: 'ShopItemNotification',
};

export const notificationGroups: {
  [group in keyof typeof messages]: NotificationCategory[];
} = {
  cardBought: ['card_bought'],
  higherBid: ['higher_bid'],
  cardSold: ['card_sold'],
  cardNotSold: ['card_not_sold'],
  externalDeposit: ['eth_deposit_pending', 'eth_deposit_finalized'],
  offerAccepted: ['offer_accepted'],
  offerReceived: ['offer_received'],
  offerRejected: ['offer_rejected'],
  offerCancelled: ['offer_cancelled'],
  offerWithdrawn: ['offer_withdrawn'],
  offerCountered: ['offer_countered'],
  cardAttached: ['card_attached'],
  shieldRewarded: ['shield_rewarded'],
  shieldDeprived: ['shield_deprived'],
  cardWithdrawals: ['card_withdrawal_started', 'card_withdrawn'],
  referralRewards: [
    'referee_reward_ready_to_claim',
    'referrer_reward_ready_to_claim',
  ],
  so5LineupCancelled: ['so5_lineup_cancelled', 'appearance_destroyed'],
  kycRequests: ['kyc_request_refused', 'kyc_request_validated'],
  rivalsGame: [
    'invite_created',
    'invite_accepted',
    'starting_lineup_revealed',
    'friendly_challenges_started',
    'arena_challenges_started',
    'challenges_closed',
    'bot_invite_created',
  ],
  userRelation: ['user_relation_followed', 'user_relation_revealed'],
  managerProgression: ['task_completed'],
  rivalsManager: ['arena_unlocked'],
  auctionReminders: [
    'auction_reminder_due',
    'auction_ends_in_5_minutes',
    'auction_ending',
    'auction_missed',
  ],
  shopItems: ['raffle_shop_item_won', 'raffle_shop_item_lost'],
};

export type NotificationGroup = keyof typeof notificationGroups;

export const allGroups = Object.keys(notificationGroups) as NotificationGroup[];
