import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

import { NBAConference } from '@sorare/core/src/__generated__/globalTypes';
import Bold from '@sorare/core/src/atoms/typography/Bold';
import { withFragments } from '@sorare/core/src/lib/gql';

import {
  SimpleRequirement,
  getAlgoliaFiltersForRequirements as getUsSportsAlgoliaFiltersForRequirements,
  getLeaderboardRequirements as getUsSportsLeaderboardRequirements,
} from '@sorare/us-sports/src/components/leaderboard/Requirements';

import { CONFERENCES } from 'lib/nba';

import {
  getAlgoliaFiltersForRequirements_NBALeaderboardRequirements,
  getLeaderboardRequirements_NBALeaderboardRequirements,
} from './__generated__/index.graphql';

const messages = defineMessages({
  budget: {
    id: 'Rules.budgetMVP',
    defaultMessage:
      '{allowMVP,select, true {Within <b>MVP + {budget}</b> points budget} other {Within <b>{budget}</b> points budget}}',
  },
  allowedConference: {
    id: 'Rules.allowedConference',
    defaultMessage: 'Allowed players from: <b>{conference}</b>',
  },
  easternConference: {
    id: 'Rules.allowedConference.eastern',
    defaultMessage: 'Eastern Conference',
  },
  westernConference: {
    id: 'Rules.allowedConference.western',
    defaultMessage: 'Western Conference',
  },
  playerAgeMin: {
    id: 'Rules.playerAge.min',
    defaultMessage:
      'Players must be <strong>at least {min}</strong> years old.',
  },
  playerAgeMax: {
    id: 'Rules.playerAge.max',
    defaultMessage: 'Players must be <strong>at most {max}</strong> years old.',
  },
  playerAgeMinMax: {
    id: 'Rules.playerAge.minMax',
    defaultMessage:
      'Players must be <strong>between {min} and {max}</strong> years old.',
  },
});

export const BudgetRequirement = ({
  budget,
  allowMVP,
}: {
  budget: number;
  allowMVP: boolean;
}) => {
  return (
    <SimpleRequirement
      message={messages.budget}
      values={{
        budget,
        allowMVP: allowMVP.toString(),
      }}
    />
  );
};

export const ConferenceRequirement = ({
  allowedConference,
}: {
  allowedConference: NBAConference;
}) => {
  const { formatMessage } = useIntl();
  return (
    <SimpleRequirement
      message={messages.allowedConference}
      values={{
        conference: formatMessage(
          allowedConference === NBAConference.EASTERN
            ? messages.easternConference
            : messages.westernConference
        ),
      }}
    />
  );
};

export const PlayerAgeRequirement = ({
  min,
  max,
}: {
  min: number | null;
  max: number | null;
}) => {
  let message: MessageDescriptor = messages.playerAgeMinMax;
  if (min === null) {
    message = messages.playerAgeMax;
  }
  if (max === null) {
    message = messages.playerAgeMin;
  }

  return (
    <SimpleRequirement
      message={message}
      values={{
        min,
        max,
        strong: Bold,
      }}
    />
  );
};

export const getLeaderboardRequirements = withFragments(
  (
    requirements: getLeaderboardRequirements_NBALeaderboardRequirements
  ): ReactNode[] => {
    const returnValue = getUsSportsLeaderboardRequirements(requirements);

    if (requirements.tenGameAverageTotalLimit > 0) {
      returnValue.push(
        <BudgetRequirement
          budget={requirements.tenGameAverageTotalLimit}
          allowMVP={requirements.allowMVP}
        />
      );
    }

    if (requirements.allowedConference) {
      returnValue.push(
        <ConferenceRequirement
          allowedConference={requirements.allowedConference}
        />
      );
    }
    if (requirements.playerAgeRequirements) {
      returnValue.push(
        <PlayerAgeRequirement
          min={requirements.playerAgeRequirements.minAge}
          max={requirements.playerAgeRequirements.maxAge}
        />
      );
    }
    return returnValue;
  },
  {
    NBALeaderboardRequirements: gql`
      fragment getLeaderboardRequirements_NBALeaderboardRequirements on NBALeaderboardRequirements {
        tenGameAverageTotalLimit
        allowMVP
        allowedConference
        playerAgeRequirements {
          minAge
          maxAge
        }
        ...getLeaderboardRequirements_LeaderboardRequirementsInterface
      }
      ${getUsSportsLeaderboardRequirements.fragments
        .LeaderboardRequirementsInterface}
    ` as TypedDocumentNode<getLeaderboardRequirements_NBALeaderboardRequirements>,
  }
);

export const getAlgoliaFiltersForRequirements = withFragments(
  (
    requirements: getAlgoliaFiltersForRequirements_NBALeaderboardRequirements
  ) => {
    const returnValue: {
      team?: string;
    } = {};

    if (requirements.allowedConference) {
      returnValue.team = CONFERENCES[requirements.allowedConference]
        .map(t => t.name)
        .join(',');
    }

    return {
      ...getUsSportsAlgoliaFiltersForRequirements(requirements),
      ...returnValue,
    };
  },
  {
    NBALeaderboardRequirements: gql`
      fragment getAlgoliaFiltersForRequirements_NBALeaderboardRequirements on NBALeaderboardRequirements {
        allowedConference
        ...getAlgoliaFiltersForRequirements_LeaderboardRequirementsInterface
      }
      ${getUsSportsAlgoliaFiltersForRequirements.fragments
        .LeaderboardRequirementsInterface}
    ` as TypedDocumentNode<getAlgoliaFiltersForRequirements_NBALeaderboardRequirements>,
  }
);
