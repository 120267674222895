import { ReactNode } from 'react';

import { Container } from '@sorare/core/src/atoms/layout/Container';
import Gap from '@sorare/core/src/components/marketing/Gap';
import MarketingPage from '@sorare/core/src/components/marketing/MarketingPage';
import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import { EnsureTopVisibleOnMount } from '@sorare/core/src/routing/EnsureTopVisibleOnMount';
import { breakpoints } from '@sorare/core/src/style/mediaQuery';

import { Form } from 'components/Form';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <AppLayout>
      <MarketingPage>
        <Container maxWidth={breakpoints.mediumDesktop}>
          <Gap size="md" />
          <EnsureTopVisibleOnMount behavior="auto">
            {children}
            <Form />
          </EnsureTopVisibleOnMount>
          <Gap size="md" />
        </Container>
      </MarketingPage>
    </AppLayout>
  );
};

export default Layout;
