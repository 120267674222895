import { NBAFixture } from '@sorare/core/src/__generated__/globalTypes';

export type PatchedNBAFixture = {
  roundTitle?: string;
} & Pick<NBAFixture, 'slug' | 'gameWeek' | 'startDate' | 'endDate'> &
  Pick<Partial<NBAFixture>, 'fixtureState' | 'leaderboards'>;

export const getPlayoffGameWeeks = (): Record<string, PatchedNBAFixture> => ({
  'nba-2023-gameweek-49': {
    slug: 'nba-2023-gameweek-49',
    gameWeek: 49,
    startDate: new Date('2024-04-16T23:20:00Z'),
    endDate: new Date('2024-04-20T01:00:00Z'),
    roundTitle: 'Play In',
  },
  'nba-2023-gameweek-50': {
    slug: 'nba-2023-gameweek-50',
    gameWeek: 50,
    startDate: new Date('2024-04-20T16:50:00Z'),
    endDate: new Date('2024-04-26T02:00:00Z'),
    roundTitle: 'First Round (1)',
  },
  'nba-2023-gameweek-51': {
    slug: 'nba-2023-gameweek-51',
    gameWeek: 51,
    startDate: new Date('2024-04-26T21:20:00Z'),
    endDate: new Date('2024-05-06T08:00:00Z'),
    roundTitle: 'First Round (2)',
  },
  'nba-2023-gameweek-52': {
    slug: 'nba-2023-gameweek-52',
    gameWeek: 52,
    startDate: new Date('2024-05-06T22:00:00Z'),
    endDate: new Date('2024-05-13T00:00:00Z'),
    roundTitle: 'Conference Semifinals (1)',
  },
  'nba-2023-gameweek-53': {
    slug: 'nba-2023-gameweek-53',
    gameWeek: 53,
    startDate: new Date('2024-05-13T00:00:00Z'),
    endDate: new Date('2024-05-21T00:00:00Z'),
    roundTitle: 'Conference Semifinals (2)',
  },
  'nba-2023-gameweek-54': {
    slug: 'nba-2023-gameweek-54',
    gameWeek: 54,
    startDate: new Date('2024-05-21T00:00:00Z'),
    endDate: new Date('2024-05-27T00:00:00Z'),
    roundTitle: 'Conference Finals (1)',
  },
  'nba-2023-gameweek-55': {
    slug: 'nba-2023-gameweek-55',
    gameWeek: 55,
    startDate: new Date('2024-05-27T23:50:00Z'),
    endDate: new Date('2024-05-31T12:30:00Z'),
    roundTitle: 'Conference Finals (2)',
  },
  'nba-2023-gameweek-56': {
    slug: 'nba-2023-gameweek-56',
    gameWeek: 56,
    startDate: new Date('2024-06-07T00:20:00Z'),
    endDate: new Date('2024-06-24T00:00:00Z'),
    roundTitle: 'Finals',
  },
});
