import { ReactNode } from 'react';
import { useMeasure } from 'react-use';

const useMeasureHeaderHeight = (header: ReactNode) => {
  const [measurementDiv, { height }] = useMeasure<HTMLDivElement>();

  return {
    measurementDiv,
    height,
    measurementReady: header === null || header === undefined || height > 0,
  };
};

export default useMeasureHeaderHeight;
