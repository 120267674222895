import { Outlet } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import SportProvider from '@sorare/core/src/contexts/sport/Provider';
import { NotFound, getRoutesFromFiles } from '@sorare/core/src/lib/routing';

import CurrentNBAUserProvider from 'contexts/currentNBAUser/Provider';
import { NBAMarketSubscriptions } from 'routing/NBAMarketSubscriptions';

export const routeObjects = [
  {
    element: (
      <SportProvider sport={Sport.NBA}>
        <CurrentNBAUserProvider>
          <NBAMarketSubscriptions />
          <Outlet />
        </CurrentNBAUserProvider>
      </SportProvider>
    ),
    children: [
      ...getRoutesFromFiles(import.meta.glob('./**/(page|layout).tsx')),
      { path: '*', element: <NotFound /> },
    ],
  },
];
