import { faCreditCardAlt } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import PaymentMethodIcon from 'components/paymentMethods/Icon';
import useFeatureFlags from 'hooks/useFeatureFlags';

const Row = styled(Horizontal).attrs({ gap: 1.5 })``;

export const NewCreditCard = () => {
  const {
    flags: { usePaymentBoxRevamp = false },
  } = useFeatureFlags();
  return (
    <Row>
      <PaymentMethodIcon icon={faCreditCardAlt} />
      <Text16 color="var(--c-neutral-1000)">
        {usePaymentBoxRevamp ? (
          <FormattedMessage
            id="NewPaymentBox.methods.newCreditCard"
            defaultMessage="New credit card"
          />
        ) : (
          <FormattedMessage
            id="NewPaymentBox.methods.addCreditCard"
            defaultMessage="Credit / Debit card"
          />
        )}
      </Text16>
    </Row>
  );
};
export default NewCreditCard;
