import { TypedDocumentNode, gql } from '@apollo/client';

import {
  Score,
  Props as ScoreProps,
} from '@sorare/core/src/components/scoring/Score';

import { So5AppearanceScore_so5Appearance } from './__generated__/index.graphql';

type Props = {
  so5Appearance: So5AppearanceScore_so5Appearance;
} & Pick<
  ScoreProps,
  'withDate' | 'withTooltip' | 'hideReviewing' | 'forceScoreStatus'
>;

export const So5AppearanceScore = ({ so5Appearance, ...rest }: Props) => {
  const { score, scoreStatus, upcomingGame, playedGames } = so5Appearance;

  return (
    <Score
      score={score}
      scoreStatus={scoreStatus}
      upcomingGame={upcomingGame || undefined}
      hasPlayedGames={playedGames.length > 0}
      {...rest}
    />
  );
};

So5AppearanceScore.fragments = {
  so5Appearance: gql`
    fragment So5AppearanceScore_so5Appearance on So5Appearance {
      id
      score
      scoreStatus
      upcomingGame {
        id
        ...Score_anyGameInterface
      }
      playedGames {
        id
      }
    }
    ${Score.fragments.anyGameInterface}
  ` as TypedDocumentNode<So5AppearanceScore_so5Appearance>,
};
