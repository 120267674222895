import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import footballLogo from 'assets/logos/football/main.svg';
import mlbLogo from 'assets/logos/mlb/mlb.svg';
import nbaLogo from 'assets/logos/nba/nba.svg';
import Button from 'atoms/buttons/Button';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Container } from 'atoms/layout/Container';
import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { LinkOther } from 'atoms/navigation/Box';
import SmallerStarBall from 'atoms/navigation/SmallerStarBall';
import ResponsiveSearchBar from 'components/search/ResponsiveSearchBar';
import { LANDING } from 'constants/__generated__/routes';
import {
  FOOTBALL_PATH,
  MLB_PATH,
  NBA_PATH,
  RIVALS_LANDING,
} from 'constants/routes';
import { useConnectionContext } from 'contexts/connection';
import useScreenSize from 'hooks/device/useScreenSize';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { glossary, navLabels, sportsLabelsMessages } from 'lib/glossary';
import AppBarProvider from 'routing/MultiSportAppBar/context/Provider';
import { breakpoints, laptopAndAbove, mobileAndAbove } from 'style/mediaQuery';

import rivalsLogo from './assets/rivalsYellowIcon.svg';

const Wrapper = styled(SBHorizontal).attrs({ gap: 0 })`
  z-index: 1;
  position: relative;
  margin: auto;
  width: 100%;
  height: var(--navbar-height-mobile);
`;

const SportsLinks = styled.div`
  display: flex;
  flex: 1;
  gap: var(--unit);
`;

const Logo = styled(Link)`
  display: flex;
  gap: var(--unit);
  align-items: center;
  @media ${mobileAndAbove} {
    margin-right: var(--double-unit);
  }
`;

const SportButton = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: var(--unit);
  justify-content: space-between;
  @media ${laptopAndAbove} {
    padding: var(--unit);
    &:hover {
      border-radius: var(--unit);
      background-color: var(--c-static-neutral-800);
    }
  }
`;

const MobileSportButtonsWrapper = styled(SBHorizontal)`
  padding: 0 0 var(--unit);
  overflow: auto;
`;

const ConnexionButtons = () => {
  const { signIn, signUp } = useConnectionContext();
  return (
    <Horizontal center>
      <Button size="small" color="primary" onClick={signUp}>
        <FormattedMessage {...glossary.signup} />
      </Button>
      <Button size="small" color="tertiary" onClick={signIn}>
        <FormattedMessage {...glossary.signin} />
      </Button>
    </Horizontal>
  );
};

const SportButtons = () => {
  const {
    flags: { useRivalsLanding = false },
  } = useFeatureFlags();
  return (
    <>
      {useRivalsLanding && (
        <LinkOther as={SportButton} to={RIVALS_LANDING}>
          <FormattedMessage {...navLabels.rivals} />
          <img src={rivalsLogo} alt="" width="24px" height="24px" />
        </LinkOther>
      )}
      <LinkOther as={SportButton} to={FOOTBALL_PATH}>
        <FormattedMessage {...sportsLabelsMessages.FOOTBALL} />
        <img src={footballLogo} alt="" width="24px" height="24px" />
      </LinkOther>
      <LinkOther as={SportButton} to={NBA_PATH}>
        <FormattedMessage {...sportsLabelsMessages.NBA} />
        <img src={nbaLogo} alt="" width="10px" height="23px" />
      </LinkOther>
      <LinkOther as={SportButton} to={MLB_PATH}>
        <FormattedMessage {...sportsLabelsMessages.BASEBALL} />
        <img src={mlbLogo} alt="" width="30px" height="17px" />
      </LinkOther>
    </>
  );
};

export const LandingAppHeader = () => {
  const { up: isMobile } = useScreenSize('mobile');
  const { up: isLaptop } = useScreenSize('laptop');
  return (
    <Container maxWidth={breakpoints.mediumDesktop}>
      <Vertical gap={0}>
        <Wrapper>
          <SportsLinks>
            <Logo to={LANDING} title="Sorare">
              <SmallerStarBall />
              {isMobile && <SorareLogo />}
            </Logo>
            {isLaptop && <SportButtons />}
          </SportsLinks>
          <AppBarProvider>
            <ResponsiveSearchBar />
          </AppBarProvider>
          <ConnexionButtons />
        </Wrapper>
        {!isLaptop && (
          <MobileSportButtonsWrapper gap={2}>
            <SportButtons />
          </MobileSportButtonsWrapper>
        )}
      </Vertical>
    </Container>
  );
};
