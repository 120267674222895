import { FOOTBALL_PLAY_RIVALS_LEAGUES_INVITE_JOINSECRET } from '@sorare/core/src/constants/__generated__/routes';
import { Navigate } from '@sorare/core/src/routing/Navigate';

// TODO: this redirection support is kept for backward compatibility during internal test
// Remove this file once the new invite url is widespread
const InviteEJoinSecretPage = () => {
  return (
    <Navigate
      replace
      to={FOOTBALL_PLAY_RIVALS_LEAGUES_INVITE_JOINSECRET}
      keepParams
    />
  );
};
export default InviteEJoinSecretPage;
