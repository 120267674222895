import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Button from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { glossary } from '@sorare/core/src/lib/glossary';

const Header = styled.header`
  display: grid;
  grid-template-areas: 'previous title close';
  grid-template-columns: max-content 1fr max-content;
  color: var(--c-neutral-1000);
`;

const CloseButton = styled(Button)`
  grid-area: close;
`;

type Props = {
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
};
export const StepHeader = ({ onClose, children, className }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Header className={className}>
      {children}
      <CloseButton
        size="medium"
        color="quaternary"
        onClick={onClose}
        aria-label={formatMessage(glossary.close)}
      >
        <FontAwesomeIcon color="black" icon={faTimes} size="lg" />
      </CloseButton>
    </Header>
  );
};
