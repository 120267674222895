import { Outlet } from 'react-router-dom';

import { getRoutesFromFiles } from '@sorare/core/src/lib/routing';

import Layout from 'components/Layout';

export const routeObjects = [
  {
    path: 'blog',
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      ...getRoutesFromFiles(import.meta.glob('./**/(page|layout).tsx')),
    ],
  },
];
