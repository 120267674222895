import { ChangeEvent, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Color } from 'style/types';

type Props = Pick<InputHTMLAttributes<HTMLInputElement>, 'name' | 'id'> & {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  $backgroundColor?: Color;
  name?: string;
  disabled?: boolean;
};

const StyledInput = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;
`;
const StyledLabel = styled.label<{
  $backgroundColor?: Color;
}>`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 24px;
  background: var(--c-neutral-400);
  display: block;
  border-radius: 24px;
  position: relative;
  flex-shrink: 0;
  ${({ $backgroundColor }) =>
    $backgroundColor
      ? css`
          background: ${$backgroundColor};
        `
      : ''}

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    transform: translateX(0px);
    width: 20px;
    height: 20px;
    background: var(--c-neutral-1000);
    border-radius: 20px;
    transition: transform 0.1s ease-in-out;
  }

  &[aria-checked='true'] {
    background: var(--c-brand-600);
  }

  &[aria-checked='true']:after {
    transform: translateX(calc(100% - 4px));
  }
`;

const Switch = ({
  checked,
  onChange,
  $backgroundColor,
  name,
  ...rest
}: Props) => {
  return (
    <StyledLabel
      $backgroundColor={$backgroundColor}
      role="checkbox"
      aria-checked={checked}
    >
      <StyledInput
        onChange={onChange}
        name={name}
        checked={checked}
        {...rest}
      />
    </StyledLabel>
  );
};

export default Switch;
