import { useCallback, useMemo, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import LoadingButton from 'atoms/buttons/LoadingButton';
import { Vertical } from 'atoms/layout/flex';
import { Caption } from 'atoms/typography';
import { useSendDeviceConfirmation } from 'components/devices/DeviceNeedsConfirming/SendDeviceConfirmation/useSendDeviceConfirmation';
import Dialog from 'components/dialog';
import { Input } from 'components/form/Form/IntlTelInput';
import useVerifyPhoneNumber, {
  canProceedToVerificationCodeInput,
} from 'components/user/VerifyPhoneNumber/useVerifyPhoneNumber';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useWalletContext } from 'contexts/wallet';

import EnterVerificationCode from '../EnterVerificationCode';
import Header from '../Header';
import PhoneNumberVerificationButton from '../PhoneNumberVerificationButton';
import SendVerificationCode from '../SendVerificationCode';
import messages from '../i18n';
import { close, completed, enterVerificationCode, openDialog } from './actions';
import reducer, { init } from './reducer';
import {
  InputPhoneNumberState,
  InputVerificationCodeState,
  State,
} from './types';

const HeaderWrapper = styled.div`
  padding: var(--triple-unit) var(--triple-unit) 0 var(--triple-unit);
`;
const Body = styled.div`
  padding: var(--unit);
`;
const Wrapper = styled.div<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const StyledButton = styled(LoadingButton)`
  align-self: flex-start;
`;

interface AddOrChangePhoneNumberProps {
  onClick: () => void;
}

const verificationPhoneSectionReducer = reducer();

const AddOrChangePhoneNumber = ({ onClick }: AddOrChangePhoneNumberProps) => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) return null;

  if (currentUser.phoneNumber) {
    return (
      <>
        <Input phone={currentUser.phoneNumber} noBorder disabled />
        <PhoneNumberVerificationButton
          message={messages.changePhoneNumber}
          onClick={onClick}
        />
      </>
    );
  }
  return (
    <PhoneNumberVerificationButton
      message={messages.addPhoneNumber}
      onClick={onClick}
    />
  );
};

const isInputPhoneNumberStatus = (
  status: State
): status is InputPhoneNumberState => status.stage === 'input_number';

const isEnterVerificationCodeStatus = (
  status: State
): status is InputVerificationCodeState =>
  status.stage === 'enter_verification_code';

const PhoneNumberVerificationSection = () => {
  const { sendDeviceConfirmation, sending } = useSendDeviceConfirmation();
  const {
    currentUser,
    emailConfirmationNeeded,
    currentDeviceConfirmationNeeded,
  } = useCurrentUserContext();
  const [state, dispatch] = useReducer(
    verificationPhoneSectionReducer,
    {
      currentUser: currentUser!,
    },
    init
  );

  const switchToInputNumber = useCallback(() => {
    dispatch(openDialog());
  }, []);
  const verifyPhoneNumber = useVerifyPhoneNumber();
  const { checkUserPhoneNumberVerificationCodeWithRecovery } =
    useWalletContext();

  const sendVerificationCode = useCallback(
    async (phoneNumber: any) =>
      verifyPhoneNumber(phoneNumber).then(async res => {
        if (canProceedToVerificationCodeInput(res)) {
          dispatch(enterVerificationCode(phoneNumber));
        }
        return Promise.resolve(res || []);
      }),
    [verifyPhoneNumber]
  );

  const submitVerificationCode = useCallback(
    async (verificationCode: any) =>
      checkUserPhoneNumberVerificationCodeWithRecovery(verificationCode).then(
        async res => {
          if (!res || res.length === 0) {
            dispatch(completed());
          }
          return res || [];
        }
      ),
    [checkUserPhoneNumberVerificationCodeWithRecovery]
  );

  const goBackCb = useMemo(() => {
    const { goBack } = state;
    if (goBack) {
      return () => {
        goBack(dispatch);
      };
    }
    return undefined;
  }, [state]);

  const closeCb = useMemo(() => {
    const { close: dialogClose } = state;
    if (dialogClose) {
      return () => {
        dialogClose(dispatch);
      };
    }
    return undefined;
  }, [state]);

  return (
    <Vertical>
      {emailConfirmationNeeded && (
        <Caption color="var(--c-red-300)">
          <FormattedMessage
            id="PhoneNumberVerificationSection.confirmEmailBeforeAddingPhoneNumber"
            defaultMessage="Please confirm your email address prior to adding a phone number."
          />
        </Caption>
      )}
      {currentDeviceConfirmationNeeded && (
        <Vertical>
          <Caption color="var(--c-red-300)">
            <FormattedMessage
              id="PhoneNumberVerificationSection.confirmDeviceBeforeAddingPhoneNumber"
              defaultMessage="Please confirm your device prior to adding a phone number."
            />
          </Caption>
          <StyledButton
            size="compact"
            onClick={sendDeviceConfirmation}
            loading={sending}
          >
            <FormattedMessage
              id="PhoneNumberVerificationSection.confirmDeviceBeforeAddingPhoneNumberCta"
              defaultMessage="Resend device confirmation email"
            />
          </StyledButton>
        </Vertical>
      )}
      <Wrapper
        disabled={emailConfirmationNeeded || currentDeviceConfirmationNeeded}
      >
        <AddOrChangePhoneNumber onClick={switchToInputNumber} />
        {state.stage !== 'status' && (
          <Dialog open maxWidth="xs" fullWidth hideHeader>
            <Vertical gap={0}>
              <HeaderWrapper>
                <Header onBack={goBackCb} onClose={closeCb} />
              </HeaderWrapper>
              <Body>
                {isInputPhoneNumberStatus(state) && (
                  <SendVerificationCode
                    sendVerificationCode={sendVerificationCode}
                  />
                )}
                {isEnterVerificationCodeStatus(state) && (
                  <EnterVerificationCode
                    resendVerificationCode={async () =>
                      verifyPhoneNumber(state.unverifiedPhoneNumber).then(
                        res => res || []
                      )
                    }
                    phoneNumber={state.unverifiedPhoneNumber}
                    submitVerificationCode={submitVerificationCode}
                    onCancel={close}
                  />
                )}
              </Body>
            </Vertical>
          </Dialog>
        )}
      </Wrapper>
    </Vertical>
  );
};

export default PhoneNumberVerificationSection;
