import { Color } from 'style/types';

export const playerScoreThresholds = [
  [14.5, 'veryLow'],
  [29.5, 'low'],
  [44.5, 'mediumLow'],
  [59.5, 'medium'],
  [74.5, 'mediumHigh'],
] as const;

export const thresholds = [
  [24.5, 'veryLow'],
  [39.5, 'low'],
  [59.5, 'mediumLow'],
  [74.5, 'medium'],
  [89.5, 'mediumHigh'],
] as const;

export const THRESHOLD_COLORS: { [key: string]: Color } = {
  veryLow: 'var(--c-score-veryLow)',
  low: 'var(--c-score-low)',
  mediumLow: 'var(--c-score-mediumLow)',
  medium: 'var(--c-score-medium)',
  mediumHigh: 'var(--c-score-mediumHigh)',
  high: 'var(--c-score-high)',
};

export type ScoreType = 'CARD_SCORE' | 'PLAYER_SCORE';

export const findThreshold = (
  score: number,
  type: ScoreType = 'PLAYER_SCORE'
) => {
  const values = type === 'PLAYER_SCORE' ? playerScoreThresholds : thresholds;
  const threshold = values.find(t => Number(t[0]) > score);

  return threshold ? threshold[1] : 'high';
};
