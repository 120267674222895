import { faTimes, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Popup } from 'atoms/layout/Popup';
import { Horizontal } from 'atoms/layout/flex';
import { Text14 } from 'atoms/typography';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

const Wrapper = styled(Horizontal).attrs({ gap: 2 })`
  color: var(--c-neutral-1000);
  padding: var(--double-unit);
  border-radius: var(--unit);
  border: 1px solid var(--c-neutral-400);
  background: var(--c-neutral-300);
`;

const OfflineIcon = styled.div`
  opacity: 0.7;
`;

const Message = styled(Text14)`
  flex: 1;
`;

const CloseButton = styled.div`
  align-self: flex-start;
`;

const NetworkInfo = () => {
  const [offline, setIsOffline] = useState(false);
  const [shouldBeClosed, setShouldBeClosed] = useState(false);

  const { isMobileApp } = useIsMobileApp();

  useEffect(() => {
    function onlineHandler() {
      setIsOffline(false);
    }

    function offlineHandler() {
      setIsOffline(true);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  if (isMobileApp) {
    return null;
  }

  if (!offline) {
    if (shouldBeClosed) {
      setShouldBeClosed(false);
    }
    return null;
  }

  if (shouldBeClosed) {
    return null;
  }

  return (
    <Popup>
      <Wrapper>
        <OfflineIcon>
          <FontAwesomeIcon icon={faWifiSlash} />
        </OfflineIcon>
        <Message>
          <FormattedMessage
            id="NetworkInfo.message"
            defaultMessage="You're offline. Most actions might not work"
          />
        </Message>
        <CloseButton>
          <IconButton
            color="transparent"
            small
            icon={faTimes}
            onClick={() => {
              setShouldBeClosed(true);
            }}
          />
        </CloseButton>
      </Wrapper>
    </Popup>
  );
};

export default NetworkInfo;
