import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { ScarcityBackground } from '@sorare/core/src/atoms/layout/ScarcityBackground';
import UninteractiveToken from '@sorare/core/src/components/token/UninteractiveToken';
import { CARD_BORDER_RADIUS } from '@sorare/core/src/lib/cards';
import { absCenter } from '@sorare/core/src/lib/style';

import { PrimaryOfferTokensPreview_anyCard } from './__generated__/index.graphql';

const RemainingCardsPlaceholderWrapper = styled.div`
  width: 100%;
`;

const RemainingCardsPlaceholder = styled(ScarcityBackground)`
  width: 100%;
  height: auto;
  position: relative;
  border-radius: ${CARD_BORDER_RADIUS};

  aspect-ratio: var(--card-aspect-ratio);
`;

const RemainingCardsText = styled.p<{ parentWidth?: number }>`
  --baseFontSize: 36px;
  --baseCardWidth: 90;

  ${absCenter}
  line-height: 100%;
  font-weight: var(--t-bold);
  color: rgba(0, 0, 0, 0.85);
  font-size: calc(
    var(--baseFontSize) * ${({ parentWidth }) => parentWidth} /
      var(--baseCardWidth)
  );
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1);
`;

type Props = {
  cards: PrimaryOfferTokensPreview_anyCard[];
};

export const PrimaryOfferTokensPreview = ({ cards }: Props) => {
  const offerSize = cards.length;
  const [measurementDiv, { width: remainingCardsPlaceholderWidth }] =
    useMeasure<HTMLDivElement>();

  // We want to display a maximum of 5 cards in the preview
  const cardsPreview = useMemo(() => {
    if (offerSize > 5) {
      return cards.slice(0, 4);
    }
    return cards;
  }, [cards, offerSize]);

  const nbRemainingCards = offerSize - cardsPreview.length;

  return (
    <>
      {cardsPreview.map((card, index) => (
        <UninteractiveToken
          card={card}
          key={card.slug}
          {...(index > 0 && { width: 160 })}
        />
      ))}
      {nbRemainingCards > 0 && (
        <RemainingCardsPlaceholderWrapper ref={measurementDiv}>
          <RemainingCardsPlaceholder
            className={classNames(cards[4]?.sport, cards[4]?.rarityTyped)}
          >
            <RemainingCardsText parentWidth={remainingCardsPlaceholderWidth}>
              +{nbRemainingCards}
            </RemainingCardsText>
          </RemainingCardsPlaceholder>
        </RemainingCardsPlaceholderWrapper>
      )}
    </>
  );
};

PrimaryOfferTokensPreview.fragments = {
  anyCard: gql`
    fragment PrimaryOfferTokensPreview_anyCard on AnyCardInterface {
      slug
      sport
      collection
      rarityTyped
      ...UninteractiveToken_anyCard
    }
    ${UninteractiveToken.fragments.anyCard}
  ` as TypedDocumentNode<PrimaryOfferTokensPreview_anyCard>,
};
