import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import {
  faExclamationCircle,
  faWallet,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Vertical } from 'atoms/layout/flex';
import { Caption, Text14, Text16 } from 'atoms/typography';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlContext } from 'contexts/intl';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import useWalletNeedsRecover from 'hooks/recovery/useWalletNeedsRecover';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import useOAuthEthWallet from 'hooks/wallets/useOAuthEthWallet';
import { minAmountToDeposit } from 'lib/ethereum';
import { RoundingMode } from 'lib/wei';

type Props = {
  compact?: boolean;
  onClick?: () => void;
};

type WalletButtonProps = {
  currentUser: NonNullable<
    ReturnType<typeof useCurrentUserContext>['currentUser']
  >;
} & Props;

const Column = styled(Vertical).attrs({ gap: 0 })`
  align-items: flex-end;
  &:not(:last-child) {
    padding-right: var(--intermediate-unit);
    margin-right: var(--half-unit);
    border-right: 1px solid var(--c-static-neutral-400);
  }
`;

const StyledCaption = styled(Caption)`
  line-height: 1;
`;
const StyledText14 = styled(Text14)`
  line-height: 1;
`;

const WalletButtons = ({
  currentUser,
  compact,
  onClick,
}: WalletButtonProps) => {
  const { formatNumber, formatWei } = useIntlContext();
  const { availableBalance, sorareAddressBalance } = currentUser;
  const {
    walletPreferences: { showEthWallet, showFiatWallet },
  } = useCurrentUserContext();

  const {
    canListAndTrade,
    availableBalance: availableFiatBalance,
    fiatCurrency,
  } = useFiatBalance();
  const { toggleDrawer } = useWalletDrawerContext();

  const openWallet = useCallback(() => {
    toggleDrawer();
    onClick?.();
  }, [onClick, toggleDrawer]);

  const emptyEthWallet = availableBalance === 0n;
  const emptyFiatWallet = !canListAndTrade || availableFiatBalance === 0;

  const showBalances =
    (showEthWallet && !emptyEthWallet) || (showFiatWallet && !emptyFiatWallet);

  if (!showBalances || currentUser.userSettings?.hideBalance) {
    return <IconButton icon={faWallet} onClick={openWallet} color="opa" />;
  }

  const renderFiatBalance = () => (
    <StyledText14 bold>
      {formatNumber(availableFiatBalance, {
        style: 'currency',
        currency: fiatCurrency,
      })}
    </StyledText14>
  );

  const renderWithBadge = (children: ReactNode) => (
    <ConditionalWrapper
      wrap={sorareAddressBalance > minAmountToDeposit}
      Wrapper={MUIBadge}
      props={{ variant: 'dot' }}
    >
      {children}
    </ConditionalWrapper>
  );

  const renderBalances = () => {
    if (showEthWallet && showFiatWallet)
      return (
        <>
          <Column>
            <StyledCaption>
              <FormattedMessage
                id="appbar.balances.cash"
                defaultMessage="Cash"
              />
              {renderFiatBalance()}
            </StyledCaption>
          </Column>
          <Column>
            <StyledCaption>
              <FormattedMessage id="appbar.balances.eth" defaultMessage="ETH" />
            </StyledCaption>
            {renderWithBadge(
              <StyledText14 bold>
                {formatWei(
                  availableBalance,
                  RoundingMode.ROUND_DOWN,
                  { minimumFractionDigits: 3 },
                  { hideSymbol: showEthWallet && showFiatWallet }
                )}
              </StyledText14>
            )}
          </Column>
        </>
      );
    if (showEthWallet)
      return renderWithBadge(
        <Text16 bold>{formatWei(availableBalance)}</Text16>
      );
    if (showFiatWallet) return renderFiatBalance();

    return null;
  };

  if (compact) {
    return (
      <Button onClick={openWallet} color="opa" size="medium">
        {compact
          ? renderWithBadge(<FontAwesomeIcon icon={faWallet} />)
          : renderBalances()}
      </Button>
    );
  }
  return (
    <>
      {showFiatWallet && (
        <Button onClick={openWallet} color="opa" size="medium">
          <Text16 bold>
            {formatNumber(availableFiatBalance, {
              style: 'currency',
              currency: fiatCurrency,
            })}
          </Text16>
        </Button>
      )}
      {showEthWallet &&
        renderWithBadge(
          <Button onClick={openWallet} color="opa" size="medium">
            <Text16 bold>
              <FontAwesomeIcon icon={faEthereum} />{' '}
              {formatWei(
                availableBalance,
                RoundingMode.ROUND_DOWN,
                { minimumFractionDigits: 3 },
                { hideSymbol: showEthWallet && showFiatWallet }
              )}
            </Text16>
          </Button>
        )}
    </>
  );
};

const StyledBadge = styled(MUIBadge)`
  > span {
    /** overlap="circular" only work for icon buttons */
    transform: translate(25%, -25%);
  }
`;

export const Balances = ({ compact, onClick }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { needsCreateEthWallet } = useOAuthEthWallet();

  if (!currentUser) {
    return null;
  }

  if (
    needsCreateEthWallet ||
    walletNeedsRecover ||
    !currentUser.confirmedDevice
  )
    return (
      <StyledBadge
        badgeContent={<FontAwesomeIcon icon={faExclamationCircle} />}
      >
        <WalletButtons
          currentUser={currentUser}
          compact={compact}
          onClick={onClick}
        />
      </StyledBadge>
    );

  return (
    <WalletButtons
      currentUser={currentUser}
      compact={compact}
      onClick={onClick}
    />
  );
};
