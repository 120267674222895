import { ReactNode, useEffect, useRef } from 'react';

import { NETHONE_PROFILER_URL } from 'config';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { sendSafeError } from 'lib/error';
import { randomUUID } from 'lib/uuid';

import NethoneContextProvider from '.';

const NETHONE_PROFILER_ID = 'external-profiler';
const FALLBACK_DELAY_MS = 15000;

declare global {
  interface Window {
    dftp: {
      init: (options: any) => void;
      profileCompleted: () => Promise<void>;
      finishBehavGathering: () => void;
    };
  }
}

interface Props {
  children: ReactNode;
}

let resolve: (value: string | null | PromiseLike<string | null>) => void;
const attemptReference = new Promise<string | null>(r => {
  resolve = r;
});

const cleanup = (child: Node) => {
  if (window.dftp) {
    // https://panel.nethone.io/docs/integration_guide/profiler/web_services_cdn
    // In some cases, like a payment page that is part of a Single Page Application, you may want to stop the gathering
    // of behavioral data after user leaves the payment page. to do that call dftp.finishBehavGathering(). No
    // behavioral data will be gathered after calling this function.
    try {
      window.dftp.finishBehavGathering();
    } catch (e) {
      // ignore any kind of exceptions here
      sendSafeError(e);
    }
  }
  document.body.removeChild(child);
};

const NethoneProvider = ({ children }: Props) => {
  const {
    flags: { enableInquiryMangopayCc = false },
  } = useFeatureFlags();

  const reference = useRef<string>(randomUUID());

  useEffect(() => {
    if (!NETHONE_PROFILER_URL || !enableInquiryMangopayCc) {
      resolve!(null);
      return () => {};
    }

    const node = document.getElementById(NETHONE_PROFILER_ID);
    if (node) {
      return () => cleanup(node);
    }

    // regenerate attempt reference on each mount
    reference.current = randomUUID();

    // load profiler script
    const script = document.createElement('script');
    script.src = NETHONE_PROFILER_URL;
    script.id = NETHONE_PROFILER_ID;
    script.async = true;
    script.addEventListener('load', () => {
      if (window.dftp) {
        try {
          window.dftp.init({
            attemptReference: reference.current,
            sensitiveFields: ['number', 'cvc'],
          });
        } catch (e) {
          // when the profiler API gets rate-limited, it throws an exception related to finishBehavGathering
          // yet we don't want to block the user because of that
          sendSafeError(e);
          resolve!(null);
          return;
        }
        window.dftp
          .profileCompleted()
          .then(() => {
            resolve!(reference.current);
          })
          .catch(e => {
            sendSafeError(e);
            resolve!(null);
          });
      }
    });
    document.body.appendChild(script);

    // fallback in case the profiler doesn't complete
    const fallback = setTimeout(() => resolve!(null), FALLBACK_DELAY_MS);
    return () => {
      clearTimeout(fallback);
      cleanup(script);
    };
  }, [enableInquiryMangopayCc]);

  return (
    <NethoneContextProvider
      value={{
        attemptReference,
      }}
    >
      {children}
    </NethoneContextProvider>
  );
};

export default NethoneProvider;
