import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import Button from 'atoms/buttons/Button';
import useMutation from 'hooks/graphql/useMutation';

import {
  DisconnectOmniauthProviderMutation,
  DisconnectOmniauthProviderMutationVariables,
} from './__generated__/index.graphql';

const DISCONNECT_OMNIAUTH_PROVIDER_MUTATION = gql`
  mutation DisconnectOmniauthProviderMutation(
    $input: disconnectOmniauthProviderInput!
  ) {
    disconnectOmniauthProvider(input: $input) {
      userProfile {
        id
        discordUsername
        twitterUsername
      }
      currentUser {
        slug
        connectedOauths {
          id
          email
          provider
        }
      }
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  DisconnectOmniauthProviderMutation,
  DisconnectOmniauthProviderMutationVariables
>;

type Props = {
  email: string | null;
  provider: 'discord' | 'twitter' | 'google_oauth2' | 'facebook';
};

export const Disconnect = ({ email, provider }: Props) => {
  const [disconnect] = useMutation(DISCONNECT_OMNIAUTH_PROVIDER_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  return (
    <Button
      onClick={() => {
        disconnect({
          variables: {
            input: {
              provider,
              email,
            },
          },
        });
      }}
      size="small"
      color="quaternary"
    >
      <FormattedMessage id="OAuthAccount.unlink" defaultMessage="Unlink" />
    </Button>
  );
};
export default Disconnect;
