import { useOutletContext } from 'react-router-dom';

import { NBAMyClubPageQuery } from './__generated__/layout.graphql';

export type LayoutData = {
  userId: NBAMyClubPageQuery['user']['id'];
  user: NBAMyClubPageQuery['user'];
  initialDeckName: string;
  currentDeckName: string;
};

export const useLayoutData = () => useOutletContext<LayoutData>();
