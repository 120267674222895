import { useCallback, useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';

import { Delivery } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSnackNotificationContext } from 'contexts/snackNotification';

import useAddDevice from './useAddDevice';

const messages = defineMessages({
  emailsBlocked: {
    id: 'DeviceNeedsConfirming.SendDeviceConfirmation.emailsBlocked',
    defaultMessage:
      'We could not send you a device confirmation request, please try again in 15 minutes.',
  },
});

const buildDeliveryParameter = (deliveryMethods: Delivery[]) => {
  if (deliveryMethods.length === 2) {
    return 'both';
  }
  if (deliveryMethods[0] === Delivery.PHONE) {
    return 'phone';
  }
  return 'email';
};

export const useSendDeviceConfirmation = () => {
  const addDevice = useAddDevice();
  const { showNotification } = useSnackNotificationContext();
  const { refetch } = useCurrentUserContext();
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState<MessageDescriptor | undefined>();
  const sendDeviceConfirmation = useCallback(() => {
    setSending(true);
    addDevice()
      .then(({ errors: graphqlErrors, deliveryType }) => {
        if (!graphqlErrors || graphqlErrors.length === 0) {
          if (deliveryType.length > 0) {
            const delivery = buildDeliveryParameter(deliveryType);
            showNotification('confirmDeviceRequestSent', { delivery });
          } else {
            setErrors(messages.emailsBlocked);
          }
        }
      })
      .finally(() => {
        setSending(false);
      });
  }, [showNotification, setSending, addDevice]);

  return {
    sending,
    errors,
    sendDeviceConfirmation,
    refetch,
  };
};
