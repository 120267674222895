import { Suspense } from 'react';

import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { lazy } from '@sorare/core/src/lib/retry';

const LandingContent = lazy(async () => import('Landing'));
const FootballLanding = lazy(
  async () => import('@sorare/football/src/pages/page')
);

const Landing = () => {
  const {
    flags: { useEarlyAccessLanding = false },
  } = useFeatureFlags();

  return (
    <AppLayout>
      <Suspense>
        {useEarlyAccessLanding ? (
          <FootballLanding isRoot />
        ) : (
          <LandingContent />
        )}
      </Suspense>
    </AppLayout>
  );
};

export default Landing;
