import { ReactNode } from 'react';

import CloseButton from 'atoms/buttons/CloseButton';

import Dialog, { Props as DialogProps } from '..';
import DialogContentWithNavigation from '../DialogContentWithNavigation';

type Props = DialogProps & {
  title?: ReactNode;
  right?: ReactNode;
  children: ReactNode;
  onBackButton?: () => void;
};
export const DialogWithNavigation = (props: Props) => {
  const { children, onBackButton, title, right, onClose, ...dialogProps } =
    props;
  return (
    <Dialog maxWidth="xs" fullWidth {...dialogProps} hideHeader>
      <DialogContentWithNavigation
        title={title}
        onBackButton={onBackButton}
        right={onClose ? <CloseButton onClose={onClose} /> : undefined}
        noPadding
      >
        {children}
      </DialogContentWithNavigation>
    </Dialog>
  );
};

export default DialogWithNavigation;
