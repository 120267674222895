const trophyFactory = ({
  name,
  from,
  to,
}: {
  name: string;
  from: string;
  to: string;
}) =>
  function Trophy() {
    const fillId = `${name}paint0_linear_3533_38699`;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12 1.41412L10.5859 0H5.41424L4.00003 1.41421L4 4C4 8.9999 7 10 7 10V12H5V14H3V16H13V14H11V12H9V10C9 10 12 8.9999 12 4V1.41412Z"
          fill={`url(#${fillId})`}
        />
        <path
          d="M2 2H0V3C0 3.92931 0 4.39397 0.0768589 4.78036C0.386035 6.3347 1.58256 7.55667 3.12279 7.90264C2.48784 7.0044 2 5.74611 2 4V2Z"
          fill={`url(#${fillId})`}
        />
        <path
          d="M12.8772 7.90263C14.4174 7.55667 15.614 6.3347 15.9231 4.78036C16 4.39397 16 3.92931 16 3V2H14V4C14 5.74611 13.5122 7.0044 12.8772 7.90263Z"
          fill={`url(#${fillId})`}
        />
        <defs>
          <linearGradient
            id={fillId}
            x1="12.8145"
            y1="14.3891"
            x2="3.18548"
            y2="1.61092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={from} />
            <stop offset="1" stopColor={to} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

export const Gold = trophyFactory({
  name: 'Gold',
  from: '#AE5E13',
  to: '#DFAD46',
});
export const Silver = trophyFactory({
  name: 'Silver',
  from: '#BCC8CB',
  to: '#5E6769',
});
export const Bronze = trophyFactory({
  name: 'Bronze',
  from: '#CC8645',
  to: '#9F530E',
});
