import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import rivalsMainLogo from 'assets/rivals/rivalsMainLogo.svg';
import { FontAwesomeIcon } from 'atoms/icons';
import { HeroBlock, HeroBlockProps } from 'components/landing/HeroBlock';
import { FOOTBALL_PLAY_RIVALS } from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { Link } from 'routing/Link';

import heroBackgroundVideo from './assets/heroBackgroundVideo.webm';
import heroVideoPoster from './assets/heroVideoPoster.png';
import slideShow_1 from './assets/slideShow_1.jpg';
import slideShow_2 from './assets/slideShow_2.jpg';
import slideShow_3 from './assets/slideShow_3.jpg';

const messages = defineMessages({
  title: {
    id: 'RivalsLanding.Hero.title',
    defaultMessage: 'Sorare Rivals Fantasy Football',
  },
  subtitle: {
    id: 'RivalsLanding.Hero.subtitle',
    defaultMessage:
      "Introducing head-to-head fantasy football with big rewards. Challenge friends and set lineups across the world's top leagues each matchday.",
  },
  scrollDown: {
    id: 'RivalsLanding.Hero.scrollDown',
    defaultMessage:
      'Scroll down to see why this is the football game you need in your life.',
  },
});

const Icon = styled(FontAwesomeIcon)`
  margin-right: var(--unit);
`;

const AlernativeLogo = styled.img`
  height: 318px;
  max-width: 100%;
`;

export const Hero = (props: Partial<HeroBlockProps>) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { currentUser } = useCurrentUserContext();

  return (
    <HeroBlock
      poster={heroVideoPoster}
      videoSrc={heroBackgroundVideo}
      subtitle={formatMessage(messages.subtitle)}
      title={formatMessage(messages.title, { br: <br /> })}
      showStoreLinks
      mobileSlides={[slideShow_1, slideShow_2, slideShow_3]}
      scrollDownText={formatMessage(messages.scrollDown)}
      additionalCTA={
        <Link to="https://youtu.be/GHRFOEfngE4" target="_blank">
          <Icon icon={faPlayCircle} />
          {formatMessage({
            id: 'RivalsLanding.Hero.AdditionalCTA',
            defaultMessage: 'Watch trailer',
          })}
        </Link>
      }
      onPlayNow={currentUser ? () => navigate(FOOTBALL_PLAY_RIVALS) : undefined}
      alernativeLogo={<AlernativeLogo src={rivalsMainLogo} alt="Rivals" />}
      {...props}
    />
  );
};
