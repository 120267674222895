import { ReactNode } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';
import { tabletAndAbove } from 'style/mediaQuery';

const StyledLink = styled(NavLink)`
  flex: 1;
  color: var(--c-neutral-600);
  position: relative;
  &:hover,
  &:focus {
    color: var(--c-neutral-1000);
    & img {
      opacity: 1;
    }
  }
  &.active {
    color: var(--c-neutral-1000);
    & img {
      opacity: 1;
    }
  }
  padding: var(--unit);
  font: var(--t-12);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tabletAndAbove} {
    flex: unset;
    gap: var(--half-unit);
    padding: var(--double-unit) var(--half-unit);
  }
  & svg {
    font-size: 20px;
    height: 24px;
  }
  & img {
    opacity: 0.7;
  }
`;

export const NavItem = ({
  to,
  discardHistory,
  children,
}: {
  to: string; // don't allow Partial on purpose. It should be a string
  discardHistory?: boolean;
  children: ReactNode;
}) => {
  const {
    flags: { useContextModeNavigation = false },
  } = useFeatureFlags();
  const [savedHistory, setSavedHistory] = useLocalStorage(
    STORAGE.savedHistory,
    {} as Record<string, string>
  );
  const match = useMatch(`${to}/*`);
  const returnToHome = match && match.pathname !== match.pathnameBase;

  if (!useContextModeNavigation || discardHistory) {
    return (
      <StyledLink
        to={to}
        state={{
          fromNavItem: true,
        }}
      >
        {children}
      </StyledLink>
    );
  }

  if (match && match.pathname !== savedHistory?.[to]) {
    setSavedHistory(saved => ({ ...saved, [to]: match.pathname }));
  }

  return (
    <StyledLink
      to={returnToHome || !savedHistory?.[to] ? to : savedHistory?.[to]}
      state={{
        fromNavItem: true,
      }}
    >
      {children}
    </StyledLink>
  );
};
