import { TypedDocumentNode, gql } from '@apollo/client';

import {
  SupportedCurrency,
  TokenPaymentMethod,
} from '@sorare/core/src/__generated__/globalTypes';
import UserBalance from '@sorare/core/src/components/wallet/UserBalance';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { errorWrapper } from '@sorare/core/src/errors';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import useCreateEthMigration from '@sorare/core/src/hooks/starkware/useCreateEthMigration';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import {
  BidWithWalletMutation,
  BidWithWalletMutationVariables,
  useBidWithWallet_auction,
} from './__generated__/useBidWithWallet.graphql';
import usePrepareBid from './usePrepareBid';

const BID_WITH_WALLET_MUTATION = gql`
  mutation BidWithWalletMutation($input: bidInput!) {
    bid(input: $input) {
      tokenBid {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        auction {
          id
          currentPrice
          privateCurrentPrice
          bidsCount
          endDate
          minNextBid
          privateMinNextBid
          currency
          bestBid {
            id
            amounts {
              ...MonetaryAmountFragment_monetaryAmount
            }
            bidder {
              ... on User {
                id
                slug
              }
            }
          }
          myLastBid {
            id
          }
          myBestBid {
            id
            fiatPayment
            maximumAmounts {
              ...MonetaryAmountFragment_monetaryAmount
            }
            conversionCredit {
              id
            }
          }
        }
      }
      currentUser {
        slug
        ...UserBalances
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${monetaryAmountFragment}
  ${UserBalance.fragments.balances}
` as TypedDocumentNode<BidWithWalletMutation, BidWithWalletMutationVariables>;

type BidWithWalletArgs = {
  supportedCurrency: SupportedCurrency;
  amount: string;
  conversionCreditId?: string;
};

export type ResultingBid = NonNullable<
  BidWithWalletMutation['bid']
>['tokenBid'];

const useBidWithWallet = (auction: useBidWithWallet_auction) => {
  const [bid] = useMutation(BID_WITH_WALLET_MUTATION, {
    showErrorsWithSnackNotification: true,
  });

  const { showNotification } = useSnackNotificationContext();
  const createEthMigration = useCreateEthMigration();

  const { prepareBid } = usePrepareBid({ signAuthorizations: true });

  const prepareAndBid = async ({
    supportedCurrency,
    conversionCreditId,
    amount,
  }: BidWithWalletArgs) => {
    const { settlementInfo, approvals } = await prepareBid({
      supportedCurrency,
      tokenPaymentMethod: TokenPaymentMethod.WALLET,
      auctionId: auction.id,
      amount,
      conversionCreditId,
      attemptReference: null,
    });

    if (!approvals) {
      showNotification('unlockWallet');
      throw new Error('Missing password');
    }

    await createEthMigration();

    const { data: dataBid } = await bid({
      variables: {
        input: {
          auctionId: auction.id,
          amount,
          settlementInfo,
          approvals,
        },
      },
    });

    return dataBid?.bid?.tokenBid;
  };

  return async (args: BidWithWalletArgs) => {
    const { result, error } = await errorWrapper(prepareAndBid)(args);
    if (error) return [{ message: `${error.name} - ${error.message}` }];
    return result;
  };
};

useBidWithWallet.fragments = {
  auction: gql`
    fragment useBidWithWallet_auction on TokenAuction {
      id
    }
  ` as TypedDocumentNode<useBidWithWallet_auction>,
};

export default useBidWithWallet;
