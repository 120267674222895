import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';

import Bold from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { ANY_SPORT_CARD_SHOW } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { getName } from 'lib/cards';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { SaleNotification_saleNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: SaleNotification_saleNotification;
};

const messages = defineMessages({
  card_sold: {
    id: 'Activity.Notifications.cardSold',
    defaultMessage: 'You sold <b>{card}</b>',
  },
  card_not_sold: {
    id: 'Activity.Notifications.cardNotSold',
    defaultMessage: 'Your sale for <b>{card}</b> has ended',
  },
});

export const SaleNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { generateSportContextPath } = useSportContext();

  const { name, createdAt, anyCard: card, sport, read } = notification;

  if (!card) return null;

  const link = generateSportContextPath(ANY_SPORT_CARD_SHOW, {
    params: {
      slug: card.slug,
    },
    sport: card.sport,
  });

  return (
    <DumbNotification
      title={
        <FormattedMessage
          {...messages[name as keyof typeof messages]}
          values={{ b: Bold, card: getName(card) }}
        />
      }
      userAvatar={currentUser}
      sport={sport}
      cardPicture={card}
      createdAt={createdAt}
      link={link}
      read={read}
      {...rest}
    />
  );
};

SaleNotification.fragments = {
  saleNotification: gql`
    fragment SaleNotification_saleNotification on SaleNotification {
      id
      ...Notification_notificationInterface
      sport
      anyCard {
        slug
        sport
        ...DumbNotification_cardPicture
        ...getName_anyCard
        player: anyPlayer {
          slug
          displayName
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
    ${getName.fragments.anyCard}
  ` as TypedDocumentNode<SaleNotification_saleNotification>,
};
