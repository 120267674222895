import '@sorare/core/src/polyfills';
// eslint-disable-next-line import/order
import { createBrowserRouter } from './sentry';

import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import DeviceFingerPrintProvider from '@sorare/core/src/contexts/deviceFingerprint/Provider';
import FeatureFlagsProvider from '@sorare/core/src/contexts/featureFlags/Provider';
import GraphqlProvider from '@sorare/core/src/contexts/graphql/Provider';
import { IntlProvider } from '@sorare/core/src/contexts/intl/Provider';
import SentryProvider from '@sorare/core/src/contexts/sentry/Provider';
import { withProfiler } from '@sorare/core/src/contexts/sentry/sentry';
import SnackNotificationProvider from '@sorare/core/src/contexts/snackNotification/Provider';
import TMProvider from '@sorare/core/src/contexts/tm/Provider';
import { useBasenameLocale } from '@sorare/core/src/hooks/useBasenameLocale';
import { withFFProvider } from '@sorare/core/src/lib/featureFlags';

import './remove-child-workaround';

import { EphemeralLink, isEphemeralLink } from './components/EphemeralLink';
import { ViteErrorHandler } from './components/ViteErrorHandler';
import { routeObjects } from './routeObjects';

import '@sorare/core/src/style/style.css';

const RootProviders = ({
  locale,
  children,
}: Pick<ComponentProps<typeof IntlProvider>, 'locale'> & {
  children: ReactNode;
}) => {
  return (
    <SentryProvider>
      <DeviceFingerPrintProvider>
        <TMProvider>
          <GraphqlProvider locale={locale}>{children}</GraphqlProvider>
        </TMProvider>
      </DeviceFingerPrintProvider>
    </SentryProvider>
  );
};

const ApolloClientRouter = ({
  basename,
  locale,
  setLocale,
}: {
  basename: string;
} & Pick<ComponentProps<typeof IntlProvider>, 'locale' | 'setLocale'>) => {
  // We need to wait for apollo client to be initialized before we can create
  // the browser router, as this will launch requests.
  const router = useMemo(
    () =>
      createBrowserRouter(routeObjects, {
        basename,
      }),
    [basename]
  );
  return (
    <IntlProvider locale={locale} setLocale={setLocale}>
      <SnackNotificationProvider>
        <RouterProvider router={router} key={basename} />
      </SnackNotificationProvider>
    </IntlProvider>
  );
};

const Root = () => {
  const { basename, locale, setLocale } = useBasenameLocale();

  return (
    <RootProviders locale={locale}>
      <ApolloClientRouter
        key={basename}
        basename={basename}
        locale={locale}
        setLocale={setLocale}
      />
    </RootProviders>
  );
};

const EphemeralLinkOrRoot = () => {
  const [, forceUpdate] = useReducer(() => ({}), {});

  const navigateTo = useCallback((path: string) => {
    window.history.replaceState({}, '', path);
    forceUpdate();
  }, []);

  // eslint-disable-next-line no-restricted-properties
  return isEphemeralLink(window.location.pathname) ? (
    <RootProviders locale="en">
      <IntlProvider locale="en" setLocale={() => {}}>
        <EphemeralLink navigateTo={navigateTo} />
      </IntlProvider>
    </RootProviders>
  ) : (
    <Root />
  );
};

const RootWithProfiler = withProfiler(EphemeralLinkOrRoot);

const container = document.getElementById('root');
const root = createRoot(container!);
const App = () => (
  <FeatureFlagsProvider>
    <ViteErrorHandler />
    <RootWithProfiler />
  </FeatureFlagsProvider>
);
const FFProvider = withFFProvider()(App);
root.render(<FFProvider />);

// unregister any service worker left-over (see https://gitlab.com/sorare/frontend/-/merge_requests/3852)
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister();
    });
  });
}
