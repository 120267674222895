import styled from 'styled-components';

import { BodyM, BodyS } from 'atoms/typography';
import useAmountWithConversion, {
  Props as UseAmountWithConversionProps,
} from 'hooks/useAmountWithConversion';

const Line = styled.div<{ $column: boolean }>`
  display: flex;
  ${props =>
    props.$column
      ? `flex-direction: column;`
      : `align-items: baseline;
      gap: var(--half-unit);`};
`;

export type Props = UseAmountWithConversionProps & {
  column?: boolean;
  withApproxSymbol?: boolean;
  hideExponent?: boolean;
  actualMain?: string | null;
  actualExponent?: string | null;
};

export const AmountWithConversion = (props: Props) => {
  const {
    column = false,
    actualMain = undefined,
    withApproxSymbol = false,
    hideExponent,
    actualExponent = undefined,
    ...useAmountWithConversionProps
  } = props;
  const { main, exponent } = useAmountWithConversion(
    useAmountWithConversionProps
  );

  return (
    <Line $column={column}>
      {(actualMain || main) && (
        <BodyM bold as="p" color="var(--c-neutral-1000)">
          {actualMain || main}
        </BodyM>
      )}
      {!hideExponent && (actualExponent || exponent) && (
        <BodyS as="span" color="var(--c-neutral-600)">
          {withApproxSymbol && '≈ '}
          {actualExponent || exponent}
        </BodyS>
      )}
    </Line>
  );
};
