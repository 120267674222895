import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  color?: SVGProps<SVGRectElement>['stroke'] | SVGProps<SVGRectElement>['fill'];
};

export const Box = ({ size = 2, color = 'white' }: Props) => {
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2528_291406)">
        <path
          d="M5.86654 7.69033L6.08765 7.78532L6.30875 7.69033L10.0194 6.09607L10.0201 9.39246L6.08857 11.0815L2.15689 9.3924L2.15622 6.09622L5.86654 7.69033Z"
          stroke={color}
          strokeWidth="1.12023"
        />
        <path
          d="M5.86736 7.67584L6.08846 7.77083L6.30955 7.67584L10.2314 5.99093L10.5707 5.84517L10.5704 5.47591L10.5696 4.32549L10.569 3.47587L9.78838 3.81126L6.08765 5.40124L2.38692 3.81126L1.6051 3.47536L1.60569 4.32628L1.6065 5.4767L1.60675 5.8454L1.94552 5.99094L5.86736 7.67584Z"
          stroke={color}
          strokeWidth="1.12023"
        />
        <path
          d="M10.5605 3.48149L6.08697 5.40342L1.61282 3.48123L1.61241 2.9924L6.08594 1.07046L10.5601 2.99266L10.5605 3.48149Z"
          stroke={color}
          strokeWidth="1.12023"
        />
        <path
          d="M6.08765 5.23511V10.5139"
          stroke={color}
          strokeWidth="1.12023"
        />
        <path
          d="M5.03911 2.71316C5.25932 2.72974 5.48217 2.71017 5.68843 2.6562C5.89469 2.60223 6.07822 2.51557 6.22316 2.4036C6.22878 2.3989 6.23626 2.39546 6.24472 2.39351C6.25318 2.39156 6.26235 2.39128 6.27107 2.39272C6.2798 2.39416 6.28771 2.39722 6.29385 2.40158C6.30007 2.40587 6.30425 2.4113 6.30611 2.4172C6.35099 2.56245 6.44806 2.69825 6.58878 2.81292C6.7295 2.92759 6.9097 3.01773 7.1139 3.07544C7.122 3.07778 7.12893 3.08149 7.134 3.08632C7.13906 3.09115 7.14208 3.09676 7.14261 3.1026C7.14313 3.10844 7.14116 3.11427 7.13697 3.11944C7.13278 3.12462 7.12654 3.1289 7.11896 3.1318C6.92611 3.20504 6.76294 3.3087 6.64344 3.43371C6.52394 3.55872 6.45176 3.70132 6.43317 3.84925C6.43227 3.85509 6.42906 3.86068 6.4238 3.86538C6.41854 3.87007 6.41141 3.87364 6.40321 3.87577C6.39502 3.8779 6.38621 3.87854 6.37748 3.87745C6.36876 3.87636 6.36084 3.87379 6.35417 3.8699C6.19037 3.77003 5.99269 3.6982 5.77831 3.66073C5.56392 3.62325 5.33924 3.62119 5.12367 3.65472C5.11512 3.65601 5.10622 3.65564 5.09785 3.65372C5.08949 3.6518 5.08228 3.64839 5.07659 3.64385C5.07108 3.63932 5.06735 3.63383 5.06621 3.62805C5.06497 3.62221 5.06623 3.61626 5.06971 3.61096C5.1611 3.4759 5.20203 3.32794 5.18893 3.17983C5.17583 3.03173 5.10896 2.88793 4.99425 2.76092C4.98963 2.75591 4.98733 2.75018 4.98743 2.74429C4.98753 2.73839 4.99003 2.73268 4.99467 2.7278C4.99939 2.72286 5.00599 2.71882 5.01383 2.71634C5.02175 2.71379 5.03048 2.71273 5.0392 2.71334L5.03911 2.71316Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2528_291406">
          <rect width="12" height="12" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
