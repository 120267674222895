import { ReactNode, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  ACTIVITY,
  CONFIRM_DEVICE,
  DEEPLINK,
  LANDING_WILDCARD,
  MOBILE_PASSWORD_FORGOTTEN,
  MOBILE_SIGN_UP,
  MY_SORARE_WILDCARD,
  OAUTH_AUTORIZE,
  PROMO_CLAIM,
  PROMO_SIGNUP,
  RIVALS_LANDING,
} from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import useGetSplat from '@sorare/core/src/hooks/useGetSplat';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { lazy } from '@sorare/core/src/lib/retry';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

import { RivalsLanding } from '@sorare/football/src/pages/RivalsLanding';
import { Deeplink } from 'components/Deeplink';

const Activity = lazy(async () => import('@sorare/shared-pages/src/Activity'));
const WalletDrawer = lazy(
  async () => import('@sorare/core/src/components/wallet/WalletDrawer')
);
const BlockchainProvider = lazy(
  async () => import('@sorare/core/src/contexts/blockchain/Provider')
);
const ConfirmDevice = lazy(
  async () => import('@sorare/shared-pages/src/ConfirmDevice')
);

const MobileSignUp = lazy(
  async () => import('@sorare/shared-pages/src/MobileSignUp')
);

const MobilePasswordForgotten = lazy(
  async () => import('@sorare/shared-pages/src/MobilePasswordForgotten')
);

const OAuth = lazy(async () => import('@sorare/shared-pages/src/OAuth'));

const PromoSignup = lazy(
  async () => import('@sorare/shared-pages/src/PromoSignup')
);
const PromoClaim = lazy(
  async () => import('@sorare/shared-pages/src/PromoClaim')
);
const MySorare = lazy(async () => import('@sorare/shared-pages/src/MySorare'));

const LandingPages = lazy(async () => import('@sorare/landing-pages/src/main'));

export const BlockchainProviders = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<LoadingIndicator fullScreen />}>
      <BlockchainProvider>
        {children}
        <WalletDrawer />
      </BlockchainProvider>
    </Suspense>
  );
};

const MobileAppLoadedEventEmiter = () => {
  const { isAndroidApp, postMessage } = useIsMobileApp();

  useEffect(() => {
    if (!isAndroidApp) return;
    postMessage('loaded');
  }, [isAndroidApp, postMessage]);
  return null;
};

const SportsRouter = () => {
  const location = useLocation();
  const getSplat = useGetSplat();

  return (
    <>
      <MobileAppLoadedEventEmiter />
      <Routes>
        {/* Force all Football routes to be prefixed with /football now */}
        <Route
          path={'/*'}
          element={
            <Navigate
              to={getSplat('/*', '/football/*') + location.search}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

// Here we are trying to include the blockchain providers only when needed, and only once
// It is not needed on the Logged out Landing page
// It is not needed on the Logged in Landing page
// It is needed on the logged out Football Player page (for instance)
export const AppRouter = () => {
  const {
    flags: { useRivalsLanding = false },
  } = useFeatureFlags();
  const { currentUser } = useCurrentUserContext();
  return (
    <Routes>
      {useRivalsLanding && (
        <Route path={RIVALS_LANDING} element={<RivalsLanding />} />
      )}
      <Route path={LANDING_WILDCARD} element={<LandingPages />} />
      <Route path={MOBILE_SIGN_UP} element={<MobileSignUp />} />
      <Route
        path={MOBILE_PASSWORD_FORGOTTEN}
        element={<MobilePasswordForgotten />}
      />
      <Route path={CONFIRM_DEVICE} element={<ConfirmDevice />} />
      <Route
        path={OAUTH_AUTORIZE}
        element={
          <RequireAuth>
            <OAuth />
          </RequireAuth>
        }
      />
      <Route
        path={ACTIVITY}
        element={
          <RequireAuth>
            <Activity tab="notifications" />
          </RequireAuth>
        }
      />
      <Route
        path={MY_SORARE_WILDCARD}
        element={
          <RequireAuth>
            <MySorare />
          </RequireAuth>
        }
      />
      <Route
        path={DEEPLINK}
        element={
          <RequireAuth>
            <Deeplink />
          </RequireAuth>
        }
      />
      <Route path={PROMO_SIGNUP} element={<PromoSignup />} />
      <Route
        path={PROMO_CLAIM}
        element={
          <RequireAuth>
            <PromoClaim />
          </RequireAuth>
        }
      />
      <Route
        path={'/*'}
        element={
          currentUser ? (
            <SportsRouter />
          ) : (
            <BlockchainProviders>
              <SportsRouter />
            </BlockchainProviders>
          )
        }
      />
    </Routes>
  );
};
