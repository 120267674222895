import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Cards = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3681 0.957304C8.22385 0.427382 7.67923 0.112962 7.14818 0.253026L0.974595 1.88133C0.43759 2.02296 0.118866 2.57504 0.264736 3.11091L2.99801 13.1519C3.14226 13.6818 3.68689 13.9962 4.21793 13.8562L4.66766 13.7375L4.63388 13.8616L11.7821 15.747C12.3131 15.887 12.8577 15.5726 13.002 15.0427L15.7353 5.00173C15.8811 4.46586 15.5624 3.91378 15.0254 3.77214L8.67866 2.09817L8.3681 0.957304ZM7.45588 13.0021L10.3915 12.2279C10.9285 12.0862 11.2473 11.5341 11.1014 10.9983L9.149 3.82601L14.0725 5.1246L11.6286 14.1027L7.45588 13.0021Z"
      fill="currentColor"
    />
  </svg>
);
