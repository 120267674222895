import { MessageDescriptor, defineMessages } from 'react-intl';

export const longLabels = defineMessages({
  totalScore: {
    id: 'NBAPerformanceTable.totalScore',
    defaultMessage: 'Total score',
  },
  bonusPoints: {
    id: 'NBAPerformanceTable.bonusPoints',
    defaultMessage: 'Bonus points ({bonus})',
  },
  fantasyPoints: {
    id: 'NBAPerformanceTable.fantasyPoints',
    defaultMessage: 'Fantasy points',
  },
});

export const shortLabels = defineMessages({
  totalScore: {
    id: 'NBAPerformanceTable.totalScore.short',
    defaultMessage: 'TOT PTS',
  },
  bonusPoints: {
    id: 'NBAPerformanceTable.bonusPoints.short',
    defaultMessage: 'BON ({bonus})',
  },
  fantasyPoints: {
    id: 'NBAPerformanceTable.fantasyPoints.short',
    defaultMessage: 'FAN PTS',
  },
});

export const longStatLabels: Record<string, MessageDescriptor> = defineMessages(
  {
    points: {
      id: 'NBAPerformanceTable.stats.points',
      defaultMessage: 'Points',
    },
    rebounds: {
      id: 'NBAPerformanceTable.stats.rebounds',
      defaultMessage: 'Rebounds',
    },
    assists: {
      id: 'NBAPerformanceTable.stats.assists',
      defaultMessage: 'Assists',
    },
    blocks: {
      id: 'NBAPerformanceTable.stats.blocks',
      defaultMessage: 'Blocks',
    },
    steals: {
      id: 'NBAPerformanceTable.stats.steals',
      defaultMessage: 'Steals',
    },
    turnovers: {
      id: 'NBAPerformanceTable.stats.turnovers',
      defaultMessage: 'Turnovers',
    },
    made3PointFGs: {
      id: 'NBAPerformanceTable.stats.made3PointFGs',
      defaultMessage: 'Made 3-point FGs',
    },
    doubleDoubles: {
      id: 'NBAPerformanceTable.stats.doubleDoubles',
      defaultMessage: 'Double doubles',
    },
    tripleDoubles: {
      id: 'NBAPerformanceTable.stats.tripleDoubles',
      defaultMessage: 'Triple doubles',
    },
  }
);

export const shortStatLabels: Record<string, MessageDescriptor> =
  defineMessages({
    points: {
      id: 'NBAPerformanceTable.stats.points.short',
      defaultMessage: 'PTS',
    },
    rebounds: {
      id: 'NBAPerformanceTable.stats.rebounds.short',
      defaultMessage: 'REB',
    },
    assists: {
      id: 'NBAPerformanceTable.stats.assists.short',
      defaultMessage: 'AST',
    },
    blocks: {
      id: 'NBAPerformanceTable.stats.blocks.short',
      defaultMessage: 'BLK',
    },
    steals: {
      id: 'NBAPerformanceTable.stats.steals.short',
      defaultMessage: 'STL',
    },
    turnovers: {
      id: 'NBAPerformanceTable.stats.turnovers.short',
      defaultMessage: 'TO',
    },
    made3PointFGs: {
      id: 'NBAPerformanceTable.stats.made3PointFGs.short',
      defaultMessage: '3PM',
    },
    doubleDoubles: {
      id: 'NBAPerformanceTable.stats.doubleDoubles.short',
      defaultMessage: 'DD2',
    },
    tripleDoubles: {
      id: 'NBAPerformanceTable.stats.tripleDoubles.short',
      defaultMessage: 'TD3',
    },
  });
