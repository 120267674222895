import { faBasketball } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from 'atoms/icons';

type Props = {
  size?: number;
};

export const NBABall = ({ size = 18 }: Props) => (
  <FontAwesomeIcon icon={faBasketball} width={size} height={size} />
);
