import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useGetInteractionContext } from '@sorare/core/src/hooks/useGetInteractionContext';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import useEvents from '@sorare/core/src/lib/events/useEvents';
import { fromWei } from '@sorare/core/src/lib/wei';

import { tokenInfoProperties, tokensInfoProperties } from 'lib/tokenEvents';

import {
  useClickBidEvent_anyCard,
  useClickBidEvent_tokenAuction,
} from './__generated__/useClickBidEvent.graphql';

export const useClickBidEvent = () => {
  const getInteractionContext = useGetInteractionContext();
  const track = useEvents();
  const trackClickBid = useCallback(
    (
      auction: useClickBidEvent_tokenAuction,
      monetaryAmount: MonetaryAmountOutput,
      cards: useClickBidEvent_anyCard[],
      sport: Sport,
      subPath?: string
    ) => {
      const sharedProperties = {
        auctionId: auction.id,
        count: auction.bidsCount,
        ethAmount: fromWei(monetaryAmount.wei),
        eurAmount: monetaryAmount.eur,
        secondary: false,
        interactionContext: getInteractionContext(subPath),
        sport,
      };

      if (cards.length === 1) {
        const params = {
          ...sharedProperties,
          ...tokenInfoProperties(cards[0]),
        };

        track('Click Bid', params);
      } else {
        const params = {
          ...sharedProperties,
          ...tokensInfoProperties(cards),
        };

        track('Click Bundled Bid', params);
      }
    },
    [track, getInteractionContext]
  );

  return trackClickBid;
};

useClickBidEvent.fragments = {
  tokenAuction: gql`
    fragment useClickBidEvent_tokenAuction on TokenAuction {
      id
      bidsCount
    }
  ` as TypedDocumentNode<useClickBidEvent_tokenAuction>,
  anyCard: gql`
    fragment useClickBidEvent_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
    }
  ` as TypedDocumentNode<useClickBidEvent_anyCard>,
};
