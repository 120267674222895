import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Friendlies = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 16V0H0L7.46846e-05 16H15.9999ZM3.70685 3.4398L1.94685 7.8398H4.14685L5.50349 4.4482L6.86013 7.8398H9.12651L10.4832 4.4482L11.8398 7.8398H14.0398L12.2798 3.4398H8.68651L7.99332 5.17277L7.30013 3.4398H3.70685ZM12.7196 7.83986H10.5196L12.2796 12.2399L14.4796 12.2399L12.7196 7.83986ZM9.12651 7.8398L10.8858 12.2399L8.68582 12.2399L7.99088 10.5025L7.29594 12.2399H5.09594L6.85594 7.83986L9.12651 7.8398ZM3.26691 7.83986H5.46691L3.70691 12.2399H1.50691L3.26691 7.83986Z"
      fill="#00FFF7"
    />
  </svg>
);
