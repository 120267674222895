import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import Dialog from 'components/dialog';

const Root = styled.div`
  padding: var(--quadruple-unit) 0;
  position: relative;
  min-height: 100%;
`;

type Props = {
  onClose: (all?: boolean) => void;
  children: (props: { onClose: () => void }) => React.JSX.Element;
};

export const Layout = ({ onClose, children }: Props) => {
  const [visible, setVisible] = useState(true);

  const onCloseDialog = useCallback(() => {
    setVisible(false);
    onClose();
  }, [onClose]);

  // prevent rerender of children component when closing dialog
  const memoizedChildren = useMemo(() => {
    return children({ onClose: onCloseDialog });
  }, [children, onCloseDialog]);

  return (
    <Dialog
      open={visible}
      maxWidth="xs"
      fullWidth
      onClose={onCloseDialog}
      hideHeader
    >
      <Root>{memoizedChildren}</Root>
    </Dialog>
  );
};

export default Layout;
