import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';

import { Level } from '..';

export const Message = ({
  id,
  level,
  notification,
}: {
  id?: string;
  level: Level;
  notification: ReactNode;
}) => {
  if (!notification) {
    return null;
  }
  return (
    <Horizontal key={id}>
      {(level === Level.ERROR || level === Level.WARN) && (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="lg"
          color={
            level === Level.ERROR
              ? 'var(--c-static-red-800)'
              : 'var(--c-static-yellow-800)'
          }
        />
      )}
      <span>{notification}</span>
    </Horizontal>
  );
};
