import { Outlet } from 'react-router-dom';

import { FOOTBALL_PLAY } from '@sorare/core/src/constants/__generated__/routes';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { Navigate } from '@sorare/core/src/routing/Navigate';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

const RivalsLeaguesLayout = () => {
  const {
    flags: { enableEuro2024 = false },
  } = useFeatureFlags();
  if (!enableEuro2024) {
    return <Navigate replace to={FOOTBALL_PLAY} />;
  }
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};
export default RivalsLeaguesLayout;
