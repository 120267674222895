import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import { Horizontal } from 'atoms/layout/flex';
import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import { HeadlineM, Text16 } from 'atoms/typography';
import Dialog from 'components/dialog';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { laptopAndAbove } from 'style/mediaQuery';

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  padding: var(--triple-unit);
  margin: auto;
  @media ${laptopAndAbove} {
    width: 480px;
  }
`;
const EmailContent = styled(Text16)`
  text-align: left;
  border-radius: var(--unit);
  padding: var(--double-unit);
  background: var(--c-neutral-100);
  color: var(--c-neutral-600);
`;
const Block = styled.div`
  gap: var(--triple-unit);
`;
const Subtitle = styled(Text16)`
  color: var(--c-neutral-600);
`;
const Timer = styled.div`
  display: flex;
  font: var(--t-12);
  justify-content: center;
  margin-top: var(--double-unit);
`;
const LoaderWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  min-height: 200px;
`;

const messages = defineMessages({
  title: {
    id: 'NoCardEntryRegisterDialog.title',
    defaultMessage: 'No Card Entry',
  },
});

type Props = {
  sport: Sport;
  emailAddress: string | undefined;
  open: boolean;
  onClose: () => void;
  maxLineupsPerUser: number | undefined;
  poolDetail?: ReactNode;
  timer?: ReactNode;
  loading?: boolean;
};
export const NoCardEntryRegisterDialog = ({
  sport,
  emailAddress,
  open,
  onClose,
  maxLineupsPerUser,
  poolDetail,
  timer,
  loading,
}: Props) => {
  const {
    flags: { enableUsSportsNoCardRouteUk = false },
  } = useFeatureFlags();
  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}
      title={
        <HeadlineM className="text-center" as="h1">
          <FormattedMessage {...messages.title} />
        </HeadlineM>
      }
    >
      <DialogContainer>
        {loading ? (
          <LoaderWrapper>
            <LoadingIndicator />
          </LoaderWrapper>
        ) : (
          <>
            <Block>
              <Subtitle>
                {enableUsSportsNoCardRouteUk ? (
                  <FormattedMessage
                    id="NoCardEntryRegisterDialog.subtitle1Uk"
                    defaultMessage="You can play in this Game Week without owning NFT cards by selecting {playerCount} players among those Sorare will give you access to."
                    values={{ playerCount: sport === Sport.BASEBALL ? 7 : 5 }}
                  />
                ) : (
                  <FormattedMessage
                    id="NoCardEntryRegisterDialog.subtitle1"
                    defaultMessage="You can play in this Game Week without owning NFT cards by selecting a team of players among those Sorare will give you access to."
                  />
                )}
              </Subtitle>
            </Block>
            <Block>
              <Subtitle>
                <FormattedMessage
                  id="NoCardEntryRegisterDialog.subtitle2"
                  defaultMessage="For this Game Week, you will be entitled to select until {maxLineupsPerUser} teams of players by following the process described below."
                  values={{ maxLineupsPerUser }}
                />
              </Subtitle>
            </Block>
            {poolDetail && (
              <Block>
                <Subtitle>{poolDetail}</Subtitle>
              </Block>
            )}
            <Block>
              <Text16>
                <FormattedMessage
                  id="NoCardEntryRegisterDialog.subtitle3"
                  defaultMessage={`To participate:{br}
                             1. Send an email to {email}{br}
                             2. Use the following template:`}
                  values={{
                    br: <br />,
                    email: (
                      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                    ),
                  }}
                />
              </Text16>
            </Block>
            <Block>
              <EmailContent>
                <FormattedMessage
                  id="NoCardEntryRegisterDialog.emailContent"
                  defaultMessage="I, surname last name, registered on Sorare with the email address XXXX and the username XXX request to get the link to participate without cards in the Game Week XXX."
                />
              </EmailContent>
            </Block>
            <Block>
              <Text16>
                <FormattedMessage
                  id="NoCardEntryRegisterDialog.subtitle4"
                  defaultMessage="3. Check your inbox (and the spams folder)"
                />
              </Text16>
            </Block>
            {timer && (
              <Block>
                <Timer>{timer}</Timer>
              </Block>
            )}
          </>
        )}
      </DialogContainer>
    </Dialog>
  );
};
