import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import { ReactNode, RefObject, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import useTouchScreen from 'hooks/device/useTouchScreen';
import useIsOverflowing from 'hooks/ui/useIsOverflowing';
import useScrollPosition from 'hooks/ui/useScrollPosition';
import { glossary } from 'lib/glossary';
import { unitMapping } from 'lib/style';

// eslint-disable-next-line sorare/use-horizontal-vertical-layout
const ScrollButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  inset: 0 calc(-1 * var(--double-unit));
  pointer-events: none;
  z-index: 2;
  > * {
    pointer-events: auto;
    &[disabled] {
      visibility: hidden;
    }
  }
  &.contained {
    inset: 0 var(--unit);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  isolation: isolate;
  &.displayOnlyOnHover {
    ${ScrollButtonWrapper} {
      visibility: hidden;
    }
    &:hover,
    &:focus-within {
      ${ScrollButtonWrapper} {
        visibility: visible;
      }
    }
  }
`;

type Props = {
  children: ReactNode;
  wrapperRef: RefObject<HTMLDivElement>;
  scrollContainerRef: RefObject<HTMLDivElement>;
  contained?: boolean;
  displayOnlyOnHover?: boolean;
  behavior?: 'smooth' | 'instant' | 'auto';
  itemGap?: keyof typeof unitMapping;
};

export const WithScrollButtons = ({
  children,
  wrapperRef,
  scrollContainerRef,
  contained,
  displayOnlyOnHover = true,
  itemGap = 2,
  behavior = 'smooth',
}: Props) => {
  // update state to force isOverflowing to recompute when the wrapperRef change
  const [, setUpdate] = useState<unknown>();
  const isOverflowing = useIsOverflowing(scrollContainerRef);
  const { scrollPosition } = useScrollPosition(scrollContainerRef);
  const { formatMessage } = useIntl();
  const isTouchScreen = useTouchScreen();

  // On IOS safari, the onMouseEnter/foxus is triggered by first click. Prevetting the click on caroussel item.
  // https://stackoverflow.com/questions/46034186/ios-safari-click-event-not-triggerd-when-there-is-an-event-listener-on-mouse
  const actualDisplayOnlyOnHover = !isTouchScreen && displayOnlyOnHover;

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseEnter={actualDisplayOnlyOnHover ? setUpdate : undefined}
      className={classnames({ displayOnlyOnHover: actualDisplayOnlyOnHover })}
    >
      {children}
      {(isOverflowing || !displayOnlyOnHover) && (
        <ScrollButtonWrapper className={classnames({ contained })}>
          <IconButton
            icon={faChevronLeft}
            color="tertiary"
            small
            disabled={scrollPosition === 'start'}
            disableDebounce
            aria-label={formatMessage(glossary.previous)}
            onClick={evt => {
              evt.preventDefault();
              scrollContainerRef.current?.scrollBy({
                left:
                  -(wrapperRef.current?.clientWidth || 0) - 8 * itemGap || -10,
                behavior,
              });
            }}
          />
          <IconButton
            icon={faChevronRight}
            color="tertiary"
            small
            disabled={scrollPosition === 'end'}
            disableDebounce
            aria-label={formatMessage(glossary.next)}
            onClick={evt => {
              evt.preventDefault();
              scrollContainerRef.current?.scrollBy({
                left:
                  (wrapperRef.current?.clientWidth || 0) + 8 * itemGap || 10,
                behavior,
              });
            }}
          />
        </ScrollButtonWrapper>
      )}
    </Wrapper>
  );
};
