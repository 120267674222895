import { dateTypePolicy, weiAmountTypePolicy } from "../gql/scalars";

export const scalarTypePolicies = {
  Age: { fields: { cutOffDate: dateTypePolicy } },
  Announcement: { fields: { createdAt: dateTypePolicy } },
  AnnouncementNotification: { fields: { createdAt: dateTypePolicy } },
  AppearanceBoostShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  AuctionDrop: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  AuctionNotification: { fields: { createdAt: dateTypePolicy } },
  AuctionReminder: { fields: { dueDate: dateTypePolicy } },
  AuthorizationRequest: {
    fields: {
      cancelledAt: dateTypePolicy,
      consumedAt: dateTypePolicy,
      createdAt: dateTypePolicy,
      provisionedAt: dateTypePolicy,
      reservedAt: dateTypePolicy,
    },
  },
  BaseballCard: {
    fields: { blueprintLaunchDate: dateTypePolicy, blueprintRevealDate: dateTypePolicy, inSeasonUntil: dateTypePolicy },
  },
  BaseballCardBundleOffer: { fields: { expiresAt: dateTypePolicy } },
  BaseballCardTrade: { fields: { expiresAfter: dateTypePolicy } },
  BaseballCollectionCard: { fields: { heldSince: dateTypePolicy } },
  BaseballFixture: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  BaseballGame: { fields: { startDate: dateTypePolicy } },
  BaseballLineup: { fields: { canceledAt: dateTypePolicy } },
  BaseballPlayer: { fields: { birthDate: dateTypePolicy, birthDay: dateTypePolicy } },
  BaseballScoutingMission: { fields: { endDate: dateTypePolicy } },
  Bid: { fields: { createdAt: dateTypePolicy } },
  Blueprint: { fields: { launchDate: dateTypePolicy, revealDate: dateTypePolicy } },
  Card: {
    fields: {
      blueprintLaunchDate: dateTypePolicy,
      blueprintRevealDate: dateTypePolicy,
      createdAt: dateTypePolicy,
      inSeasonUntil: dateTypePolicy,
      ownerSince: dateTypePolicy,
    },
  },
  CardCollectionCard: { fields: { heldSince: dateTypePolicy } },
  CardCollectionNotification: { fields: { createdAt: dateTypePolicy } },
  CardNotification: { fields: { createdAt: dateTypePolicy } },
  CardSubscription: {
    fields: {
      blueprintLaunchDate: dateTypePolicy,
      blueprintRevealDate: dateTypePolicy,
      createdAt: dateTypePolicy,
      inSeasonUntil: dateTypePolicy,
      ownerSince: dateTypePolicy,
    },
  },
  CommonCardSwapShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  Config: { fields: { ethQuantum: weiAmountTypePolicy, minimumReceiveWeiAmount: weiAmountTypePolicy } },
  ConversionCredit: { fields: { endDate: dateTypePolicy } },
  ConversionCreditCampaign: { fields: { endDate: dateTypePolicy } },
  CurrentUser: {
    fields: {
      availableBalance: weiAmountTypePolicy,
      availableBalanceForWithdrawal: weiAmountTypePolicy,
      bankBalance: weiAmountTypePolicy,
      blockedUntil: dateTypePolicy,
      createdAt: dateTypePolicy,
      matchedContactsAt: dateTypePolicy,
      nextBaseballCardTradeAvailableAfter: dateTypePolicy,
      nextCommonCardDropAvailableAfter: dateTypePolicy,
      nextNBACardTradeAvailableAfter: dateTypePolicy,
      sorareAddressBalance: weiAmountTypePolicy,
      totalBalance: weiAmountTypePolicy,
    },
  },
  DecisiveActionNotification: { fields: { createdAt: dateTypePolicy } },
  DeliverableItemOrder: { fields: { claimedAt: dateTypePolicy, createdAt: dateTypePolicy, expiresAt: dateTypePolicy } },
  DeliverableWithCustomVariantShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  DeliverableWithNoVariantShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  EmailSubscription: { fields: { updatedAt: dateTypePolicy } },
  EmoteSentActivity: { fields: { createdAt: dateTypePolicy } },
  EthDeposit: { fields: { weiAmount: weiAmountTypePolicy } },
  EthereumTransaction: { fields: { confirmedAt: dateTypePolicy } },
  ExchangeRate: { fields: { time: dateTypePolicy } },
  ExternalDepositNotification: { fields: { amount: weiAmountTypePolicy, createdAt: dateTypePolicy } },
  ExtraSwapShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  FastWithdrawal: { fields: { amount: weiAmountTypePolicy, createdAt: dateTypePolicy } },
  FastWithdrawalWithRates: { fields: { amount: weiAmountTypePolicy, createdAt: dateTypePolicy } },
  Fee: { fields: { feeLimit: weiAmountTypePolicy } },
  FirstRivalsLineupSubmittedActivity: { fields: { createdAt: dateTypePolicy } },
  FootballManagerTask: { fields: { claimedAt: dateTypePolicy, readyAt: dateTypePolicy, resetAt: dateTypePolicy } },
  FootballRivalsCurrentManager: {
    fields: {
      nextArenaFreePlayAvailableAt: dateTypePolicy,
      nextArenaRequestAvailableAt: dateTypePolicy,
      nextArenaTicketClaimableAt: dateTypePolicy,
    },
  },
  FootballRivalsDivisionLeaderboard: { fields: { endDate: dateTypePolicy } },
  FootballRivalsDraftableCard: { fields: { canSubInUntil: dateTypePolicy } },
  FootballRivalsDraftablePlayer: { fields: { canSubInUntil: dateTypePolicy } },
  FootballRivalsGameNotification: { fields: { createdAt: dateTypePolicy } },
  FootballRivalsManagerNotification: { fields: { createdAt: dateTypePolicy } },
  FootballRivalsRankingDelta: { fields: { date: dateTypePolicy } },
  FootballRivalsSeason: { fields: { cutOffDate: dateTypePolicy, endDate: dateTypePolicy, startDate: dateTypePolicy } },
  FootballRivalsSquadMembershipsLeaderboard: { fields: { endDate: dateTypePolicy } },
  FootballRivalsSquadsLeaderboard: { fields: { endDate: dateTypePolicy } },
  Game: { fields: { date: dateTypePolicy } },
  GameOfBaseball: { fields: { date: dateTypePolicy } },
  GameOfBasketball: { fields: { date: dateTypePolicy } },
  GiphySentActivity: { fields: { createdAt: dateTypePolicy } },
  Injury: { fields: { expectedEndDate: dateTypePolicy, startDate: dateTypePolicy } },
  JerseyShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  JwtToken: { fields: { expiredAt: dateTypePolicy } },
  KycRequestNotification: { fields: { createdAt: dateTypePolicy } },
  LevelUpShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  LobbyTile: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  ManagerProgressionMission: {
    fields: { claimedAt: dateTypePolicy, completedAt: dateTypePolicy, cutoffAt: dateTypePolicy },
  },
  ManagerProgressionNotification: { fields: { createdAt: dateTypePolicy } },
  ManagerProgressionTask: { fields: { claimedAt: dateTypePolicy, completedAt: dateTypePolicy } },
  MarketplaceSpecialEvent: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  Membership: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  MonetaryAmount: { fields: { wei: weiAmountTypePolicy } },
  NBACard: {
    fields: { blueprintLaunchDate: dateTypePolicy, blueprintRevealDate: dateTypePolicy, inSeasonUntil: dateTypePolicy },
  },
  NBACardBundleOffer: { fields: { expiresAt: dateTypePolicy } },
  NBACardTrade: { fields: { expiresAfter: dateTypePolicy } },
  NBACollectionCard: { fields: { heldSince: dateTypePolicy } },
  NBAFixture: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  NBAGame: { fields: { startDate: dateTypePolicy } },
  NBALineup: { fields: { canceledAt: dateTypePolicy } },
  NBAPlayer: { fields: { birthDate: dateTypePolicy, birthDay: dateTypePolicy } },
  NBARivalsPastGameOrCompetition: { fields: { date: dateTypePolicy } },
  NoCardRoute: { fields: { nextCloseDate: dateTypePolicy, nextOpenDate: dateTypePolicy } },
  OAuthAccessToken: { fields: { createdAt: dateTypePolicy, expiresAt: dateTypePolicy } },
  OfferNotification: { fields: { createdAt: dateTypePolicy } },
  Owner: { fields: { from: dateTypePolicy, settleAt: dateTypePolicy } },
  OwnerWithRates: { fields: { from: dateTypePolicy, settleAt: dateTypePolicy } },
  Payment: { fields: { amount: weiAmountTypePolicy, spentAt: dateTypePolicy } },
  PendingDeposit: { fields: { amount: weiAmountTypePolicy, date: dateTypePolicy } },
  Player: { fields: { birthDate: dateTypePolicy, birthDay: dateTypePolicy, u23EligibleUntil: dateTypePolicy } },
  PlayerInjury: { fields: { returnDate: dateTypePolicy, updateDateTime: dateTypePolicy } },
  PlayerNewsUpdate: { fields: { date: dateTypePolicy } },
  ProbablePitchersForGameWeek: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  RaffleShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  Range: { fields: { max: weiAmountTypePolicy, min: weiAmountTypePolicy } },
  Referral: {
    fields: {
      completedAt: dateTypePolicy,
      expirationDate: dateTypePolicy,
      refereeConfirmedAt: dateTypePolicy,
      refereeInvitationSentAt: dateTypePolicy,
    },
  },
  ReferralLeaderboard: { fields: { lastRefreshedAt: dateTypePolicy } },
  ReferralLeaderboardRanking: { fields: { scoreReachedAt: dateTypePolicy } },
  ReferralRewardNotification: { fields: { createdAt: dateTypePolicy } },
  ReminderNotification: { fields: { createdAt: dateTypePolicy } },
  RivalsGameEndedActivity: { fields: { createdAt: dateTypePolicy } },
  RivalsGameStartedActivity: { fields: { createdAt: dateTypePolicy } },
  RivalsSquadMembership: { fields: { createdAt: dateTypePolicy } },
  RivalsUserGroupMembership: { fields: { createdAt: dateTypePolicy } },
  SaleNotification: { fields: { createdAt: dateTypePolicy } },
  ShopItem: { fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy } },
  ShopItemNotification: { fields: { createdAt: dateTypePolicy } },
  SkinShopItem: {
    fields: { createdAt: dateTypePolicy, expirationDate: dateTypePolicy, myLimitResetAt: dateTypePolicy },
  },
  So5Fixture: {
    fields: {
      cutOffDate: dateTypePolicy,
      endDate: dateTypePolicy,
      rewardsDeliveryDate: dateTypePolicy,
      startDate: dateTypePolicy,
    },
  },
  So5Leaderboard: { fields: { cutOffDate: dateTypePolicy, endDate: dateTypePolicy, startDate: dateTypePolicy } },
  So5League: { fields: { rewardPoolComputedAt: dateTypePolicy } },
  So5Lineup: { fields: { cancelledAt: dateTypePolicy } },
  So5LineupNotification: { fields: { createdAt: dateTypePolicy } },
  So5Reward: { fields: { weiAmount: weiAmountTypePolicy } },
  So5UserGroupMembership: { fields: { createdAt: dateTypePolicy } },
  So5UserGroupNotification: { fields: { createdAt: dateTypePolicy } },
  StarkexLimitOrderAuthorizationRequest: {
    fields: { amountBuy: weiAmountTypePolicy, amountSell: weiAmountTypePolicy },
  },
  StarkexTransferAuthorizationRequest: { fields: { amount: weiAmountTypePolicy } },
  StarkwarePrivateAccount: {
    fields: {
      availableBalance: weiAmountTypePolicy,
      availableBalanceForWithdrawal: weiAmountTypePolicy,
      totalBalance: weiAmountTypePolicy,
    },
  },
  StarterPack: { fields: { endDate: dateTypePolicy } },
  StartingLineupRevealedActivity: { fields: { createdAt: dateTypePolicy } },
  Story: { fields: { expiresAt: dateTypePolicy } },
  Suspension: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  TeamsPlayingNextGameWeek: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  Token: { fields: { privateMinPrice: weiAmountTypePolicy, publicMinPrice: weiAmountTypePolicy } },
  TokenAuction: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy, updatedAt: dateTypePolicy } },
  TokenBid: { fields: { createdAt: dateTypePolicy } },
  TokenMonetaryReward: { fields: { amount: weiAmountTypePolicy } },
  TokenMyBid: { fields: { createdAt: dateTypePolicy } },
  TokenOffer: {
    fields: {
      acceptedAt: dateTypePolicy,
      cancelledAt: dateTypePolicy,
      createdAt: dateTypePolicy,
      endDate: dateTypePolicy,
      startDate: dateTypePolicy,
      updatedAt: dateTypePolicy,
    },
  },
  TokenOfferSide: { fields: { wei: weiAmountTypePolicy } },
  TokenOwner: { fields: { from: dateTypePolicy, priceWei: weiAmountTypePolicy, settleAt: dateTypePolicy } },
  TokenPrice: { fields: { date: dateTypePolicy } },
  TokenPrimaryOffer: {
    fields: {
      cancelledAt: dateTypePolicy,
      endDate: dateTypePolicy,
      priceWei: weiAmountTypePolicy,
      settledAt: dateTypePolicy,
      startDate: dateTypePolicy,
      updatedAt: dateTypePolicy,
    },
  },
  TransferRequest: { fields: { amount: weiAmountTypePolicy } },
  User: { fields: { createdAt: dateTypePolicy } },
  UserAccountEntry: { fields: { date: dateTypePolicy } },
  UserAward: { fields: { updatedAt: dateTypePolicy } },
  UserAwardNotification: { fields: { createdAt: dateTypePolicy } },
  UserCardCollection: { fields: { completedAt: dateTypePolicy } },
  UserDevice: { fields: { lastUsedAt: dateTypePolicy } },
  UserDeviceEvent: { fields: { lastUsedAt: dateTypePolicy } },
  UserGroupCreatedActivity: { fields: { createdAt: dateTypePolicy } },
  UserGroupMemberJoinedActivity: { fields: { createdAt: dateTypePolicy } },
  UserGroupRankedActivity: { fields: { createdAt: dateTypePolicy } },
  UserOwner: { fields: { from: dateTypePolicy, settleAt: dateTypePolicy } },
  UserProfile: { fields: { proSince: dateTypePolicy } },
  UserRelationNotification: { fields: { createdAt: dateTypePolicy } },
  UserWithSubscriptionSlug: { fields: { createdAt: dateTypePolicy } },
  Validity: { fields: { upcomingAt: dateTypePolicy } },
  Withdrawal: {
    fields: { agreedFeeAmount: weiAmountTypePolicy, amount: weiAmountTypePolicy, createdAt: dateTypePolicy },
  },
  WithdrawalWithRates: {
    fields: { agreedFeeAmount: weiAmountTypePolicy, amount: weiAmountTypePolicy, createdAt: dateTypePolicy },
  },
};
