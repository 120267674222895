import useScrollToHook from './useScrollTo';
import useQueryParameter from './useScrollToQueryParameter';

export enum ScrollTo {
  Bid = 'Bid',
}

export const useScrollTo = useScrollToHook;

export const useScrollToQueryParameter = useQueryParameter;
