import { faCreditCard } from '@fortawesome/pro-solid-svg-icons';

import PaymentMethodIcon, { Props as PaymentMethodIconProps } from '..';

type Props = Pick<PaymentMethodIconProps, 'size'>;

const CreditCardIcon = ({ size }: Props) => {
  return (
    <PaymentMethodIcon
      size={size}
      backgroundColor="var(--c-brand-300)"
      color="var(--c-neutral-1000)"
      icon={faCreditCard}
    />
  );
};

export default CreditCardIcon;
