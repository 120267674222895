import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';

import { ManagerProgressionCategoryType } from '__generated__/globalTypes';
import Bold from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { FOOTBALL_PLAY_RIVALS_ARENA } from 'constants/__generated__/routes';
import { ANY_SPORT_MY_CLUB_CHALLENGES } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { generateSportPath } from 'lib/routing/generateSportPath';
import { getSportFromSportOrShared } from 'lib/sports';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { ManagerProgressionNotification_managerProgressionNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: ManagerProgressionNotification_managerProgressionNotification;
};

const messages = defineMessages({
  taskCompleted: {
    id: 'Activity.Notifications.managerProgressionTaskCompleted',
    defaultMessage:
      'You have completed the Challenge <b>{title}</b>. Claim your reward!',
  },
  taskClaimed: {
    id: 'Activity.Notifications.managerProgressionTaskClaimed',
    defaultMessage: 'You have completed the Challenge <b>{title}</b>',
  },
});
export const ManagerProgressionNotification = ({
  notification,
  ...rest
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { createdAt, read, sport, managerProgressionTask } = notification;
  if (!currentUser) {
    return null;
  }

  const { category } = managerProgressionTask;
  const link =
    category.type === ManagerProgressionCategoryType.RIVALS_DAILY
      ? FOOTBALL_PLAY_RIVALS_ARENA
      : `${generateSportPath(ANY_SPORT_MY_CLUB_CHALLENGES, {
          params: {
            slug: currentUser.slug,
          },
          sport: getSportFromSportOrShared(sport) || undefined,
        })}?category=${managerProgressionTask.category?.name}`;

  return (
    <DumbNotification
      title={
        <FormattedMessage
          {...(managerProgressionTask.claimedAt
            ? messages.taskClaimed
            : messages.taskCompleted)}
          values={{
            title: managerProgressionTask.title,
            b: Bold,
          }}
        />
      }
      link={link}
      createdAt={createdAt}
      read={read}
      sport={sport}
      avatarUrl={managerProgressionTask.category?.badgeUrl}
      {...rest}
    />
  );
};

ManagerProgressionNotification.fragments = {
  managerProgressionNotification: gql`
    fragment ManagerProgressionNotification_managerProgressionNotification on ManagerProgressionNotification {
      id
      ...Notification_notificationInterface
      managerProgressionTask {
        id
        title
        claimedAt
        category {
          id
          name
          badgeUrl
          type
        }
      }
    }
    ${commonNotificationInterfaceFragment}
  ` as TypedDocumentNode<ManagerProgressionNotification_managerProgressionNotification>,
};
