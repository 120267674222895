import { TypedDocumentNode, gql } from '@apollo/client';

import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { DumbNotification } from 'components/activity/DumbNotification';
import { FOOTBALL_PLAY_RIVALS_MATCHES } from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { FootballRivalsManagerNotification_footballRivalsManagerNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: FootballRivalsManagerNotification_footballRivalsManagerNotification;
};
export const FootballRivalsManagerNotification = ({
  notification,
  ...rest
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { createdAt, title, body, read, sport } = notification;

  if (!currentUser) {
    return null;
  }
  return (
    <DumbNotification
      title={
        <Vertical gap={0}>
          <LabelM as="span" bold>
            {title}
          </LabelM>
          <LabelM as="span" color="var(--c-neutral-600)">
            {body}
          </LabelM>
        </Vertical>
      }
      link={FOOTBALL_PLAY_RIVALS_MATCHES}
      createdAt={createdAt}
      read={read}
      sport={sport}
      {...rest}
    />
  );
};

FootballRivalsManagerNotification.fragments = {
  footballRivalsManagerNotification: gql`
    fragment FootballRivalsManagerNotification_footballRivalsManagerNotification on FootballRivalsManagerNotification {
      id
      createdAt
      title
      body
      read
      sport
      ...Notification_notificationInterface
    }
    ${commonNotificationInterfaceFragment}
  ` as TypedDocumentNode<FootballRivalsManagerNotification_footballRivalsManagerNotification>,
};
