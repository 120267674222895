import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faChevronRight,
  faPaperPlaneTop,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { BodyM } from '@sorare/core/src/atoms/typography';
import { INVITE } from '@sorare/core/src/constants/routes';

import {
  ReferYourFriends_configBaseball,
  ReferYourFriends_configNBA,
} from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0 })<{
  $backgroundImageUrl?: string;
}>`
  border-radius: var(--double-unit);
  background-color: var(--c-neutral-200);
  ${props =>
    props.$backgroundImageUrl &&
    css`
      background-image: url(${props.$backgroundImageUrl});
      background-position: right;
      background-blend-mode: overlay, normal;
      background-size: contain;
      background-repeat: no-repeat;
    `}
`;

const StyledLink = styled(Link)`
  padding: var(--intermediate-unit);
`;

interface Props {
  title?: string;
  description?: string;
  backgroundImageUrl?: string;
}

export const ReferYourFriends = ({
  description,
  title,
  backgroundImageUrl,
}: Props) => {
  return (
    <Root $backgroundImageUrl={backgroundImageUrl}>
      <StyledLink to={INVITE}>
        <SBHorizontal>
          <Horizontal gap={1.5}>
            <FontAwesomeIcon icon={faPaperPlaneTop} />
            <Vertical gap={0}>
              <BodyM as="p" bold>
                {title || (
                  <FormattedMessage
                    id="Profile.Links.referYourFriends"
                    defaultMessage="Refer your friends"
                  />
                )}
              </BodyM>
              <BodyM as="p" color="var(--c-neutral-600)">
                {description || (
                  <FormattedMessage
                    id="Profile.Links.inviteFriends"
                    defaultMessage="Invite friends, receive referral credits"
                  />
                )}
              </BodyM>
            </Vertical>
          </Horizontal>
          <FontAwesomeIcon icon={faChevronRight} />
        </SBHorizontal>
      </StyledLink>
    </Root>
  );
};

ReferYourFriends.fragments = {
  configBaseball: gql`
    fragment ReferYourFriends_configBaseball on Config {
      id
      referralLobbyTile: lobbyTile(sport: BASEBALL, position: REFERRAL) {
        id
        title
        description
        titleImageUrl
      }
    }
  ` as TypedDocumentNode<ReferYourFriends_configBaseball>,
  configNBA: gql`
    fragment ReferYourFriends_configNBA on Config {
      id
      referralLobbyTile: lobbyTile(sport: NBA, position: REFERRAL) {
        id
        title
        description
        titleImageUrl
      }
    }
  ` as TypedDocumentNode<ReferYourFriends_configNBA>,
};
