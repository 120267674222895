import { Outlet } from 'react-router-dom';

import { EnsureTopVisibleOnMount } from '@sorare/core/src/routing/EnsureTopVisibleOnMount';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

const Layout = () => (
  <RequireAuth>
    <EnsureTopVisibleOnMount>
      <Outlet />
    </EnsureTopVisibleOnMount>
  </RequireAuth>
);
export default Layout;
