import { TypedDocumentNode, gql } from '@apollo/client';

import useQuery from 'hooks/graphql/useQuery';

import {
  GetSupportedCountriesQuery,
  GetSupportedCountriesQueryVariables,
} from './__generated__/useGetSupportedCountries.graphql';

const GET_SUPPORTED_COUNTRIES_QUERY = gql`
  query GetSupportedCountriesQuery {
    config {
      id
      fiatWalletCountries {
        slug
        name
      }
    }
  }
` as TypedDocumentNode<
  GetSupportedCountriesQuery,
  GetSupportedCountriesQueryVariables
>;

const useGetSupportedCountries = () => {
  const { data, loading } = useQuery(GET_SUPPORTED_COUNTRIES_QUERY);

  return {
    supportedCountries:
      data?.config?.fiatWalletCountries
        ?.filter(Boolean)
        ?.sort((a, b) => a.slug.localeCompare(b.slug)) || [],
    loading,
  };
};

export default useGetSupportedCountries;
