import { memo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal } from 'atoms/layout/flex';
import Notifications from 'components/notification/Notifications';
import ResponsiveSearchBar from 'components/search/ResponsiveSearchBar';
import { FOOTBALL_PLAY_RIVALS_WILDCARD } from 'constants/routes';
import useIsLoggedIn from 'hooks/auth/useIsLoggedIn';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import AppBarProvider from 'routing/MultiSportAppBar/context/Provider';
import { mobileAndAbove, tabletAndAbove } from 'style/mediaQuery';

import { MasterSwitch } from '../MasterSwitch';
import { CoinsAndBalances } from './CoinsAndBalances';
import { LandingAppHeader } from './LandingAppHeader';

const Container = styled(FullWidthContainer)`
  border-bottom: 1px solid var(--c-neutral-300);
`;

const Left = styled(Horizontal)`
  grid-area: left;
  flex-shrink: 1;
  min-width: 0;
`;
const SubNav = styled(Horizontal).attrs({ id: 'subnav', gap: 0.5 })`
  @media ${tabletAndAbove} {
    gap: var(--unit);
  }
`;

const Wrapper = styled.div`
  height: 100%;
  gap: var(--unit);
  display: grid;
  padding: var(--unit) 0;
  grid-template-areas: 'left buttons';
  grid-template-columns: 1fr max-content;
  align-items: center;
  @media ${tabletAndAbove} {
    align-items: center;
    padding: var(--intermediate-unit) 0;
  }
  &:has(${Left} button) {
    h1 {
      text-align: center;
      @media ${mobileAndAbove} {
        text-align: left;
      }
    }
    ${SubNav} {
      display: none;
      @media ${mobileAndAbove} {
        display: flex;
      }
    }
  }
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  @media ${mobileAndAbove} {
    justify-content: space-between;
    align-items: center;
  }
  > * {
    justify-content: flex-end;
  }
`;

const PageHeader = styled(Horizontal).attrs({ gap: 0.5 })`
  flex: 1;
  max-width: 100%;
  @media ${tabletAndAbove} {
    gap: var(--double-unit);
  }
`;
const PageHeaderRightButton = styled.div`
  margin-left: auto;
`;

const PageHeaderTitle = styled.div`
  overflow: hidden;
  width: 100%;
`;

const PageHeaderBreadcrumb = styled.div`
  overflow: hidden;
`;

type Props = {
  headerMode?: 'ios' | 'loggedIn';
  isRivals: boolean;
};
const Header = memo(function Header({ headerMode, isRivals }: Props) {
  const isDesktopAndAbove = useIsDesktopAndAbove();

  switch (headerMode) {
    case 'ios': {
      return (
        <Container>
          <Wrapper>
            <PageHeader>
              <PageHeaderTitle id="page-header-title" />
              <PageHeaderBreadcrumb id="page-header-title-breadcrumb" />
            </PageHeader>
          </Wrapper>
        </Container>
      );
    }
    case 'loggedIn': {
      return (
        <AppBarProvider>
          <Container>
            <Wrapper>
              <Left>
                <PageHeader>
                  <PageHeaderTitle id="page-header-title" />
                  <PageHeaderBreadcrumb id="page-header-title-breadcrumb" />
                  <PageHeaderRightButton id="page-header-right-button" />
                </PageHeader>
              </Left>
              <Buttons>
                <Horizontal gap={isDesktopAndAbove ? 2 : 0.5}>
                  <SubNav>
                    {!isRivals && (
                      <CoinsAndBalances compact={!isDesktopAndAbove} />
                    )}
                    <ResponsiveSearchBar />
                    <Notifications />
                  </SubNav>
                  <MasterSwitch />
                </Horizontal>
              </Buttons>
            </Wrapper>
          </Container>
        </AppBarProvider>
      );
    }
    default: {
      return <LandingAppHeader />;
    }
  }
});

export const AppHeader = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const { isIosApp } = useIsMobileApp();
  let headerMode: Props['headerMode'] = isIosApp ? 'ios' : undefined;
  headerMode ||= isLoggedIn ? 'loggedIn' : undefined;
  const isRivals = !!matchPath(FOOTBALL_PLAY_RIVALS_WILDCARD, pathname);

  return <Header headerMode={headerMode} isRivals={isRivals} />;
};
