import classNames from 'classnames';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { updateUserProfileInput } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import {
  GraphQLResult,
  GraphqlForm,
  SubmitButtonProps,
  SwitchField,
  TextField,
} from 'components/form/Form';
import FormLabel from 'components/form/FormLabel';
import UploadFile from 'components/form/UploadFile';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import useLifecycle, { LIFECYCLE, Lifecycle } from 'hooks/useLifecycle';
import useUpdateUserProfile from 'hooks/useUpdateUserProfile';
import { useUploadWithPresign } from 'hooks/useUploadWithPresign';
import { glossary, userAttributes } from 'lib/glossary';
import { formatGqlErrors } from 'lib/gql';

import SettingsSection from '../SettingsSection';

const messages = defineMessages({
  title: {
    id: 'Settings.updateProfile.title',
    defaultMessage: 'User Profile',
  },
  nicknameWarning: {
    id: 'Settings.updateProfile.nicknameWarning',
    defaultMessage:
      'Username can only be changed once during a three-month period',
  },
  hideOnlineStatusTitle: {
    id: 'Settings.updateProfile.hideOnlineStatusTitle.title',
    defaultMessage: 'Always appear offline',
  },
  hideOnlineStatusDescription: {
    id: 'Settings.updateProfile.hideOnlineStatusTitle.descriptionAlwaysAppearOffline',
    defaultMessage: 'Always appear offline to other managers.',
  },
});

const ContentRoot = styled(Vertical).attrs({ gap: 0 })`
  align-items: stretch;
  gap: var(--double-unit) 0;

  &.withinSettings {
    padding: 0;
  }
`;
const Form = styled(GraphqlForm)`
  height: 100%;
  margin-bottom: 0;
`;
const ButtonRow = styled.div<{ fullWidth: boolean }>`
  ${props =>
    props.fullWidth &&
    css`
      & > * {
        width: 100%;
      }
    `}
  margin-top: auto;
`;

type Props = {
  withinSettings?: boolean;
  onSuccess?: () => void;
  onSubmit?: (attributes: any) => void;
};

const Content = ({
  SubmitButton,
  setPicture,
  withinSettings,
}: {
  SubmitButton: React.ComponentType<React.PropsWithChildren<SubmitButtonProps>>;
  setPicture: (file: File | null) => void;
  withinSettings?: boolean;
}) => {
  const { currentUser } = useCurrentUserContext();
  const { formatMessage } = useIntl();
  if (!currentUser) return null;

  const { nickname, pictureUrl, status } = currentUser;

  return (
    <ContentRoot className={classNames({ withinSettings })}>
      {!withinSettings && (
        <FormLabel id="picture">
          <FormattedMessage id="UpdateProfile.avatar" defaultMessage="Avatar" />
        </FormLabel>
      )}
      <UploadFile
        name="picture"
        currentFileUrl={pictureUrl}
        onChange={(pic: FileWithDataURL) => setPicture(pic.file)}
        type="image/*"
        buttonLabel={
          <FormattedMessage
            id="UploadFile.cta"
            defaultMessage="Upload an image"
          />
        }
        row={!withinSettings}
        size={withinSettings ? 'xl' : 'sm'}
      />
      <TextField
        name="nickname"
        label={formatMessage(userAttributes.nickname)}
        defaultValue={nickname}
        helperText={formatMessage(messages.nicknameWarning)}
      />
      <TextField
        name="status"
        maxLength={100}
        label={formatMessage(userAttributes.status)}
        defaultValue={status || undefined}
      />
      <SwitchField
        name="hideOnlineStatus"
        label={formatMessage(messages.hideOnlineStatusTitle)}
        helperText={formatMessage(messages.hideOnlineStatusDescription)}
        defaultValue={
          !!(currentUser.userSettings.lifecycle as Lifecycle)?.hideOnlineStatus
        }
      />
      <ButtonRow fullWidth={!withinSettings}>
        <SubmitButton size="small">
          <FormattedMessage {...glossary.submit} />
        </SubmitButton>
      </ButtonRow>
    </ContentRoot>
  );
};

const UpdateProfile = ({ withinSettings, onSuccess, onSubmit }: Props) => {
  const updateUserProfile = useUpdateUserProfile();
  const { showNotification } = useSnackNotificationContext();
  const { update: updateLifecycle } = useLifecycle();
  const uploadWithPresign = useUploadWithPresign();

  const submit = async (
    {
      status,
      nickname,
      picturePresignedKey,
      hideOnlineStatus,
    }: updateUserProfileInput & { hideOnlineStatus?: string },
    onResult?: (result: GraphQLResult) => void
  ) => {
    if (hideOnlineStatus !== undefined) {
      await updateLifecycle(
        LIFECYCLE.hideOnlineStatus,
        hideOnlineStatus === SwitchField.ON
      );
    }

    const input: updateUserProfileInput = {};
    if (status) input.status = status;
    if (nickname) input.nickname = nickname;
    if (picturePresignedKey) input.picturePresignedKey = picturePresignedKey;
    const result = await updateUserProfile(input);

    if (result?.errors?.length) {
      showNotification('errors', { errors: formatGqlErrors(result.errors) });
    }

    if (result && onResult) onResult(result);
  };

  const setPicture = async (picture: File | null) => {
    if (!picture) return;

    const picturePresignedKey = await uploadWithPresign(picture);
    if (picturePresignedKey) submit({ picturePresignedKey });
  };

  return (
    <Form
      onSubmit={(attributes, onResult) => {
        submit(attributes, onResult);
        onSubmit?.(attributes);
      }}
      onSuccess={() => {
        onSuccess?.();
      }}
      render={(
        Error: React.ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: React.ComponentType<
          React.PropsWithChildren<SubmitButtonProps>
        >
      ) =>
        withinSettings ? (
          <SettingsSection title={messages.title}>
            <Content
              SubmitButton={SubmitButton}
              setPicture={setPicture}
              withinSettings={withinSettings}
            />
          </SettingsSection>
        ) : (
          <Content
            SubmitButton={SubmitButton}
            setPicture={setPicture}
            withinSettings={withinSettings}
          />
        )
      }
    />
  );
};

export default UpdateProfile;
