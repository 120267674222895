import { MessageDescriptor, defineMessage, defineMessages } from 'react-intl';

import {
  NBAConference,
  NBAPlayerPosition,
} from '@sorare/core/src/__generated__/globalTypes';

export const CURRENT_SEASON_CARD_SEASON = '2023';

export const messages = defineMessages({
  playerAdd: {
    id: 'nba.slot.playerAdd',
    defaultMessage: 'Add player',
  },

  pointCap: {
    id: 'nba.requirements.pointCap',
    defaultMessage: '{capLimit} Point Cap',
  },
  noCap: {
    id: 'nba.requirements.noCap',
    defaultMessage: 'No Cap',
  },
  allowMVP: {
    id: 'nba.requirements.allowMVP',
    defaultMessage: 'MVP',
  },
  disallowMVP: {
    id: 'nba.requirements.disallowMVP',
    defaultMessage: 'No MVP',
  },
  guard: {
    id: 'nba.guard.name',
    defaultMessage: 'Guard',
  },
  forward: {
    id: 'nba.forward.name',
    defaultMessage: 'Forward',
  },
  center: {
    id: 'nba.center.name',
    defaultMessage: 'Center',
  },
});

export type NBASlot = {
  id: string;
  i18nName: MessageDescriptor;
  initials?: string;
  validPositions?: NBAPlayerPosition[];
  hint?: MessageDescriptor | null;
};

export const SLOTS: NBASlot[] = [
  {
    id: 'player1',
    i18nName: messages.playerAdd,
  },
  {
    id: 'player2',
    i18nName: messages.playerAdd,
  },
  {
    id: 'player3',
    i18nName: messages.playerAdd,
  },
  {
    id: 'player4',
    i18nName: messages.playerAdd,
  },
  {
    id: 'player5',
    i18nName: messages.playerAdd,
  },
];

export const POSITIONAL_SLOTS: Record<
  NBAPlayerPosition,
  { desc: string } & Partial<NBASlot>
> = {
  [NBAPlayerPosition.NBA_GUARD]: {
    desc: 'Guard',
    i18nName: messages.guard,
    initials: 'G',
  },
  [NBAPlayerPosition.NBA_FORWARD]: {
    desc: 'Forward',
    i18nName: messages.forward,
    initials: 'F',
  },
  [NBAPlayerPosition.NBA_CENTER]: {
    desc: 'Center',
    i18nName: messages.center,
    initials: 'C',
  },
};

export const CONFERENCES = {
  [NBAConference.WESTERN]: [
    { slug: 'denver-nuggets', name: 'Denver Nuggets' },
    { slug: 'memphis-grizzlies', name: 'Memphis Grizzlies' },
    { slug: 'new-orleans-pelicans', name: 'New Orleans Pelicans' },
    { slug: 'sacramento-kings', name: 'Sacramento Kings' },
    { slug: 'dallas-mavericks', name: 'Dallas Mavericks' },
    { slug: 'la-clippers', name: 'LA Clippers' },
    { slug: 'golden-state-warriors', name: 'Golden State Warriors' },
    { slug: 'utah-jazz', name: 'Utah Jazz' },
    { slug: 'minnesota-timberwolves', name: 'Minnesota Timberwolves' },
    { slug: 'portland-trail-blazers', name: 'Portland Trail Blazers' },
    { slug: 'oklahoma-city-thunder', name: 'Oklahoma City Thunder' },
    { slug: 'phoenix-suns', name: 'Phoenix Suns' },
    { slug: 'los-angeles-lakers', name: 'Los Angeles Lakers' },
    { slug: 'san-antonio-spurs', name: 'San Antonio Spurs' },
    { slug: 'houston-rockets', name: 'Houston Rockets' },
  ],
  [NBAConference.EASTERN]: [
    { slug: 'boston-celtics', name: 'Boston Celtics' },
    { slug: 'brooklyn-nets', name: 'Brooklyn Nets' },
    { slug: 'milwaukee-bucks', name: 'Milwaukee Bucks' },
    { slug: 'philadelphia-76ers', name: 'Philadelphia 76ers' },
    { slug: 'cleveland-cavaliers', name: 'Cleveland Cavaliers' },
    { slug: 'new-york-knicks', name: 'New York Knicks' },
    { slug: 'miami-heat', name: 'Miami Heat' },
    { slug: 'indiana-pacers', name: 'Indiana Pacers' },
    { slug: 'atlanta-hawks', name: 'Atlanta Hawks' },
    { slug: 'chicago-bulls', name: 'Chicago Bulls' },
    { slug: 'toronto-raptors', name: 'Toronto Raptors' },
    { slug: 'washington-wizards', name: 'Washington Wizards' },
    { slug: 'orlando-magic', name: 'Orlando Magic' },
    { slug: 'detroit-pistons', name: 'Detroit Pistons' },
    { slug: 'charlotte-hornets', name: 'Charlotte Hornets' },
  ],
};

export const COLLECTIONS_SLOTS: {
  id: string;
  i18nName: MessageDescriptor;
  validPositions: NBAPlayerPosition[];
}[] = [
  {
    id: 'Forwards',
    i18nName: defineMessage({
      id: 'slot.forwards.name',
      defaultMessage: 'Forwards',
    }),
    validPositions: [NBAPlayerPosition.NBA_FORWARD],
  },
  {
    id: 'Centers',
    i18nName: defineMessage({
      id: 'slot.centers.name',
      defaultMessage: 'Centers',
    }),
    validPositions: [NBAPlayerPosition.NBA_CENTER],
  },
  {
    id: 'Guards',
    i18nName: defineMessage({
      id: 'slot.guards.name',
      defaultMessage: 'Guards',
    }),
    validPositions: [NBAPlayerPosition.NBA_GUARD],
  },
];
