import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from 'gql';

import { AnnouncementNotification } from './AnnouncementNotification';
import { AuctionNotification } from './AuctionNotification';
import { AuctionReminderNotification } from './AuctionReminderNotification';
import { CardCollectionNotification } from './CardCollectionNotification';
import { CardNotification } from './CardNotification';
import { ExternalDepositNotification } from './ExternalDepositNotification';
import { FootballRivalsGameNotification } from './FootballRivalsGameNotification';
import { FootballRivalsManagerNotification } from './FootballRivalsManagerNotification';
import { KycRequestNotification } from './KycRequestNotification';
import { ManagerProgressionNotification } from './ManagerProgressionNotification';
import { OfferNotification } from './OfferNotification';
import { ReferralRewardNotification } from './ReferralRewardNotification';
import { SaleNotification } from './SaleNotification';
import { ShopItemNotification } from './ShopItemNotification';
import { So5LineupNotification } from './So5LineupNotification';
import { UserRelationNotification } from './UserRelationNotification';
import { Notification_notification } from './__generated__/index.graphql';
import { CommonNotificationProps } from './types';

type Props = CommonNotificationProps & {
  notification: Notification_notification;
};

export const Notification = ({ notification, ...rest }: Props) => {
  if (isType(notification, 'AuctionNotification')) {
    return <AuctionNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'SaleNotification')) {
    return <SaleNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'OfferNotification')) {
    return <OfferNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'So5LineupNotification')) {
    return <So5LineupNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'AnnouncementNotification')) {
    return <AnnouncementNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'ReferralRewardNotification')) {
    return <ReferralRewardNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'CardNotification')) {
    return <CardNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'CardCollectionNotification')) {
    return <CardCollectionNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'ExternalDepositNotification')) {
    return (
      <ExternalDepositNotification notification={notification} {...rest} />
    );
  }
  if (isType(notification, 'KycRequestNotification')) {
    return <KycRequestNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'FootballRivalsGameNotification')) {
    return (
      <FootballRivalsGameNotification notification={notification} {...rest} />
    );
  }
  if (isType(notification, 'UserRelationNotification')) {
    return <UserRelationNotification notification={notification} {...rest} />;
  }
  if (isType(notification, 'ManagerProgressionNotification')) {
    return (
      <ManagerProgressionNotification notification={notification} {...rest} />
    );
  }
  if (isType(notification, 'FootballRivalsManagerNotification')) {
    return (
      <FootballRivalsManagerNotification
        notification={notification}
        {...rest}
      />
    );
  }
  if (isType(notification, 'ReminderNotification')) {
    return (
      <AuctionReminderNotification notification={notification} {...rest} />
    );
  }
  if (isType(notification, 'ShopItemNotification')) {
    return <ShopItemNotification notification={notification} {...rest} />;
  }

  return null;
};

Notification.fragments = {
  notification: gql`
    fragment Notification_notification on Notification {
      ...AuctionNotification_auctionNotification
      ...SaleNotification_saleNotification
      ...OfferNotification_offerNotification
      ...So5LineupNotification_so5LineupNotification
      ...AnnouncementNotification_announcementNotification
      ...ReferralRewardNotification_referralRewardNotification
      ...CardNotification_cardNotification
      ...CardCollectionNotification_cardCollectionNotification
      ...ExternalDepositNotification_externalDepositNotification
      ...KycRequestNotification_kycRequestNotification
      ...FootballRivalsGameNotification_footballRivalsGameNotification
      ...UserRelationNotification_userRelationNotification
      ...ManagerProgressionNotification_managerProgressionNotification
      ...FootballRivalsManagerNotification_footballRivalsManagerNotification
      ...ReminderNotification_auctionReminderNotification
      ...ShopItemNotification_shopItemNotification
    }
    ${AuctionNotification.fragments.auctionNotification}
    ${SaleNotification.fragments.saleNotification}
    ${OfferNotification.fragments.offerNotification}
    ${So5LineupNotification.fragments.so5LineupNotification}
    ${AnnouncementNotification.fragments.announcementNotification}
    ${ReferralRewardNotification.fragments.referralRewardNotification}
    ${CardNotification.fragments.cardNotification}
    ${CardCollectionNotification.fragments.cardCollectionNotification}
    ${ExternalDepositNotification.fragments.externalDepositNotification}
    ${KycRequestNotification.fragments.kycRequestNotification}
    ${FootballRivalsGameNotification.fragments.footballRivalsGameNotification}
    ${UserRelationNotification.fragments.userRelationNotification}
    ${ManagerProgressionNotification.fragments.managerProgressionNotification}
    ${FootballRivalsManagerNotification.fragments
      .footballRivalsManagerNotification}
    ${AuctionReminderNotification.fragments.auctionReminderNotification}
    ${ShopItemNotification.fragments.shopItemNotification}
  ` as TypedDocumentNode<Notification_notification>,
};

export default Notification;
