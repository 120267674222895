import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';

import { DeliverableItemStatus } from '@sorare/core/src/__generated__/globalTypes';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { FOOTBALL_RIVALS } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { isType } from '@sorare/core/src/gql';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { useCloseDialog } from '@sorare/core/src/hooks/navigation/useCloseDialog';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import useDeeplinkFromNativeApp from '@sorare/core/src/hooks/useDeeplinkFromNativeApp';

import DeliverableItemOrderFormDialog, {
  deliverableItemOrderFragment,
} from 'components/rewards/deliverableItem/DeliverableItemOrderFormDialog';

import {
  UnclaimedDeliverableItemOrder,
  UnclaimedDeliverableItemOrderVariables,
} from './__generated__/index.graphql';

const UNCLAIMED_DELIVERABLE_ITEM_ORDER = gql`
  query UnclaimedDeliverableItemOrder($deliverableItemOrderId: ID!) {
    node(id: $deliverableItemOrderId) {
      id
      ... on DeliverableItemOrder {
        id
        aasmState
        user {
          slug
        }
        ...DeliverableItemOrderFormDialog_deliverableItemOrderFragment
      }
    }
  }
  ${deliverableItemOrderFragment}
` as TypedDocumentNode<
  UnclaimedDeliverableItemOrder,
  UnclaimedDeliverableItemOrderVariables
>;

export const DeliverableItemOrderFormRoutedDialog = () => {
  const { currentUser } = useCurrentUserContext();
  const [uncachedData, setUncachedData] =
    useState<UnclaimedDeliverableItemOrder | null>(null);
  const deliverableItemOrderId =
    useDialogParam(DialogKey.deliveryItemOrder) || '';
  const closeDialog = useCloseDialog();
  const { isWebviewFromNativeApp, navigateWithDeeplink } =
    useDeeplinkFromNativeApp();

  const { data } = useQuery(UNCLAIMED_DELIVERABLE_ITEM_ORDER, {
    variables: {
      deliverableItemOrderId,
    },
    skip: !deliverableItemOrderId,
  });

  const onClose = () => {
    if (isWebviewFromNativeApp) {
      navigateWithDeeplink(FOOTBALL_RIVALS);
    } else {
      closeDialog(DialogKey.deliveryItemOrder);
    }
  };

  if (!uncachedData && data) {
    setUncachedData(data);
  }

  const deliverableItemOrder = uncachedData?.node;

  if (
    !deliverableItemOrder ||
    !isType(deliverableItemOrder, 'DeliverableItemOrder') ||
    currentUser?.slug !== deliverableItemOrder.user.slug
  ) {
    return null;
  }

  if (deliverableItemOrder.aasmState !== DeliverableItemStatus.READY) {
    onClose();
    return null;
  }

  return (
    <DeliverableItemOrderFormDialog
      open
      deliverableItemOrder={deliverableItemOrder}
      onCancel={onClose}
      onDone={onClose}
    />
  );
};
