import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';

import { isType } from '@sorare/core/src/gql';

import TokenStatsIcon from 'components/token/TokenStatsIcon';

import BaseballCardProperties from './BaseballCardProperties';
import FootballCardProperties from './FootballCardProperties';
import NBACardProperties from './NBACardProperties';
import {
  CardPropertiesWithStats_anyCard,
  CardProperties_anyCard,
} from './__generated__/index.graphql';

interface Props {
  card: CardProperties_anyCard;
  withTransferMalus: boolean;
  onInfoClick?: () => void;
  supensionsAndInjuries?: ReactNode;
  cardWithStats?: CardPropertiesWithStats_anyCard;
}

export const CardProperties = ({
  card,
  withTransferMalus,
  onInfoClick,
  supensionsAndInjuries,
  cardWithStats,
}: Props) => {
  const stats = onInfoClick && cardWithStats && (
    <TokenStatsIcon card={cardWithStats} onInfoClick={onInfoClick} />
  );

  if (isType(card, 'Card')) {
    return (
      <FootballCardProperties
        card={card}
        withTransferMalus={withTransferMalus}
        stats={stats}
        supensionsAndInjuries={supensionsAndInjuries}
      />
    );
  }
  if (isType(card, 'NBACard')) {
    return (
      <NBACardProperties
        card={card}
        withTransferMalus={!!withTransferMalus}
        stats={stats}
        supensionsAndInjuries={supensionsAndInjuries}
      />
    );
  }
  if (isType(card, 'BaseballCard')) {
    return (
      <BaseballCardProperties
        card={card}
        withTransferMalus={withTransferMalus}
        stats={stats}
        supensionsAndInjuries={supensionsAndInjuries}
      />
    );
  }

  return null;
};

CardProperties.fragments = {
  anyCard: gql`
    fragment CardProperties_anyCard on AnyCardInterface {
      slug
      ... on Card {
        slug
        ...FootballCardProperties_card
      }
      ... on NBACard {
        slug
        ...NBACardProperties_NBACard
      }
      ...BaseballCardProperties_anyCard
    }
    ${FootballCardProperties.fragments.card}
    ${NBACardProperties.fragments.NBACard}
    ${BaseballCardProperties.fragments.anyCard}
  ` as TypedDocumentNode<CardProperties_anyCard>,
  anyCardWithStats: gql`
    fragment CardPropertiesWithStats_anyCard on AnyCardInterface {
      slug
      ...TokenStatsIcon_anyCard
    }
    ${TokenStatsIcon.fragments.anyCard}
  ` as TypedDocumentNode<CardPropertiesWithStats_anyCard>,
};

export default CardProperties;
