import qs from 'qs';
import { useCallback } from 'react';
import {
  NavigateOptions,
  To,
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { SESSION_STORAGE, getValue } from './useSessionStorage';

export const getRedirectUrl = (
  deeplink: string,
  searchParams: URLSearchParams,
  params?: object
) => {
  if (
    !(
      /^sorare:\/\//.test(deeplink) ||
      /^sorare:\/\//.test(decodeURIComponent(deeplink))
    )
  ) {
    return null;
  }
  const decodedDeeplink = decodeURIComponent(deeplink);
  const decodedDeeplinkUrl = decodedDeeplink.split('?')[0];
  const decodedDeeplinkParam = decodedDeeplink.split('?')[1];
  const deeplinkParam = qs.parse(decodedDeeplinkParam);
  const otherParams = qs.parse(decodeURIComponent(searchParams.toString()));
  delete otherParams.deeplink;

  const newParams = {
    ...deeplinkParam,
    ...otherParams,
    ...params,
  };

  const withoutParams = Object.keys(newParams).length === 0;

  if (withoutParams) {
    return decodedDeeplinkUrl;
  }

  return `${decodedDeeplinkUrl}?${qs.stringify(newParams, {
    encode: false,
  })}`;
};

const useDeeplinkFromNativeApp = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const storedDeeplink = getValue(SESSION_STORAGE.deeplink);
  const deeplink = searchParams.get('deeplink') || storedDeeplink || '';
  const navigateWithDeeplink = useCallback(
    (
      path?: To | number,
      options?: NavigateOptions,
      params?: Record<string, string | null>
    ) => {
      const redirectUrl = getRedirectUrl(deeplink, searchParams, params);
      if (redirectUrl) {
        // eslint-disable-next-line no-restricted-properties
        window.location.href = redirectUrl; // even though we "navigate" here sometimes the app will send us back to this page
      } else if (path) {
        if (typeof path === 'object') {
          navigate(path);
        } else {
          navigate(generatePath(path as any, params || {}), options);
        }
      }
    },
    [deeplink, searchParams, navigate]
  );

  return { isWebviewFromNativeApp: !!deeplink, navigateWithDeeplink };
};

export default useDeeplinkFromNativeApp;
