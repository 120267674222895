import { Navigate, generatePath } from 'react-router-dom';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import {
  FOOTBALL_PLAY_INVENTORY_BOXES_TYPE,
  FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
} from '@sorare/core/src/constants/__generated__/routes';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

const InventoryPage = () => {
  const {
    flags: { useRevealCeremony = false },
  } = useFeatureFlags();

  const defaultPage = useRevealCeremony
    ? generatePath(FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE, {
        type: Rarity.limited,
      })
    : generatePath(FOOTBALL_PLAY_INVENTORY_BOXES_TYPE, {
        type: Rarity.limited,
      });

  return <Navigate replace to={defaultPage} />;
};

export default InventoryPage;
