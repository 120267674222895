import { FOOTBALL_PLAY_RIVALS_LEAGUES } from '@sorare/core/src/constants/__generated__/routes';
import { Navigate } from '@sorare/core/src/routing/Navigate';

const Create = () => {
  return (
    <Navigate
      to={FOOTBALL_PLAY_RIVALS_LEAGUES}
      state={{ create: true }}
      replace
    />
  );
};

export default Create;
