import { MessageDescriptor, defineMessages } from 'react-intl';

import { NBAPlayerGameDetailedScores } from '@sorare/core/src/__generated__/globalTypes';

import { longStatLabels } from 'components/stats/PerformanceTable/messages';

export type StatKey = keyof Omit<
  NBAPlayerGameDetailedScores,
  '__typename' | 'fieldGoalAttempts' | 'freeThrowAttempts'
>;

export const STAT_KEYS: readonly StatKey[] = [
  'points',
  'rebounds',
  'assists',
  'blocks',
  'steals',
  'turnovers',
  'made3PointFGs',
  'doubleDoubles',
  'tripleDoubles',
] as const;

export const messages = defineMessages({
  game: {
    id: 'CompetitionDetails.LineupStats.Game',
    defaultMessage: 'Game',
  },
  total: {
    id: 'CompetitionDetails.LineupStats.Total',
    defaultMessage: 'Total',
  },
  totalLong: {
    id: 'CompetitionDetails.LineupStats.Total.long',
    defaultMessage: 'Total score',
  },
  bonusShort: {
    id: 'CompetitionDetails.LineupStats.Bonus.short',
    defaultMessage: '+%',
  },
  bonusLong: {
    id: 'CompetitionDetails.LineupStats.Bonus.long',
    defaultMessage: 'Bonus points',
  },
  actualGameStat: {
    id: 'CompetitionDetails.LineupStats.ActualGameStat',
    defaultMessage: 'Actual: {gameStat}',
  },
  noGames: {
    id: 'CompetitionDetails.LineupStats.NoGames',
    defaultMessage: 'No games',
  },
  minutes: {
    id: 'CompetitionDetails.LineupStats.Minutes',
    defaultMessage: 'Minutes',
  },
});

export const shortStatMessages = defineMessages<StatKey>({
  points: {
    id: 'NBAPerformanceTable.stats.points.short',
    defaultMessage: 'PTS',
  },
  assists: {
    id: 'NBAPerformanceTable.stats.assists.short',
    defaultMessage: 'AST',
  },
  steals: {
    id: 'NBAPerformanceTable.stats.steals.short',
    defaultMessage: 'STL',
  },
  // These abbreviations are different from Stats.PerformanceTable
  rebounds: {
    id: 'CompetitionDetails.LineupStats.Rebounds',
    defaultMessage: 'RBD',
  },
  blocks: {
    id: 'CompetitionDetails.LineupStats.Blocks',
    defaultMessage: 'BLC',
  },
  turnovers: {
    id: 'CompetitionDetails.LineupStats.Turnovers',
    defaultMessage: 'TOV',
  },
  made3PointFGs: {
    id: 'CompetitionDetails.LineupStats.Made3PointFGs',
    defaultMessage: '3PT',
  },
  doubleDoubles: {
    id: 'CompetitionDetails.LineupStats.DoubleDoubles',
    defaultMessage: 'DD',
  },
  tripleDoubles: {
    id: 'CompetitionDetails.LineupStats.TripleDoubles',
    defaultMessage: 'TD',
  },
});

export const longStatMessages = longStatLabels as Record<
  StatKey,
  MessageDescriptor
>;
