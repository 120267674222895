import { ReactNode, useMemo } from 'react';
import { Params } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import useStoreLastVisitedSport from 'hooks/useStoreLastVisitedSport';
import { generateSportPath } from 'lib/routing/generateSportPath';

import SportContextProvider from '.';

interface Props {
  children: ReactNode;
  sport?: Sport;
}

const SportProvider = ({ children, sport: defaultSport }: Props) => {
  useStoreLastVisitedSport(defaultSport);

  const value = useMemo(
    () => ({
      sport: defaultSport,
      // wrap the `generateSportPath` to handle the default sport
      generateSportContextPath: (
        path: string,
        {
          params,
          sport = defaultSport,
        }: { params?: Params; sport?: Sport } = {}
      ) => generateSportPath(path, { params, sport }),
    }),
    [defaultSport]
  );

  return <SportContextProvider value={value}>{children}</SportContextProvider>;
};

export default SportProvider;
