import { TypedDocumentNode, gql } from '@apollo/client';

import { CardCoverageStatus } from '@sorare/core/src/__generated__/globalTypes';
import { messages } from '@sorare/core/src/lib/cardCoverage';

import So5Eligibility from '../So5Eligibility';
import { ItemEligibility_card } from './__generated__/index.graphql';

type Props = {
  cards: ItemEligibility_card[];
};

export const ItemEligibility = ({ cards }: Props) => {
  const bundle = cards.length > 1;
  const coverageStatuses = new Set(cards.map(c => c.coverageStatus));

  if (coverageStatuses.has(CardCoverageStatus.NOT_ELIGIBLE)) {
    return <So5Eligibility description={messages.notEligible} />;
  }

  if (coverageStatuses.has(CardCoverageStatus.LEGEND)) {
    return <So5Eligibility partial description={messages.legend} />;
  }

  if (coverageStatuses.has(CardCoverageStatus.PARTIAL)) {
    return (
      <So5Eligibility
        partial
        description={bundle ? messages.partialBundle : messages.partial}
      />
    );
  }

  if (coverageStatuses.has(CardCoverageStatus.SPECIAL)) {
    return <So5Eligibility description={messages.collectible} />;
  }

  if (coverageStatuses.has(CardCoverageStatus.NOT_COVERED)) {
    return (
      <So5Eligibility
        description={bundle ? messages.notCoveredBundle : messages.notCovered}
      />
    );
  }

  return null;
};

const cardFragment = gql`
  fragment ItemEligibility_card on Card {
    slug
    coverageStatus
  }
` as TypedDocumentNode<ItemEligibility_card>;

ItemEligibility.fragments = {
  card: cardFragment,
};

export default ItemEligibility;
