import { PropsWithChildren, Suspense } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ContentWithStickyHeader } from 'atoms/layout/ContentWithStickyHeader';
import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import { AppHeader } from 'components/navigation/AppHeader';
import {
  APP_BAR_MOBILE_HEIGHT,
  AppNavigation,
} from 'components/navigation/AppNavigation';
import {
  BLOG,
  FOOTBALL_CLUBS_SLUG_CARDS,
  FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS,
  FOOTBALL_LEAGUES_SLUG_CARDS,
  FOOTBALL_MARKET,
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_PLAYERS_SLUG_CARDS,
  FOOTBALL_PLAY_RIVALS,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
} from 'constants/__generated__/routes';
import { ACTIVITY, catchAll } from 'constants/routes';
import useIsLoggedIn from 'hooks/auth/useIsLoggedIn';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import AppBarProvider from 'routing/MultiSportAppBar/context/Provider';
import MultiSportFooter from 'routing/MultiSportFooter';
import { tabletAndAbove } from 'style/mediaQuery';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column-reverse;
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

// min-width: 0; used to override default min-width: content; Prevents content from overflowing window container (Firefox)
const MainWrapper = styled.div`
  flex: 1;
  overflow: clip;
  min-width: 0;
  padding-bottom: calc(
    ${APP_BAR_MOBILE_HEIGHT}px + var(--safe-area-inset-bottom)
  );
  @media ${tabletAndAbove} {
    padding-bottom: 0;
  }
`;

export const Content = styled.main`
  isolation: isolate;
  min-height: calc(100vh - var(--current-stack-height));
`;

const PAGES_WITHOUT_FOOTER = [
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_PLAYERS_SLUG_CARDS,
  FOOTBALL_CLUBS_SLUG_CARDS,
  FOOTBALL_LEAGUES_SLUG_CARDS,
  FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG_CARDS,
  BLOG,
  ACTIVITY,
  (pathname: string) =>
    matchPath(catchAll(FOOTBALL_MARKET), pathname) &&
    !matchPath(FOOTBALL_MARKET, pathname),
  (pathname: string) => matchPath(catchAll(FOOTBALL_PLAY_RIVALS), pathname),
];

const Footer = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const { isIosApp } = useIsMobileApp();

  const footer =
    !isIosApp &&
    !PAGES_WITHOUT_FOOTER.some(path =>
      typeof path === 'string' ? matchPath(path, pathname) : path(pathname)
    );

  if (!footer) {
    return null;
  }

  return children;
};

export const AppLayout = ({ children }: PropsWithChildren) => {
  const { isIosApp } = useIsMobileApp();
  const isLoggedIn = useIsLoggedIn();

  return (
    <Wrapper>
      <div id="full-screen-animation" />
      <AppBarProvider>
        <AppNavigation />
      </AppBarProvider>
      <MainWrapper>
        <ContentWithStickyHeader hideHeader={isIosApp} header={<AppHeader />}>
          <Content>
            <Suspense fallback={<LoadingIndicator fullHeight />}>
              {children}
            </Suspense>
          </Content>
          <Footer>
            <MultiSportFooter wide={isLoggedIn} />
          </Footer>
        </ContentWithStickyHeader>
      </MainWrapper>
    </Wrapper>
  );
};
