import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { SBHorizontal } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';

const Footer = styled(SBHorizontal).attrs({ gap: 0 })`
  margin-top: var(--unit);
`;

type Props = {
  value: number;
  total: number;
  onClick: () => void;
  buttonLabel: MessageDescriptor;
};
const TooltipFooter = ({ value, total, onClick, buttonLabel }: Props) => {
  return (
    <Footer>
      <LabelM color="var(--c-neutral-500)">
        <FormattedMessage
          id="TooltipContent.steps"
          defaultMessage="{value} of {total}"
          values={{
            value,
            total,
          }}
        />
      </LabelM>
      <Button size="small" color="primary" onClick={onClick}>
        <FormattedMessage {...buttonLabel} />
      </Button>
    </Footer>
  );
};

export default TooltipFooter;
