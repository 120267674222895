import { ReactNode } from 'react';
import styled from 'styled-components';

import CloseButton from '@sorare/core/src/atoms/buttons/CloseButton';
import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';

const DrawerContent = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--double-unit);
  background-color: var(--c-neutral-100);
  border-top-left-radius: var(--double-unit);
  border-top-right-radius: var(--double-unit);
`;

const DrawerHeader = styled(SBHorizontal).attrs({ gap: 2 })`
  width: 100%;
`;
const DrawerTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--double-unit);
`;

type Props = {
  open: boolean;
  onBackdropClick: () => void;
  title?: ReactNode;
  children?: ReactNode;
};

const TokenDrawerSummary = ({
  title,
  children,
  open,
  onBackdropClick,
}: Props) => (
  <Drawer open={open} onBackdropClick={onBackdropClick} side="bottom">
    <DrawerContent>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <CloseButton menu onClose={onBackdropClick} />
      </DrawerHeader>
      {children}
    </DrawerContent>
  </Drawer>
);

export default TokenDrawerSummary;
