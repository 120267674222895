import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineL, HeadlineM, LabelL, LabelM, LabelS } from 'atoms/typography';
import Dialog from 'components/dialog';

const Root = styled.div`
  padding: var(--double-unit);
`;
const Appearances = styled(Vertical)`
  padding: var(--double-unit);
  text-align: center;
`;
const Appearance = styled.div`
  width: 20%;
  color: var(--c-neutral-500);
  &.selected {
    color: var(--c-neutral-1000);
  }
`;
const Substituted = styled(LabelM)`
  text-decoration: line-through;
`;

type Appearance = {
  player: {
    displayName: string;
  };
  team?: {
    name: string;
  } | null;
  season?: {
    startYear: number;
  } | null;
  bonus: number;
  position: string;
};

type Position = 'Goalkeeper' | 'Defender' | 'Midfielder' | 'Forward' | 'Extra';

const formatAppearance = (appearance: Appearance | null | undefined) =>
  appearance
    ? `${[
        appearance.player.displayName,
        appearance?.team?.name,
        appearance?.season?.startYear,
      ]
        .filter(Boolean)
        .join(' - ')} (${appearance.bonus})`
    : '';

const PositionAppearance = ({
  appearance,
  proposedAppearance,
}: {
  appearance: Appearance | null | undefined;
  proposedAppearance: Appearance | null | undefined;
}) => {
  return (
    <LabelL as="p">
      {proposedAppearance ? (
        <>
          <LabelM>{formatAppearance(proposedAppearance)}</LabelM>
          <Substituted>{formatAppearance(appearance)}</Substituted>
        </>
      ) : (
        <LabelM>{formatAppearance(appearance)}</LabelM>
      )}
    </LabelL>
  );
};

type Props = {
  requestAppearance: () => Promise<Appearance | null | undefined>;
  acceptAppearance: () => Promise<boolean>;
  resetAppearances: () => Promise<void>;
  onSubmit: () => void;
  open: boolean;
};
const defaultLineup = {
  Goalkeeper: null,
  Defender: null,
  Midfielder: null,
  Forward: null,
  Extra: null,
};
export const ComposeTeam = ({
  onSubmit,
  requestAppearance,
  acceptAppearance,
  resetAppearances,
  open,
}: Props) => {
  const [reseting, setReseting] = useState(true);
  const [proposedAppearance, setProposedAppearance] =
    useState<Appearance | null>();
  const [lineup, setLineup] = useState<{
    [key in Position]: Appearance | null;
  }>(defaultLineup);
  const ready = Object.values(lineup).every(Boolean);
  const currentPosition: Position | undefined =
    proposedAppearance?.position !== 'Goalkeeper' &&
    lineup.Defender &&
    lineup.Midfielder &&
    lineup.Forward
      ? ('Extra' as const)
      : (proposedAppearance?.position as Position);

  const requestNewAppearance = useCallback(async () => {
    setProposedAppearance(await requestAppearance());
  }, [requestAppearance]);

  const acceptProposedAppearance = async () => {
    const success = await acceptAppearance();
    if (success && proposedAppearance && currentPosition) {
      const newLineup = { ...lineup, [currentPosition]: proposedAppearance };
      setLineup(newLineup);
      setProposedAppearance(null);
      if (!Object.values(newLineup).every(Boolean)) requestNewAppearance();
    }
  };

  const onReset = useCallback(async () => {
    setReseting(true);
    setProposedAppearance(null);
    setLineup(defaultLineup);
    await resetAppearances();
    requestNewAppearance();
    setReseting(false);
  }, [resetAppearances, requestNewAppearance]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  if (reseting) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Root>
        <Vertical gap={4}>
          <Appearances gap={4}>
            <HeadlineL as="p">
              <FormattedMessage
                id="noCardEntry.ComposeTeam.title"
                defaultMessage="Validate your lineup"
              />
            </HeadlineL>
            <Horizontal>
              {(Object.keys(lineup) as Array<keyof typeof lineup>).map(
                position => (
                  <Appearance
                    key={position}
                    className={classnames({
                      selected: currentPosition === position,
                    })}
                  >
                    <HeadlineM as="p">
                      {position}{' '}
                      {lineup[position] && (
                        <FontAwesomeIcon icon={faCheck} size="xs" />
                      )}
                    </HeadlineM>
                    <PositionAppearance
                      appearance={lineup[position]}
                      proposedAppearance={
                        currentPosition === position
                          ? proposedAppearance
                          : undefined
                      }
                    />
                  </Appearance>
                )
              )}
            </Horizontal>
          </Appearances>
          <Vertical gap={4}>
            <Vertical gap={2}>
              <Horizontal>
                <Button
                  fullWidth
                  size="medium"
                  color="tertiary"
                  onClick={requestNewAppearance}
                  disabled={ready}
                >
                  <FormattedMessage
                    id="noCardEntry.ComposeTeam.deny"
                    defaultMessage="Deny"
                  />
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  color="primary"
                  onClick={acceptProposedAppearance}
                  disabled={ready}
                >
                  <FormattedMessage
                    id="noCardEntry.ComposeTeam.accept"
                    defaultMessage="Accept"
                  />
                </Button>
              </Horizontal>
              <Button
                fullWidth
                color="tertiary"
                size="medium"
                onClick={onReset}
              >
                <FormattedMessage
                  id="noCardEntry.ComposeTeam.reset"
                  defaultMessage="Reset"
                />
              </Button>
            </Vertical>
            <Button
              fullWidth
              size="medium"
              onClick={onSubmit}
              disabled={!ready}
            >
              <FormattedMessage
                id="noCardEntry.ComposeTeam.submit"
                defaultMessage="Submit"
              />
            </Button>
          </Vertical>
          <Vertical gap={1}>
            <LabelS color="var(--c-neutral-600)">
              <FormattedMessage
                id="NoCardEntry.ComposeTeam.eligibility"
                defaultMessage="Considering the eligibility requirements for Sorare Pro 24/25 competitions, the first player you select will influence what other players will be suggested to you, so that your team can always be entered in at least one competition."
              />
            </LabelS>
            <LabelS color="var(--c-neutral-600)">
              <FormattedMessage
                id="NoCardEntry.ComposeTeam.disclaimer"
                defaultMessage="Once you have selected your 5 players, you will be able to select the competition in which your lineup will enter, subject to the eligibility criteria."
              />
            </LabelS>
          </Vertical>
        </Vertical>
      </Root>
    </Dialog>
  );
};

export default ComposeTeam;
