import { TypedDocumentNode, gql } from '@apollo/client';

import Layout from '../Layout';
import AuctionNotificationContent from './Content';
import { NotificationDialog_auctionNotification } from './__generated__/index.graphql';

export interface Props {
  notification: NotificationDialog_auctionNotification;
  onClose: (markAllAsRead?: boolean) => void;
}

export const AuctionNotification = ({ notification, onClose }: Props) => {
  const { name, tokenAuction } = notification;

  if (name !== 'card_bought') return null;

  return (
    <Layout onClose={onClose}>
      {({ onClose: onCloseDialog }) => (
        <AuctionNotificationContent
          onClick={onCloseDialog}
          tokenAuction={tokenAuction}
          rewards={notification.rewards}
        />
      )}
    </Layout>
  );
};

AuctionNotification.fragments = {
  notification: gql`
    fragment NotificationDialog_auctionNotification on AuctionNotification {
      id
      name
      tokenAuction {
        id
        ...AuctionWonContent_tokenAuction
      }
      rewards {
        id
        ...AuctionNotificationContent_anyReward
      }
    }
    ${AuctionNotificationContent.fragments.tokenAuction}
    ${AuctionNotificationContent.fragments.anyReward}
  ` as TypedDocumentNode<NotificationDialog_auctionNotification>,
};

export default AuctionNotification;
