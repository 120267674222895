import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  SubmitNoCardDraftableAppearances,
  SubmitNoCardDraftableAppearancesVariables,
} from './__generated__/useSubmitNoCardDraftableAppearances.graphql';

const SUBMIT_NO_CARD_DRAFTABLE_APPEARANCES_MUTATION = gql`
  mutation SubmitNoCardDraftableAppearances($userID: UUID!, $fixtureID: UUID!) {
    submitNoCardDraftableAppearances(userID: $userID, fixtureID: $fixtureID) {
      id
    }
  }
` as TypedDocumentNode<
  SubmitNoCardDraftableAppearances,
  SubmitNoCardDraftableAppearancesVariables
>;

type SubmitNoCardDraftableAppearancesInput = {
  userID: string;
  fixtureID: string;
};
export const useSubmitNoCardDraftableAppearances = () => {
  const [mutate] = useMutation(SUBMIT_NO_CARD_DRAFTABLE_APPEARANCES_MUTATION, {
    showErrorsInForm: true,
  });

  return async (input: SubmitNoCardDraftableAppearancesInput) =>
    mutate({ variables: input });
};
