import { FiatCurrency } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';

const useMangopayCreditCardsEnabled = () => {
  const {
    fiatCurrency: { code },
  } = useCurrentUserContext();
  return code !== FiatCurrency.USD;
};

export default useMangopayCreditCardsEnabled;
