import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Ineligible } from '@sorare/core/src/atoms/icons/Ineligible';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';

import { IneligibleIndicator_NBAPlayer } from './__generated__/index.graphql';

type Props = {
  player: IneligibleIndicator_NBAPlayer;
};

export const IneligibleIndicator = ({ player }: Props) => {
  if (player.isActive) {
    return null;
  }
  return (
    <Tooltip
      title={
        <div>
          <strong>
            <FormattedMessage
              id="IneligibleIndicator.title"
              defaultMessage="Inactive Player"
            />
          </strong>
          <p>
            <FormattedMessage
              id="IneligibleIndicator.description"
              defaultMessage="This player is currently out for an extended period of time."
            />
          </p>
        </div>
      }
    >
      <span aria-label="Ineligible">
        <Ineligible />
      </span>
    </Tooltip>
  );
};

IneligibleIndicator.fragments = {
  NBAPlayer: gql`
    fragment IneligibleIndicator_NBAPlayer on NBAPlayer {
      slug
      isActive
    }
  ` as TypedDocumentNode<IneligibleIndicator_NBAPlayer>,
};
