import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  CommonDraftCampaignType,
  Sport,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { LineupLayout } from 'components/lineup/LineupsLayoutSwitch/types';
import {
  CreditCardPaymentMethod,
  PaymentMethod as DepositMethod,
} from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/types';
import { AlgoliaCardIndexesName } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import {
  PaymentMethod,
  PaymentProvider_paymentMethod,
} from 'lib/paymentMethod';

import {
  CurrentUserLifecycleQuery,
  CurrentUserLifecycleQueryVariables,
  UpdateLifecycleMutation,
  UpdateLifecycleMutationVariables,
} from './__generated__/useLifecycle.graphql';

export enum LIFECYCLE {
  sawCaptainTuto = 'sawCaptainTuto',
  sawCollectionCardTuto = 'sawCollectionCardTuto',
  sawMarketplaceOnboarding = 'sawMarketplaceOnboarding',
  sawManagersSalesOnboarding = 'sawManagersSalesOnboarding',
  sawStarterPacksOnboarding = 'sawStarterPacksOnboarding',
  sawDraftTuto = 'sawDraftTuto',
  transferMarket = 'transferMarket',
  newSignings = 'newSignings',
  lastVisitedSport = 'lastVisitedSport',
  nextTimeUserNeedsCheckSecurity = 'nextTimeUserNeedsCheckSecurity',
  nextTimeUserShouldSeeActivateFiatWalletOnListing = 'nextTimeUserShouldSeeActivateFiatWalletOnListing',
  nextTimeUserShouldSeeAcceptedCurrenciesOnListing = 'nextTimeUserShouldSeeAcceptedCurrenciesOnListing',
  userHasSetupWallet = 'userHasSetupWallet',
  nextTimeUserShouldSetupWallet = 'nextTimeUserShouldSetupWallet',
  sawUsSportsCollectionsDialog = 'sawUsSportsCollectionsDialog',
  lastSaleDuration = 'lastSaleDuration',
  sawMlbDraftErrorPrompt = 'sawMlbDraftErrorPrompt',
  sawMlbComposeTeamOnboarding = 'sawMlbComposeTeamOnboarding',
  inSeasonFilterEnabled = 'inSeasonFilterEnabled',
  latestSearchedItems = 'latestSearchedItems',
  sawMlbOnboarding = 'sawMlbOnboarding',
  sawMlbDraftWelcome = 'sawMlbDraftWelcome',
  sawNbaOnboarding = 'sawNbaOnboarding',
  marketSorts = 'marketSorts',
  sawMlbDraftEducationDialog = 'sawMlbDraftEducationDialog',
  saw100PersonLeaderboardDialog = 'saw100PersonLeaderboardDialog',
  hideActivity = 'hideActivity',
  disable3D = 'disable3D',
  hideOnlineStatus = 'hideOnlineStatus',
  preferredPaymentMethod = 'preferredPaymentMethod',
  preferredDepositMethod = 'preferredDepositMethod',
  sawNewCustomListTuto = 'sawNewCustomListTuto',
  sawMinorsUnlockedDialog = 'sawMinorsUnlockedDialog',
  sawCommonLevel1UnlockedDialog = 'sawCommonLevel1UnlockedDialog',
  sawCommonLevel2UnlockedDialog = 'sawCommonLevel2UnlockedDialog',
  sawCommonLevel3UnlockedDialog = 'sawCommonLevel3UnlockedDialog',
  sawCommonLevel4UnlockedDialog = 'sawCommonLevel4UnlockedDialog',
  sawCommonLevel5UnlockedDialog = 'sawCommonLevel5UnlockedDialog',
  sawRewardUnlockedDialog = 'sawRewardsUnlockedDialog',
  referenceCurrencyForSale = 'referenceCurrencyForSale',
  sawMlbClubShopIntro = 'sawMlbClubShopIntro',
  hideReferralBanner = 'hideReferralBanner',
  sawKickoffWelcomeToKickoff = 'sawKickoffWelcomeToKickoff',
  preferedLineupLayout = 'preferedLineupLayout',
  showTrainingLineups = 'showTrainingLineups',
  showOnlyCompetitionsEntered = 'showOnlyCompetitionsEntered',
  sawChallengesOnboarding = 'sawChallengesOnboarding',
  sawTacticsOnboarding = 'sawTacticsOnboarding',
  doNotShowRivalsDownloadApp = 'doNotShowRivalsDownloadApp',
  doNotShowAddAuctionReminderDownloadApp = 'doNotShowAddAuctionReminderDownloadApp',
  sawForYouFirstTime = 'sawForYouFirstTime',
  unselectConversionCreditByDefaultFromSettings = 'unselectConversionCreditByDefaultFromSettings',
  sawWelcomeToSeasonsArena = 'sawWelcomeToSeasonsArena',
  sawSocialWallDialog = 'sawSocialWallDialog',
  hideConversionCreditBanner = 'hideConversionCreditBanner',
  sawEuropeanChampionshipTooltip = 'sawEuropeanChampionshipTooltip',
  sawRivalsLeagueOnboarding = 'sawRivalsLeagueOnboarding',
  footballPlayVisitLastDate = 'footballPlayVisitLastDate',
  playRivalsLeagueReminderLastDate = 'playRivalsLeagueReminderLastDate',
  sawRivalsLeagueMatchSlugs = 'sawRivalsLeagueMatchSlugs',
  sawRivalsPlayButtonOnboarding = 'sawRivalsPlayButtonOnboarding',
  sawBoxOnboarding = 'sawBoxOnboarding',
  sawRivalsLeagueCrossMode = 'sawRivalsLeagueCrossMode',
  sawRivalsPlayPageHighlight = 'sawRivalsPlayPageHighlight',
  sawRivalsLeagueEndingStory = 'sawRivalsLeagueEndingStory',
  sawEarlyAccessAnnouncementDialog = 'sawEarlyAccessAnnouncementDialog',
  sawStartedEarlyAccessAnnouncementDialog = 'sawStartedEarlyAccessAnnouncementDialog',
  sawChallengerEarlyAccessAnnouncementDialog = 'sawChallengerEarlyAccessAnnouncementDialog',
  sawChampionEarlyAccessAnnouncementDialog = 'sawChampionEarlyAccessAnnouncementDialog',
  sawSubstitutesFTUE = 'sawSubstitutesFTUE',
  sawChaseHelpDialog = 'sawChaseHelpDialog',
  sawComposeTeamOnboarding = 'sawComposeTeamOnboarding',
  sawMySquadOnboarding = 'sawMySquadOnboarding',
}

export type LifecycleEntries = {
  [LIFECYCLE.preferedLineupLayout]?: LineupLayout;
  [LIFECYCLE.sawCaptainTuto]?: boolean;
  [LIFECYCLE.sawCollectionCardTuto]?: boolean;
  [LIFECYCLE.sawMarketplaceOnboarding]?: boolean;
  [LIFECYCLE.sawManagersSalesOnboarding]?: boolean;
  [LIFECYCLE.sawStarterPacksOnboarding]?: boolean;
  [LIFECYCLE.sawDraftTuto]?: CommonDraftCampaignType;
  [LIFECYCLE.sawChaseHelpDialog]?: boolean;
  [LIFECYCLE.transferMarket]?: string;
  [LIFECYCLE.newSignings]?: string;
  [LIFECYCLE.lastVisitedSport]?: Sport;
  [LIFECYCLE.nextTimeUserNeedsCheckSecurity]?: string;
  [LIFECYCLE.nextTimeUserShouldSeeActivateFiatWalletOnListing]?: string;
  [LIFECYCLE.nextTimeUserShouldSeeAcceptedCurrenciesOnListing]?: string;
  [LIFECYCLE.userHasSetupWallet]?: boolean;
  [LIFECYCLE.nextTimeUserShouldSetupWallet]?: string;
  [LIFECYCLE.sawUsSportsCollectionsDialog]?: boolean;
  [LIFECYCLE.lastSaleDuration]?: number;
  [LIFECYCLE.sawMlbDraftErrorPrompt]?: boolean;
  [LIFECYCLE.sawMlbComposeTeamOnboarding]?: boolean;
  [LIFECYCLE.saw100PersonLeaderboardDialog]?: boolean;
  [LIFECYCLE.inSeasonFilterEnabled]?: boolean;
  [LIFECYCLE.latestSearchedItems]?: {
    [sport: string]: {
      objectID: string;
      index: string;
      sport: Sport;
    }[];
  };
  [LIFECYCLE.sawMlbOnboarding]?: boolean;
  [LIFECYCLE.sawMlbDraftWelcome]?: boolean;
  [LIFECYCLE.sawNbaOnboarding]?: boolean;
  [LIFECYCLE.marketSorts]?: Record<string, AlgoliaCardIndexesName>;
  [LIFECYCLE.sawMlbDraftEducationDialog]?: boolean;
  [LIFECYCLE.hideActivity]?: boolean;
  [LIFECYCLE.disable3D]?: boolean;
  [LIFECYCLE.sawNewCustomListTuto]?: boolean;
  [LIFECYCLE.hideOnlineStatus]?: boolean;
  [LIFECYCLE.referenceCurrencyForSale]: SupportedCurrency;
  [LIFECYCLE.sawMinorsUnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel1UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel2UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel3UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel4UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel5UnlockedDialog]?: boolean;
  [LIFECYCLE.sawRewardUnlockedDialog]?: boolean;
  [LIFECYCLE.preferredPaymentMethod]?:
    | PaymentProvider_paymentMethod
    | PaymentMethod.ETH_WALLET
    | PaymentMethod.FIAT_WALLET;
  [LIFECYCLE.preferredDepositMethod]?: DepositMethod | CreditCardPaymentMethod;
  [LIFECYCLE.sawMlbClubShopIntro]?: boolean;
  [LIFECYCLE.hideReferralBanner]?: boolean;
  [LIFECYCLE.sawKickoffWelcomeToKickoff]?: boolean;
  [LIFECYCLE.showTrainingLineups]?: boolean;
  [LIFECYCLE.showOnlyCompetitionsEntered]?: boolean;
  [LIFECYCLE.sawChallengesOnboarding]?: boolean;
  [LIFECYCLE.sawTacticsOnboarding]?: boolean;
  [LIFECYCLE.doNotShowRivalsDownloadApp]?: boolean;
  [LIFECYCLE.doNotShowAddAuctionReminderDownloadApp]?: boolean;

  [LIFECYCLE.sawForYouFirstTime]?: boolean;
  [LIFECYCLE.unselectConversionCreditByDefaultFromSettings]?: boolean;
  [LIFECYCLE.sawWelcomeToSeasonsArena]?: boolean;
  [LIFECYCLE.sawSocialWallDialog]?: boolean;
  [LIFECYCLE.hideConversionCreditBanner]?: boolean;
  [LIFECYCLE.sawEuropeanChampionshipTooltip]?: boolean;
  [LIFECYCLE.sawRivalsLeagueOnboarding]?: boolean;
  [LIFECYCLE.footballPlayVisitLastDate]?: string;
  [LIFECYCLE.playRivalsLeagueReminderLastDate]?: string;
  [LIFECYCLE.sawRivalsLeagueMatchSlugs]?: [string];
  [LIFECYCLE.sawRivalsPlayButtonOnboarding]?: boolean;
  [LIFECYCLE.sawBoxOnboarding]?: {
    limitedCard?: boolean;
    essence?: boolean;
    arenaTickets?: boolean;
  };
  [LIFECYCLE.sawRivalsLeagueCrossMode]?: boolean;
  [LIFECYCLE.sawRivalsPlayPageHighlight]?: boolean;
  [LIFECYCLE.sawRivalsLeagueEndingStory]?: boolean;
  [LIFECYCLE.sawEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawStartedEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawSubstitutesFTUE]?: boolean;
  [LIFECYCLE.sawChallengerEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawChampionEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawComposeTeamOnboarding]?: boolean;
  [LIFECYCLE.sawMySquadOnboarding]?: boolean;
};

export type Lifecycle = LifecycleEntries | undefined;
export type LifecycleValue = LifecycleEntries[LIFECYCLE];
export type LifecycleGetter = LifecycleValue;

const UPDATE_LIFECYCLE_MUTATION = gql`
  mutation UpdateLifecycleMutation($input: updateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      userSettings {
        id
        lifecycle
      }
      currentUser {
        slug
        active
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  UpdateLifecycleMutation,
  UpdateLifecycleMutationVariables
>;

// The lifecycle within useCurrentUserContext is not always up-to-date:
// using that won't explicitly trigger another fetch upon rendering
// + caching mechanism in apollo make state changes on the backend having
// a lag to be reflected on the client.
export const CURRENT_USER_LIFECYCLE_QUERY = gql`
  query CurrentUserLifecycleQuery {
    currentUser {
      slug
      userSettings {
        id
        lifecycle
      }
    }
  }
` as TypedDocumentNode<
  CurrentUserLifecycleQuery,
  CurrentUserLifecycleQueryVariables
>;

export default function useLifecycle() {
  const { currentUser } = useCurrentUserContext();
  const [updateLifecycle, { loading }] = useMutation(UPDATE_LIFECYCLE_MUTATION);

  const update = useCallback(
    async <K extends keyof LifecycleEntries>(
      name: K,
      value: Omit<LifecycleEntries[K], 'undefined'>
    ) => {
      if (!currentUser?.slug) {
        return;
      }
      await updateLifecycle({
        variables: {
          input: {
            lifecycle: {
              name,
              value,
            },
          },
        },
        optimisticResponse: {
          updateUserSettings: {
            __typename: 'updateUserSettingsPayload',
            userSettings: {
              __typename: 'UserSettings',
              id: currentUser.userSettings.id,
              lifecycle: {
                ...(currentUser.userSettings.lifecycle as Lifecycle),
                ...{ [name]: value },
              },
            },
            currentUser: {
              __typename: 'CurrentUser',
              slug: currentUser.slug,
              active: currentUser.active,
            },
            errors: [],
          },
        },
      });
    },
    [updateLifecycle, currentUser]
  );

  const lifecycle = currentUser?.userSettings.lifecycle as Lifecycle;

  return {
    lifecycle,
    update,
    loading,
  };
}
