import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { DivisionLogo_LeaderboardInterface } from './__generated__/index.graphql';

type Props = {
  leaderboard: {
    iconImageUrl: string;
  };
  className?: string;
};

const Image = styled.img`
  width: calc(5 * var(--unit));
`;

export const DivisionLogo = ({
  leaderboard: { iconImageUrl },
  className,
}: Props) => {
  return <Image alt="" src={iconImageUrl} className={className} />;
};

DivisionLogo.fragments = {
  LeaderboardInterface: gql`
    fragment DivisionLogo_LeaderboardInterface on LeaderboardInterface {
      slug
      iconImageUrl
    }
  ` as TypedDocumentNode<DivisionLogo_LeaderboardInterface>,
};
