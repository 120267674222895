import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import Button, { Props as ButtonProps } from 'atoms/buttons/Button';
import DotsLoader from 'atoms/loader/DotsLoader';
import { BaseLoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { glossary } from 'lib/glossary';

export interface Props extends ButtonProps {
  loading: boolean;
  dots?: boolean;
}

const Loading = styled.span`
  margin-left: 10px;
`;
const LoadingIndicator = styled.div`
  font-size: calc(3 * var(--unit));
  &.small {
    font-size: calc(2 * var(--unit));
  }
  &.compact {
    font-size: var(--unit);
  }
`;
const StyledButton = styled(Button)`
  position: relative;
`;

const zoomIn = keyframes`
  to {
    transform: scale(1)
  }
`;

const Loader = styled.span`
  position: absolute;
  display: flex;
  margin: auto;
  transform: scale(0);
  ${StyledButton}.loading & {
    animation: 0.2s ease-in-out ${zoomIn} both;
  }
`;
const Cta = styled.span`
  transition: transform ease-in-out 0.2s;
  transform: scale(1);
  ${StyledButton}.loading & {
    transform: scale(0);
  }
`;

const LoadingButton = ({
  loading,
  children,
  disabled,
  dots,
  className,
  size: variant,
  ...rest
}: Props) => {
  const renderLoader = () => {
    if (dots) {
      return (
        <>
          <FormattedMessage {...glossary.loading} />
          <Loading className={classnames(rest.color)}>
            <DotsLoader />
          </Loading>
        </>
      );
    }
    return (
      <LoadingIndicator className={variant}>
        <BaseLoadingIndicator />
      </LoadingIndicator>
    );
  };

  return (
    <StyledButton
      disabled={disabled || loading}
      className={classnames(className, { loading })}
      size={variant}
      {...rest}
    >
      {loading && <Loader>{renderLoader()}</Loader>}
      <Cta>{children}</Cta>
    </StyledButton>
  );
};

export default LoadingButton;
