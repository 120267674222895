import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import Gap from '@sorare/core/src/components/marketing/Gap';
import {
  DrukWide64,
  MarketingText20,
} from '@sorare/core/src/components/marketing/typography';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

const Desc = styled(MarketingText20)`
  @media ${tabletAndAbove} {
    max-width: 50%;
  }
`;
const Row = styled.div`
  display: flex;
  gap: calc(5 * var(--unit));
  flex-direction: column;
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;
const PostLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--intermediate-unit);
  width: 100%;
  @media ${tabletAndAbove} {
    gap: var(--double-and-a-half-unit);
  }
`;
const ThumbnailContainer = styled(Horizontal).attrs({ gap: 0 })`
  overflow: hidden;
  aspect-ratio: 2/1;
`;
const Thumbnail = styled.img`
  object-fit: cover;
  aspect-ratio: 2/1;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  object-position: center;
  &:hover {
    transform: scale(1.02);
  }
`;
const DefaultBackground = styled.div`
  background-color: var(--c-neutral-400);
  width: 100%;
  aspect-ratio: 2/1;
`;

const mediumURL =
  'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sorare';

type Post = {
  title: string;
  thumbnail?: string;
  pubDate: string;
  categories: string[];
  guid: string;
};
export const Blog = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    fetch(mediumURL)
      .then(async res => res.json())
      .then(data => {
        setPosts(data.items);
      });
  }, []);

  if (!posts.length) return null;

  return (
    <>
      <Vertical>
        <DrukWide64 color="var(--c-static-neutral-1000)">
          <FormattedMessage
            id="press.blog.title"
            defaultMessage="Latest News"
          />
        </DrukWide64>
        <Desc color="var(--c-static-neutral-1000)">
          <FormattedMessage
            id="press.blog.desc"
            defaultMessage="Our thought leadership in the press and latest thinking on vision, strategy, culture, and brand."
          />
        </Desc>
      </Vertical>
      <Gap size="xs" />
      <Row>
        {posts.slice(0, 3).map(post => (
          <PostLink key={post.title} href={post.guid} target="_blank">
            <ThumbnailContainer>
              {post.thumbnail ? (
                <Thumbnail src={post.thumbnail} alt={post.title} />
              ) : (
                <DefaultBackground />
              )}
            </ThumbnailContainer>
            <MarketingText20 color="var(--c-static-neutral-1000)">
              {post.title}
            </MarketingText20>
            <LabelM uppercase color="var(--c-static-neutral-600)">
              <FormattedDate
                value={post.pubDate}
                month="short"
                day="numeric"
                year="numeric"
              />
            </LabelM>
          </PostLink>
        ))}
      </Row>
    </>
  );
};
