export const RedShirt = () => {
  return (
    <svg
      width="160"
      height="128"
      viewBox="0 0 160 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.654 34.3456L125.655 2.34668C124.155 0.845368 122.121 0.00298507 119.999 0.00164795H95.9998C93.2182 0.126199 90.6654 1.57125 89.1281 3.89232C87.1593 6.86949 83.8111 8.63874 80.2421 8.58766C76.6716 8.58096 73.3556 6.73681 71.4644 3.70752C69.9952 1.4013 67.4506 0.00301935 64.7143 0.00164795H39.9996C37.8782 0.00298747 35.8439 0.845368 34.344 2.34668L2.34503 34.3456C0.843723 35.8456 0 37.8801 0 40.0013C0 42.1225 0.843723 44.157 2.34503 45.6569L24.9852 68.2971H24.9838C26.8213 70.1359 29.4381 70.9662 32.0003 70.5243V119.998C32.0003 122.12 32.8427 124.155 34.344 125.655C35.8439 127.155 37.8784 127.998 39.9996 127.998H120C122.122 127.998 124.156 127.155 125.656 125.655C127.157 124.155 128 122.12 128 119.998V70.5243C130.56 70.9635 133.176 70.1332 135.016 68.2971L157.656 45.6569L157.655 45.6583C159.156 44.1583 160 42.1238 160 40.0012C160 37.8799 159.156 35.8456 157.655 34.3456L157.654 34.3456Z"
        fill="url(#paint0_linear_460_213)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_460_213"
          x1="130.773"
          y1="111.285"
          x2="95.7687"
          y2="-19.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C22342" />
          <stop offset="1" stopColor="#F2412D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RedShirt;
