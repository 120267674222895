import { TypedDocumentNode, gql } from '@apollo/client';

import { FootballRivalsOutcome } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';
import { partition } from '@sorare/core/src/lib/arrays';
import { fantasy } from '@sorare/core/src/lib/glossary';

import {
  findLeaderboardConfigByDivisionId_leaderboardsConfig,
  splitLeaguesByFavorite_footballRivalsLeagueGame,
} from './__generated__/rivals.graphql';

export const splitLeaguesByFavorite = withFragments(
  <T extends splitLeaguesByFavorite_footballRivalsLeagueGame>(
    rivalsLeagueGames: T[]
  ) => {
    return partition(
      rivalsLeagueGames,
      ({ competition }) => !!competition.currentUserSubscription?.slug
    );
  },
  {
    footballRivalsLeagueGame: gql`
      fragment splitLeaguesByFavorite_footballRivalsLeagueGame on FootballRivalsLeagueGame {
        competition {
          slug
          currentUserSubscription {
            slug
          }
        }
      }
    ` as TypedDocumentNode<splitLeaguesByFavorite_footballRivalsLeagueGame>,
  }
);

export const getOutcome = ({
  managerScore = 0,
  opponentScore = 0,
}: {
  managerScore?: number;
  opponentScore?: number;
}) => {
  if (managerScore > opponentScore) {
    return FootballRivalsOutcome.WIN;
  }
  if (managerScore < opponentScore) {
    return FootballRivalsOutcome.LOSE;
  }
  return FootballRivalsOutcome.DRAW;
};

export const getOutcomeMessage = (outcome: FootballRivalsOutcome) => {
  if (outcome === FootballRivalsOutcome.WIN) {
    return fantasy.win;
  }
  if (outcome === FootballRivalsOutcome.LOSE) {
    return fantasy.loss;
  }
  return fantasy.draw;
};

export const findLeaderboardConfigByDivisionId = withFragments(
  <T extends findLeaderboardConfigByDivisionId_leaderboardsConfig>(
    leaderboardsConfig: T[],
    id?: string
  ) => {
    return leaderboardsConfig.find(
      ({ divisionConfig }) => divisionConfig.id === id
    );
  },
  {
    footballRivalsDivisionLeaderboardConfig: gql`
      fragment findLeaderboardConfigByDivisionId_leaderboardsConfig on FootballRivalsDivisionLeaderboardConfig {
        divisionConfig {
          id
        }
      }
    ` as TypedDocumentNode<findLeaderboardConfigByDivisionId_leaderboardsConfig>,
  }
);

export const RIVALS_START_DATE = new Date('2023-12-01');
