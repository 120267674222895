import classNames from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import {
  Currency,
  Rarity,
  SeasonEligibility,
} from '@sorare/core/src/__generated__/globalTypes';
import { Skeleton } from '@sorare/core/src/atoms/animations/Skeleton';
import Block from '@sorare/core/src/atoms/layout/Block';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text16 } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import OpenItemDialogLink from '@sorare/core/src/components/link/OpenItemDialogLink';
import { range } from '@sorare/core/src/lib/arrays';
import { isType } from '@sorare/core/src/lib/gql';

import FlexCard from 'components/token/FlexCard';
import { PriceHistoryQuery } from 'hooks/__generated__/useGetPriceHistory.graphql';
import useGetPriceHistory from 'hooks/useGetPriceHistory';

import PriceHistoryDate from '../PriceHistoryDate';
import { TransactionLabel } from '../TransactionLabel';

type PriceHistoryQuery_tokens_tokenPrices =
  PriceHistoryQuery['tokens']['tokenPrices'][number];

type PriceHistoryQuery_tokens_tokenPrices_deal =
  PriceHistoryQuery_tokens_tokenPrices['deal'];

type PriceHistoryQuery_tokens_tokenPrices_deal_TokenAuction =
  PriceHistoryQuery_tokens_tokenPrices_deal & { __typename: 'TokenAuction' };

type PriceHistoryQuery_tokens_tokenPrices_deal_TokenAuction_nfts =
  PriceHistoryQuery_tokens_tokenPrices_deal_TokenAuction['cards'][number];

type PriceHistoryQuery_tokens_tokenPrices_deal_TokenOffer =
  PriceHistoryQuery_tokens_tokenPrices_deal & { __typename: 'TokenOffer' };

type PriceHistoryQuery_tokens_tokenPrices_deal_TokenOffer_senderSide_nfts =
  PriceHistoryQuery_tokens_tokenPrices_deal_TokenOffer['senderSide']['cards'][number];

const messages = defineMessages({
  noResult: {
    id: 'PriceHistory.noResult',
    defaultMessage: 'No recent sales',
  },
});

const HistoryList = styled(Vertical).attrs({ gap: 1.5 })`
  overflow: auto;
`;
const SkeletonWrapped = styled(Skeleton)`
  width: 100%;
  height: 88.5px;
  display: block;
`;

export const EmptyResult = styled(Block)`
  padding: var(--intermediate-unit);
  &.compact {
    font: var(--t-12);
    padding: var(--unit);
  }
`;

const ItemLink = styled(OpenItemDialogLink)`
  display: grid;
  grid-template-areas:
    'card deal amount'
    'card date amount';
  grid-template-columns: 40px 1fr 1fr;
  column-gap: var(--intermediate-unit);
  padding: var(--intermediate-unit);

  &.noPadding {
    padding: 0;
  }
`;

const Card = styled.div`
  grid-area: card;
`;
const Deal = styled(Text16)`
  grid-area: deal;
  align-self: flex-end;
`;
const Date = styled(LabelM)`
  grid-area: date;
  align-self: flex-start;
`;
const Amount = styled(Text16)`
  grid-area: amount;
  align-self: center;
  text-align: right;
`;

type TransactionToken =
  | PriceHistoryQuery_tokens_tokenPrices_deal_TokenAuction_nfts
  | PriceHistoryQuery_tokens_tokenPrices_deal_TokenOffer_senderSide_nfts;
export interface ItemProps {
  priceHistorySample: PriceHistoryQuery_tokens_tokenPrices;
  onClick?: (token: TransactionToken) => void;
  inBlock?: boolean;
}

const getCardFromDeal = (deal: PriceHistoryQuery_tokens_tokenPrices_deal) => {
  const dealIsTokenOffer = isType(deal, 'TokenOffer');

  const items = dealIsTokenOffer ? deal.senderSide.cards : deal.cards;
  if (items.length === 1) return items[0];
  return null;
};

const Item = ({ priceHistorySample, onClick, inBlock }: ItemProps) => {
  const card = getCardFromDeal(priceHistorySample.deal);

  if (!card) return null;

  const content = (
    <ItemLink
      onClick={() => {
        if (onClick) onClick(card);
      }}
      item={card}
      sport={card.sport}
      className={classNames({ noPadding: !inBlock })}
    >
      <Card>
        <FlexCard card={card} width={80} />
      </Card>
      <Deal color="var(--c-neutral-1000)">
        <TransactionLabel deal={priceHistorySample.deal} />
      </Deal>
      <Date color="var(--c-neutral-600)">
        <PriceHistoryDate date={priceHistorySample.date} />
      </Date>
      <Amount as="div">
        <AmountWithConversion
          monetaryAmount={priceHistorySample.amounts}
          column
          primaryCurrency={Currency.FIAT}
        />
      </Amount>
    </ItemLink>
  );

  if (inBlock) {
    return (
      <Block noCollapse noPadding>
        {content}
      </Block>
    );
  }

  return content;
};

const PriceHistoryFromProps = ({
  variables,
  count = 5,
  onClick,
  inBlock = true,
}: Omit<ItemProps, 'priceHistorySample'> & {
  count?: number;
  variables: {
    rarity: Rarity;
    playerSlug: string;
    seasonEligibility?: SeasonEligibility;
  };
}) => {
  const { data, loading } = useGetPriceHistory(variables);
  const priceHistory = data?.tokens?.tokenPrices.slice(0, count);

  if (priceHistory?.length === 0 && !loading) {
    return (
      <EmptyResult>
        <FormattedMessage {...messages.noResult} />
      </EmptyResult>
    );
  }

  return (
    <HistoryList>
      {loading
        ? range(count).map(i => (
            <Block noPadding key={i}>
              <SkeletonWrapped />
            </Block>
          ))
        : priceHistory?.map(priceHistorySample => (
            <Item
              key={priceHistorySample.id}
              priceHistorySample={priceHistorySample}
              onClick={onClick}
              inBlock={inBlock}
            />
          ))}
    </HistoryList>
  );
};

export default PriceHistoryFromProps;
