import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from '@sorare/core/src/atoms/layout/Container';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Title4 } from '@sorare/core/src/atoms/typography';
import { GraphQLResult } from '@sorare/core/src/components/form/Form';
import { NoCardEntryForm } from '@sorare/core/src/components/noCardEntry/NoCardEntryForm';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import { ComposeTeam } from './ComposeTeam';
import {
  USSportsGenerateNoCardLineupMutation,
  USSportsGenerateNoCardLineupMutationVariables,
} from './__generated__/index.graphql';

const US_SPORTS_GENERATE_NO_CARD_LINEUP_MUTATION = gql`
  mutation USSportsGenerateNoCardLineupMutation(
    $userID: UUID!
    $fixtureID: UUID!
    $token: String!
  ) {
    generateNoCardLineup(
      userID: $userID
      fixtureID: $fixtureID
      token: $token
    ) {
      id
    }
  }
` as TypedDocumentNode<
  USSportsGenerateNoCardLineupMutation,
  USSportsGenerateNoCardLineupMutationVariables
>;

const Wrapper = styled(Vertical).attrs({ gap: 4, center: true })`
  padding: var(--quadruple-unit);

  font: var(--t-14);
  text-align: center;
  justify-content: stretch;
`;

export const NoCardEntryRequest = () => {
  const {
    flags: { enableUsSportsNoCardRouteUk = false },
  } = useFeatureFlags();
  const [params] = useSearchParams();
  const { currentUser } = useCurrentUserContext();
  const [submitted, setSubmitted] = useState(false);
  const [lineupSubmitted, setLineupSubmitted] = useState(false);

  const userID = idFromObject(currentUser?.id);
  const token = params.get('token');
  const fixtureID = params.get('fixture_id');

  const [generateNoCardLineupMutation] = useMutation(
    US_SPORTS_GENERATE_NO_CARD_LINEUP_MUTATION,
    {
      warnIfNoErrorHandling: false,
    }
  );

  const onSubmit = async (
    attributes: any,
    onResult: (res: GraphQLResult) => void
  ) => {
    if (!submitted && userID && fixtureID && token) {
      if (!enableUsSportsNoCardRouteUk) {
        const result = await generateNoCardLineupMutation({
          variables: { userID, fixtureID, token },
        });
        onResult(result);
      }
      setSubmitted(true);
    }
  };

  const invalid = !userID || !fixtureID || !token;

  if (!submitted) {
    return (
      <Container>
        <NoCardEntryForm
          onSubmit={onSubmit}
          onSuccess={() => setSubmitted(true)}
        />
      </Container>
    );
  }
  if (enableUsSportsNoCardRouteUk) {
    if (invalid) {
      return (
        <Wrapper>
          <Title4>
            <FormattedMessage
              id="NoCardEntryCancel.errorTitle"
              defaultMessage="An error occured"
            />
          </Title4>
        </Wrapper>
      );
    }

    if (submitted && !lineupSubmitted) {
      return (
        <Wrapper>
          <ComposeTeam
            userID={userID}
            fixtureID={fixtureID}
            onSubmit={() => setLineupSubmitted(true)}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {lineupSubmitted && (
          <Title4>
            <FormattedMessage
              id="NoCardEntryForm.successSubtitleUk"
              defaultMessage="Your team is confirmed and will be submitted in the Game Week! We’ll send you an email for you to select the competition your team can be entered into."
            />
          </Title4>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {submitted ? (
        <Title4>
          <FormattedMessage
            id="NoCardEntryForm.successSubtitle"
            defaultMessage="You will soon receive an email with a proposed team"
          />
        </Title4>
      ) : (
        <>
          {invalid && (
            <Title4>
              <FormattedMessage
                id="NoCardEntryCancel.errorTitle"
                defaultMessage="An error occured"
              />
            </Title4>
          )}
        </>
      )}
    </Wrapper>
  );
};
