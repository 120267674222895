import { TypedDocumentNode, gql } from '@apollo/client';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SmallContainer } from 'atoms/layout/Container';
import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import { DialogKey } from 'components/navigation/WithDialogs';
import idFromObject from 'gql/idFromObject';
import useQuery from 'hooks/graphql/useQuery';
import { useDialogParam } from 'hooks/navigation/useDialogParam';

import Announcement from '../Announcement';
import {
  GetSpecificNewsQuery,
  GetSpecificNewsQueryVariables,
} from './__generated__/index.graphql';

const Root = styled.div`
  padding: var(--triple-unit) 0;
`;

const GET_SPECIFIC_NEWS_QUERY = gql`
  query GetSpecificNewsQuery($cursor: String, $announcementIds: [String!]) {
    announcements(
      after: $cursor
      announcementIds: $announcementIds
      first: 10
    ) {
      nodes {
        id
        ...Announcement_announcement
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${Announcement.fragments.announcement}
` as TypedDocumentNode<GetSpecificNewsQuery, GetSpecificNewsQueryVariables>;

type Props = {
  inDialog?: boolean;
  id: string;
};

const SpecificNewsComponent = ({ inDialog, id }: Props) => {
  const { data, loading } = useQuery(GET_SPECIFIC_NEWS_QUERY, {
    variables: {
      announcementIds: [id!],
    },
    skip: !id,
  });

  if (!data || loading) {
    return <LoadingIndicator />;
  }
  const announcement = data?.announcements.nodes[0];

  const Wrapper = inDialog ? Fragment : SmallContainer;

  return (
    <Root>
      <Wrapper>
        {announcement ? (
          <Announcement announcement={announcement} />
        ) : (
          <FormattedMessage
            id="SpecificNews.notFound"
            defaultMessage="This news does not exist."
          />
        )}
      </Wrapper>
    </Root>
  );
};

export const SpecificNewsDialogContent = ({
  ...otherProps
}: Omit<Props, 'id'>) => {
  const id = useDialogParam(DialogKey.news);
  if (!id) {
    return null;
  }
  return <SpecificNewsComponent id={idFromObject(id)} {...otherProps} />;
};

export const SpecificNews = ({ ...otherProps }: Omit<Props, 'id'>) => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  return <SpecificNewsComponent id={idFromObject(id)} {...otherProps} />;
};
