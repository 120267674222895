import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { Text16, Title3 } from 'atoms/typography';
import { useConnectionContext } from 'contexts/connection';
import WalletPlaceholder from 'contexts/wallet/Placeholder';
import { glossary } from 'lib/glossary';

const Frame = styled(WalletPlaceholder)`
  margin-top: 10px;
  position: relative;
  top: 0;
  height: 200px;
`;

type Props = {
  hasSubmitted: boolean;
};

export const ResetPasswordContent = ({ hasSubmitted }: Props) => {
  const { signIn } = useConnectionContext();
  const [, setSearchParams] = useSearchParams();

  if (hasSubmitted) {
    return (
      <Vertical gap={2}>
        <Title3 className="text-center">
          <FormattedMessage
            id="ResetPasswordDialog.submitted.title"
            defaultMessage="You’re all set"
          />
        </Title3>
        <Text16 color="var(--c-neutral-1000)" className="text-center">
          <FormattedMessage
            id="ResetPasswordDialog.submitted.desc"
            defaultMessage="Your new password has been saved. You can try to sign in using your new password."
          />
        </Text16>
        <Button
          size="medium"
          color="primary"
          onClick={() => {
            signIn();
            setSearchParams({});
          }}
        >
          <FormattedMessage {...glossary.signin} />
        </Button>
      </Vertical>
    );
  }
  return (
    <Vertical gap={2}>
      <Title3>
        <FormattedMessage
          id="ResetPasswordDialog.title"
          defaultMessage="Create your new password"
        />
      </Title3>
      <Text16 color="var(--c-neutral-1000)">
        <FormattedMessage
          id="ResetPasswordDialog.desc"
          defaultMessage="Enter a new password to secure your Sorare account. It must be at least six characters long."
        />
      </Text16>
      <Text16 color="var(--c-neutral-1000)">
        <FormattedMessage
          id="ResetPasswordDialog.helper"
          defaultMessage="Setting a new password will require you to recover your Sorare wallet the next time you log in."
        />
      </Text16>
      <Frame />
    </Vertical>
  );
};
