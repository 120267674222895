import { css, keyframes } from 'styled-components';

const textAppearanceAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  38%{
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0
  }
`;

export const textAnimation = css`
  animation:
    ${opacityAnimation} 0.8s,
    ${textAppearanceAnimation} 0.5s 0.8s cubic-bezier(1, 0, 0, 1);
`;

const cardOpacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const cardScaleAnimation = keyframes`
  from {
    transform: scale(1.22)
  }
  to {
    transform: scale(1)
  }
`;

const cardMoveAnimation = keyframes`
  from {
    transform: translateY(48px) scale(1.22)
  }
  to {
    transform:  translateY(0) scale(1.22);
  }
`;

export const cardsAnimation = css`
  animation:
    ${cardOpacityAnimation} 0.56s cubic-bezier(0, 0.67, 0.33, 1),
    ${cardMoveAnimation} 1s cubic-bezier(0, 0.67, 0.33, 1),
    ${cardScaleAnimation} 0.5s 0.8s cubic-bezier(1, 0, 0, 1);
`;

export const actionsKeyframe = keyframes`
  0%{
    opacity: 0;
  }
  67%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const actionsAnimation = css`
  animation: ${actionsKeyframe} 2.45s ease-in-out;
`;
