import { faGavel } from '@fortawesome/pro-solid-svg-icons';
import { defineMessages } from 'react-intl';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Ball } from '@sorare/core/src/atoms/icons/Ball';
import { Bell } from '@sorare/core/src/atoms/icons/Bell';
import { DownloadApp } from '@sorare/core/src/components/DownloadApp';

type Props = { open: boolean; onClose: (doNotShowAgain: boolean) => void };

const messages = defineMessages({
  alertsOnOffers: {
    id: 'AddReminderDownloadApp.listItem.alertsOnOffer',
    defaultMessage: 'Alerts on Offers',
  },
  alertsOnAuctions: {
    id: 'AddReminderDownloadApp.listItem.alertsOnAuctions',
    defaultMessage: 'Alerts on Auctions',
  },
  livePerformanceUpdates: {
    id: 'AddReminderDownloadApp.listItem.liveUpdates',
    defaultMessage: 'Live Performance Updates',
  },
});

const AlertsOnAuctions = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  return <FontAwesomeIcon icon={faGavel} width={width} height={height} />;
};

const benefits = [
  {
    id: 'alerts-on-offers',
    Component: Bell,
    label: messages.alertsOnOffers,
  },
  {
    id: 'alerts-on-auctions',
    Component: AlertsOnAuctions,
    label: messages.alertsOnAuctions,
  },
  {
    id: 'live-performance-updates',
    Component: Ball,
    label: messages.livePerformanceUpdates,
  },
];

export const AddReminderDownloadApp = ({ open, onClose }: Props) => {
  return <DownloadApp open={open} onClose={onClose} benefits={benefits} />;
};
