export enum Steps {
  DRAFT,
  CAPTAIN_SELECTION,
  TACTIC_SELECTION,
  PRO_UPGRADE,
  REVIEW,
  SUBMITTED,
  SUBMITTED_ONBOARDING,
  FINISHED_ONBOARDING,
}
