import { getValue } from 'components/PersistsQueryStringParameters/storage';
import { STORE_URL } from 'constants/externalLinks';

interface Options {
  invitationToken?: string | null;
  impactId?: string | null;
  referrer?: string | null;
}

export const useOneLinkUrl = (
  href: string,
  { invitationToken, impactId, referrer }: Options
) => {
  const url = new URL(href);
  if (invitationToken) {
    url.searchParams.append('deep_link_sub1', invitationToken);
  }
  if (impactId) {
    url.searchParams.append('deep_link_sub2', impactId);
  }
  if (referrer) {
    url.searchParams.append('deep_link_sub3', `referrer:${referrer}`);
  }

  return url.href;
};

export const useStoreUrl = () => {
  const referrer = getValue('referrer');
  const impactId = getValue('irclickid');
  const invitationToken = getValue('invitation_token');

  return useOneLinkUrl(STORE_URL, {
    invitationToken,
    impactId,
    referrer,
  });
};
