import { useKey } from 'react-use';
import styled, { css, keyframes } from 'styled-components';

import { theme } from 'style/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackdropWrapper = styled.div<{ blur?: boolean }>`
  position: fixed;
  z-index: ${theme.zIndex.backdrop};
  inset: 0;
  background: rgba(var(--c-static-rgb-neutral-1000), 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  ${({ blur }) =>
    blur
      ? css`
          backdrop-filter: blur(4px);
        `
      : ''};
`;

type Props = {
  onClick?: () => void;
  blur?: boolean;
};
export const Backdrop = ({ onClick, blur = false }: Props) => {
  useKey('Escape', onClick);
  return (
    <BackdropWrapper
      blur={blur}
      onClick={onClick}
      as={onClick ? 'button' : 'div'}
    />
  );
};

export default Backdrop;
