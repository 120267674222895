import { RefObject, useEffect, useState } from 'react';

const useScrollPosition = (ref: RefObject<HTMLDivElement>) => {
  const scrollReverse =
    ref.current &&
    getComputedStyle(ref.current).flexDirection === 'row-reverse';
  const initialPosition = scrollReverse ? 'end' : 'start';
  // update state to force scrollPosition to recompute when the wrapperRef change relying on normal rerendering flow
  const [, updateState] = useState<unknown>();
  const [scrollPosition, setScrollPosition] = useState<
    'start' | 'middle' | 'end' | undefined
  >(initialPosition);

  const handleScroll = () => {
    if (ref.current) {
      const { offsetWidth, scrollLeft, scrollWidth } = ref.current;
      if (scrollLeft === 0) {
        const scrollPositionValue = scrollReverse ? 'end' : 'start';
        if (scrollPosition !== scrollPositionValue) {
          setScrollPosition(scrollPositionValue);
        }
      } else if ((offsetWidth || 0) + (scrollLeft || 0) >= (scrollWidth || 0)) {
        const scrollPositionValue = scrollReverse ? 'start' : 'end';
        if (scrollPosition !== scrollPositionValue) {
          setScrollPosition(scrollPositionValue);
        }
      } else if (scrollPosition !== 'middle') {
        setScrollPosition('middle');
      }
    }
  };

  useEffect(() => {
    const divElement = ref.current;
    divElement?.addEventListener('scroll', updateState);
    return () => divElement?.removeEventListener('scroll', updateState);
  }, [ref]);

  handleScroll();

  return { scrollPosition };
};

export default useScrollPosition;
