import { generatePath, useParams } from 'react-router-dom';

import { FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_ARENA } from '@sorare/core/src/constants/__generated__/routes';
import { Navigate } from '@sorare/core/src/routing/Navigate';

const RivalsMatchPage = () => {
  const { slug = '', date = '' } = useParams();

  return (
    <Navigate
      keepSearchParams
      to={generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_ARENA, {
        date,
        slug,
      })}
      replace
    />
  );
};

export default RivalsMatchPage;
