import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import defaultShield from 'assets/club/shield_none.png';
import { Horizontal } from 'atoms/layout/flex';
import { BodyM, BodyS } from 'atoms/typography';
import { unitMapping } from 'lib/style';

import triangle from './assets/triangle.svg';

const Logo = styled.img`
  width: var(--triple-unit);
`;
const IndicatorCell = styled(Horizontal).attrs({ gap: 0 })`
  height: calc(6 * var(--unit));
  border-left: 2px solid transparent;
`;

export type Props<T extends string> = {
  stats: T[];
  current: boolean;
  statusColor?: string;
  standing: {
    slug: string;
    rank: number;
    rankStatus: string | null;
    name: string;
    pictureUrl?: string | null;
    to?: string;
    stats: Record<T, ReactNode>;
  };
};

export const Standing = <T extends string>({
  standing,
  current,
  stats,
  statusColor,
}: Props<T>) => {
  const standingName = (
    <Horizontal gap={1.5}>
      <Logo src={standing.pictureUrl || defaultShield} alt="" />
      <BodyM as="p">{standing.name}</BodyM>
    </Horizontal>
  );

  return (
    <tr>
      <td
        style={{
          width: unitMapping[1.5],
        }}
      >
        <IndicatorCell center style={{ borderColor: statusColor }}>
          {current && <img src={triangle} alt="" />}
        </IndicatorCell>
      </td>
      <td
        style={{
          width: unitMapping[4],
          textAlign: 'center',
        }}
      >
        <BodyS as="p">{standing.rank}</BodyS>
      </td>
      <td>
        {standing.to ? (
          <Link to={standing.to}>{standingName}</Link>
        ) : (
          standingName
        )}
      </td>
      {stats.map(stat => (
        <td key={stat}>
          <BodyS as="p">{standing.stats[stat]}</BodyS>
        </td>
      ))}
    </tr>
  );
};
