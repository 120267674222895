import {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { Title4, Title5 } from 'atoms/typography';
import {
  GraphQLResult,
  GraphqlForm,
  SubmitButtonProps,
} from 'components/form/Form';
import { GAME_RULES } from 'constants/__generated__/routes';
import { useIntlContext } from 'contexts/intl';
import useIsVisibleInViewport from 'hooks/ui/useIsVisibleInViewport';
import useFetch from 'hooks/useFetch';
import useToggle from 'hooks/useToggle';
import { fileUrl } from 'lib/gitlab';
import LazyMarkdown from 'routing/LazyMarkdown';
import { tabletAndAbove } from 'style/mediaQuery';

import AcceptAgeLimit from '../AcceptAgeLimit';
import AcceptTermsAndPrivacyPolicy from '../AcceptTermsAndPrivacyPolicy';
import AcceptanceCheckbox from '../AcceptanceCheckbox';
import AgreeToReceiveOffersFromPartners from '../AgreeToReceiveOffersFromPartners';
import sharedMessages from '../messages';
import { AcceptTermsArgs } from '../useAcceptTerms';

const messages = defineMessages({
  formTermsTitle: {
    id: 'PromptTermsDialog.formTermsTitle',
    defaultMessage:
      'You must accept the following to proceed with registration:',
  },
  termsDialogTitle: {
    id: 'PromptTermsDialog.termsDialogTitle',
    defaultMessage: 'Terms and Conditions',
  },
  gameRulesLabel: {
    id: 'PromptTermsDialog.gameRuleLabel',
    defaultMessage: 'I agree to the applicable <link>Game Rules</link>',
  },
  formPartnersTitle: {
    id: 'PromptTermsDialog.formPartnersTitle',
    defaultMessage:
      "Please indicate if you agree to share your information with Sorare's partners. This is optional.",
  },
  submit: {
    id: 'PromptTermsDialog.submit',
    defaultMessage: 'Accept',
  },
  scrollToBottom: {
    id: 'PromptTermsDialog.scrollToBottom',
    defaultMessage: 'Scroll to bottom',
  },
});

const TitleSection = styled(Title4)`
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: -0.05em;
`;
const Scrollable = styled.div`
  overflow: auto;
  max-height: calc(100% - calc(8 * var(--unit)));
  padding: var(--double-unit);
`;
const Group = styled(Vertical).attrs({ gap: 2 })`
  margin-top: var(--double-unit);
`;
const MarkdownContainer = styled.div`
  min-height: 100vh;
  display: flex;
  @media ${tabletAndAbove} {
    min-height: calc(100% + var(--double-unit));
  }
`;
const Form = styled(GraphqlForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
`;

const LinkToGameRules = (s: ReactNode) => (
  <a href={GAME_RULES} target="_blank" rel="noreferrer">
    {s}
  </a>
);
export const Title = () => {
  const { formatMessage } = useIntlContext();
  return (
    <TitleSection>{formatMessage(messages.termsDialogTitle)}</TitleSection>
  );
};

const hasAcceptedMandatoryTerms = (acceptanceTerms: EffectiveAcceptTermsArgs) =>
  acceptanceTerms.acceptTerms &&
  acceptanceTerms.acceptAgeLimit &&
  acceptanceTerms.acceptPrivacyPolicy &&
  acceptanceTerms.acceptGameRules;

const Footer = styled(Vertical).attrs({ gap: 2 })`
  padding-top: var(--triple-unit);

  .isMobileWebviewSignUp & {
    border-right: 1px solid var(--c-neutral-300);
    border-left: 1px solid var(--c-neutral-300);
    border-top-left-radius: var(--quadruple-unit);
    border-top-right-radius: var(--quadruple-unit);
  }
`;

type EffectiveAcceptTermsArgs = Omit<AcceptTermsArgs, 'tcuToken'>;
type Props = {
  onFormSuccess: (
    acceptedTerms: EffectiveAcceptTermsArgs,
    result: GraphQLResult
  ) => void;
  acceptTerms: (args: EffectiveAcceptTermsArgs) => Promise<GraphQLResult>;
};
const ReadThroughForm = ({ onFormSuccess, acceptTerms }: Props) => {
  const { formatMessage } = useIntlContext();
  const [error, setError] = useState<Error | null>(null);
  const [
    acceptTermsAndPrivacyPolicyLabel,
    toggleAcceptTermsAndPrivacyPolicyLabel,
  ] = useToggle(false);
  const [acceptAgeLimit, toggleAcceptAgeLimit] = useToggle(false);
  const [acceptGameRules, toggleAcceptGameRules] = useToggle(false);
  const [
    agreedToReceiveOffersFromPartners,
    toggleAgreedToReceiveOffersFromPartners,
  ] = useToggle(false);

  const {
    handleRef,
    node,
    isVisible: formIsVisible,
  } = useIsVisibleInViewport<HTMLDivElement>();

  const scrollToBottom = useCallback(() => {
    node?.scrollIntoView({ behavior: 'smooth' });
  }, [node]);

  const data = useFetch(
    fileUrl(
      formatMessage({
        id: 'Terms.path',
        defaultMessage: 'TERMS.md',
      })
    )
  );

  const onError = useCallback(
    (result: GraphQLResult) => {
      if (result?.errors?.length) {
        setError(new Error(formatMessage(sharedMessages.error)));
      }
    },
    [setError, formatMessage]
  );

  const termsInput = useMemo<EffectiveAcceptTermsArgs>(
    () => ({
      acceptTerms: acceptTermsAndPrivacyPolicyLabel,
      acceptAgeLimit,
      acceptPrivacyPolicy: acceptTermsAndPrivacyPolicyLabel,
      acceptGameRules,
      agreedToReceiveOffersFromPartners,
    }),
    [
      acceptGameRules,
      acceptTermsAndPrivacyPolicyLabel,
      acceptAgeLimit,
      agreedToReceiveOffersFromPartners,
    ]
  );

  const doAcceptTerms = useCallback(
    (values: any, onResult: (result: GraphQLResult) => void) => {
      setError(null);
      if (hasAcceptedMandatoryTerms(termsInput)) {
        acceptTerms(termsInput).then(result => onResult(result));
      } else {
        onResult({
          error: formatMessage(sharedMessages.error),
          errors: [
            {
              message: formatMessage(sharedMessages.error),
            },
          ],
        });
      }
    },
    [acceptTerms, termsInput, formatMessage]
  );

  const onSuccess = useCallback(
    (result: GraphQLResult) => onFormSuccess(termsInput, result),
    [onFormSuccess, termsInput]
  );

  return (
    <Form
      onSubmit={doAcceptTerms}
      onSuccess={onSuccess}
      onError={onError}
      render={(
        Error: ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: FunctionComponent<
          React.PropsWithChildren<SubmitButtonProps>
        >
      ) => (
        <>
          <Scrollable>
            <MarkdownContainer>
              <LazyMarkdown inModal data={data} skipHtml />
            </MarkdownContainer>
            <Group ref={handleRef}>
              <Title5 style={error ? { color: 'var(--c-red-300)' } : {}}>
                <FormattedMessage {...messages.formTermsTitle} />
              </Title5>
              <AcceptTermsAndPrivacyPolicy
                name="acceptTermsAndPrivacyPolicyLabel"
                value={acceptTermsAndPrivacyPolicyLabel}
                onChange={toggleAcceptTermsAndPrivacyPolicyLabel}
              />
              <AcceptAgeLimit
                name="acceptAgeLimit"
                value={acceptAgeLimit}
                onChange={toggleAcceptAgeLimit}
              />
              <AcceptanceCheckbox
                name="acceptGameRules"
                value={acceptGameRules}
                onChange={toggleAcceptGameRules}
                label={formatMessage(messages.gameRulesLabel, {
                  link: LinkToGameRules,
                })}
              />
            </Group>
            <Group>
              <Title5>
                <FormattedMessage {...messages.formPartnersTitle} />
              </Title5>
              <AgreeToReceiveOffersFromPartners
                name="agreedToReceiveOffersFromPartners"
                value={agreedToReceiveOffersFromPartners}
                onChange={toggleAgreedToReceiveOffersFromPartners}
              />
            </Group>
          </Scrollable>
          <Footer>
            {formIsVisible !== undefined && (
              <>
                {!formIsVisible ? (
                  <Button
                    color="quaternary"
                    size="medium"
                    onClick={scrollToBottom}
                  >
                    <FormattedMessage {...messages.scrollToBottom} />
                  </Button>
                ) : (
                  <SubmitButton
                    size="medium"
                    disabled={!hasAcceptedMandatoryTerms(termsInput)}
                  >
                    <FormattedMessage {...messages.submit} />
                  </SubmitButton>
                )}
              </>
            )}
          </Footer>
        </>
      )}
    />
  );
};

export default ReadThroughForm;
