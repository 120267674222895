import {
  Snackbar as MUISnackbar,
  Slide,
  SnackbarOrigin,
} from '@material-ui/core';
import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { css } from 'styled-components';

import CloseButton from 'atoms/buttons/CloseButton';
import IconButton from 'atoms/buttons/IconButton';
import { Portal } from 'atoms/layout/Portal';
import { OverrideClasses } from 'style/utils';

type Props = {
  onClose: () => void;
  className?: string;
  anchorOrigin?: SnackbarOrigin;
  message: ReactNode;
  renderActionButton?: (ActionButton: typeof IconButton) => ReactNode;
  open: boolean;
  autoHideDuration?: number | null;
  transition?: 'grow' | 'slide';
  ClickAwayListenerProps?: ComponentProps<
    typeof MUISnackbar
  >['ClickAwayListenerProps'];
  rounded?: boolean;
};

const [Root, classes] = OverrideClasses(
  MUISnackbar,
  css`
    max-width: calc(100% - 15px);
    margin: auto;
  `,
  {
    root: css`
      align-items: center;
      background-color: var(--c-dialog-background);
      backdrop-filter: blur(2px);
      color: var(--c-neutral-1000);
      flex-wrap: wrap;
      padding: var(--double-unit);
      width: max-content;
      word-break: break-word;
      border: 1px solid var(--c-neutral-150);
      .rounded & {
        border-radius: var(--quadruple-unit);
        padding: var(--unit) var(--intermediate-unit);
      }
      gap: var(--unit);
    `,
    action: css`
      padding-left: 0;
      margin-right: 0;
    `,
    message: css`
      padding: 0;
    `,
  }
);
const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up" />;
};

export const Snackbar = ({
  onClose,
  className,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  open,
  renderActionButton,
  message,
  autoHideDuration = 5000,
  ClickAwayListenerProps,
  transition = 'grow',
  rounded,
}: Props) => {
  return (
    <Portal id="snackbar">
      <Root
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        TransitionComponent={
          transition === 'grow' ? undefined : SlideTransition
        }
        className={classNames(className, { rounded })}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes,
        }}
        message={message}
        action={
          renderActionButton?.(IconButton) || <CloseButton onClose={onClose} />
        }
        ClickAwayListenerProps={ClickAwayListenerProps}
      />
    </Portal>
  );
};

export default Snackbar;
