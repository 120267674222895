import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button, { Props as ButtonProps } from 'atoms/buttons/Button';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { mobileApp } from 'lib/glossary';

type Props = {
  children?: ReactNode;
};

export const NotIOsAppFeature = ({ children }: Props) => {
  const { isIosApp } = useIsMobileApp();
  if (isIosApp) {
    return null;
  }
  return <>{children}</>;
};

export const IOsAppFeature = ({ children }: Props) => {
  const { isIosApp } = useIsMobileApp();
  if (isIosApp) {
    return <>{children}</>;
  }
  return null;
};

export const NotAndroidAppFeature = ({ children }: Props) => {
  const {
    flags: { useRemoveBuyableFeatureOnAndroid = false },
  } = useFeatureFlags();
  const { isAndroidApp } = useIsMobileApp();

  if (isAndroidApp && useRemoveBuyableFeatureOnAndroid) {
    return null;
  }
  return <>{children}</>;
};

export const AndroidAppFeature = ({ children }: Props) => {
  const {
    flags: { useRemoveBuyableFeatureOnAndroid = false },
  } = useFeatureFlags();
  const { isAndroidApp } = useIsMobileApp();

  if (isAndroidApp && useRemoveBuyableFeatureOnAndroid) {
    return <>{children}</>;
  }
  return null;
};

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  opacity: 0.5;
`;

export const UnvailableButtonForAndroid = (props: ButtonProps) => {
  return (
    <StyledTooltip
      title={
        <FormattedMessage
          id="UnvailableButtonForAndroid.tootltip"
          defaultMessage="Temporarily unavailable in-app. Please go to sorare.com"
        />
      }
    >
      <StyledButton {...props}>
        <FormattedMessage {...mobileApp.unavailable} />
      </StyledButton>
    </StyledTooltip>
  );
};

export const NotMobileAppFeature = ({ children }: Props) => {
  const {
    flags: { useRemoveBuyableFeatureOnAndroid = false },
  } = useFeatureFlags();
  const { isAndroidApp, isIosApp } = useIsMobileApp();

  if (isIosApp || (isAndroidApp && useRemoveBuyableFeatureOnAndroid)) {
    return null;
  }
  return <>{children}</>;
};

export const MobileAppFeature = ({ children }: Props) => {
  const {
    flags: { useRemoveBuyableFeatureOnAndroid = false },
  } = useFeatureFlags();
  const { isAndroidApp, isIosApp } = useIsMobileApp();
  if (isIosApp || (isAndroidApp && !useRemoveBuyableFeatureOnAndroid)) {
    return <>{children}</>;
  }
  return null;
};
