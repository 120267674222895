import { Instance } from '@react-three/fiber/dist/declarations/src/core/renderer';

export function resolve(instance: Instance, key: string) {
  let target = instance;
  if (key.includes('-')) {
    const entries = key.split('-');
    const last = entries.pop() as string;
    target = entries.reduce((acc, k) => acc[k], instance);
    return { target, key: last };
  }
  return { target, key };
}
