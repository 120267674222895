import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Ineligible } from '@sorare/core/src/atoms/icons/Ineligible';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';

import TooltipContent from '../TooltipContent';

const titleMessages = defineMessages({
  partial: {
    id: 'So5Eligibility.partial',
    defaultMessage: 'Sorare Pro Partially eligible',
  },
  ineligible: {
    id: 'So5Eligibility.ineligible',
    defaultMessage: 'Sorare Pro Ineligible',
  },
});

const PartialIcon = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  z-index: 0;
  color: white;
  font-size: 11;
  width: 24px;
  height: 24px;
  &::after {
    content: '';
    background-color: var(--c-brand-600);
    inset: 0;
    margin: 3px;
    border-radius: 2px;
    z-index: -1;
    position: absolute;
    transform: rotateZ(45deg);
  }
`;

type Props = {
  partial?: boolean;
  description: MessageDescriptor;
};

export const So5Eligibility = ({ description, partial }: Props) => {
  const { formatMessage } = useIntl();
  const title = partial ? titleMessages.partial : titleMessages.ineligible;

  return (
    <Tooltip title={<TooltipContent title={title} description={description} />}>
      {partial ? (
        <PartialIcon>
          <FontAwesomeIcon icon={faTimes} title={formatMessage(title)} />
        </PartialIcon>
      ) : (
        <Ineligible title={formatMessage(title)} />
      )}
    </Tooltip>
  );
};

export default So5Eligibility;
