import { ReactNode } from 'react';
import styled from 'styled-components';

import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { StepHeader } from './StepHeader';

const Wrapper = styled.div`
  padding: calc(3 * var(--unit));
  @media ${laptopAndAbove} {
    width: 480px;
  }
`;

type Props = {
  header?: ReactNode;
  onClose?: () => void;
  children: ReactNode;
};

export const DialogBody = ({ header, onClose = () => {}, children }: Props) => {
  return (
    <Wrapper>
      <StepHeader onClose={onClose}>{header || null}</StepHeader>
      {children}
    </Wrapper>
  );
};
