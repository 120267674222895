import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Rarity, So5LeaderboardRarity } from '__generated__/globalTypes';

import { User } from '../User';

type ScarcityType =
  | Lowercase<keyof typeof So5LeaderboardRarity>
  | keyof typeof Rarity;

export type Props = {
  scarcity: ScarcityType;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

const Icon = styled.span`
  display: inline-flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  color: var(--c-static-neutral-100);
  width: 1.5em;
  height: 2em;
  &.common {
    background: var(--c-gradient-common);
    color: var(--c-static-neutral-1000);
  }
  &.custom_series {
    background: var(--c-gradient-customSeries);
  }
  &.limited {
    background: var(--c-gradient-limited);
  }
  &.mix {
    background: var(--c-gradient-mix);
  }
  &.rare {
    background: var(--c-gradient-rare);
  }
  &.rare_pro {
    background: var(--c-gradient-rare);
  }
  &.super_rare {
    background: var(--c-gradient-superRare);
  }
  &.unique {
    background: var(--c-gradient-unique);
  }
  &.sm {
    font-size: 7px;
  }
  &.md {
    font-size: 10px;
  }
  &.lg {
    font-size: 14px;
  }
  &.xl {
    font-size: 18px;
  }
`;

export const ScarcityIcon = ({
  scarcity,
  size = 'sm',
  children = <User />,
}: Props) => {
  return (
    <Icon aria-label={scarcity} className={classNames(scarcity, size)}>
      {children}
    </Icon>
  );
};

const Svg = styled.svg`
  --bg-color: #8a8a8a;
  --inner-bg-color: #3a3a3a;
  &.limited {
    --bg-color: #d98c00;
    --inner-bg-color: #583900;
  }
  &.rare,
  &.rare_pro {
    --bg-color: #bf030e;
    --inner-bg-color: #4d0000;
  }
  &.super_rare {
    --bg-color: #032ab9;
    --inner-bg-color: #001049;
  }
  &.mix,
  &.custom_series,
  &.unique {
    --bg-color: #3c3461;
    --inner-bg-color: #5444a0;
  }
  &.small {
    height: 20px;
  }
`;

export const NewScarcityIcon = ({
  scarcity,
  size,
}: {
  scarcity: ScarcityType;
  size?: 'small';
}) => {
  return (
    <Svg
      width="15"
      height="25.88"
      viewBox="0 0 15 25.88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(scarcity, size)}
    >
      <g clipPath="url(#clip)">
        <rect
          y="0.271484"
          width="15"
          height="25.8849"
          rx="1.84892"
          fill="var(--bg-color)"
        />
        <path
          d="M0.593475 15.7829V5.73463L7.74341 2.0275L14.6418 5.73463V15.7829L7.74341 19.3274L0.593475 15.7829Z"
          fill="var(--inner-bg-color)"
        />
        <path
          d="M14.6418 11.653C14.2838 11.653 14.5405 11.1115 14.1335 10.9744C13.8634 10.8379 11.5448 9.85565 10.4193 9.3816C10.3127 9.30976 10.094 9.01116 9.91885 8.77212C9.8166 8.63256 9.72923 8.5133 9.68775 8.47133C9.5977 8.38024 9.61271 7.44741 9.63147 6.99238C9.65023 6.70795 9.74402 6.30965 9.74402 6.30965C10.3631 6.0822 10.4193 5.00136 10.4193 5.00136C10.5544 4.45525 10.138 4.4325 9.96912 4.65995L9.96912 3.86363C9.96912 3.81813 9.89409 3.04827 9.85657 2.66902C9.85657 2.02431 9.12499 0.962437 7.60555 0.791779C5.80473 0.837288 5.27949 2.06224 5.24197 2.66902L5.24197 3.80675L5.29825 4.65995C5.14818 4.3376 4.84804 4.48938 4.84804 4.94439C4.84804 5.94558 5.33577 6.27173 5.57963 6.30965C5.65466 6.51824 5.81598 6.99229 5.86101 7.21983C5.90603 7.44738 5.84225 8.14898 5.80473 8.47133L5.35452 9.32462C4.36032 9.70386 2.21435 10.5192 1.58406 10.7468C0.953773 10.9743 0.743544 11.7534 0.593475 12.0758L0.593475 13.1489L0.593475 14.6962C0.593475 15.5131 0.593482 15.7504 1.49988 16.1754L7.60555 19.36L13.8504 16.1754C14.6788 16.1754 14.6418 15.5131 14.6418 14.6962L14.6418 11.653Z"
          fill={`url(#player_color_${scarcity})`}
        />
        <path
          d="M7.91444 20.1079L0.560486 16.4333C0.560486 17.4479 0.93423 17.6798 1.18706 17.7991L7.91444 21.3436C9.7282 20.3897 13.7778 18.0592 14.1472 17.7991C14.5165 17.5389 14.5869 16.8669 14.6089 16.4333L7.91444 20.1079Z"
          fill="#181818"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <linearGradient
          id={`player_color_${scarcity}`}
          x1="7.61778"
          y1="0.791779"
          x2="7.61778"
          y2="19.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={scarcity === 'unique' ? 'black' : 'white'} />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip">
          <rect
            y="0.271484"
            width="15"
            height="25.8849"
            rx="1.84892"
            fill="white"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default ScarcityIcon;
