import { useIsMobileApp } from './useIsMobileApp';

const useCheckForMobilePushNotifications = () => {
  const { isIosApp, isMobileApp, postMessage } = useIsMobileApp();
  if (!isMobileApp) {
    return () => {};
  }
  if (isIosApp) {
    return () => {
      // eslint-disable-next-line no-restricted-properties
      window.location.replace('sorare://enable-push-notifications');
    };
  }
  return () => {
    postMessage('enablePushNotifications');
  };
};

export default useCheckForMobilePushNotifications;
