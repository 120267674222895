import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SeasonEligibility } from '@sorare/core/src/__generated__/globalTypes';
import ButtonBase from '@sorare/core/src/atoms/buttons/ButtonBase';
import Dropdown from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { BodyS, HeadlineXS, LabelM } from '@sorare/core/src/atoms/typography';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { formatScarcity } from '@sorare/core/src/lib/cards';
import { seasonEligibilityMessages } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import CompetitionLogo from 'components/GenericCardPage/CompetitionsEligibility/CompetitionLogo';
import useCompetitionsEligibility from 'components/GenericCardPage/CompetitionsEligibility/useCompetitionsEligibility';

import {
  AnyCardEligibilityQuery,
  AnyCardEligibilityQueryVariables,
  EligibilityTooltip_anyCard,
} from './__generated__/index.graphql';

const CompetitionRoot = styled(Horizontal)`
  padding: var(--unit) var(--double-unit);
  border-radius: var(--unit);
  background-color: var(--c-neutral-200);
  @media ${laptopAndAbove} {
    background-color: var(--c-neutral-300);
  }
`;

const TooltipRoot = styled(Vertical)`
  max-width: 400px;
`;

const Competitions = styled(Horizontal)`
  flex-wrap: wrap;
`;

const DropdownContent = styled.div`
  padding: var(--double-unit);
`;

const ANY_CARD_ATTRIBUTES_QUERY = gql`
  query AnyCardEligibilityQuery($slug: String!) {
    anyCard(slug: $slug) {
      slug
      sport
      eligibleUpcomingLeagueTracks {
        slug
        so5Leaderboards {
          slug
          ...CompetitionLogo_so5Leaderboard
        }
      }
      ...useCompetitionsEligibility_anyCard
    }
  }
  ${useCompetitionsEligibility.fragments.anyCard}
  ${CompetitionLogo.fragments.so5Leaderboard}
` as TypedDocumentNode<
  AnyCardEligibilityQuery,
  AnyCardEligibilityQueryVariables
>;

const EligibilityTooltip = ({
  card,
}: {
  card: AnyCardEligibilityQuery['anyCard'];
}) => {
  const isLaptopAndAbove = useIsLaptop();
  const { inSeasonLeaderboards, classicCompetitions } =
    useCompetitionsEligibility(card);

  const label = (
    <Horizontal>
      <FontAwesomeIcon icon={faInfoCircle} />{' '}
      <FormattedMessage
        id="CardOverview.competitions"
        defaultMessage="{nbCompetitions, plural, =0 {# competition} one {# competition} other {# competitions}}"
        values={{
          nbCompetitions:
            inSeasonLeaderboards.length + classicCompetitions.length,
        }}
      />
    </Horizontal>
  );

  const content = (
    <TooltipRoot>
      <HeadlineXS as="h3">
        <FormattedMessage
          id="CompetitionsEligibility.title"
          defaultMessage="Competitions eligibility"
        />
      </HeadlineXS>
      <BodyS as="h6">
        <FormattedMessage
          {...seasonEligibilityMessages[SeasonEligibility.IN_SEASON]}
        />
      </BodyS>
      {inSeasonLeaderboards.length > 0 ? (
        <Competitions>
          {inSeasonLeaderboards.map(so5Leaderboard => (
            <CompetitionRoot key={so5Leaderboard.slug}>
              <CompetitionLogo size={2} so5Leaderboard={so5Leaderboard} />
              <LabelM>
                {`${so5Leaderboard.so5League.displayName} - ${
                  so5Leaderboard.mainRarityType &&
                  formatScarcity(so5Leaderboard.mainRarityType)
                }`}
              </LabelM>
            </CompetitionRoot>
          ))}
        </Competitions>
      ) : (
        <BodyS as="p" color="var(--c-neutral-700)">
          <FormattedMessage
            id="CompetitionsEligibility.max1classicPerTeam"
            defaultMessage="Max 1 Classic Card per team"
          />
        </BodyS>
      )}
      <BodyS as="h6">
        <FormattedMessage
          {...seasonEligibilityMessages[SeasonEligibility.CLASSIC]}
        />
      </BodyS>
      {classicCompetitions.length > 0 ? (
        <Competitions>
          {classicCompetitions.map(so5Leaderboard => (
            <CompetitionRoot key={so5Leaderboard.slug}>
              <CompetitionLogo size={2} so5Leaderboard={so5Leaderboard} />
              <LabelM>
                {`${so5Leaderboard.so5League.displayName} - ${
                  so5Leaderboard.mainRarityType &&
                  formatScarcity(so5Leaderboard.mainRarityType)
                }`}
              </LabelM>
            </CompetitionRoot>
          ))}
        </Competitions>
      ) : (
        <BodyS as="p" color="var(--c-neutral-700)">
          <FormattedMessage
            id="CompetitionsEligibility.notEligible"
            defaultMessage="Not eligible"
          />
        </BodyS>
      )}
    </TooltipRoot>
  );

  if (isLaptopAndAbove) {
    return <Tooltip title={content}>{label}</Tooltip>;
  }
  return (
    <Dropdown label={props => <ButtonBase {...props}>{label}</ButtonBase>}>
      <DropdownContent>{content}</DropdownContent>
    </Dropdown>
  );
};

const LazyEligibilityTooltip = ({
  card,
}: {
  card: EligibilityTooltip_anyCard;
}) => {
  const { data } = useQuery(ANY_CARD_ATTRIBUTES_QUERY, {
    variables: { slug: card.slug },
  });

  if (data?.anyCard) return <EligibilityTooltip card={data.anyCard} />;

  return (
    <Horizontal>
      <FontAwesomeIcon icon={faInfoCircle} />
      <FormattedMessage
        id="CardOverview.competitionsPlaceholder"
        defaultMessage="- competitions"
      />
    </Horizontal>
  );
};

LazyEligibilityTooltip.fragments = {
  anyCard: gql`
    fragment EligibilityTooltip_anyCard on AnyCardInterface {
      slug
      rarityTyped
    }
  ` as TypedDocumentNode<EligibilityTooltip_anyCard>,
};
export default LazyEligibilityTooltip;
