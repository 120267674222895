import { MouseEvent } from 'react';
import styled from 'styled-components';

import { useCurrentUserContext } from 'contexts/currentUser';
import { useRestrictedAccessContext } from 'contexts/restrictedAccess';
import useEvents from 'lib/events/useEvents';

const Wrapper = styled.div`
  display: inline-flex;
  width: max-content;
  & > * {
    flex: 1;
  }
  &:empty {
    display: none;
  }
`;

type Props = {
  hasBlockchainRewards: boolean;
  trackProperties?: {
    gameweek?: number;
  };
  children: ({
    disabledClaim,
  }: {
    disabledClaim: boolean;
  }) => React.JSX.Element;
  shouldTrack?: boolean;
};

export const ShouldVerifyUserBeforeClaiming = ({
  hasBlockchainRewards,
  trackProperties,
  children,
  shouldTrack = true,
}: Props) => {
  const track = useEvents();
  const { currentUser } = useCurrentUserContext();
  const { setShowRestrictedAccess } = useRestrictedAccessContext();
  const shouldVerifyPhone =
    hasBlockchainRewards && !currentUser?.phoneNumberVerified;
  const shouldVerifyEmail = !currentUser?.confirmed;

  const onClick = (e: MouseEvent<HTMLElement>) => {
    if (shouldTrack) {
      track('Click rewards banner', trackProperties);
    }
    if (shouldVerifyEmail) {
      setShowRestrictedAccess('email');
      e.stopPropagation();
      e.preventDefault();
    } else if (shouldVerifyPhone) {
      setShowRestrictedAccess('phone');
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Wrapper onClickCapture={onClick}>
      {children({ disabledClaim: shouldVerifyPhone })}
    </Wrapper>
  );
};
