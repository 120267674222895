import { ReactNode, useEffect, useRef } from 'react';

import {
  OnboardingHighlightName,
  useOnboardingHighLightContext,
} from 'contexts/onboardingHighlight';

type Props = {
  highlightName: OnboardingHighlightName;
  children?: ReactNode;
  className?: string;
};

export const OnboardingHighlightWrapper = ({
  highlightName,
  children,
  className,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { registerHighlight } = useOnboardingHighLightContext();

  useEffect(() => {
    registerHighlight(highlightName, () => {
      if (ref.current) {
        return ref.current.getBoundingClientRect();
      }
      return null;
    });
  }, [highlightName, registerHighlight]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
