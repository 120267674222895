interface Post {
  content: string | null;
  featuredImage: {
    node: {
      altText: string | null;
      sourceUrl: string | null;
      thumbnail: string | null;
    };
  } | null;
}

const usePostImage = <P extends Post>(
  post: P | null | undefined,
  featured: boolean
) => {
  if (!post) return null;

  const featuredImage = post.featuredImage?.node;

  if (featuredImage) {
    const url = featured ? featuredImage.sourceUrl : featuredImage.thumbnail;

    return {
      url,
      alt: featuredImage.altText,
    };
  }

  const m = post.content?.match(/<img[^>]+src="([^">]+)"/);

  return {
    url: m?.[1]?.split('?')[0] || null,
    alt: null,
  };
};

export default usePostImage;
