import { Navigate, generatePath } from 'react-router-dom';

import { MLB_TEAMS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { PageParams } from './__generated__/routeParams';

const BaseballTeamPlayers = () => {
  const { slug } = useTypedParams<PageParams>();

  return <Navigate to={generatePath(MLB_TEAMS_SLUG, { slug })} />;
};

export default BaseballTeamPlayers;
