import { TypedDocumentNode, gql } from '@apollo/client';

import { Rarity } from '__generated__/globalTypes';

import { formatLineupDisplayName_so5Lineup } from './__generated__/so5.graphql';
import { withFragments } from './gql';

export const formatLineupDisplayName = withFragments(
  (so5Lineup: formatLineupDisplayName_so5Lineup) => {
    const { name, so5Leaderboard } = so5Lineup;

    const { displayName, trainingCenter } = so5Leaderboard || {};

    return [displayName, trainingCenter && name].filter(Boolean).join(' ');
  },
  {
    so5Lineup: gql`
      fragment formatLineupDisplayName_so5Lineup on So5Lineup {
        id
        name
        so5Leaderboard {
          slug
          displayName
          trainingCenter
        }
      }
    ` as TypedDocumentNode<formatLineupDisplayName_so5Lineup>,
  }
);

export enum PlayerScoreStatus {
  NO_GAME = 'no_game',
  DID_NOT_PLAY = 'did_not_play',
  REVIEWING = 'reviewing',
  PENDING = 'pending',
}

export const isRarityStr = (rarity: string): rarity is Rarity =>
  rarity in Rarity;
