import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessage,
  defineMessages,
  useIntl,
} from 'react-intl';

import { CardRarity } from '@sorare/core/src/__generated__/globalTypes';
import ScarcityIcon from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import Bold from '@sorare/core/src/atoms/typography/Bold';
import ScarcityBall, {
  ScarcityType,
} from '@sorare/core/src/components/card/ScarcityBall';
import { Scarcity, formatScarcity } from '@sorare/core/src/lib/cards';
import { withFragments } from '@sorare/core/src/lib/gql';

import { getAgeFilterValue } from '@sorare/marketplace/src/lib/filters';

import {
  getAlgoliaFiltersForRequirements_LeaderboardRequirementsInterface,
  getLeaderboardRequirements_LeaderboardRequirementsInterface,
} from './__generated__/index.graphql';

const messages = defineMessages({
  rarities: {
    id: 'Rules.rarities',
    defaultMessage: 'Allowed Cards: <b>{value}</b>',
  },
  none: {
    id: 'Rules.noRarities',
    defaultMessage: 'None',
  },
});

export const SimpleRequirement = ({
  message,
  values,
}: {
  message: MessageDescriptor;
  values?: object;
}) => {
  return (
    <span>
      <FormattedMessage {...message} values={{ ...values, b: Bold }} />
    </span>
  );
};

type Props = {
  minRarity?: Scarcity;
  minCount?: number;
  allowedRarities?: Scarcity[];
  minRarityMessage: MessageDescriptor;
};

export const FullRequirements = ({
  minRarity,
  minCount,
  allowedRarities,
  minRarityMessage,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <span>
      {minRarity === undefined && allowedRarities === undefined && (
        <SimpleRequirement message={messages.none} />
      )}
      {allowedRarities && (
        <SimpleRequirement
          message={messages.rarities}
          values={{
            value: allowedRarities
              .map(rarity => formatScarcity(rarity))
              .join(', '),
          }}
        />
      )}

      {minRarity && (
        <Horizontal>
          <ScarcityBall scarcity={minRarity as ScarcityType} iconOnly />
          {formatMessage(minRarityMessage, {
            rarity: formatScarcity(minRarity),
            min: minCount,
          })}
        </Horizontal>
      )}
    </span>
  );
};

export const NoRequirement = () => {
  return <SimpleRequirement message={messages.none} />;
};

const defaultMinRarityMessage = defineMessage({
  id: 'Rules.MinRarity.minimumRarity',
  defaultMessage: '{min} {rarity} {min, plural, one {card} other {cards}}',
});

export const MinRarityRequirement = ({
  min,
  rarity,
  minRarityMessage = defaultMinRarityMessage,
}: {
  min: number;
  rarity: Scarcity;
  minRarityMessage?: MessageDescriptor;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Horizontal>
      <ScarcityIcon scarcity={rarity} />
      {formatMessage(minRarityMessage, {
        rarity: formatScarcity(rarity),
        min,
      })}
    </Horizontal>
  );
};

export const AllowedRaritiesRequirement = ({
  allowedRarities,
}: {
  allowedRarities: Scarcity[];
}) => {
  return (
    <SimpleRequirement
      message={messages.rarities}
      values={{
        value: allowedRarities.map(rarity => formatScarcity(rarity)).join(', '),
      }}
    />
  );
};

const defaultCurrentSeasonCardCountMessage = defineMessage({
  id: 'Rules.MinCurrentSeasonCardCount.currentSeason',
  defaultMessage:
    'Requires <b>{minCurrentSeasonCardCount, plural, one {# card} other {# cards}}</b> from current season',
});
const preciseCurrentSeasonCardCountMessage = defineMessage({
  id: 'Rules.MinCurrentSeasonCardCount.exactSeason',
  defaultMessage:
    'Requires <b>{minCurrentSeasonCardCount, plural, one {# card} other {# cards}}</b> from {currentSeason} season',
});

export const MinCurrentSeasonCardCountRequirement = ({
  minCurrentSeasonCardCount,
  currentSeason,
}: {
  minCurrentSeasonCardCount: number;
  currentSeason?: string;
}) => {
  return (
    <SimpleRequirement
      message={
        currentSeason
          ? preciseCurrentSeasonCardCountMessage
          : defaultCurrentSeasonCardCountMessage
      }
      values={{
        minCurrentSeasonCardCount,
        currentSeason,
        b: Bold,
      }}
    />
  );
};

export const getLeaderboardRequirements = withFragments(
  (
    requirements: getLeaderboardRequirements_LeaderboardRequirementsInterface
  ): ReactNode[] => {
    const returnValue = [];
    if (requirements.allowedRarities) {
      returnValue.push(
        <AllowedRaritiesRequirement
          allowedRarities={requirements.allowedRarities}
        />
      );
    }
    if (requirements.minCurrentSeasonCardCount > 0) {
      returnValue.push(
        <MinCurrentSeasonCardCountRequirement
          minCurrentSeasonCardCount={requirements.minCurrentSeasonCardCount}
        />
      );
    }
    if (requirements.minRarity) {
      returnValue.push(
        <MinRarityRequirement
          min={requirements.minRarity.minCount}
          rarity={requirements.minRarity.rarity}
        />
      );
    }

    return returnValue;
  },
  {
    LeaderboardRequirementsInterface: gql`
      fragment getLeaderboardRequirements_LeaderboardRequirementsInterface on LeaderboardRequirementsInterface {
        allowedRarities
        minRarity {
          minCount
          rarity
        }
        minCurrentSeasonCardCount
        ... on NBALeaderboardRequirements {
          tenGameAverageTotalLimit
          allowMVP
        }
      }
    ` as TypedDocumentNode<getLeaderboardRequirements_LeaderboardRequirementsInterface>,
  }
);

export const getAlgoliaFiltersForRequirements = withFragments(
  (
    requirements: getAlgoliaFiltersForRequirements_LeaderboardRequirementsInterface
  ) => {
    const returnValue: {
      rarity?: string;
      age?: string;
    } = {};

    const rarities = requirements.allowedRarities.filter(
      r => r !== CardRarity.common
    );
    if (rarities.length > 0) {
      returnValue.rarity = rarities.join(',');
    }
    const ageFilterValue = getAgeFilterValue({
      min: requirements.playerAgeRequirements?.minAge ?? undefined,
      max: requirements.playerAgeRequirements?.maxAge ?? undefined,
    });
    if (ageFilterValue !== undefined) {
      returnValue.age = ageFilterValue;
    }
    return returnValue;
  },
  {
    LeaderboardRequirementsInterface: gql`
      fragment getAlgoliaFiltersForRequirements_LeaderboardRequirementsInterface on LeaderboardRequirementsInterface {
        allowedRarities
        minRarity {
          minCount
          rarity
        }
        playerAgeRequirements {
          minAge
          maxAge
        }
      }
    ` as TypedDocumentNode<getAlgoliaFiltersForRequirements_LeaderboardRequirementsInterface>,
  }
);
