const mayChase = 'May Chase';
const juneChase = 'June Chase';
const julyChase = 'July Chase';
const augustChase = 'August Chase';

export const chaseGameWeeks = {
  16: mayChase,
  17: mayChase,
  18: mayChase,
  19: mayChase,
  24: juneChase,
  25: juneChase,
  26: juneChase,
  27: juneChase,
  33: julyChase,
  34: julyChase,
  35: julyChase,
  36: julyChase,
  42: augustChase,
  43: augustChase,
  44: augustChase,
  45: augustChase,
} as Record<number, string>;
