import { flushSync } from 'react-dom';

const useTransitionApi = (props?: {
  skipTransition?: boolean;
  flushSync?: boolean;
}) => {
  const { skipTransition = false, flushSync: flushSyncOpt = true } =
    props || {};
  const updateDOM = (callback: () => void) => {
    const fn = () => {
      if (skipTransition || !(document as any).startViewTransition) {
        const updateCallbackDone = Promise.resolve(callback()).then(() => {});
        const ready = Promise.reject(Error('View transitions unsupported'));
        // Avoid spamming the console with this error unless the promise is used.
        ready.catch(() => {});
        return {
          ready,
          updateCallbackDone,
          finished: updateCallbackDone,
          skipTransition: () => {},
        };
      }
      const transition = (document as any).startViewTransition(callback);
      return transition as ViewTransition;
    };
    return flushSyncOpt ? flushSync(fn) : fn();
  };
  return { updateDOM };
};

export default useTransitionApi;
