import { Navigate, Outlet, generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { LayoutParams } from './__generated__/routeParams';

const SquadLeaderboardSeasonSlugLayout = () => {
  const { userSlug, seasonSlug } = useTypedParams<LayoutParams>();
  const { currentUser } = useCurrentUserContext();

  if (!userSlug && currentUser) {
    return (
      <Navigate
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG,
          {
            seasonSlug,
            userSlug: currentUser.slug,
          }
        )}
      />
    );
  }
  return <Outlet />;
};
export default SquadLeaderboardSeasonSlugLayout;
