import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Checkbox from 'atoms/inputs/Checkbox';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { NativeSelect } from 'atoms/inputs/NativeSelect';
import { Vertical } from 'atoms/layout/flex';
import { Caption, LabelM, Text16, Title3 } from 'atoms/typography';
import IntlDate from 'components/IntlDate';
import { GraphQLResult, GraphqlForm, TextField } from 'components/form/Form';
import { useIntlCountries } from 'hooks/useIntlCountries';
import {
  CreateFiatWalletArgs,
  useCreateOrUpdateFiatWallet,
} from 'hooks/wallets/useCreateOrUpdateFiatWallet';
import { fiatWallet, glossary, userAttributes } from 'lib/glossary';

import { EditableRow } from '../EditableRow';
import StepWrapper from '../StepWrapper';
import { MANGOPAY_PRIVACY_POLICY, MANGOPAY_TERMS } from '../externalLinks';
import { CreateFiatWalletSteps } from '../type';
import { useCurrencyOptions } from '../useCurrencyOptions';
import { useIsNotMajor } from '../useIsNotMajor';

const Content = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
`;

const Rows = styled(Vertical).attrs({ gap: 0 })``;

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
`;

const Label = styled(Text16)`
  color: var(--c-neutral-1000);
`;

const CheckboxFormControlLabel = styled(FormControlLabel)`
  gap: var(--double-unit);
  border-top: 1px solid var(--c-neutral-400);
  padding-top: var(--double-unit);
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: var(--c-link);
`;

type DeclarativeFormArgs = Omit<
  CreateFiatWalletArgs,
  'mangopayTermsAndConditionsAccepted' | 'dob'
> & { dob: Date };

type Props = {
  setStep: (step: CreateFiatWalletSteps) => void;
  args: DeclarativeFormArgs;
  onChange: (a: DeclarativeFormArgs) => void;
};

export const ReviewInfoBeforeAddingId = ({
  setStep,
  args,
  onChange: doOnChange,
}: Props) => {
  const countriesOfResidence = useIntlCountries(true);
  const nationalities = useIntlCountries(true);
  const currencies = useCurrencyOptions();
  const { create } = useCreateOrUpdateFiatWallet();
  const [declarativeFormArgs, setDeclarativeFormArgs] =
    useState<DeclarativeFormArgs>(args);

  const [
    mangopayTermsAndConditionsAccepted,
    setMangopayTermsAndConditionsAccepted,
  ] = useState<boolean>(false);

  const {
    firstName,
    lastName,
    countryOfResidenceCode,
    nationalityCode,
    dob,
    currency,
  } = declarativeFormArgs;

  const submitAndCreateFiatWallet = async (
    onResult: (result: GraphQLResult) => void
  ) => {
    const createResult = await create({
      ...declarativeFormArgs,
      dob,
      mangopayTermsAndConditionsAccepted,
    });
    onResult(createResult);
  };

  const isNotMajor = useIsNotMajor(dob);

  const onChange =
    (key: keyof DeclarativeFormArgs) => (value: string | Date) => {
      setDeclarativeFormArgs((prev: DeclarativeFormArgs) => ({
        ...prev,
        [key]: value,
      }));
      doOnChange({ ...declarativeFormArgs, [key]: value });
    };

  const disabled =
    isNotMajor ||
    firstName === '' ||
    lastName === '' ||
    countryOfResidenceCode === '' ||
    nationalityCode === '' ||
    !currency ||
    !mangopayTermsAndConditionsAccepted;

  return (
    <StyledGraphqlForm
      onSubmit={(variables, onResult) => {
        submitAndCreateFiatWallet(onResult);
      }}
      onSuccess={() => {
        setStep(CreateFiatWalletSteps.ACTIVATION_SUCCESS);
      }}
      render={(Error, SubmitButton) => (
        <StepWrapper
          setStep={setStep}
          step={CreateFiatWalletSteps.REVIEW_INFORMATION}
          submitButton={
            <SubmitButton size="medium" disabled={disabled}>
              <FormattedMessage {...fiatWallet.activateCashWallet} />
            </SubmitButton>
          }
        >
          <>
            <Title3>
              <FormattedMessage
                id="CreateFiatWallet.reviewInformation.title"
                defaultMessage="Review your information"
              />
            </Title3>
            <Text16>
              <FormattedMessage
                id="CreateFiatWallet.reviewInformation.description"
                defaultMessage="You're almost done! Please review your information below for accuracy."
              />
            </Text16>
            <Content>
              <Rows>
                <EditableRow
                  value={
                    countriesOfResidence.find(
                      c => c.value === countryOfResidenceCode
                    )?.label || ''
                  }
                  label={
                    <Label>
                      <FormattedMessage {...glossary.countryOfResidence} />
                    </Label>
                  }
                >
                  <NativeSelect
                    values={countriesOfResidence}
                    value={countryOfResidenceCode || ''}
                    name="countryOfResidence"
                    fullWidth
                    onChange={onChange('countryOfResidenceCode')}
                  />
                </EditableRow>
                <EditableRow
                  value={
                    nationalities.find(n => n.value === nationalityCode)
                      ?.label || ''
                  }
                  label={
                    <Label>
                      <FormattedMessage {...glossary.nationality} />
                    </Label>
                  }
                >
                  <NativeSelect
                    values={nationalities}
                    value={nationalityCode || ''}
                    name="nationality"
                    fullWidth
                    onChange={onChange('nationalityCode')}
                  />
                </EditableRow>
                <EditableRow
                  value={firstName}
                  label={
                    <Label>
                      <FormattedMessage {...glossary.firstName} />
                    </Label>
                  }
                >
                  <TextField
                    autoFocus
                    name="firstName"
                    defaultValue={firstName}
                    onChange={event =>
                      onChange('firstName')(event.target.value)
                    }
                  />
                </EditableRow>
                <EditableRow
                  value={lastName}
                  label={
                    <Label>
                      <FormattedMessage {...glossary.lastName} />
                    </Label>
                  }
                >
                  <TextField
                    autoFocus
                    name="lastName"
                    onChange={event => onChange('lastName')(event.target.value)}
                    defaultValue={lastName}
                  />
                </EditableRow>
                <EditableRow
                  value={
                    <FormattedDate
                      value={dob}
                      day="numeric"
                      month="long"
                      year="numeric"
                      timeZone="UTC"
                    />
                  }
                  label={
                    <Label>
                      <FormattedMessage {...glossary.dateOfBirth} />
                    </Label>
                  }
                >
                  <IntlDate value={dob} onChange={onChange('dob')} />
                </EditableRow>
                {isNotMajor && (
                  <LabelM color="var(--c-red-600)">
                    <FormattedMessage {...glossary.notMajorError} />
                  </LabelM>
                )}
                <EditableRow
                  value={currency}
                  label={
                    <Label>
                      <FormattedMessage {...userAttributes.fiatCurrency} />
                    </Label>
                  }
                >
                  <NativeSelect
                    fullWidth
                    name="currency"
                    values={currencies}
                    value={currency ?? ''}
                    onChange={onChange('currency')}
                  />
                </EditableRow>
                <CheckboxFormControlLabel
                  name="mangopayTermsAndConditionsAccepted"
                  control={
                    <StyledCheckbox
                      checked={mangopayTermsAndConditionsAccepted}
                      onChange={e => {
                        setMangopayTermsAndConditionsAccepted(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Caption color="var(--c-neutral-1000)">
                      <FormattedMessage
                        id="createFiatWallet.chooseCurrency.terms"
                        defaultMessage="I agree to Mangopay’s <linkTerms>Terms and Conditions</linkTerms> and that my personal data will be processed pursuant to the <linkPrivacy>Privacy Policy</linkPrivacy>."
                        values={{
                          linkTerms: children => (
                            <StyledLink href={MANGOPAY_TERMS} target="_blank">
                              {children}
                            </StyledLink>
                          ),
                          linkPrivacy: children => (
                            <StyledLink
                              href={MANGOPAY_PRIVACY_POLICY}
                              target="_blank"
                            >
                              {children}
                            </StyledLink>
                          ),
                        }}
                      />
                    </Caption>
                  }
                />
              </Rows>
              <Error />
            </Content>
          </>
        </StepWrapper>
      )}
    />
  );
};

export default ReviewInfoBeforeAddingId;
