import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { FC, FormEventHandler, ReactNode, useCallback, useState } from 'react';
import { useDebounce, useKey } from 'react-use';
import styled from 'styled-components';

import { ButtonProps } from 'atoms/buttons/ButtonBase';
import { Drawer } from 'atoms/layout/Drawer';
import { useIsLaptop } from 'hooks/device/useIsLaptop';
import useTouchScreen from 'hooks/device/useTouchScreen';
import { theme } from 'style/theme';

const Content = styled.div`
  background-color: var(--c-neutral-200);
  box-shadow: var(--shadow-200);
  border-radius: var(--intermediate-unit) var(--intermediate-unit) 0 0;
  max-height: calc(
    var(--radix-popover-content-available-height, 100vh) - var(--unit)
  );
  overflow: auto;
`;
const StyledForm = styled.form`
  margin: 0;
`;
const StyledPopoverContent = styled(Popover.Content)`
  z-index: ${theme.zIndex.tooltip};
  border-radius: var(--intermediate-unit);
  &.fullWidth {
    width: var(--radix-popover-trigger-width);
  }
`;

export const THRESHOLD = 10;

const useOpenState = ({
  open,
  onClose,
  onOpen,
}: Pick<Props, 'open' | 'onClose' | 'onOpen'>) => {
  const [openState, setOpenState] = useState(false);
  const isControlledMode = typeof open === 'boolean';
  if (isControlledMode) {
    return [
      open,
      (v: boolean) => {
        if (v) {
          onOpen?.();
        } else {
          onClose?.();
        }
      },
    ] as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  }
  return [openState, setOpenState] as const;
};

export type Props = {
  children:
    | ReactNode
    | FC<React.PropsWithChildren<{ closeDropdown: () => void }>>;
  label: (
    props: Pick<ButtonProps, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>
  ) => ReactNode;
  gap?: 0 | 4 | 8 | 16;
  horizontalGap?: -16 | -8 | -4 | 0 | 4 | 8 | 16;
  onChange?: FormEventHandler<HTMLFormElement>;
  triggerOnHover?: boolean;
  closeOnChange?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  fullWidth?: boolean;
  align?: 'left' | 'right';
};

export const DropdownRadix = ({
  open: openProp,
  children,
  label,
  gap = 0,
  horizontalGap = 0,
  onChange,
  triggerOnHover = false,
  closeOnChange = false,
  onClose = () => {},
  onOpen = () => {},
  fullWidth,
  align = 'left',
}: Props) => {
  const [open, setOpen] = useOpenState({ open: openProp, onClose });
  const [mouseEntered, setMouseEntered] = useState(false);
  const isTouchScreen = useTouchScreen();
  const isLaptopAndAbove = useIsLaptop();

  const triggerOnHoverEnabled =
    !isTouchScreen && isLaptopAndAbove && triggerOnHover;

  const closeDropdown = useCallback(() => {
    onClose();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const openDropdown = useCallback(() => {
    onOpen();
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useKey('Escape', () => open && closeDropdown(), undefined, [open]);

  useDebounce(
    () => {
      if (!mouseEntered) {
        closeDropdown();
      }
    },
    100,
    [mouseEntered]
  );

  const mouseLeave = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(false);
    }
  };

  const mouseEnter = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(true);
      openDropdown();
    }
  };

  const button = label({
    onClick: () => {
      if (open) {
        closeDropdown();
      } else {
        openDropdown();
      }
    },
    onMouseEnter: mouseEnter,
    onMouseLeave: mouseLeave,
  });

  const content = (
    <Content>
      <StyledForm
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
          if (closeOnChange) {
            closeDropdown();
          }
        }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {typeof children === 'function'
          ? children({ closeDropdown })
          : children}
      </StyledForm>
    </Content>
  );
  if (isLaptopAndAbove) {
    return (
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>{button}</Popover.Trigger>
        <Popover.Portal>
          <StyledPopoverContent
            sideOffset={gap}
            align={
              align
                ? ({ left: 'start', right: 'end' } as const)[align]
                : 'start'
            }
            alignOffset={horizontalGap}
            className={classNames({ fullWidth })}
            asChild
          >
            {content}
          </StyledPopoverContent>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  return (
    <>
      {button}
      <Drawer open={open} onBackdropClick={closeDropdown} side="bottom">
        {content}
      </Drawer>
    </>
  );
};
