import styled from 'styled-components';

export const LineupCardsContainer = styled.div`
  --columns: 5;
  --card-gap: var(--unit);
  display: flex;
  justify-content: center;
  flex-wrap: var(--wrap-cards, nowrap);
  gap: var(--card-gap);
  overflow: none;
  & > * {
    flex-basis: calc(
      (100% - (var(--columns) - 1) * var(--card-gap)) / var(--columns)
    );
  }
`;
