import styled from 'styled-components';

import { Container } from 'atoms/layout/Container';
import { Color } from 'style/types';

export const ColouredContainer = styled(Container)<{ color: Color }>`
  background-color: ${({ color }) => color};
`;

export default ColouredContainer;
