import { ReactNode } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';

import { LabelM } from 'atoms/typography';
import Container from 'components/onboarding/managerTask/Container';
import { MarketplaceOnboardingStep } from 'contexts/managerTask';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { glossary } from 'lib/glossary';

const marketplaceTaskTitles = defineMessages<MarketplaceOnboardingStep>({
  [MarketplaceOnboardingStep.menu]: {
    id: 'MarketplaceTaskTitles.menu',
    defaultMessage: 'Access the Market',
  },
  [MarketplaceOnboardingStep.managerSalesLink]: {
    id: 'MarketplaceTaskTitles.managerSalesLink',
    defaultMessage: 'Access Manager Sales',
  },
  [MarketplaceOnboardingStep.search]: {
    id: 'MarketplaceTaskTitles.search',
    defaultMessage: 'Search for a player',
  },
  [MarketplaceOnboardingStep.marketplaceItem]: {
    id: 'MarketplaceTaskTitles.marketplaceItem',
    defaultMessage: 'Cards are worth money',
  },
  [MarketplaceOnboardingStep.buy]: {
    id: 'MarketplaceTaskTitles.buy',
    defaultMessage: 'Buy your card',
  },
});

export const marketplaceTaskDescription =
  defineMessages<MarketplaceOnboardingStep>({
    [MarketplaceOnboardingStep.menu]: {
      id: 'MarketplaceTaskDescription.menu',
      defaultMessage:
        'You can recruit new players on the Sorare Market, accessible from the menu.',
    },
    [MarketplaceOnboardingStep.managerSalesLink]: {
      id: 'MarketplaceTaskDescription.managerSalesLink',
      defaultMessage:
        'The best way to get started is to buy cards from other Sorare Managers.',
    },
    [MarketplaceOnboardingStep.search]: {
      id: 'MarketplaceTaskDescription.search',
      defaultMessage:
        'There are thousands of players licensed on Sorare. You can search for a player using the search bar.',
    },
    [MarketplaceOnboardingStep.marketplaceItem]: {
      id: 'MarketplaceTaskDescription.marketplaceItem',
      defaultMessage:
        "{playerName}'s {rarity} is currently available at {price}.",
    },
    [MarketplaceOnboardingStep.buy]: {
      id: 'MarketplaceTaskDescription.buy',
      defaultMessage:
        'Once you decided which player you want to recruit for your team, you can buy the card here.',
    },
  });

const MarketplaceOnboardingTask = ({
  name,
  title = marketplaceTaskTitles[name],
  buttonLabel = glossary.continue,
  content = (
    <LabelM color="var(--c-neutral-500)">
      <FormattedMessage {...marketplaceTaskDescription[name]} />
    </LabelM>
  ),
  onClick,
}: {
  title?: MessageDescriptor;
  name: MarketplaceOnboardingStep;
  content?: ReactNode;
  buttonLabel?: MessageDescriptor;
  onClick: () => void;
}) => {
  const { hideBottomNavBar } = useIsMobileApp();
  const steps = Object.keys(MarketplaceOnboardingStep);
  // For webview we skip the first task, has we display bottom bar from app.
  const stepsCount = hideBottomNavBar ? steps.length - 1 : steps.length;
  return (
    <Container
      current={steps.findIndex(key => key === name) + 1}
      total={stepsCount}
      onClick={onClick}
      labels={{ title, button: buttonLabel }}
    >
      {content}
    </Container>
  );
};

export default MarketplaceOnboardingTask;
