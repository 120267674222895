import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  RejectNoCardDraftableAppearance,
  RejectNoCardDraftableAppearanceVariables,
} from './__generated__/useRejectNoCardDraftableAppearance.graphql';

export const REJECT_NO_CARD_DRAFTABLE_APPEARANCE_MUTATION = gql`
  mutation RejectNoCardDraftableAppearance($userID: UUID!, $id: UUID!) {
    rejectNoCardDraftableAppearance(userID: $userID, id: $id) {
      id
      player {
        slug
        displayName
      }
      team {
        slug
        name
      }
      bonus
      season
    }
  }
` as TypedDocumentNode<
  RejectNoCardDraftableAppearance,
  RejectNoCardDraftableAppearanceVariables
>;

type RejectNoCardDraftableAppearanceInput = {
  userID: string;
  id: string;
};
export const useRejectNoCardDraftableAppearance = () => {
  const [mutate] = useMutation(REJECT_NO_CARD_DRAFTABLE_APPEARANCE_MUTATION);

  return useCallback(
    async (input: RejectNoCardDraftableAppearanceInput) =>
      mutate({ variables: input }),
    [mutate]
  );
};
