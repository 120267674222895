import { Ref, forwardRef } from 'react';

import IconButton, { Props as IconButtonProps } from 'atoms/buttons/IconButton';

interface Props extends IconButtonProps {
  active?: boolean;
}
// eslint-disable-next-line react/display-name
export const MenuIconButton = forwardRef(
  (props: Props, ref: Ref<HTMLElement>) => {
    const { active, className, ...rest } = props;

    return <IconButton ref={ref} {...rest} color="opa" className={className} />;
  }
);

export default MenuIconButton;
