import { ReactNode, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { LabelL, LabelM } from 'atoms/typography';
import Dialog from 'components/dialog';
import VerifyPhoneNumber from 'components/user/VerifyPhoneNumber';
import { useCurrentUserContext } from 'contexts/currentUser';
import { laptopAndAbove } from 'style/mediaQuery';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  padding: 0 var(--triple-unit) var(--triple-unit) var(--triple-unit);
  @media ${laptopAndAbove} {
    width: 480px;
  }
`;

const messages = defineMessages({
  title: {
    id: 'football.noCardEntry.title',
    defaultMessage: 'No Card Entry',
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  poolDetail?: ReactNode;
  emailAddress: string;
};
export const NoCardEntryInfoDialog = ({
  open,
  onClose,
  poolDetail,
  emailAddress,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const [showVerify, setShowVerify] = useState(false);
  return (
    <>
      {showVerify && (
        <VerifyPhoneNumber onCancel={() => setShowVerify(false)} />
      )}
      <Dialog
        maxWidth={false}
        open={open}
        onClose={onClose}
        title={
          <LabelL bold className="text-center">
            <FormattedMessage {...messages.title} />
          </LabelL>
        }
      >
        <Body>
          <LabelM color="var(--c-neutral-600)">
            <FormattedMessage
              id="NoCardEntryInfoDialog.infoTitle"
              defaultMessage="If you have not entered a lineup in a competition that requires NFT cards, you can participate in this Game Week without cards by clicking on the “No Card entry” button once the Entry Period is closed.{br}{br}
              To participate, you will have to send an email at {email} and follow the instructions (including submitting a proof of residence) to select a team within a 45 minute window."
              values={{
                br: <br />,
                email: emailAddress,
              }}
            />
          </LabelM>
          {poolDetail && (
            <LabelM color="var(--c-neutral-600)">{poolDetail}</LabelM>
          )}
          {!currentUser?.phoneNumberVerified && (
            <Button
              color="quaternary"
              onClick={() => setShowVerify(true)}
              size="medium"
            >
              <FormattedMessage
                id="NoCardEntryInfoDialog.verifyPhoneNumber"
                defaultMessage="Verify your phone number to be eligible"
              />
            </Button>
          )}
        </Body>
      </Dialog>
    </>
  );
};
