import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { Text16, Title3 } from 'atoms/typography';
import { glossary } from 'lib/glossary';

import { PhoneNumber } from './PhoneNumber';
import { RecoveryEmail } from './RecoveryEmail';
import { TwoFA } from './TwoFA';

const Content = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
`;

const Blocks = styled.div`
  border: solid 1px var(--c-neutral-400);
  border-radius: var(--double-unit);
`;

const Block = styled.div`
  padding: var(--double-unit);
  &:not(:last-child) {
    border-bottom: solid 1px var(--c-neutral-400);
  }
`;

type Props = {
  onClose: () => void;
};
export const Home = ({ onClose }: Props) => {
  return (
    <Content>
      <Vertical>
        <div className="text-center">
          <Title3 color="var(--c-neutral-1000)">
            <FormattedMessage
              id="accountSecurityCheck.title"
              defaultMessage="Account Security Overview"
            />
          </Title3>
          <Text16 color="var(--c-neutral-1000)">
            <FormattedMessage
              id="accountSecurityCheck.subtitle"
              defaultMessage="Please review and confirm your account security preferences below. Consider adding missing information to ensure you keep your account secure and avoid getting locked out."
            />
          </Text16>
        </div>
      </Vertical>
      <Blocks>
        <Block>
          <RecoveryEmail />
        </Block>
        <Block>
          <TwoFA />
        </Block>
        <Block>
          <PhoneNumber />
        </Block>
      </Blocks>
      <Button size="medium" onClick={onClose} color="primary">
        <FormattedMessage {...glossary.confirm} />
      </Button>
    </Content>
  );
};

export default Home;
