import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title4, Title5 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  USSportsAcceptNoCardLineupMutation,
  USSportsAcceptNoCardLineupMutationVariables,
} from './__generated__/index.graphql';

const US_SPORTS_ACCEPT_NO_CARD_LINEUP_MUTATION = gql`
  mutation USSportsAcceptNoCardLineupMutation($userID: UUID!, $id: UUID!) {
    confirmNoCardLineup(userID: $userID, id: $id) {
      id
    }
  }
` as TypedDocumentNode<
  USSportsAcceptNoCardLineupMutation,
  USSportsAcceptNoCardLineupMutationVariables
>;

const Wrapper = styled(Vertical).attrs({ gap: 4, center: true })`
  padding: var(--quadruple-unit);

  font: var(--t-14);
  text-align: center;
  justify-content: stretch;
`;

export const NoCardEntryAccept = () => {
  const [params] = useSearchParams();
  const { currentUser } = useCurrentUserContext();
  const [submitted, setSubmitted] = useState(false);

  const userID = idFromObject(currentUser?.id);
  const id = params.get('id');

  const [confirmNoCardLineupMutation] = useMutation(
    US_SPORTS_ACCEPT_NO_CARD_LINEUP_MUTATION,
    {
      warnIfNoErrorHandling: false,
    }
  );

  useEffect(() => {
    const submit = async () => {
      if (!submitted && userID && id) {
        await confirmNoCardLineupMutation({
          variables: { userID, id },
        });
        setSubmitted(true);
      }
    };

    submit();
  }, [confirmNoCardLineupMutation, userID, id, submitted]);

  const invalid = !userID || !id;

  return (
    <Wrapper>
      {submitted ? (
        <>
          <Title4>
            <FormattedMessage
              id="NoCardEntryAccept.title"
              defaultMessage="Your team is confirmed."
            />
          </Title4>

          <Title5>
            <FormattedMessage
              id="NoCardEntryAccept.subtitle"
              defaultMessage="Please check your emails to select the competition."
            />
          </Title5>
        </>
      ) : (
        <>
          {invalid ? (
            <Title4>
              <FormattedMessage
                id="NoCardEntryCancel.errorTitle"
                defaultMessage="An error occured"
              />
            </Title4>
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
    </Wrapper>
  );
};
