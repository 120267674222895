import { ReactNode } from 'react';

import ErrorBoundary from 'atoms/ErrorBoundary';

type Props = {
  fallback: ReactNode;
  children: ReactNode;
  onError?: (e: Error) => void;
};

export const CatchErrors = ({
  fallback,
  children,
  onError = () => {},
}: Props) => {
  return (
    <ErrorBoundary onCatch={onError}>
      {({ error }) => (error ? fallback : children)}
    </ErrorBoundary>
  );
};
