import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

interface SVGRProps {
  title?: string;
  size?: keyof typeof unitMapping;
  fill?: Color;
}

export const Timer = ({
  size = 2,
  fill = 'currentColor',
  title,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      viewBox="0 0 13 15"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      aria-labelledby={title ? 'timer' : undefined}
      {...props}
    >
      {title ? <title id="timer">{title}</title> : null}
      <path
        d="m3.7866 13.9363c-1.28991-.5452-2.35638-1.5146-3.023939-2.7488-.6675598-1.23423-.89644-2.65962-.64896-4.04168.24747-1.38205.956549-2.63843 2.010559-3.56239 1.054-.92397 2.39014-1.46048 3.78856-1.52126-.0184-.05014-.02778-.10315-.02771-.15658v-.30167h-.60128c-.11959 0-.23428-.04766-.31885-.13249-.08456-.08483-.13207-.19989-.13207-.31986s.04751-.235029.13207-.319861c.08457-.084832.19926-.13249.31885-.13249h2.10454c.1196 0 .23429.047658.31885.13249.08457.084832.13207.199891.13207.319861s-.0475.23503-.13207.31986c-.08456.08483-.19925.13249-.31885.13249h-.60143v.30167c.0001.06051-.01196.12042-.03546.17615 1.16633.10705 2.27812.54535 3.20502 1.26354s1.6306 1.68652 2.0285 2.79152c.398 1.10501.4738 2.30091.2185 3.44757-.2552 1.14663-.831 2.19663-1.6598 3.02673-.8675.8695-1.9737 1.4599-3.17718 1.6959-1.20353.236-2.44986.1071-3.57992-.3707zm-2.862219-5.69704c0 1.0439.308579 2.06434.886709 2.93234.57813.8679 1.39985 1.5444 2.36124 1.9439.9614.3995 2.01928.504 3.03988.3003 1.02059-.2037 1.95809-.7064 2.69389-1.4446.7358-.7381 1.2368-1.6786 1.4398-2.70245s.0987-2.08508-.2995-3.0495c-.3983-.96442-1.0727-1.78871-1.9379-2.36862-.8653-.57992-1.88255-.88941-2.92314-.88935-1.39484.00168-2.73206.55831-3.71833 1.54776-.98626.98946-1.541049 2.33096-1.542649 3.73022zm10.906919-3.57888-1.4881-1.49281c-.0846-.08483-.1321-.19989-.1321-.31987s.0475-.23505.132-.3199c.0846-.08485.1993-.13252.3189-.13254.1196-.00001.2343.04764.3189.13246l1.4881 1.49282c.0845.08483.1321.19989.1321.31987s-.0475.23505-.1321.3199c-.0845.08484-.1992.13252-.3188.13253-.1196.00002-.2343-.04763-.3189-.13246z"
        fill="#fff"
      />
      <path
        d="m6.10676 12.545c2.30964 0 4.18194-1.8936 4.18194-4.22951h-4.18194v-4.22955c-2.30963 0-4.18196 1.89363-4.18196 4.22955 0 2.33591 1.87233 4.22951 4.18196 4.22951z"
        fill={fill}
      />
    </svg>
  );
};
