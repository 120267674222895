import { ReactNode, useMemo } from 'react';
import { animated, easings, useTransition } from 'react-spring';
import styled from 'styled-components';

import { Portal } from 'atoms/layout/Portal';
import Backdrop from 'atoms/loader/Backdrop';
import useBodyLock from 'hooks/useBodyLock';
import { theme } from 'style/theme';

type DrawerSide = 'right' | 'left' | 'bottom' | 'top';

const Wrapper = styled(animated.aside)`
  position: fixed;
  inset: 0;
  isolation: isolate;
  z-index: ${theme.zIndex.drawer};
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
`;

export type Props = {
  children: ReactNode;
  open: boolean;
  className?: string;
  side?: DrawerSide;
  onBackdropClick?: () => void;
  blur?: boolean;
};

export const Drawer = ({
  children,
  className,
  side = 'right',
  open,
  onBackdropClick,
  blur = false,
}: Props) => {
  const hiddenStyle = useMemo(() => {
    switch (side) {
      case 'right':
        return {
          x: '100%',
          y: '0%',
        };
      case 'left':
        return {
          x: '-100%',
          y: '0%',
        };
      case 'bottom':
        return {
          x: '0%',
          y: '100%',
        };
      case 'top':
      default:
        return {
          x: '0%',
          y: '-100%',
        };
    }
  }, [side]);
  const positionning = useMemo(() => {
    switch (side) {
      case 'right':
        return {
          left: 'unset',
        };
      case 'left':
        return {
          right: 'unset',
        };
      case 'bottom':
        return {
          top: 'unset',
        };
      case 'top':
      default:
        return {
          bottom: 'unset',
        };
    }
  }, [side]);
  useBodyLock(!!(open && onBackdropClick));

  const transitions = useTransition(open, {
    from: { ...positionning, ...hiddenStyle },
    enter: { ...positionning, x: '0%', y: '0%' },
    leave: { ...positionning, ...hiddenStyle },
    reverse: open,
    config: {
      duration: 250,
      easing: open ? easings.easeOutSine : easings.easeInSine,
    },
  });
  return (
    <Portal id="drawer">
      <div
        role="presentation"
        // Since React Portal maintain event propagation, we need to manually stop them in the DOM in case this Drawer is used inside an Element with mouse events
        // There is no scenario where we want to keep the event propagation in a Drawer
        // https://github.com/facebook/react/issues/11387
        // https://react.dev/reference/react-dom/createPortal#:~:text=more%20examples%20below.-,A%20portal%20only%20changes%20the%20physical%20placement%20of%20the%20DOM%20node,-.%20In%20every%20other
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        {onBackdropClick && open && (
          <Backdrop blur={blur} onClick={onBackdropClick} />
        )}
        {transitions(
          (styles, isOpen) =>
            isOpen && (
              <Wrapper style={styles} className={className}>
                {children}
              </Wrapper>
            )
        )}
      </div>
    </Portal>
  );
};
