import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { ConditionalWrapper } from '@sorare/core/src/atoms/layout/ConditionalWrapper';
import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';

import TeamPicture from 'components/club/TeamPicture';

import { GameDetails_game } from './__generated__/index.graphql';

const GameDetailsWrapper = styled.div`
  padding: var(--unit) var(--triple-unit);
  background-color: var(--c-neutral-200);
  clip-path: polygon(
    20px 0,
    100% 0,
    calc(100% - 15px) 50%,
    calc(100% - 5px) 50%,
    calc(100% - 20px) 100%,
    0 100%,
    15px 50%,
    5px 50%
  );
`;

const Wrapper = styled(SBHorizontal)`
  width: 100%;
`;
const ClubLogo = styled(TeamPicture)<{ size: number }>`
  ${props => `height: calc(${props.size} * var(--unit))`};
`;

type Props = {
  withBackground?: boolean;
  game: GameDetails_game;
  details: ReactNode;
  withCode?: boolean;
  size?: number;
};

export const GameDetails = ({
  withBackground,
  game,
  details,
  withCode,
  size = 5,
}: Props) => {
  return (
    <ConditionalWrapper wrap={!!withBackground} Wrapper={GameDetailsWrapper}>
      <Wrapper>
        <Horizontal>
          {game.homeTeam && (
            <ClubLogo
              size={size}
              team={game.homeTeam}
              useEuropeanChampionshipFlag
            />
          )}
          {withCode && <LabelM bold>{game.homeTeam!.code}</LabelM>}
        </Horizontal>
        {details}
        <Horizontal>
          {withCode && <LabelM bold>{game.awayTeam!.code}</LabelM>}
          {game.awayTeam && (
            <ClubLogo
              size={size}
              team={game.awayTeam}
              useEuropeanChampionshipFlag
            />
          )}
        </Horizontal>
      </Wrapper>
    </ConditionalWrapper>
  );
};

GameDetails.fragments = {
  game: gql`
    fragment GameDetails_game on Game {
      id
      date
      homeTeam {
        slug
        name
        code
        ...TeamPicture_team
      }
      awayTeam {
        slug
        name
        code
        ...TeamPicture_team
      }
    }
    ${TeamPicture.fragments.team}
  ` as TypedDocumentNode<GameDetails_game>,
};
