import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';

type Props = {
  onClick: () => void;
  onSkip?: () => void;
};
export const DeliverableItemOrderFooter = ({
  onClick,
  onSkip,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Vertical center gap={0.5}>
      <Horizontal>
        <Button size="small" color="primary" onClick={onClick}>
          <FormattedMessage
            id="DeliverableItemFooter.cta"
            defaultMessage="Enter delivery address"
          />
        </Button>
        {onSkip && (
          <Button size="small" color="secondary" onClick={onSkip}>
            <FormattedMessage
              id="DeliverableItemFooter.cta.secondary"
              defaultMessage="I'll do it later"
            />
          </Button>
        )}
      </Horizontal>
      {children}
    </Vertical>
  );
};
