import { TypedDocumentNode, gql } from '@apollo/client';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from '@sorare/core/src/atoms/buttons/Button';
import IconButton from '@sorare/core/src/atoms/buttons/IconButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { MY_SORARE_AUCTIONS } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import useCheckForMobilePushNotifications from '@sorare/core/src/hooks/useCheckForMobilePushNotifications';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import useLifecycle, {
  LIFECYCLE,
  Lifecycle,
} from '@sorare/core/src/hooks/useLifecycle';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import { Link } from '@sorare/core/src/routing/Link';

import { useAddReminder } from 'hooks/auctions/useAddReminder';
import { useRemoveReminder } from 'hooks/auctions/useRemoveReminder';

import { AddReminderDownloadApp } from './AddReminderDownloadApp';
import { AuctionReminder_auction } from './__generated__/index.graphql';

type Props = {
  auction: AuctionReminder_auction;
  withText?: boolean;
  small?: boolean;
};
const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const ToggleAuctionReminderButton = ({
  auction,
  withText,
  small,
  onEnabled,
}: Props & { onEnabled: () => void }) => {
  const { isIosApp } = useIsMobileApp();
  const { myReminder } = auction;
  const [reminder, updateReminder] = useState(myReminder);
  const { showNotification } = useSnackNotificationContext();
  const { addReminder, loading: addReminderLoading } = useAddReminder();
  const { removeReminder, loading: removeReminderLoading } =
    useRemoveReminder();

  const reminderEnabled = !!reminder?.enabled;

  const toggleReminder = async () => {
    if (reminderEnabled) {
      const updatedReminder = await removeReminder({
        id: auction.id,
        reminder,
      });
      if (updatedReminder) {
        updateReminder(updatedReminder);
        showNotification('reminderSuccessfullyRemovedFromAuction');
      }
    } else {
      const updatedReminder = await addReminder({ auction });
      if (updatedReminder) {
        updateReminder(updatedReminder);
        showNotification('reminderSuccessfullyAddedOnAuction', {
          myAuctionsLink: (chunks: ReactNode) =>
            isIosApp ? (
              chunks
            ) : (
              <StyledLink to={MY_SORARE_AUCTIONS}>{chunks}</StyledLink>
            ),
        });
        onEnabled();
      }
    }
  };
  const updating = addReminderLoading || removeReminderLoading;

  const onClick = useLoggedCallback(() => {
    if (updating) return;
    toggleReminder();
  });

  const color = reminderEnabled ? 'primary' : 'tertiary';

  if (withText && !reminderEnabled) {
    return (
      <Button
        size={small ? 'small' : 'medium'}
        fullWidth
        onClick={onClick}
        color={color}
      >
        <FormattedMessage
          id="auctionReminder.addReminder"
          defaultMessage="Notify me"
        />
        <FontAwesomeIcon icon={faBell} />
      </Button>
    );
  }
  return (
    <IconButton small={small} onClick={onClick} icon={faBell} color={color} />
  );
};

export const AuctionReminder = ({ auction, withText, small }: Props) => {
  const { isMobileApp } = useIsMobileApp();
  const { open } = auction;
  const { currentUser } = useCurrentUserContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { update: updateLifecycle } = useLifecycle();
  const checkForMobilePushNotifications = useCheckForMobilePushNotifications();

  if (!open) {
    return null;
  }

  const doNotShowDownloadApp = (
    currentUser?.userSettings?.lifecycle as Lifecycle
  )?.doNotShowAddAuctionReminderDownloadApp;
  const onEnabledReminder = () => {
    checkForMobilePushNotifications();
    if (!isMobileApp && !doNotShowDownloadApp) {
      setDialogOpen(true);
    }
  };

  const onCloseDialog = (doNotShowAgain: boolean) => {
    setDialogOpen(false);

    if (doNotShowAgain) {
      updateLifecycle(LIFECYCLE.doNotShowAddAuctionReminderDownloadApp, true);
    }
  };

  return (
    <>
      <AddReminderDownloadApp open={dialogOpen} onClose={onCloseDialog} />
      <ToggleAuctionReminderButton
        auction={auction}
        small={small}
        withText={withText}
        onEnabled={onEnabledReminder}
      />
    </>
  );
};

AuctionReminder.fragments = {
  auction: gql`
    fragment AuctionReminder_auction on TokenAuction {
      id
      open
      myReminder {
        id
        enabled
      }
    }
  ` as TypedDocumentNode<AuctionReminder_auction>,
};
