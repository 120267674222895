import { useCallback, useEffect, useState } from 'react';

const useIsVisibleInViewport = <T extends Element>() => {
  const [node, setNode] = useState<T>();
  const [isVisible, setState] = useState<boolean>();

  const handleRef = useCallback(
    (el: T | null) => {
      if (el) {
        setNode(el);
      }
    },
    [setNode]
  );

  useEffect(() => {
    if (node && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting);
      });

      observer.observe(node);

      return () => observer.unobserve(node);
    }
    return () => null;
  }, [node]);

  return {
    handleRef,
    node,
    isVisible,
  };
};

export default useIsVisibleInViewport;
