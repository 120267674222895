import { ReactNode, Suspense, useCallback, useState } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import Dialog from '@sorare/core/src/components/dialog';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import BuyTokenConfirmationById from 'components/buyActions/BuyTokenConfirmationById';
import { BuyPrimaryBuyConfirmationById } from 'components/primaryBuy/BuyPrimaryBuyConfirmationById';

import BuyConfirmationContextProvider, {
  BuyConfirmationProviderStateProps,
} from '.';

const Body = styled.div`
  position: relative;
  isolation: isolate;
  min-height: 100%;
  @media ${tabletAndAbove} {
    height: auto;
  }
`;

const Content = styled(Vertical)`
  height: 100%;
`;

type Props = {
  children?: ReactNode;
};

export const BuyConfirmationDialog = (
  props:
    | (Omit<BuyConfirmationProviderStateProps, 'onClose'> & {
        showBuyingConfirmation: boolean;
        onClose: () => void;
      })
    | null
) => {
  const { up: upTablet } = useScreenSize('tablet');
  const {
    tokenOfferId,
    primaryBuyId,
    primaryBuyConfirmationOptions,
    onClose,
    showBuyingConfirmation,
  } = props || { onClose: () => {} };
  return (
    <Dialog
      open={showBuyingConfirmation}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      hideHeader
      fullScreen={!upTablet}
    >
      <Body>
        <Content>
          {tokenOfferId && (
            <BuyTokenConfirmationById
              tokenOfferId={tokenOfferId}
              onClose={onClose}
            />
          )}
          {primaryBuyId && primaryBuyConfirmationOptions && (
            <BuyPrimaryBuyConfirmationById
              primaryBuyId={primaryBuyId}
              options={primaryBuyConfirmationOptions}
              onClose={onClose}
            />
          )}
        </Content>
      </Body>
    </Dialog>
  );
};

const BuyConfirmationProvider = ({ children }: Props) => {
  const [buyConfirmationProps, setBuyConfirmationProps] =
    useState<BuyConfirmationProviderStateProps | null>(null);

  const [showBuyingConfirmation, setShowBuyingConfirmation] =
    useState<boolean>(false);

  const { onClose: doOnClose, beforeOnClose } = buyConfirmationProps || {};

  const onClose = useCallback(() => {
    beforeOnClose?.();
    setBuyConfirmationProps(null);
    setShowBuyingConfirmation(false);
    doOnClose?.();
  }, [beforeOnClose, doOnClose]);

  return (
    <BuyConfirmationContextProvider
      value={{
        showBuyingConfirmation,
        setBuyConfirmationProps,
        setShowBuyingConfirmation,
      }}
    >
      <Suspense fallback={null}>
        <BuyConfirmationDialog
          {...buyConfirmationProps}
          showBuyingConfirmation={showBuyingConfirmation}
          onClose={onClose}
        />
      </Suspense>
      {children}
    </BuyConfirmationContextProvider>
  );
};

export default BuyConfirmationProvider;
