import { TypedDocumentNode, gql } from '@apollo/client';

import { PaymentBoxBlock } from '../PaymentBox/ui';
import { TokenSummary } from '../TokenSummary';
import { BidTokenSummary_anyCard } from './__generated__/index.graphql';

type Props = {
  card: BidTokenSummary_anyCard;
  withoutRecentSales?: boolean;
};

export const BidTokenSummary = (props: Props) => {
  return (
    <PaymentBoxBlock>
      <TokenSummary {...props} />
    </PaymentBoxBlock>
  );
};

BidTokenSummary.fragments = {
  anyCard: gql`
    fragment BidTokenSummary_anyCard on AnyCardInterface {
      slug
      ...TokenSummary_anyCard
    }
    ${TokenSummary.fragments.anyCard}
  ` as TypedDocumentNode<BidTokenSummary_anyCard>,
};

export default BidTokenSummary;
