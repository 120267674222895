import { Navigate, generatePath } from 'react-router-dom';

import { MLB_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { PageParams } from './__generated__/routeParams';

const CompetitionPage = () => {
  const { fixture } = useTypedParams<PageParams>();

  return <Navigate to={generatePath(MLB_PLAY_PRO_FIXTURE, { fixture })} />;
};
export default CompetitionPage;
