import * as React from 'react';
import styled, {
  RuleSet,
  createGlobalStyle,
  css,
  keyframes,
} from 'styled-components';

import { tabletAndAbove } from './mediaQuery';

export const hideScrollbar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox (CSS standard) */

  /* Webkit (Chrome, Safari and Opera) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const stickyBottom = css`
  position: sticky;
  bottom: 0;
  transform: translateY(calc(-1 * (var(--bottom-bar-height-mobile))));
  @media ${tabletAndAbove} {
    transform: unset;
  }
  transition: transform 0.3s ease;
`;

export function OverrideClasses<
  C extends React.ComponentType<React.PropsWithChildren<any>>,
  T extends { [name: string]: RuleSet<object> },
>(
  Base: C,
  baseStyles: null | RuleSet<object>,
  overrides: T,
  attrs: any = {}
): [C, { [K in keyof T]: string }] {
  // Generate unique sc-prefix without the period
  const Unique = styled.div``;
  const prefix = Unique.toString().slice(1);

  const newStyles = Object.entries(overrides).map(([key, value]) => {
    const className = `${prefix}--${key}`;

    return {
      css: css`
        .${className} {
          ${value}
        }
      `,
      key,
      className,
    };
  });

  const Main = styled(Base).attrs(attrs)`
    ${baseStyles}
  `;

  const GlobalStyles = createGlobalStyle`
    ${newStyles.map(e => e.css)}
  `;

  const classes = newStyles.reduce(
    (acc, { key, className }) => ({ ...acc, [key]: className }),
    {}
  );

  // We need to inject globalStyles
  const Wrapper = (props: any) => (
    <>
      <GlobalStyles />
      <Main {...props} />
    </>
  );

  // @ts-expect-error Types are invalid
  return [Wrapper, classes];
}

// styled.div``.toString() returns a unique string of the form .sc-HASH
// We get the unique HASH from it
const unique = () => styled.div``.toString().slice(4);

// Inspired by vanilla-extract for the API
// https://vanilla-extract.style/documentation/api/create-var/
// We start these private variables with three dashes to be more explicit

/**
 * This utility function allows us to create scoped CSS properties
 *
 * This allows us to reduce code duplication when injecting
 * dynamic props into styled-components.
 *
 * @example
 * const background = createVar();
 * const mobileBackground = createVar('mobile')
 *
 * const Styled = styled.div`
 *   background: var(${mobileBackground});
 *  '@media (screen and min-width: 200px)` {
 *     background: var(${background});
 *   }
 * `;
 *
 * <Styled style={{
 *   [background]: 'linear-gradient(red, blue)'
 *   [mobileBackground]: 'linear-gradient(blue, red)'
 * }} />
 *
 */
export const createVar = <T extends string>(
  // @ts-expect-error `label: T = ''` is not well understood by tsc
  label: T = ''
): `---${string}-${T}` => `---${unique()}-${label}`;

export const stroke = (size = `1px`, color = `var(--c-brand-600)`) => {
  /* outline the border of the image respecting transparency */
  /* size is divided by 2 as stacking 8 drop shadows has a compounding effect */
  /* https://stackoverflow.com/a/55012328/332389 */
  return css`
    --stroke-size: calc(${size} / 2);
    --stroke-color: ${color};

    filter: drop-shadow(0 var(--stroke-size) 0 var(--stroke-color))
      drop-shadow(var(--stroke-size) 0 0 var(--stroke-color))
      drop-shadow(calc(-1 * var(--stroke-size)) 0 0 var(--stroke-color))
      drop-shadow(0 calc(-1 * var(--stroke-size)) 0 var(--stroke-color))
      drop-shadow(var(--stroke-size) var(--stroke-size) 0 var(--stroke-color))
      drop-shadow(
        var(--stroke-size) calc(-1 * var(--stroke-size)) 0 var(--stroke-color)
      )
      drop-shadow(
        calc(-1 * var(--stroke-size)) var(--stroke-size) 0 var(--stroke-color)
      )
      drop-shadow(
        calc(-1 * var(--stroke-size)) calc(-1 * var(--stroke-size)) 0
          var(--stroke-color)
      );
    will-change: filter;
  `;
};

export type inferRuleSetProps<R> = R extends RuleSet<infer P> ? P : never;

const skeletonKeyframes = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50%, 100% {
    transform: translateX(100%);
  }
`;

export const skeletonStyle = css`
  position: relative;
  display: block;
  --skeleton-highlight: rgba(var(--c-rgb-neutral-800), 0.025);
  overflow-x: hidden; /* For Safari <= v15 */
  overflow: clip;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    animation: ${skeletonKeyframes} 2s linear 500ms infinite;
    background: linear-gradient(
      to right,
      transparent,
      var(--skeleton-highlight),
      transparent
    );
  }
`;
