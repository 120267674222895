import { TypedDocumentNode, gql } from '@apollo/client';

import { ScoreStatus } from '@sorare/core/src/__generated__/globalTypes';
import {
  Score,
  Props as ScoreProps,
} from '@sorare/core/src/components/scoring/Score';

import { RivalsAppearanceScore_footballRivalsAppearance } from './__generated__/index.graphql';

type Props = {
  appearance: RivalsAppearanceScore_footballRivalsAppearance;
  rarityBonusEnabled?: boolean;
} & Pick<ScoreProps, 'withDate' | 'withTooltip' | 'hideReviewing'>;

export const RivalsAppearanceScore = ({
  appearance,
  rarityBonusEnabled,
  ...rest
}: Props) => {
  return (
    <Score
      score={
        rarityBonusEnabled
          ? appearance.score
          : appearance.scoreWithoutRarityBonus
      }
      scoreStatus={appearance.so5Score?.scoreStatus || ScoreStatus.DID_NOT_PLAY}
      {...rest}
    />
  );
};

RivalsAppearanceScore.fragments = {
  footballRivalsAppearance: gql`
    fragment RivalsAppearanceScore_footballRivalsAppearance on FootballRivalsAppearance {
      id
      score
      scoreWithoutRarityBonus
      so5Score {
        id
        scoreStatus
      }
    }
  ` as TypedDocumentNode<RivalsAppearanceScore_footballRivalsAppearance>,
};
