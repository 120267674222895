import styled from 'styled-components';

import creditCoin from 'assets/credit_coin.png';

const Img = styled.img`
  width: var(--double-unit);
  height: var(--double-unit);
`;
export const CreditCoin = () => {
  return <Img src={creditCoin} alt="Credit Coin" />;
};
