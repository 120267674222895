import { useEffect } from 'react';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

const loadScript = async (src: string) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('ze-snippet')) {
      resolve(undefined);
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

declare global {
  interface Window {
    zESettings: any;
    zE: any;
  }
}

export const openWidget = () => {
  if (window.zE) window.zE('webWidget', 'open');
};

export const hideWidget = () => {
  if (window.zE) window.zE('webWidget', 'hide');
};

export const showWidget = () => {
  if (window.zE) window.zE('webWidget', 'show');
};

export const Form = () => {
  const { currentUser } = useCurrentUserContext();

  useEffect(() => {
    loadScript(
      'https://static.zdassets.com/ekr/snippet.js?key=5f9868fc-4b4d-4ddf-980f-a12935a05f96'
    ).then(() => showWidget());
  }, []);

  useEffect(() => {
    return () => hideWidget();
  }, []);

  window.zESettings = {
    webWidget: {
      contactForm: {
        fields: [
          { id: 'email', prefill: { '*': currentUser?.email } },
          { id: '360023889697', prefill: { '*': currentUser?.nickname } },
        ],
        ticketForms: [{ id: '10206971384221' }],
      },
    },
  };

  return null;
};
