import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Rarity } from '__generated__/globalTypes';
import { IS_TEST_RUNNER } from 'config';
import { range } from 'lib/arrays';
import {
  CARD_ASPECT_RATIO,
  CARD_SIZE,
  getHumanReadableSerialNumber,
} from 'lib/cards';
import { useFormatAnyPositions } from 'lib/players';

import { CardMeta_AnyCardInterface } from './__generated__/index.graphql';

type Props = {
  card: Omit<CardMeta_AnyCardInterface, 'rarityTyped'> & {
    rarityTyped?: Rarity;
  };
};
export const colors = {
  [Rarity.custom_series]: '#FFFFFF', // Unsupported
  [Rarity.common]: '#D1DCDF',
  [Rarity.limited]: '#DFAD46',
  [Rarity.rare]: '#F2412D',
  [Rarity.super_rare]: '#00CAFA',
  [Rarity.unique]: '#C277FD',
};
export const CardMeta = ({ card }: Props) => {
  const formatPositions = useFormatAnyPositions();
  const width = CARD_SIZE;
  const height = CARD_SIZE / CARD_ASPECT_RATIO;
  const stroke = 2;
  const radius = 12;
  const steps = 30;
  const angle = 270;
  if (IS_TEST_RUNNER) {
    // this placeholder content messes up the tests otherwise
    return null;
  }
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="white"
      stroke="transparent"
      style={{
        position: 'absolute',
        inset: 0,
      }}
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="87"
        x2="87"
        y1="51.5"
        y2="162"
      >
        <stop offset="0" stopColor="#ececec" />
        <stop offset="1" stopColor="#ececec" stopOpacity="0" />
      </linearGradient>

      <mask id="strokeMask">
        <g
          transform={`scale(${width / height} 1)`}
          // eslint-disable-next-line react/no-unknown-property
          transform-origin={`${width / 2} ${height / 2}`}
        >
          <g>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from={`0 ${width / 2} ${height / 2}`}
              to={`360 ${width / 2} ${height / 2}`}
              dur="5s"
              repeatCount="indefinite"
            />
            {range(steps).map(i => (
              <rect
                key={i}
                width={height}
                height={60}
                x="50%"
                y="50%"
                // eslint-disable-next-line react/no-unknown-property
                transform-origin={`${width / 2} ${height / 2}`}
                transform={`rotate(${(angle / steps) * i})`}
                fill={`rgb(${(255 / 20) * i},${(255 / 20) * i},${(255 / 20) * i})`}
              />
            ))}
          </g>
        </g>

        <rect
          fill="#000"
          x={stroke}
          y={stroke}
          width={width - 2 * stroke}
          height={height - 2 * stroke}
          rx={radius - stroke}
        />
        <rect
          stroke="#000"
          fill="transparent"
          x={-2 * stroke}
          y={-2 * stroke}
          strokeWidth={8}
          width={width + 4 * stroke}
          height={height + 4 * stroke}
          rx={radius + stroke}
        />
      </mask>

      <path
        d="m22.5 118.499-12.5 29.5c31.5 16.001 46 18.001 78.5 20.501 29.5 5.5 76.5-19.5 76-20.501-.4-.8-6.167-16.666-9-24.499-.5-2-3-11.5-10.5-14-2.4-1.2-23-9.8333-33-14-1.5-1-5.5-7-6.5-8.0008-.8-.8006-.667-8.9997-.5-12.9992.167-2.5 1-6.0008 1-6.0008 5.5-1.9992 6-11.4992 6-11.4992 1.2-4.8-2.5-5-4-3.0008v-6.9992c0-.4-.667-7.1667-1-10.5 0-5.6667-6.5-15-20-16.5-16 .4-20.6667 11.1667-21 16.5v10l.5 7.4992c-1.3333-2.8333-4-1.4992-4 2.5 0 8.8 4.3333 11.6667 6.5 12 .6667 1.8334 2.1 6 2.5 8s-.1667 8.1667-.5 11l-4 7.5c-8.8333 3.3334-27.9 10.4998-33.5 12.4998s-9.6667 8.167-11 11z"
        fill="url(#a)"
        fillOpacity=".1"
        transform="scale(1.83)"
      />

      <rect
        width={width}
        height={height}
        fill={card.rarityTyped ? colors[card.rarityTyped] : '#fff'}
        mask="url(#strokeMask)"
      />

      <text x="95%" y="5%" textAnchor="end">
        {card.anyTeam.name}
      </text>
      <text x="5%" y="5%" textAnchor="start">
        {card.seasonYear}
      </text>
      <text x="50%" y="80%" textAnchor="middle" fontSize={20} fontWeight="bold">
        {card.anyPlayer.displayName.toLocaleUpperCase()}
      </text>
      <text x="50%" y="85%" textAnchor="middle">
        {formatPositions(card.anyPositions)}
      </text>
      {card.rarityTyped && (
        <text x="95%" y="95%" textAnchor="end">
          {getHumanReadableSerialNumber(card as CardMeta_AnyCardInterface)}
        </text>
      )}

      <text x="50%" y="50%" textAnchor="middle" fontSize={20}>
        <FormattedMessage
          id="cardMeta.loading"
          defaultMessage="Loading card…"
        />
      </text>
    </svg>
  );
};
CardMeta.fragments = {
  AnyCardInterface: gql`
    fragment CardMeta_AnyCardInterface on AnyCardInterface {
      slug
      anyPositions
      anyPlayer {
        slug
        displayName
      }
      seasonYear
      anyTeam {
        slug
        name
      }
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<CardMeta_AnyCardInterface>,
};
