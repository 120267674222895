import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title4 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';

import {
  USSportsRequestLeaderboardsNoCardLineupQuery,
  USSportsRequestLeaderboardsNoCardLineupQueryVariables,
} from './__generated__/index.graphql';

const US_SPORTS_REQUEST_LEADERBOARDS_NO_CARD_LINEUP_QUERY = gql`
  query USSportsRequestLeaderboardsNoCardLineupQuery(
    $userID: UUID!
    $id: UUID!
  ) {
    requestLeaderboardsNoCardLineup(userID: $userID, id: $id) {
      id
      name
    }
  }
` as TypedDocumentNode<
  USSportsRequestLeaderboardsNoCardLineupQuery,
  USSportsRequestLeaderboardsNoCardLineupQueryVariables
>;

const Wrapper = styled(Vertical).attrs({ gap: 4, center: true })`
  padding: var(--quadruple-unit);

  font: var(--t-14);
  text-align: center;
  justify-content: stretch;
`;

export const NoCardEntryEligibleLeaderboards = () => {
  const [params] = useSearchParams();
  const { currentUser } = useCurrentUserContext();

  const userID = idFromObject(currentUser?.id);
  const id = params.get('id');
  const invalid = !userID || !id;

  const { data } = useQuery(
    US_SPORTS_REQUEST_LEADERBOARDS_NO_CARD_LINEUP_QUERY,
    {
      variables: { userID: userID!, id: id! },
      skip: invalid,
    }
  );

  const leaderboards = data?.requestLeaderboardsNoCardLineup || [];
  return (
    <Wrapper>
      {leaderboards.length > 0 ? (
        <Title4>
          <FormattedMessage
            id="NoCardEntryEligibleLeaderboards.title"
            defaultMessage="Other competitions will be proposed to you by email."
          />
        </Title4>
      ) : (
        <LoadingIndicator />
      )}
    </Wrapper>
  );
};
