import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { LabelM } from '@sorare/core/src/atoms/typography';

import { FootballTeam_card } from './__generated__/index.graphql';

type Props = {
  card: FootballTeam_card;
};

const FootballTeam = ({ card }: Props) => {
  const { anyTeam: team, anyPlayer: player } = card;
  const { slug, name } = team;

  const activeClubSlug = player?.activeClub?.slug;
  const activeClubName = player?.activeClub?.name;

  return (
    <>
      <LabelM color="var(--c-neutral-600)">{name}</LabelM>
      {activeClubSlug !== slug && (
        <LabelM color="var(--c-neutral-600)">
          <FormattedMessage
            id="FootballTeam.currentClub"
            defaultMessage="Current club: {activeClubName}"
            values={{ activeClubName }}
          />
        </LabelM>
      )}
    </>
  );
};

FootballTeam.fragments = {
  card: gql`
    fragment FootballTeam_card on Card {
      slug
      anyTeam {
        slug
        name
      }
      anyPlayer {
        slug
        activeClub {
          slug
          name
        }
      }
    }
  ` as TypedDocumentNode<FootballTeam_card>,
};

export default FootballTeam;
