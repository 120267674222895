import { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { LabelM, LabelS } from 'atoms/typography';

import { Rank } from '../Rank';

type Props = {
  rank?: number | null;
  total: number;
};

const Root = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2px;
`;

const Span: FC<React.PropsWithChildren<unknown>> = props => <span {...props} />;

export const FormattedRank = ({ rank, total }: Props) => {
  return (
    <Root>
      <LabelM bold as="span">
        {typeof rank !== 'number' ? '-' : <Rank rank={rank} Sup={Span} />}
      </LabelM>
      <LabelS color="var(--c-neutral-600)" as="span">
        /
      </LabelS>
      <LabelS color="var(--c-neutral-600)" as="span">
        <FormattedNumber value={total} />
      </LabelS>
    </Root>
  );
};
