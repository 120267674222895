import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, useMemo } from 'react';

import DumbStatsIcon from '@sorare/core/src/components/draft/StatsIcon';
import { isType } from '@sorare/core/src/lib/gql';
import { findThresholdColor } from '@sorare/core/src/lib/thresholds';

import { AnyPlayerStatsIcon_anyPlayer } from './__generated__/index.graphql';

type Props = {
  outlined?: boolean;
  player: AnyPlayerStatsIcon_anyPlayer;
  onClick?: ComponentProps<typeof DumbStatsIcon>['onClick'];
};

export const AnyPlayerStatsIcon = ({ player, onClick, outlined }: Props) => {
  const stats = useMemo(() => {
    return [...player.rawPlayerGameScores].reverse().map(so5Score => ({
      score: so5Score || 0,
      color: findThresholdColor(so5Score || 0, player.sport),
    }));
  }, [player]);

  const multiplier = isType(player, 'BaseballPlayer') ? 3 : 1;

  return (
    <DumbStatsIcon
      stats={stats}
      onClick={onClick}
      outlined={outlined}
      multiplier={multiplier}
    />
  );
};

AnyPlayerStatsIcon.fragments = {
  anyPlayer: gql`
    fragment AnyPlayerStatsIcon_anyPlayer on AnyPlayerInterface {
      slug
      rawPlayerGameScores(last: 5)
      sport
    }
  ` as TypedDocumentNode<AnyPlayerStatsIcon_anyPlayer>,
};
