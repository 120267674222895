import { ReactNode } from 'react';
import styled from 'styled-components';

import { desktopAndAbove } from 'style/mediaQuery';

import img from './assets/stadium.jpg';

const Root = styled.section`
  @media ${desktopAndAbove} {
    isolation: isolate;
    &::before {
      content: '';
      position: fixed;
      inset: 0;
      z-index: -1;
      background-image: url(${img});
      background-size: cover;
    }
  }
`;

interface Props {
  children: ReactNode;
}
export const FootballStadium = ({ children }: Props) => {
  return <Root>{children}</Root>;
};
