import { SVGProps, useId } from 'react';

type Props = { className?: string } & Pick<SVGProps<SVGPathElement>, 'fill'>;

export const Shape = ({ className, fill }: Props) => {
  const id = useId();
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.015 6.848A4 4 0 0 0 0 10.321V21.68a4 4 0 0 0 2.015 3.473l10 5.714a4 4 0 0 0 3.97 0l10-5.714A4 4 0 0 0 28 21.679V10.32a4 4 0 0 0-2.015-3.473l-10-5.714a4 4 0 0 0-3.97 0l-10 5.714Z"
        fill={fill || `url(#${id})`}
      />
      {!fill && (
        <defs>
          <linearGradient
            id={id}
            x1="23.8995"
            y1="27.3137"
            x2="1.47232"
            y2="7.68993"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5974FF" />
            <stop offset="1" stopColor="#2943CC" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
