export const DISCORD_INVITATION_URL = 'https://discord.gg/so5mlfooter';
export const TWITTER_URL = 'https://twitter.com/sorare';
export const SUPPORT_TWITTER_URL = 'https://twitter.com/SorareSupport';
export const INSTAGRAM_URL = 'https://www.instagram.com/sorare';
export const YOUTUBE_URL = 'https://www.youtube.com/@Sorare';
export const TIKTOK_URL = 'https://www.tiktok.com/@sorare';
export const REDDIT_URL = 'https://www.reddit.com/r/Sorare';
export const FACEBOOK_URL = 'https://www.facebook.com/SorareHQ/';

export const MLB_INSTAGRAM_URL = 'https://www.instagram.com/soraremlb';
export const MLB_TWITTER_URL = 'https://twitter.com/SorareMLB';
export const MLB_DISCORD_INVITATION_URL = 'https://discord.gg/99BNrkTXZG';
export const MLB_FACEBOOK_URL = 'https://www.facebook.com/SorareMLB/';

export const NBA_INSTAGRAM_URL = 'https://www.instagram.com/sorarenba';
export const NBA_TWITTER_URL = 'https://twitter.com/SorareNBA';
export const NBA_FACEBOOK_URL = 'https://facebook.com/SorareNBA';
export const NBA_DISCORD_INVITATION_URL = 'https://discord.gg/STdWv5Dr79';

export const ONELINK_FOOTBALL_INVITE_REDIRECTION_BASE =
  'https://sorare.onelink.me/4vwF/3kvepmyu';
export const ONELINK_FOOTBALL_RIVALS =
  'https://sorare.onelink.me/4vwF/8687s5am';
export const ONELINK_FOOTBALL_RIVALS_LEAGUE_JOIN =
  'https://sorare.onelink.me/4vwF/aao60aqh';

// this is the AppsFlyer link either opening the app or redirecting to the app store, allowing us to forward
// some query parameters to the native apps
export const STORE_URL = 'https://sorare.onelink.me/Rk91/3bxn978p';
