import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Title4 } from '@sorare/core/src/atoms/typography';

const GroupContent = styled(Vertical).attrs({ gap: 0, center: true })`
  padding: var(--triple-unit);
  border-radius: var(--triple-unit);
  border: 2px solid var(--c-neutral-300);
  margin: auto;
  flex: 1;
  max-width: min(80%, calc(40 * var(--unit))); /* 320px */
  justify-content: stretch;
`;

const IconWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  color: var(--c-brand-600);
  margin-right: var(--unit);
  background-color: rgba(var(--c-rgb-brand-600), 0.25);
  width: var(--triple-unit);
  height: var(--triple-unit);
  border-radius: var(--unit);
`;

const MembersCount = styled(LabelM).attrs({ as: 'div' })`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--unit);
  color: var(--c-neutral-600);
`;

type Props = {
  displayName: string;
  membersCount: number;
};

export const LeaguePreview = ({ displayName, membersCount }: Props) => {
  return (
    <GroupContent>
      <Title4>{displayName}</Title4>

      <MembersCount>
        <IconWrapper>
          <FontAwesomeIcon icon={faUserFriends} fontSize={11} />
        </IconWrapper>
        {membersCount}
      </MembersCount>
    </GroupContent>
  );
};
