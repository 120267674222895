import { IconDefinition } from 'atoms/icons';

export const turnSVGintoImageURL = (svg: string) => {
  return `data:image/svg+xml;base64,${window.btoa(
    // eslint-disable-next-line deprecation/deprecation
    unescape(encodeURIComponent(svg))
  )}`;
};

export const turnIconIntoBackground = (icon: IconDefinition) => {
  const [
    width, // width
    height, // height // ligatures // unicode
    ,
    ,
    IconPathData, // svgPathData
  ] = icon.icon;
  return turnSVGintoImageURL(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" fill="white"><path d="${IconPathData}" /></svg>`
  );
};
