import { lightFormat } from 'date-fns';
import { resolvePath, useLocation, useParams } from 'react-router-dom';

import { catchAll } from 'constants/routes';
import { Navigate } from 'routing/Navigate';

import { getRoutesFromFiles } from './routing/autoroute';

export { catchAll, getRoutesFromFiles };

export function relative(path: string, childrenPath: string) {
  return childrenPath.replace(path, '').replace(/^\//, '');
}

export function hasPreviousLocation(delta?: number) {
  const backDelta = delta || -1;
  return window.history.state?.idx > Math.abs(backDelta) - 1;
}

export const formatDateForUrl = (date: Date) => {
  return lightFormat(date, 'yyyy-MM-dd');
};

export const useTypedParams = <T extends Record<string, string>>() => {
  return useParams() as T;
};

export const NotFound = () => {
  const location = useLocation();

  return (
    <Navigate
      to={resolvePath('..', location.pathname).pathname}
      keepSearchParams
    />
  );
};
