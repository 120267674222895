import styled from 'styled-components';

import { Container } from 'atoms/layout/Container';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Text16 } from 'atoms/typography';
import { SocialLinks } from 'atoms/ui/SocialMedias';
import LanguageSwitcher from 'components/user/LanguageSwitcher';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { breakpoints, tabletAndAbove } from 'style/mediaQuery';

import FooterLinks from './FooterLinks';
import { copyright } from './copyright';
import socialLinks from './socialLinks';

const Footer = styled.footer`
  isolation: isolate;
  background: var(--c-neutral-100);
  color: var(--c-neutral-1000);
`;
const CopyAndLanguage = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--double-unit);
  padding: var(--double-unit) 0;
  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const CopyContainer = styled(Text16)`
  @media ${tabletAndAbove} {
    text-align: right;
  }
`;

type Props = {
  wide?: boolean;
};
const MultiSportFooter = ({ wide }: Props) => {
  const { isMobileApp } = useIsMobileApp();

  if (isMobileApp) {
    return null;
  }

  const Wrapper = wide ? FullWidthContainer : Container;

  return (
    <Footer>
      <Wrapper maxWidth={breakpoints.mediumDesktop}>
        <SocialLinks socialLinks={socialLinks} />
        <FooterLinks />
        <CopyAndLanguage>
          <LanguageSwitcher />
          <CopyContainer>{copyright}</CopyContainer>
        </CopyAndLanguage>
      </Wrapper>
    </Footer>
  );
};

export default MultiSportFooter;
