import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode, Suspense, lazy, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { CatchErrors } from 'atoms/CatchErrors';
import Button from 'atoms/buttons/Button';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import StarBall from 'atoms/icons/StarBall';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineL } from 'atoms/typography';
import { Card3DWithFallback } from 'components/3d/Card3DWithFallback';
import { UninteractiveBundledCardsPreview } from 'components/bundled/UninteractiveBundledCardsPreview';
import { isType } from 'gql';
import { scarcityNames } from 'lib/cards';
import { shardsIconUrl } from 'lib/rewards';
import { tabletAndAbove } from 'style/mediaQuery';

import { Background } from './Background';
import {
  BuyConfirmation_anyCard,
  BuyConfirmation_anyReward,
} from './__generated__/index.graphql';
import { actionsAnimation, cardsAnimation, textAnimation } from './ui';

const BuyConfirmationEssence = lazy(
  async () => import('@sorare/trois/src/components/BuyConfirmationEssence')
);
const Root = styled(Vertical)`
  position: relative;
  height: 100%;
  text-align: center;
  justify-content: flex-start;
  color: var(--c-neutral-1000);
  margin-inline: auto;
  @media ${tabletAndAbove} {
    justify-content: center;
  }
`;
const LogoCtn = styled(Horizontal)`
  ${textAnimation};
`;

const Title = styled(HeadlineL)`
  ${textAnimation};
  width: 100%;
  text-transform: uppercase;
  font-size: 36px;
  text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.58);
  line-height: 0.9;
  .showRewards & {
    animation: none;
    transition: transform 0.5s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const Subtitle = styled(BodyM)`
  width: 100%;
  ${textAnimation}
  .showRewards & {
    animation: none;
    transition: transform 0.6s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const TokenImg = styled.div`
  position: relative;
  width: 100%;
  max-width: 28vh;
  align-self: center;
  ${cardsAnimation}
  @media ${tabletAndAbove} {
    min-width: 50%;
    transition: all 0.5s;
  }
  .isBundle & {
    @media ${tabletAndAbove} {
      min-width: 0;
    }
  }
`;

const SlideShow = styled(Horizontal)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: stretch;
`;

const CardSlideShow = styled(Horizontal)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-block: 24px;
  @media ${tabletAndAbove} {
    .isBundle & {
      height: 415px;
    }
  }
`;

const MainColumn = styled(Vertical)`
  width: 50%;
  .showRewards.isBundle & {
    width: 0;
    opacity: 0;
    transition: all 0.3s;
    overflow: hidden;
  }
`;

const Column = styled(Vertical)`
  width: 50%;
  .isBundle & {
    width: calc(30% - var(--unit));
  }
`;

const Grid = styled(Horizontal)`
  width: 0;
  .showRewards & {
    width: 100%;
  }
  flex-wrap: wrap;
  justify-content: center;
`;
const NoShrinkColumn = styled(Vertical)`
  width: 100%;
  flex-shrink: 0;
  transition: all 0.5s;
`;

const BundleWrapper = styled.div`
  position: relative;
  padding-block: var(--triple-unit);
  align-self: center;
  ${cardsAnimation}
`;
const Reward = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  transition: all 0.5s;
  align-self: stretch;
  .showRewards & {
    width: 50%;
  }
  .showRewards.isBundle & {
    width: calc(33% - var(--unit));
  }
`;
const Actions = styled(Vertical)`
  flex-grow: 1;
  max-width: 340px;
  width: 100%;
  gap: var(--double-unit);
  justify-content: center;
  ${actionsAnimation};
`;
const ActionsCtn = styled(Vertical)`
  height: 88px;
  width: 100%;
  margin-inline: auto;
  justify-content: center;
  align-items: center;
`;

export type Props = {
  cards: BuyConfirmation_anyCard[];
  rewards?: Nullable<BuyConfirmation_anyReward[]>;
  actions?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
};

const MAP = {
  [Rarity.common]: 'limited',
  [Rarity.custom_series]: 'limited',
  [Rarity.limited]: 'limited',
  [Rarity.rare]: 'rare',
  [Rarity.super_rare]: 'super_rare',
  [Rarity.unique]: 'unique',
} as const;

export const BuyConfirmation = ({
  actions,
  cards,
  rewards,
  title,
  subtitle,
}: Props) => {
  const hasRewards = (rewards?.length || 0) > 0;
  const [showRewards, setShowRewards] = useState(false);
  const isBundle = cards.length > 1;
  const card = cards[0];
  const reward = rewards?.[0];
  return (
    <>
      <Background rarity={card.rarityTyped} />
      <Root gap={1.5} center className={classNames({ isBundle, showRewards })}>
        <LogoCtn center>
          <StarBall width={16} />
          <SorareLogo />
        </LogoCtn>
        <SlideShow gap={0}>
          <NoShrinkColumn center>
            <Title brand>
              {title || (
                <FormattedMessage
                  id="buyTokenConfirmation.buy.title"
                  defaultMessage="New{br}signing"
                  values={{ br: <br /> }}
                />
              )}
            </Title>
            <Subtitle bold>
              {subtitle || (
                <FormattedMessage
                  id="buyTokenConfirmation.buy.subtitle"
                  defaultMessage="Welcome {playerDisplayName} to your team!"
                  values={{ playerDisplayName: card.anyPlayer.displayName }}
                />
              )}
            </Subtitle>
          </NoShrinkColumn>
          {hasRewards && (
            <NoShrinkColumn center>
              <Title brand>
                <FormattedMessage
                  id="buyTokenConfirmation.rewards.title"
                  defaultMessage="There's{br}More"
                  values={{ br: <br /> }}
                />
              </Title>
              <Subtitle bold>
                {!isBundle && isType(reward, 'AnyCardReward') && (
                  <FormattedMessage
                    id="buyTokenConfirmation.buy.cardReward.subtitle"
                    defaultMessage="2 for 1, {playerDisplayName} is also joining your team!"
                    values={{
                      playerDisplayName: reward.card.anyPlayer.displayName,
                    }}
                  />
                )}
                {!isBundle && isType(reward, 'CardShardsReward') && (
                  <FormattedMessage
                    id="buyTokenConfirmation.buy.cardShardReward.subtitle"
                    defaultMessage="You also received {quantity} {rarity} card essence!"
                    values={{
                      quantity: reward.quantity,
                      rarity: scarcityNames[reward.rarity],
                    }}
                  />
                )}
              </Subtitle>
            </NoShrinkColumn>
          )}
        </SlideShow>
        <CardSlideShow gap={0}>
          <MainColumn center>
            {isBundle ? (
              <BundleWrapper>
                <UninteractiveBundledCardsPreview cards={cards} size="md" />
              </BundleWrapper>
            ) : (
              <TokenImg>
                <Card3DWithFallback card={card} disableFallback />
              </TokenImg>
            )}
          </MainColumn>
          <ConditionalWrapper wrap={isBundle} Wrapper={Grid}>
            {showRewards &&
              rewards?.map(r => {
                if (isType(r, 'CardShardsReward'))
                  return (
                    <Reward key={r.id}>
                      <Suspense>
                        <CatchErrors
                          fallback={
                            <img
                              src={shardsIconUrl(r.rarity)}
                              alt=""
                              style={{
                                maxWidth: '100px',
                              }}
                            />
                          }
                        >
                          <BuyConfirmationEssence
                            rarity={MAP[r.rarity]}
                            quantity={r.quantity}
                          />
                        </CatchErrors>
                      </Suspense>
                    </Reward>
                  );
                if (isType(r, 'AnyCardReward'))
                  return (
                    <Column key={r.id}>
                      <TokenImg>
                        <Card3DWithFallback
                          card={r.card}
                          disableFallback
                          startOnBack
                        />
                      </TokenImg>
                    </Column>
                  );
                return null;
              })}
          </ConditionalWrapper>
        </CardSlideShow>

        <ActionsCtn>
          {hasRewards && !showRewards ? (
            <Actions key="1">
              <Button
                size="medium"
                color="secondary"
                onClick={() => setShowRewards(!showRewards)}
              >
                <FormattedMessage
                  id="buyTokenConfirmation.buy.showRewards"
                  defaultMessage="And..."
                />
              </Button>
            </Actions>
          ) : (
            actions && <Actions key="2">{actions}</Actions>
          )}
        </ActionsCtn>
      </Root>
    </>
  );
};

const anyCardFragment = gql`
  fragment BuyConfirmation_anyCard on AnyCardInterface {
    slug
    sport
    anyPlayer {
      slug
      displayName
    }
    ...Card3DWithFallback_anyCardInterface
    ...UninteractiveBundledCardsPreview_anyCard
  }
  ${Card3DWithFallback.fragments.anyCardInterface}
  ${UninteractiveBundledCardsPreview.fragments.anyCard}
` as TypedDocumentNode<BuyConfirmation_anyCard>;

BuyConfirmation.fragments = {
  anyCard: anyCardFragment,
  anyReward: gql`
    fragment BuyConfirmation_anyReward on AnyRewardInterface {
      id
      ... on AnyCardReward {
        id
        card {
          slug
          anyPlayer {
            slug
            displayName
          }
          ...Card3DWithFallback_anyCardInterface
        }
      }
      ... on CardShardsReward {
        id
        quantity
        rarity
      }
    }
    ${Card3DWithFallback.fragments.anyCardInterface}
  ` as TypedDocumentNode<BuyConfirmation_anyReward>,
};

export default BuyConfirmation;
