import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, Outlet, generatePath } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  FOOTBALL_PLAY_RIVALS_ARENA,
  FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import {
  GetCurrentSeasonSlugQuery,
  GetCurrentSeasonSlugQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const GET_CURRENT_SEASON_SLUG = gql`
  query GetCurrentSeasonSlugQuery {
    football {
      rivals {
        id
        currentSeason {
          slug
        }
      }
    }
  }
` as TypedDocumentNode<
  GetCurrentSeasonSlugQuery,
  GetCurrentSeasonSlugQueryVariables
>;

const SquadLeaderboardLayout = () => {
  const { seasonSlug } = useTypedParams<LayoutParams>();
  const {
    flags: { enableRivalsSquads = false },
  } = useFeatureFlags();

  const { data, loading } = useQuery(GET_CURRENT_SEASON_SLUG, {
    skip: !!seasonSlug,
  });

  if (!enableRivalsSquads) {
    return <Navigate to={FOOTBALL_PLAY_RIVALS_ARENA} replace />;
  }

  if (loading) {
    return <LoadingIndicator fullHeight />;
  }

  if (!seasonSlug) {
    return (
      <Navigate
        to={generatePath(FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG, {
          seasonSlug: data?.football.rivals.currentSeason?.slug || '',
        })}
      />
    );
  }

  return <Outlet />;
};

export default SquadLeaderboardLayout;
