import { Color } from 'style/types';

type Props = { className?: string; fill: Color };
export const RivalsLightning = ({ className, fill }: Props) => {
  return (
    <svg
      width="9"
      viewBox="0 0 6 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipRule="evenodd" fill={fill} fillRule="evenodd">
        <path d="m4.28993.344967-.34125-.000607-2.72637 3.91455 1.58035-.00819-1.09298 3.40431.34158.00061 2.72638-3.91455-1.58069.00819z" />
        <path d="m3.76925 0 .99219.0017634-1.09191 3.4010166 1.76937-.00916-3.20822 4.60638-.99253-.00176 1.09192-3.40102-1.769035.00916zm-.96659 4.25072-1.09298 3.40431.34158.00061 2.72638-3.91455-1.58069.00819 1.09298-3.404313-.34125-.000607-2.72637 3.91455z" />
      </g>
    </svg>
  );
};
