import { ApolloProvider } from '@apollo/client/react';
import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from '@sorare/core/src/atoms/layout/Container';
import Gap from '@sorare/core/src/components/marketing/Gap';
import MarketingPage from '@sorare/core/src/components/marketing/MarketingPage';
import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import { NoIndex } from '@sorare/core/src/contexts/seo';
import { useBasenameLocale } from '@sorare/core/src/hooks/useBasenameLocale';
import { EnsureTopVisibleOnMount } from '@sorare/core/src/routing/EnsureTopVisibleOnMount';

import { BLOG_POST_PREVIEW } from 'constants/routes';
import client from 'lib/graphql';

const BlogContainer = styled.div`
  margin: 0 auto;
  color: var(--c-static-neutral-100);
  width: 100%;
  max-width: 1200px;

  & hr {
    margin: 2em 0 0 0;
  }

  & a {
    font-weight: 500;
    color: var(--c-static-neutral-100);
    text-decoration: underline;
  }

  & em {
    font-weight: bold;
    font-style: italic;
    font-family: Romie-Italic, serif;
  }
`;

const DraftRibbon = styled.div`
  position: fixed;
  top: 20px;
  left: -20px;
  white-space: nowrap;
  transform: rotate(-35deg);
  z-index: 2;
  background-color: var(--c-red-600);
  color: var(--c-static-neutral-100);
  font-weight: bold;
  text-align: center;
  padding: 3px 50px;
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const { basename } = useBasenameLocale();
  const preview = matchPath(BLOG_POST_PREVIEW, pathname);

  return (
    <>
      <Helmet>
        <meta name="twitter:widgets:theme" content="dark" />
        <meta name="twitter:dnt" content="on" />
        <link
          rel="stylesheet"
          href="https://soraremedium.wpcomstaging.com/wp-content/plugins/gutenberg/build/block-library/style.css"
        />
      </Helmet>
      {basename && <NoIndex />}
      {preview && <DraftRibbon>DRAFT</DraftRibbon>}
      <AppLayout>
        <MarketingPage>
          <Container>
            <Gap size="md" />
            <BlogContainer>
              <ApolloProvider client={client}>
                <EnsureTopVisibleOnMount behavior="auto">
                  {children}
                </EnsureTopVisibleOnMount>
              </ApolloProvider>
            </BlogContainer>
            <Gap size="md" />
          </Container>
        </MarketingPage>
      </AppLayout>
    </>
  );
};

export default Layout;
