import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';

import PressContent from 'Press';

export const Press = () => {
  return (
    <AppLayout>
      <PressContent />
    </AppLayout>
  );
};

export default Press;
