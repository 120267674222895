import { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

import { materialUIOpacity } from 'style/theme';

const Wrapper = styled.div`
  border: 1px solid var(--c-neutral-300);
  border-radius: 24px;
  display: inline-flex;
  gap: var(--unit);
  align-items: center;
  position: relative;
  padding: var(--unit) var(--double-unit);
  color: var(--c-neutral-1000);
  background: var(--c-neutral-300);
  width: 100%;
  height: 40px;
  &:focus-within {
    & p {
      opacity: 1;
    }
  }
`;
const Input = styled.input`
  resize: none;
  width: 100%;
  font: inherit;
  color: currentColor;
  border: none;
  letter-spacing: inherit;
  background: var(--c-neutral-300);
  height: inherit;
  &::placeholder {
    color: currentColor;
    opacity: ${materialUIOpacity};
  }
`;

type Props = {
  className?: string;
  id?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

export const TextInput = ({
  className,
  id,
  startAdornment,
  endAdornment,
  inputRef,
  ...rest
}: Props) => {
  return (
    <Wrapper className={className}>
      {startAdornment}
      <Input id={id} {...rest} ref={inputRef} />
      {endAdornment}
    </Wrapper>
  );
};
