import { ReactNode, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import {
  FOOTBALL_CLUB_SHOW_FIXTURES,
  FOOTBALL_LEAGUE_SHOW_FIXTURES,
  FOOTBALL_PLAYER_SHOW_FIXTURES,
} from 'constants/routes';

const IGNORED_ROUTES = [
  FOOTBALL_PLAYER_SHOW_FIXTURES,
  FOOTBALL_CLUB_SHOW_FIXTURES,
  FOOTBALL_LEAGUE_SHOW_FIXTURES,
];

export const EnsureTopVisibleOnMount = ({
  children,
  behavior = 'smooth',
}: {
  children: ReactNode;
  behavior?: ScrollBehavior;
}) => {
  const location = useLocation();

  useEffect(() => {
    if (!IGNORED_ROUTES.some(route => matchPath(route, location.pathname))) {
      window.scrollTo({ top: 0, behavior });
    }
  }, [behavior, location.pathname]);

  return <div>{children}</div>;
};
