import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { resetSo5NoCardDraftedAppearancesInput } from '@sorare/core/src/__generated__/globalTypes';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  ResetNoCardDraftedAppearancesMutation,
  ResetNoCardDraftedAppearancesMutationVariables,
} from './__generated__/useResetNoCardDraftedAppearances.graphql';

export const RESET_DRAFTED_APPEARANCES_MUTATION = gql`
  mutation ResetNoCardDraftedAppearancesMutation(
    $input: resetSo5NoCardDraftedAppearancesInput!
  ) {
    resetSo5NoCardDraftedAppearances(input: $input) {
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  ResetNoCardDraftedAppearancesMutation,
  ResetNoCardDraftedAppearancesMutationVariables
>;

const useResetNoCardDraftedAppearance = () => {
  const [mutate] = useMutation(RESET_DRAFTED_APPEARANCES_MUTATION, {
    showErrorsWithSnackNotification: true,
  });

  return useCallback(
    async (input: resetSo5NoCardDraftedAppearancesInput) =>
      mutate({ variables: { input } }),
    [mutate]
  );
};

export default useResetNoCardDraftedAppearance;
