import { TypedDocumentNode, gql } from '@apollo/client';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  PostalAddressInput,
  WearableSize,
} from '@sorare/core/src/__generated__/globalTypes';
import Button from '@sorare/core/src/atoms/buttons/Button';
import LoadingButton from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import Select from '@sorare/core/src/atoms/inputs/Select';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import {
  BodyM,
  HeadlineM,
  HeadlineXS,
} from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { ShouldVerifyUserBeforeClaiming } from '@sorare/core/src/components/rewards/ShouldVerifyUserBeforeClaiming';
import useUpdateUserPostalAddress from '@sorare/core/src/components/settings/PostalAddress/useUpdateUserPostalAddress';
import PostalAddressForm, {
  Attributes,
} from '@sorare/core/src/components/settings/PostalAddressForm';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { glossary } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { AddressPreview } from 'components/rewards/deliverableItem/DeliverableItemFooter/AdressPreview';
import {
  BorderedBox,
  Column,
  Content,
  Footer,
  TwoColumn,
  Wrapper,
} from 'components/rewards/deliverableItem/DeliverableItemFooter/ui';
import DeliverableItemPreview from 'components/rewards/deliverableItem/DeliverableItemPreview';
import DeliveryConfirmed from 'components/rewards/deliverableItem/DeliveredConfirmed';

import { DeliverableItemOrderFormDialog_deliverableItemOrderFragment } from './__generated__/index.graphql';
import { useClaimDeliverableItemOrder } from './useClaimDeliverableItemOrder';

const PreviewContainer = styled(Vertical).attrs({ gap: 2 })`
  align-items: center;
  @media (${laptopAndAbove}) {
    align-items: flex-start;
  }
`;

export const deliverableItemOrderFragment = gql`
  fragment DeliverableItemOrderFormDialog_deliverableItemOrderFragment on DeliverableItemOrder {
    id
    deliverableItem {
      slug
      ... on DeliverableItemInterface {
        slug
        id
        mandatoryWearableSizeInput
      }
      ...DeliverableItemPreview_deliverableItem
      ...DeliveryConfirmed_DeliverableItem
    }
  }
  ${DeliveryConfirmed.fragments.deliverableItem}
  ${DeliverableItemPreview.fragments.deliverableItem}
` as TypedDocumentNode<DeliverableItemOrderFormDialog_deliverableItemOrderFragment>;

const orderedShirtSize = [
  WearableSize.XS,
  WearableSize.S,
  WearableSize.M,
  WearableSize.L,
  WearableSize.XL,
  WearableSize.XXL,
] as const;

type Props = {
  deliverableItemOrder: DeliverableItemOrderFormDialog_deliverableItemOrderFragment;
  open: boolean;
  onCancel: () => void;
  onDone: () => void;
  hideSignedVariant?: boolean;
};
const DeliverableItemOrderFormDialog = ({
  deliverableItemOrder,
  open,
  onCancel,
  onDone,
  hideSignedVariant,
}: Props) => {
  const { claim, loading } = useClaimDeliverableItemOrder();
  const updateUserPostalAddress = useUpdateUserPostalAddress();
  const { currentUser } = useCurrentUserContext();
  const defaultValues = currentUser!.userSettings.postalAddress;

  const [errors, setErrors] = useState<string[]>([]);
  const [address, setAddress] = useState<Attributes>(() => {
    const { __typename, ...rest } = defaultValues;
    return rest;
  });
  const [isEditable, setIsEditable] = useState(!defaultValues.firstName);
  const [selectedSize, setSelectedSize] = useState<
    WearableSize | undefined | null
  >(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { deliverableItem } = deliverableItemOrder;

  const validAddress =
    !!(
      address.city &&
      address.firstName &&
      address.lastName &&
      address.zipcode &&
      address.streetAddress &&
      address.countryCode
    ) && (address as PostalAddressInput);

  const onSubmit = async () => {
    setErrors([]);
    if (validAddress) {
      const result = await claim({
        variables: {
          input: {
            deliverableItemOrderId: deliverableItemOrder.id,
            postalAddress: {
              city: address.city!,
              countryCode: address.countryCode!,
              firstName: address.firstName!,
              lastName: address.lastName!,
              streetAddress: address.streetAddress!,
              zipcode: address.zipcode!,
            },
            userSelection: {
              ...(deliverableItem.mandatoryWearableSizeInput
                ? { size: selectedSize }
                : {}),
            },
          },
        },
      });
      if (result.errors.length > 0) {
        setErrors(result.errors.map(error => error.message));
        return;
      }
      if (!defaultValues.firstName) updateUserPostalAddress(validAddress);
      setShowConfirmationDialog(true);
    }
  };

  const formCompleted =
    (!deliverableItem.mandatoryWearableSizeInput || selectedSize) &&
    !!validAddress;

  return (
    <>
      <Dialog open={open} onBack={onCancel} fullWidth maxWidth="md">
        <Wrapper>
          <Content>
            <TwoColumn>
              <Column gap={2}>
                <HeadlineM as="h2">
                  <FormattedMessage
                    id="DeliverableItemForm.title"
                    defaultMessage="Reward details"
                  />
                </HeadlineM>
                <PreviewContainer>
                  <DeliverableItemPreview
                    deliverableItem={deliverableItem}
                    hideSignedVariant={hideSignedVariant}
                  />
                  {deliverableItem.mandatoryWearableSizeInput && (
                    <Vertical>
                      <HeadlineXS as="h4">
                        <FormattedMessage
                          id="DeliverableItemForm.selectSizeLabel"
                          defaultMessage="Preferred size:"
                        />
                      </HeadlineXS>
                      <Select
                        menuPlacement="top"
                        menuPortalTarget={document.body}
                        placeholder={
                          <FormattedMessage
                            id="DeliverableItemForm.selectSize"
                            defaultMessage="Select preferred size"
                          />
                        }
                        value={
                          selectedSize && {
                            label: selectedSize,
                            value: selectedSize,
                          }
                        }
                        options={orderedShirtSize.map(s => ({
                          label: s,
                          value: s,
                        }))}
                        onChange={option => {
                          setSelectedSize(option?.value);
                        }}
                      />
                    </Vertical>
                  )}
                </PreviewContainer>
              </Column>
              <Column gap={2}>
                <SBHorizontal>
                  <HeadlineM as="h2">
                    <FormattedMessage
                      id="DeliverableItemForm.Address"
                      defaultMessage="Shipping Address"
                    />
                  </HeadlineM>
                  {!isEditable && (
                    <Button
                      size="small"
                      color="tertiary"
                      onClick={() => {
                        setIsEditable(true);
                      }}
                    >
                      <Horizontal as="span">
                        <FontAwesomeIcon size="xs" icon={faPen} />
                        <FormattedMessage {...glossary.edit} />
                      </Horizontal>
                    </Button>
                  )}
                </SBHorizontal>
                {isEditable ? (
                  <PostalAddressForm
                    onChange={setAddress}
                    onSubmit={() => {}}
                    onSuccess={() => {}}
                    button={() => null}
                  />
                ) : (
                  <BorderedBox>
                    <AddressPreview address={address} />
                  </BorderedBox>
                )}
              </Column>
            </TwoColumn>
          </Content>
          <Footer>
            {errors.map(error => (
              <BodyM as="p" key={error}>
                {error}
              </BodyM>
            ))}
            <Horizontal center>
              <Button size="medium" color="tertiary" onClick={onCancel}>
                <FormattedMessage {...glossary.cancel} />
              </Button>
              <ShouldVerifyUserBeforeClaiming hasBlockchainRewards>
                {() => (
                  <LoadingButton
                    loading={loading}
                    disabled={!formCompleted}
                    onClick={onSubmit}
                    size="medium"
                    color="primary"
                  >
                    <FormattedMessage {...glossary.next} />
                  </LoadingButton>
                )}
              </ShouldVerifyUserBeforeClaiming>
            </Horizontal>
          </Footer>
        </Wrapper>
      </Dialog>
      {showConfirmationDialog && (
        <DeliveryConfirmed
          deliverableItem={deliverableItem}
          address={address}
          selectedSize={selectedSize}
          hideSignedVariant={hideSignedVariant}
          onDone={onDone}
        />
      )}
    </>
  );
};

export default DeliverableItemOrderFormDialog;
