import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import {
  Rarity,
  SeasonEligibility,
} from '@sorare/core/src/__generated__/globalTypes';
import { useInSeasonReady } from '@sorare/core/src/hooks/useInSeasonReady';
import useEvents from '@sorare/core/src/lib/events/useEvents';

import PriceHistoryFromProps from '../PriceHistoryFromProps';
import { AnyCardPriceHistory_anyCard } from './__generated__/index.graphql';

type Props = {
  card: AnyCardPriceHistory_anyCard;
  context: string;
};

export const AnyCardPriceHistory = ({ card, context }: Props) => {
  const track = useEvents();
  const inSeasonReady = useInSeasonReady();
  const { slug, rarityTyped, anyPlayer, inSeasonEligible } = card;
  const { slug: playerSlug } = anyPlayer;

  const variables = {
    rarity: rarityTyped === Rarity.common ? Rarity.limited : rarityTyped,
    playerSlug,
    ...(inSeasonReady && {
      seasonEligibility: inSeasonEligible
        ? SeasonEligibility.IN_SEASON
        : SeasonEligibility.CLASSIC,
    }),
  };

  const onClick = useCallback(
    (clickedToken: { slug: string }) => {
      if (clickedToken) {
        track('Click Price History', {
          clickedCardSlug: clickedToken.slug,
          cardSlug: slug,
          interactionContext: context,
        });
      }
    },
    [slug, context, track]
  );
  return <PriceHistoryFromProps variables={variables} onClick={onClick} />;
};

AnyCardPriceHistory.fragments = {
  anyCard: gql`
    fragment AnyCardPriceHistory_anyCard on AnyCardInterface {
      slug
      rarityTyped
      inSeasonEligible
      anyPlayer {
        slug
        displayName
      }
    }
  ` as TypedDocumentNode<AnyCardPriceHistory_anyCard>,
};
