import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { AveragePlayerScore, ScoreStatus } from '__generated__/globalTypes';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { cardAttributes } from 'lib/glossary';
import { statusMessages } from 'lib/players';

import { Score } from '../Score';
import { PlayerScore_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: PlayerScore_anyPlayer;
  mode: AveragePlayerScore;
  isCappedMode?: boolean;
  animated?: boolean;
};

const modeTooltipMessages: Record<Props['mode'], MessageDescriptor> = {
  LAST_FIVE_SO5_AVERAGE_SCORE: cardAttributes.L5Score,
  LAST_FIFTEEN_SO5_AVERAGE_SCORE: cardAttributes.L15Score,
  SEASON_AVERAGE_SCORE: cardAttributes.seasonAverageScore,
};
export const PlayerScore = ({
  player,
  mode,
  isCappedMode,
  animated,
}: Props) => {
  const score = {
    LAST_FIVE_SO5_AVERAGE_SCORE: player.last5,
    LAST_FIFTEEN_SO5_AVERAGE_SCORE: player.last15,
    SEASON_AVERAGE_SCORE: player.seasonAverage,
  }[mode];

  return (
    <Tooltip
      title={
        <FormattedMessage
          {...(score ? modeTooltipMessages[mode] : statusMessages.DID_NOT_PLAY)}
        />
      }
    >
      <Score
        hideReviewing
        shape="HEXAGON"
        score={score || 0}
        scoreStatus={ScoreStatus.FINAL}
        scoreType="PLAYER_SCORE"
        isCappedMode={isCappedMode}
        animated={animated}
      />
    </Tooltip>
  );
};

PlayerScore.fragments = {
  anyPlayer: gql`
    fragment PlayerScore_anyPlayer on AnyPlayerInterface {
      slug
      last15: averageScore(type: LAST_FIFTEEN_SO5_AVERAGE_SCORE)
      last5: averageScore(type: LAST_FIVE_SO5_AVERAGE_SCORE)
      seasonAverage: averageScore(type: SEASON_AVERAGE_SCORE)
    }
  ` as TypedDocumentNode<PlayerScore_anyPlayer>,
};
