import classnames from 'classnames';
import {
  FC,
  FormEventHandler,
  ReactElement,
  ReactNode,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickAway, useKey, useMedia } from 'react-use';
import styled, { css } from 'styled-components';

import ButtonBase, { ButtonProps } from 'atoms/buttons/ButtonBase';
import { Portal } from 'atoms/layout/Portal';
import { Text16 } from 'atoms/typography';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';
import useTouchScreen from 'hooks/device/useTouchScreen';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useFocusTrap from 'hooks/useFocusTrap';
import { clamp } from 'lib/math';
import { Breakpoint, breakpoints } from 'style/mediaQuery';
import { theme } from 'style/theme';

import { DropdownRadix } from './DropdownRadix';

const drawerThresholdDefault = breakpoints.laptop;

const SelectWrapper = styled.div`
  display: inline-flex;

  &.fullWidth {
    width: 100%;
  }
`;
const Overlay = styled(ButtonBase)<{ $drawerThreshold: number }>`
  position: fixed;
  z-index: ${theme.zIndex.tooltip};
  inset: 0;
  background: rgba(var(--c-static-rgb-neutral-1000), 0.7);
  content: '';
  ${({ $drawerThreshold }) => css`
    @media (min-width: ${$drawerThreshold}px) {
      display: none;
    }
  `}
`;
const DropdownContent = styled.form<{ drawerThreshold: number }>`
  position: fixed;
  z-index: ${theme.zIndex.tooltip};
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-color: var(--c-neutral-200);
  box-shadow: var(--shadow-200);
  border-radius: var(--intermediate-unit) var(--intermediate-unit) 0 0;
  pointer-events: none;
  opacity: 0;
  transform: translateY(50%);
  visibility: hidden;
  transition:
    0.15s ease transform,
    0.15s ease opacity;
  overflow: auto;
  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  &.border {
    border: 1px solid var(--c-neutral-300);
  }
  ${({ drawerThreshold }) => css`
    @media (min-width: ${drawerThreshold}px) {
      overscroll-behavior: contain;
      border-radius: var(--intermediate-unit);
      transform: translateY(-5%);
      min-width: max-content;
      &.open {
        transform: translateY(0);
      }
      &.fullWidth {
        width: 100%;
        min-width: 0;
      }
    }
  `}
`;

export const DropdownOptionLabel = styled(Text16).attrs({ as: 'label' })`
  display: flex;
  padding: var(--unit) var(--quadruple-unit) var(--unit) var(--double-unit);
  color: var(--c-neutral-600);
  cursor: pointer;
  &:focus,
  &:focus-within {
    background: var(--c-neutral-300);
  }
  &:hover,
  &.selected {
    background: var(--c-neutral-400);
  }
`;

const ButtonWrapper = styled.button`
  position: relative;
  &::before {
    pointer-events: none;
    opacity: 0;
  }
  &.open {
    &.triggerOnHover::before {
      @media (min-width: ${drawerThresholdDefault}px) {
        display: none;
      }
    }
  }
`;

export type Props = {
  children:
    | ReactNode
    | FC<React.PropsWithChildren<{ closeDropdown: () => void }>>;
  label: (
    props: Pick<ButtonProps, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>
  ) => ReactElement;
  gap?: 0 | 4 | 8 | 16;
  horizontalGap?: -16 | -8 | -4 | 0 | 4 | 8 | 16;
  onChange?: FormEventHandler<HTMLFormElement>;
  triggerOnHover?: boolean;
  closeOnChange?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  fullWidth?: boolean;
  align?: 'left' | 'right';
  breakpoint?: Breakpoint;
};

export const THRESHOLD = 10;

const useOpenState = ({
  open,
  onClose,
  onOpen,
}: Pick<Props, 'open' | 'onClose' | 'onOpen'>) => {
  const [openState, setOpenState] = useState(false);
  const isControlledMode = typeof open === 'boolean';
  if (isControlledMode) {
    return [
      open,
      (v: boolean) => {
        if (v) {
          onOpen?.();
        } else {
          onClose?.();
        }
      },
    ] as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  }
  return [openState, setOpenState] as const;
};

const Dropdown = ({
  open: openProp,
  children,
  label,
  gap = 0,
  horizontalGap = 0,
  onChange,
  triggerOnHover = false,
  closeOnChange = false,
  onClose = () => {},
  onOpen = () => {},
  fullWidth,
  align = 'left',
  breakpoint = 'laptop',
}: Props) => {
  const drawerThreshold = breakpoints[breakpoint] || drawerThresholdDefault;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const isTouchScreen = useTouchScreen();
  const [open, setOpen] = useOpenState({ open: openProp, onClose });
  const dropdownContentRef = useFocusTrap(open);
  const isTabletAndAbove = useIsTabletAndAbove();
  const isAboveDrawerThreshold = useMedia(`(min-width: ${drawerThreshold}px)`);
  const triggerOnHoverEnabled =
    !isTouchScreen && isAboveDrawerThreshold && triggerOnHover;

  const [coordinates, setCoordinates] = useState<
    Record<string, string | number>
  >({
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  });
  const [maxHeight, setMaxHeight] = useState('100%');
  const [maxWidth, setMaxWidth] = useState('auto');

  const closeDropdown = useCallback(() => {
    onClose();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const openDropdown = useCallback(() => {
    onOpen();
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useClickAway(dropdownContentRef, () => {
    if (open) closeDropdown();
  }, ['mousedown']);
  useKey('Escape', () => open && closeDropdown(), undefined, [open]);
  useKey(
    'Enter',
    e => {
      if (open) {
        e.preventDefault();
        e.stopPropagation();
        const currentFocus =
          dropdownContentRef.current.querySelector('*:focus');
        if (currentFocus) {
          currentFocus.click();
        }
      }
    },
    undefined,
    [open]
  );
  const mouseLeave = useCallback(() => {
    if (triggerOnHoverEnabled) {
      closeDropdown();
    }
  }, [closeDropdown, triggerOnHoverEnabled]);
  const mouseEnter = useCallback(() => {
    if (triggerOnHoverEnabled) {
      openDropdown();
    }
  }, [openDropdown, triggerOnHoverEnabled]);

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      // SSR mode, skip this
      return () => {};
    }

    const buttonCurrentRef = wrapperRef.current;
    const dropdownCurrentRef = dropdownContentRef.current;
    // Intersection observer here allows to retrieve up to date position
    // without forcing a layout reflow
    // https://gist.github.com/paulirish/5d52fb081b3570c81e3a
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const { offsetWidth, offsetHeight } = dropdownCurrentRef;
          const {
            top,
            height,
            left: rectLeft,
            right,
          } = entry.boundingClientRect;
          const left = rectLeft + horizontalGap;
          const { width: rootWidth, height: rootHeight } = entry.rootBounds || {
            width: 0,
            height: 0,
          };
          if (rootWidth < drawerThreshold) {
            setCoordinates({
              top: 'auto',
              right: 0,
              bottom: 0,
              left: 0,
            });
            setMaxHeight('80%');
          } else {
            const canBeBottom =
              top + height + offsetHeight + gap + THRESHOLD < rootHeight;
            const canBeTop = offsetHeight + gap < top;

            const bottomPosition = Math.max(THRESHOLD, top + height + gap);

            const topPosition = top - offsetHeight - gap;

            let dropdownPosition = bottomPosition;
            if (!canBeBottom) {
              if (canBeTop) {
                dropdownPosition = topPosition;
              } else {
                // we are in a small height window here (or the dropdown content is long),
                // let's try to position the dropdown to where there is max space
                // eslint-disable-next-line no-lonely-if
                if (top > offsetHeight - top) {
                  const newMaxHeight = top - gap - THRESHOLD;
                  dropdownPosition = top - newMaxHeight - gap;
                  setMaxHeight(`${newMaxHeight}px`);
                } else {
                  dropdownPosition = bottomPosition;
                  setMaxHeight(
                    `${rootHeight - top - height - gap - THRESHOLD}px`
                  );
                }
              }
            }

            if (fullWidth) {
              setCoordinates({
                top: `${dropdownPosition}px`,
                left,
                bottom: 'auto',
                right,
              });
              setMaxWidth(`${right - left}px`);
            } else {
              setCoordinates({
                top: `${dropdownPosition}px`,
                left: `${clamp(
                  align === 'left' ? left : right - offsetWidth,
                  THRESHOLD,
                  rootWidth - offsetWidth - THRESHOLD
                )}px`,
                bottom: 'auto',
                right: 'auto',
              });
              setMaxWidth('auto');
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    );
    if (buttonCurrentRef) {
      observer.observe(buttonCurrentRef);
    }
    return () => {
      if (buttonCurrentRef) {
        observer.unobserve(buttonCurrentRef);
      }
    };
  }, [
    open,
    dropdownContentRef,
    wrapperRef,
    gap,
    horizontalGap,
    align,
    fullWidth,
    drawerThreshold,
  ]);

  useEffect(() => {
    if (!open || !isTabletAndAbove) {
      return undefined;
    }

    window.addEventListener('scroll', closeDropdown);
    return () => {
      window.removeEventListener('scroll', closeDropdown);
    };
  }, [open, closeDropdown, isTabletAndAbove]);

  const as = useCallback(
    (props: React.ComponentProps<'button'>) =>
      cloneElement(
        label({
          onClick: () => {
            if (open) {
              closeDropdown();
            } else {
              openDropdown();
            }
          },
          onMouseEnter: mouseEnter,
          onMouseLeave: mouseLeave,
        }),
        props
      ),
    [closeDropdown, label, mouseEnter, mouseLeave, open, openDropdown]
  );
  return (
    <SelectWrapper ref={wrapperRef} className={classnames({ fullWidth })}>
      <ButtonWrapper
        as={as}
        onClick={() => {
          if (open) {
            closeDropdown();
          } else {
            openDropdown();
          }
        }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        className={classnames({
          triggerOnHover: triggerOnHoverEnabled,
          open,
        })}
      />
      <Portal id="dropdown">
        {open && (
          <Overlay
            $drawerThreshold={drawerThreshold}
            type="button"
            onClick={() => {
              closeDropdown();
            }}
          />
        )}
        <DropdownContent
          drawerThreshold={drawerThreshold}
          style={{ ...coordinates, maxHeight, maxWidth }}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          className={classnames({
            open,
            fullWidth,
          })}
          ref={dropdownContentRef}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
            if (closeOnChange) {
              closeDropdown();
            }
          }}
        >
          {open &&
            (typeof children === 'function'
              ? children({ closeDropdown })
              : children)}
        </DropdownContent>
      </Portal>
    </SelectWrapper>
  );
};

const ExportedDropdown = (props: Props) => {
  const {
    flags: { useDropdownRadix = false },
  } = useFeatureFlags();

  if (useDropdownRadix) {
    return <DropdownRadix {...props} />;
  }

  return <Dropdown {...props} />;
};

export default ExportedDropdown;
