/* eslint-disable jsx-a11y/media-has-caption */
import { faVolumeMute } from '@fortawesome/pro-regular-svg-icons';
import {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { useVideoAutoPlay } from 'hooks/useVideoAutoPlay';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const MutedIcon = styled(FontAwesomeIcon)`
  position: absolute;
  z-index: 2;
  bottom: var(--double-unit);
  right: var(--double-unit);
`;

export enum VIDEO_FORMAT {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
}

type Props = {
  hideMutedIcon?: boolean;
  sources: React.JSX.IntrinsicElements['source'][];
  className?: string;
} & React.JSX.IntrinsicElements['video'];
export const Video = forwardRef<HTMLVideoElement, PropsWithChildren<Props>>(
  (
    { sources, className, autoPlay, hideMutedIcon, onError, ...rest }: Props,
    forwardedRef
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    useImperativeHandle(forwardedRef, () => videoRef.current!);
    const { muted } = useVideoAutoPlay(videoRef, !!autoPlay, onError);

    return (
      <Root className={className}>
        <video
          ref={videoRef}
          // 1x1 transparent GIF to avoid ugly default poster in Android webview
          poster="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
          {...rest}
        >
          {sources.map(
            ({ src, type = VIDEO_FORMAT.MP4, media }, i) =>
              src && (
                <source
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${src}`}
                  src={src}
                  type={type}
                  media={media}
                />
              )
          )}
          {rest.children}
        </video>
        {muted && !hideMutedIcon && <MutedIcon icon={faVolumeMute} />}
      </Root>
    );
  }
);
Video.displayName = 'Video';
