import { useCallback } from 'react';

import useMutation from 'hooks/graphql/useMutation';
import { CLAIM_NBA_REFERRAL_REWARDS_MUTATION } from 'lib/usSportsGraphql/queries';

export default () => {
  const [claim] = useMutation(CLAIM_NBA_REFERRAL_REWARDS_MUTATION);

  return useCallback(
    async (referralRewardId: string) => {
      await claim({
        variables: {
          referralIDs: [referralRewardId],
        },
        refetchQueries: ['ConfigQuery'],
      });
    },
    [claim]
  );
};
