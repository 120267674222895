import { Sport } from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';

import { SESSION_STORAGE, useSessionStorage } from './useSessionStorage';

const useCurrentSport = () => {
  const { sport } = useSportContext();
  const [sessionSport] = useSessionStorage(SESSION_STORAGE.sport);
  const currentSport = sport || sessionSport || Sport.FOOTBALL;
  return currentSport;
};

export default useCurrentSport;
