import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath, useSearchParams } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  NBA_LIVE_PRO,
  NBA_PLAY_POSTSEASON_FIXTURE,
  NBA_PLAY_POSTSEASON_FIXTURE_COMPETITION,
} from '@sorare/core/src/constants/__generated__/routes';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';
import { useTypedParams } from '@sorare/core/src/lib/routing';
import { Navigate } from '@sorare/core/src/routing/Navigate';

import { LayoutParams } from './$fixture/__generated__/routeParams';
import {
  NBAPlayPostseasonFindRedirect,
  NBAPlayPostseasonFindRedirectVariables,
} from './__generated__/page.graphql';

const query = gql`
  query NBAPlayPostseasonFindRedirect {
    nbaPastFixtures(first: 1) {
      nodes {
        slug
        gameWeek
      }
    }
    nbaLiveFixture {
      slug
      gameWeek
    }
    nbaOpenFixture {
      slug
      gameWeek
      leaderboards(includeTraining: false) {
        slug
      }
    }
  }
` as TypedDocumentNode<
  NBAPlayPostseasonFindRedirect,
  NBAPlayPostseasonFindRedirectVariables
>;

const PostseasonRedirect = () => {
  const { fixture: fixtureSlug } = useTypedParams<LayoutParams>();
  const [searchParams] = useSearchParams();

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: Boolean(fixtureSlug),
  });

  const redirectToUpcomingFixture =
    searchParams.get('to_fixture') === 'upcoming';
  const redirectToLeaderboard = searchParams.get('to_leaderboard');

  const redirectFixture = redirectToUpcomingFixture
    ? data?.nbaOpenFixture?.slug
    : data?.nbaOpenFixture?.slug ||
      data?.nbaLiveFixture?.slug ||
      data?.nbaPastFixtures?.nodes?.[0]?.slug;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!redirectFixture) {
    return <Navigate replace to={NBA_LIVE_PRO} />;
  }

  if (redirectToLeaderboard) {
    return (
      <Navigate
        replace
        to={generatePath(NBA_PLAY_POSTSEASON_FIXTURE_COMPETITION, {
          fixture: redirectFixture,
          competition: redirectToLeaderboard,
        })}
      />
    );
  }

  return (
    <Navigate
      replace
      to={generatePath(NBA_PLAY_POSTSEASON_FIXTURE, {
        fixture: redirectFixture,
      })}
    />
  );
};

export default PostseasonRedirect;
