import { useIsTabletAndAbove } from './useIsTabletAndAbove';

export const useIsIPhone = () => {
  const isTabletAndAbove = useIsTabletAndAbove();

  return (
    // IOS Mobile app is not suitable to be used on tablets, it would look zoomed
    !isTabletAndAbove && !!navigator.userAgent.match(/iPhone/i)
  );
};
