import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';

import { getGoFixtureSlug_so5Fixture } from './__generated__/getGoFixtureSlug.graphql';

export const getGoFixtureSlug = withFragments(
  (so5Fixture: Nullable<getGoFixtureSlug_so5Fixture>, sport: Sport): string => {
    if (sport === Sport.FOOTBALL || !so5Fixture) return '';

    const { startDate, gameWeek } = so5Fixture;
    const prefix = sport === Sport.BASEBALL ? 'mlb' : 'nba';

    return `${prefix}-${startDate.getFullYear()}-gameweek-${gameWeek}`;
  },
  {
    so5Fixture: gql`
      fragment getGoFixtureSlug_so5Fixture on So5Fixture {
        slug
        startDate
        gameWeek
      }
    ` as TypedDocumentNode<getGoFixtureSlug_so5Fixture>,
  }
);
