import classnames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { LabelM } from 'atoms/typography';

const Root = styled(LabelM)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-unit);
  min-width: var(--triple-unit);
  padding: 0 var(--unit);
  background: var(--c-neutral-150);
  color: var(--c-neutral-1000);
  border-radius: 2em;
  position: relative;
  > svg,
  img {
    display: inline-flex;
    &:first-child:last-child {
      position: absolute; /** hack to have a solo icon properly rounded. */
    }
  }
  &.alert {
    background: var(--c-red-600);
    color: var(--c-neutral-1000);
  }
  &.red {
    background: var(--c-score-veryLow);
    color: var(--c-neutral-100);
  }
  &.blue {
    background: var(--c-brand-600);
  }
`;
type Props = {
  children: ReactNode;
  variant?: 'red' | 'blue' | 'alert';
  className?: string;
  ariaLabel?: string;
};
export const Badge = ({ children, variant, className, ariaLabel }: Props) => {
  return (
    <Root
      bold
      as="div"
      className={classnames(className, variant)}
      aria-label={ariaLabel}
    >
      {children}
    </Root>
  );
};
