import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { Title3 } from 'atoms/typography';
import { ErrorProps, images, levelOfErrorCode } from 'routing/HandledError';

const Container = styled(Vertical).attrs({ center: true })`
  width: 100%;
  height: 100%;
  margin: auto;
  padding: var(--double-unit);
  text-align: center;
`;
const Cta = styled(Button)`
  margin-top: var(--double-unit);
`;

export const HandledError = ({ code, message }: ErrorProps) => {
  const level = levelOfErrorCode(code);
  const navigate = useNavigate();

  const handleNavigate = () => navigate(-1);

  return (
    <Container>
      <div>{images[level]}</div>
      <Title3>{message}</Title3>
      <Cta size="large" onClick={handleNavigate} color="primary">
        <FormattedMessage
          id="DialogHandledError.GoBack"
          defaultMessage="Go Back"
        />
      </Cta>
    </Container>
  );
};

export default HandledError;
