import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ReactNode } from 'react';
import ReactSelect, { components } from 'react-select';

import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';

import selectStyle from './style';

export interface Props<T> {
  icon?: IconDefinition;
  menuLateralAlignment?: 'left' | 'right';
  onChange?: (option?: T | null) => void;
  menuPosition?: 'fixed';
  options?: T[];
  value?: T;
  menuPortalTarget?: HTMLElement;
  menuPlacement?: 'top';
  isDisabled?: boolean;
  placeholder?: ReactNode;
  small?: boolean;
}

const DropdownIndicator = (props: any) => {
  const { icon, ...rest } = props;
  return (
    <components.DropdownIndicator {...rest}>
      <FontAwesomeIcon icon={icon} />
    </components.DropdownIndicator>
  );
};

const Select = <T,>({
  icon = faCaretDown,
  menuLateralAlignment = 'left',
  menuPortalTarget = undefined,
  small = false,
  ...rest
}: Props<T>) => {
  const themedStyle = selectStyle();
  if (menuLateralAlignment === 'right') {
    const existingMenu = themedStyle.menu;
    themedStyle.menu = (provided: any) => ({
      ...existingMenu(provided),
      left: 'unset',
    });
  }
  return (
    <ReactSelect
      className={classNames({ small })}
      styles={themedStyle}
      openMenuOnFocus
      menuPortalTarget={menuPortalTarget}
      components={{
        DropdownIndicator: props => (
          <DropdownIndicator icon={icon} {...props} />
        ),
      }}
      isSearchable={false}
      {...rest}
    />
  );
};

export default Select;
