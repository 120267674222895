import classnames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import Dropdown from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import useEvents from 'lib/events/useEvents';

export type Social =
  | 'x'
  | 'instagram'
  | 'discord'
  | 'reddit'
  | 'youtube'
  | 'facebook';

interface Link {
  label: string;
  link: string;
}

const SocialLink = styled.a`
  transition: 0.15s ease color;
  position: relative;
  cursor: pointer;
  color: var(--c-static-neutral-100);
  padding: var(--unit);
  font-size: 22px;
  &:hover,
  &:focus {
    &.instagram {
      color: var(--c-social-instagram);
    }
    &.x {
      color: var(--c-social-x-twitter);
    }
    &.discord {
      color: var(--c-social-discord);
    }
    &.reddit {
      color: var(--c-social-reddit);
    }
    &.youtube {
      color: var(--c-social-youtube);
    }
    &.facebook {
      color: var(--c-social-facebook);
    }
    &.tiktok {
      color: var(--c-social-tiktok);
    }
  }
`;

const Link = styled.a`
  color: var(--c-neutral-1000);
  display: block;
  padding: var(--unit) var(--double-unit);
  &:not(:last-child) {
    border-bottom: 1px solid var(--c-neutral-300);
  }
`;

const messages = defineMessages({
  share: {
    id: 'SocialMedia.label',
    defaultMessage: 'Learn about Sorare on {social}',
  },
});

export interface SocialLinkProps {
  social: string;
  icon: IconDefinition;
  links: Link[];
}
const SocialItem = ({ icon, links, social }: SocialLinkProps) => {
  const track = useEvents();
  const trackLink = (source: string) => {
    track('Click Footer link', {
      source,
    });
  };
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage(messages.share, {
    social: social[0].toUpperCase() + social.slice(1),
  });

  if (links.length === 1) {
    return (
      <SocialLink
        href={links[0].link}
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(social)}
        onClick={() => trackLink(social)}
        aria-label={ariaLabel}
      >
        <FontAwesomeIcon icon={icon} />
      </SocialLink>
    );
  }
  return (
    <Dropdown
      triggerOnHover
      onChange={() => {}}
      label={props => (
        <SocialLink
          {...props}
          as="button"
          type="button"
          className={classnames(social)}
          aria-label={ariaLabel}
        >
          <FontAwesomeIcon icon={icon} />
        </SocialLink>
      )}
    >
      <>
        {links.map(({ link, label }) => {
          return (
            <Link
              key={label}
              href={link}
              onClick={() => trackLink(link)}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={ariaLabel}
            >
              {label}
            </Link>
          );
        })}
      </>
    </Dropdown>
  );
};

const Root = styled.div`
  display: flex;
  gap: var(--double-unit);
  overflow: auto;
`;

type Props = {
  socialLinks: SocialLinkProps[];
};
export const SocialLinks = ({ socialLinks }: Props) => {
  return (
    <Root>
      {socialLinks.map(socialLink => {
        return (
          <SocialItem
            key={socialLink.social}
            icon={socialLink.icon}
            links={socialLink.links}
            social={socialLink.social}
          />
        );
      })}
    </Root>
  );
};
