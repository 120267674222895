import {
  BrowserOptions,
  breadcrumbsIntegration,
  browserProfilingIntegration,
  globalHandlersIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  withProfiler,
} from '@sentry/react';
import type { Integration } from '@sentry/types';
import { useEffect } from 'react';
import {
  createBrowserRouter as RRcreateBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { SOFE_API_PATH, SOFE_API_ROOT } from 'config';

// See https://gist.github.com/impressiver/5092952
const communityIgnoredErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Those are actual errors which are raised whenever there is a recaptcha error
  // there is nothing to do for those
  'Invalid recaptcha token',
];

const customIgnoredErrors = [
  // Ignoring this because there's nothing that can be done when this is thrown, and currently
  // thrown by launchdarkly API
  'A network error occurred',

  // Ignore network failed errors as they are not actionable & already handled with an error page
  'TypeError: Failed to fetch', // Chrome
  'TypeError: NetworkError when attempting to fetch resource', // Firefox
  'Error: Load failed', // iOS

  // TODO(SO5-952): Remove these once we can actually solve it, but for now they're spamming us
  "Cannot read properties of undefined (reading 'focus')",
  "undefined is not an object (evaluating 't.input.focus')",

  // skip rate limit errors
  /rate limit exceeded/,

  // Ignore errors from API calls done while being logged out
  /Not authorized to access/,

  // There is a fallback for such a case. webgl can be disabled for security/privacy reasons.
  'Error creating WebGL context with your selected attributes.',

  // Especially on mobile, we can't do anything about this
  'Large Render Blocking Asset',

  // Not in our control: https://github.com/getsentry/sentry-javascript/issues/8444
  /logMutedMessage/,
];

const ignoredErrorPatterns = [
  ...communityIgnoredErrors,
  ...customIgnoredErrors,
];

const ignoredUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

export type SentryApp = 'sorare' | 'playground' | 'rendering';

export const startSentry = ({
  dsn,
  env,
  release,
}: {
  dsn: string;
  env: string;
  release: string;
  app: SentryApp;
}) => {
  const sentryEnv = env === 'production' ? 'prod' : env;
  const isDevelopment = sentryEnv === 'development';

  let sampleRates: {
    traces: number;
    replaysSession: number;
    replaysOnError: number;
    profiles: number;
  };

  switch (sentryEnv) {
    case 'prod':
      sampleRates = {
        traces: 0.01,
        profiles: 1,
        replaysSession: 0,
        replaysOnError: 0.01,
      };
      break;
    case 'staging':
    case 'mockprod':
      sampleRates = {
        traces: 0.01,
        profiles: 1,
        replaysSession: 0.1,
        replaysOnError: 1,
      };
      break;
    default:
      sampleRates = {
        traces: 0.01,
        profiles: 1,
        replaysSession: 0,
        replaysOnError: 0,
      };
      break;
  }

  const integrations: Integration[] = [
    globalHandlersIntegration({
      onerror: true,
      onunhandledrejection: false,
    }),
    breadcrumbsIntegration({
      console: !isDevelopment,
    }),
    browserProfilingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      stripBasename: true,
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ];

  const options: BrowserOptions = {
    dsn,
    environment: sentryEnv,
    allowUrls: isDevelopment ? [] : ['/static/js', '/assets'],
    denyUrls: ignoredUrls,
    ignoreErrors: ignoredErrorPatterns,
    integrations,
    tracesSampleRate: sampleRates.traces,
    tracePropagationTargets: [`${SOFE_API_ROOT}${SOFE_API_PATH}`],
    release,
    autoSessionTracking: false,
    replaysSessionSampleRate: sampleRates.replaysSession,
    replaysOnErrorSampleRate: sampleRates.replaysOnError,
    profilesSampleRate: sampleRates.profiles,
  };
  init(options);
  // Must be after init
  const createBrowserRouter = RRcreateBrowserRouter;

  return { createBrowserRouter };
};

export { withProfiler };
