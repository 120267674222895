import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineS, HeadlineXS, LabelS } from 'atoms/typography';
import useScreenSize from 'hooks/device/useScreenSize';
import { unitMapping } from 'lib/style';
import { breakpoints } from 'style/mediaQuery';

import { Standing, Props as StandingProps } from './Standing';

const StyledLabelS = styled(LabelS)`
  text-transform: uppercase;
`;
const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;
const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    text-align: left;
  }
  td {
    padding: 0;
  }

  tr > td:nth-child(n + 4),
  tr > th:nth-child(n + 4) {
    text-align: right;
  }

  tbody tr:nth-child(even) {
    background-color: rgba(var(--c-rgb-neutral-200), 0.7);
  }
  tr th:last-child,
  tr td:last-child {
    padding-right: var(--intermediate-unit);
    box-sizing: content-box;
  }
`;
const StyledFullWidthContainer = styled(FullWidthContainer)`
  @media (max-width: ${breakpoints.laptop}px) {
    padding: 0;
  }
`;

const RANK_STATUS_COLOR = {
  top: [
    'var(--c-score-high)',
    'var(--c-brand-600)',
    'var(--c-score-mediumHigh)',
  ],
  bottom: ['var(--c-red-600)', 'var(--c-score-low)'],
};

type Props<T extends string> = {
  displayName: string;
  as?: keyof HTMLElementTagNameMap;
  filters?: ReactNode;
  stats: StandingProps<T>['stats'];
  headers: Record<T, ReactNode>;
  widths: Record<T, keyof typeof unitMapping>;
  groups: {
    title?: string | null;
    standings: StandingProps<T>['standing'][];
  }[];
  highlightedSlug?: string;
  nbItemsToShow?: number;
};

const GroupStandings = <T extends string>({
  title,
  standings,
  headers,
  widths,
  stats,
  highlightedSlug,
  nbItemsToShow,
}: {
  title?: string | null;
  standings: StandingProps<T>['standing'][];
} & Omit<Props<T>, 'displayName' | 'as' | 'filters' | 'groups'>) => {
  const { up: isLaptopAndAbove } = useScreenSize('laptop');
  const standingsOrderedByRank = useMemo(
    () => standings.sort((a, b) => a.rank - b.rank),
    [standings]
  );

  const colorByRankStatus = useMemo(() => {
    const topRankStatuses = [...standingsOrderedByRank].reduce(
      (acc: Record<string, string>, standing, i, array) => {
        if (!standing.rankStatus) {
          array.splice(i - 1);
          return acc;
        }

        return {
          ...acc,
          [standing.rankStatus]: RANK_STATUS_COLOR.top[Object.keys(acc).length],
        };
      },
      {}
    );
    const bottomRankStatuses = [...standingsOrderedByRank]
      .reverse()
      .reduce((acc: Record<string, string>, standing, i, array) => {
        if (!standing.rankStatus) {
          array.splice(i - 1);
          return acc;
        }

        return {
          ...acc,
          [standing.rankStatus]:
            RANK_STATUS_COLOR.bottom[Object.keys(acc).length],
        };
      }, {});

    return { ...topRankStatuses, ...bottomRankStatuses };
  }, [standingsOrderedByRank]);

  const standingsToShow = useMemo(() => {
    if (!nbItemsToShow) {
      return standingsOrderedByRank;
    }

    const highlightedStandingRank = standingsOrderedByRank.find(
      standing => standing.slug === highlightedSlug
    )?.rank;

    if (!highlightedSlug || !highlightedStandingRank) {
      return standingsOrderedByRank.slice(0, nbItemsToShow);
    }

    const teamsBefore = Math.floor(nbItemsToShow / 2);
    const startIndex = highlightedStandingRank - teamsBefore - 1;
    const startOverflow = Math.max(-1 * startIndex, 0);

    const teamsAfter = nbItemsToShow - teamsBefore - 1;
    const endIndex = highlightedStandingRank + teamsAfter;
    const endOverflow = Math.max(endIndex - standingsOrderedByRank.length, 0);

    return standingsOrderedByRank.slice(
      Math.max(startIndex - endOverflow, 0),
      Math.min(endIndex + startOverflow, standingsOrderedByRank.length)
    );
  }, [highlightedSlug, nbItemsToShow, standingsOrderedByRank]);

  return (
    <>
      {title && (
        <FullWidthContainer>
          <HeadlineXS as="h5">{title}</HeadlineXS>
        </FullWidthContainer>
      )}
      <StyledFullWidthContainer>
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th
                  style={{
                    width: unitMapping[1],
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th
                  style={{
                    width: unitMapping[3],
                  }}
                />
                <th
                  style={{
                    width: isLaptopAndAbove ? undefined : '180px',
                  }}
                >
                  <StyledLabelS as="p" color="var(--c-neutral-600)" bold>
                    <FormattedMessage
                      id="Seo.Transfers.team"
                      defaultMessage="Team"
                    />
                  </StyledLabelS>
                </th>
                {stats.map(stat => (
                  <th
                    key={stat}
                    style={{
                      width: unitMapping[widths[stat]],
                    }}
                  >
                    <LabelS as="p" color="var(--c-neutral-600)" bold>
                      {headers[stat]}
                    </LabelS>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {standingsToShow.map(standing => (
                <Standing
                  key={standing.slug}
                  standing={standing}
                  current={standing.slug === highlightedSlug}
                  stats={stats}
                  statusColor={
                    standing.rankStatus
                      ? colorByRankStatus[standing.rankStatus]
                      : undefined
                  }
                />
              ))}
            </tbody>
          </StyledTable>
        </TableContainer>
      </StyledFullWidthContainer>
    </>
  );
};

export const Standings = <T extends string>({
  displayName,
  as = 'h2',
  filters,
  stats,
  widths,
  headers,
  groups,
  highlightedSlug,
  nbItemsToShow,
}: Props<T>) => (
  <div>
    <Vertical gap={2}>
      <FullWidthContainer>
        <Vertical gap={1.5}>
          <HeadlineS as={as}>
            <FormattedMessage
              id="Seo.Team.Standings.teamStandings"
              defaultMessage="{team} standings"
              values={{ team: displayName }}
            />
          </HeadlineS>
          {filters}
        </Vertical>
      </FullWidthContainer>
      {groups.map(group => (
        <GroupStandings
          key={group.title}
          title={group.title}
          standings={group.standings}
          highlightedSlug={highlightedSlug}
          nbItemsToShow={nbItemsToShow}
          stats={stats}
          headers={headers}
          widths={widths}
        />
      ))}
    </Vertical>
  </div>
);
