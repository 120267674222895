import { unitMapping } from '@sorare/core/src/lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const DefaultPosition = ({ size = 2, className }: Props) => {
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69145 1.08398C6.2092 1.08398 5.0076 2.28558 5.0076 3.76783C5.0076 4.72502 5.50869 5.56518 6.26286 6.04029H6.0413C4.67307 6.04029 3.5639 7.14946 3.5639 8.51769V15.1241H5.2155H7.11009V10.7019H9.00249V8.51769H11.8219C11.8219 7.14946 10.7127 6.04029 9.3445 6.04029H9.12004C9.8742 5.56518 10.3753 4.72502 10.3753 3.76783C10.3753 2.28558 9.17369 1.08398 7.69145 1.08398ZM11.821 10.1677V11.8183H13.4727V13.4699H11.821V15.1225H10.1694V13.4699H8.51792L8.51792 11.8183H10.1694V10.1677H11.821Z"
        fill="currentColor"
      />
    </svg>
  );
};
