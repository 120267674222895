import { ReactElement, ReactNode } from 'react';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { Chip } from 'atoms/ui/Chip';
import { Color } from 'style/types';

export type Props = {
  value: string | number | ReactElement;
  title?: NonNullable<ReactNode>;
  large?: boolean;
  custom?: {
    color: Color;
    background: Color;
  };
};

export const CardItem = (props: Props) => {
  const { value, title, large, custom } = props;

  if (!title) {
    return (
      <Chip
        size={large ? 'small' : 'smaller'}
        label={Label => <Label>{value}</Label>}
        custom={
          custom || {
            color: 'var(--c-neutral-1000)',
            background: 'var(--c-neutral-300)',
          }
        }
      />
    );
  }

  return (
    <Tooltip title={title}>
      <Chip
        size={large ? 'small' : 'smaller'}
        label={Label => <Label>{value}</Label>}
        custom={
          custom || {
            color: 'var(--c-neutral-1000)',
            background: 'var(--c-neutral-400)',
          }
        }
      />
    </Tooltip>
  );
};

export default CardItem;
