import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16, Title2, Title5 } from '@sorare/core/src/atoms/typography';
import { GraphQLResult } from '@sorare/core/src/components/form/Form';
import ComposeTeam from '@sorare/core/src/components/noCardEntry/ComposeTeam';
import NoCardEntryForm from '@sorare/core/src/components/noCardEntry/NoCardEntryForm';
import {
  GoogleReCAPTCHA,
  ReCAPTCHA,
} from '@sorare/core/src/components/recaptcha';
import useQueryString from '@sorare/core/src/hooks/useQueryString';
import { useUploadWithPresign } from '@sorare/core/src/hooks/useUploadWithPresign';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import Header from './Header';
import {
  NoCardDraftableAppearanceQuery,
  NoCardDraftableAppearanceQueryVariables,
} from './__generated__/page.graphql';
import { PageParams } from './__generated__/routeParams';
import useAcceptNoCardDraftableAppearance from './useAcceptNoCardDraftableAppearance';
import useGenerateNoCardLineup from './useGenerateSo5NoCardLineup';
import useResetNoCardDraftedAppearances from './useResetNoCardDraftedAppearances';

const Root = styled(Vertical).attrs({ gap: 0 })``;
const CenteredContainer = styled(Vertical).attrs({ gap: 0, center: true })`
  margin-top: var(--triple-unit);
  width: 100%;
  justify-content: stretch;
`;

export const GET_DRAFTABLE_APPEARANCE_QUERY = gql`
  query NoCardDraftableAppearanceQuery {
    so5 {
      noCardRoute {
        id
        draftableAppearance {
          id
          player {
            ... on AnyPlayerInterface {
              slug
              displayName
            }
          }
          team {
            ... on TeamInterface {
              slug
              name
            }
          }
          season {
            id
            startYear
          }
          bonus
          position
        }
      }
    }
  }
` as TypedDocumentNode<
  NoCardDraftableAppearanceQuery,
  NoCardDraftableAppearanceQueryVariables
>;

const NoCardEntry = () => {
  const { so5FixtureId } = useTypedParams<PageParams>();

  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);
  const [fetchAppearance] = useLazyQuery(GET_DRAFTABLE_APPEARANCE_QUERY, {
    fetchPolicy: 'network-only',
  });
  const accessToken = useQueryString('token') || '';
  const version = useQueryString('version');
  const [teamCompleted, setTeamCompleted] = useState(version === '1');
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);

  const acceptAppearance = useAcceptNoCardDraftableAppearance();
  const resetAppearances = useResetNoCardDraftedAppearances();
  const generateNoCardLineup = useGenerateNoCardLineup();
  const uploadWithPresign = useUploadWithPresign();

  const onSubmit = async (
    attributes: any,
    onResult: (res: GraphQLResult) => void
  ) => {
    if (!teamCompleted) {
      setStep(2);
      onResult({});
    } else {
      const {
        recaptchaTokenV2,
        proofOfResidency,
      }: {
        recaptchaTokenV2: Nullable<string>;
        proofOfResidency: Nullable<File>;
      } = attributes;

      let proofOfResidencyPresignedKey;
      if (proofOfResidency) {
        proofOfResidencyPresignedKey =
          await uploadWithPresign(proofOfResidency);
        if (!proofOfResidencyPresignedKey) {
          return;
        }
      }

      const result = await generateNoCardLineup({
        so5FixtureId: `So5Fixture:${so5FixtureId}`,
        recaptchaTokenV2,
        accessToken,
        proofOfResidencyPresignedKey,
      });
      onResult(result);
    }
  };

  const requestDraftableAppearance = useCallback(async () => {
    const data = await fetchAppearance();
    return data.data?.so5?.noCardRoute?.draftableAppearance as any;
  }, [fetchAppearance]);

  const resetDraftedAppearances = useCallback(async () => {
    await resetAppearances({
      accessToken,
      so5FixtureId: `So5Fixture:${so5FixtureId}`,
    });
  }, [resetAppearances, accessToken, so5FixtureId]);

  const acceptDraftableAppearance = async () => {
    const data = await acceptAppearance({
      accessToken,
      so5FixtureId: `So5Fixture:${so5FixtureId}`,
    });
    return data.errors.length === 0;
  };

  const onTeamCompleted = () => {
    setTeamCompleted(true);
    setStep(1);
  };

  if (success)
    return (
      <Root>
        <CenteredContainer>
          <Title2>
            <FormattedMessage
              id="NoCardEntryForm.successTitle"
              defaultMessage="Thank you"
            />
          </Title2>
          <Title5>
            {version === '1' ? (
              <FormattedMessage
                id="NoCardEntryForm.successSubtitle"
                defaultMessage="You will soon receive an email with a proposed team"
              />
            ) : (
              <FormattedMessage
                id="NoCardEntryForm.composeTeam.successSubtitle"
                defaultMessage="You will soon receive an email with a proposed competition"
              />
            )}
          </Title5>
        </CenteredContainer>
      </Root>
    );

  return (
    <Root>
      <Header />
      <NoCardEntryForm
        onSubmit={onSubmit}
        onSuccess={() => setSuccess(teamCompleted)}
        information={
          version === '2' && teamCompleted ? (
            <Text16 color="var(--c-static-green-300)">
              <FormattedMessage
                id="NoCardEntry.teamCompleted"
                defaultMessage="Team completed"
              />
            </Text16>
          ) : undefined
        }
      />
      {step === 2 && (
        <ComposeTeam
          open
          onSubmit={onTeamCompleted}
          requestAppearance={requestDraftableAppearance}
          resetAppearances={resetDraftedAppearances}
          acceptAppearance={acceptDraftableAppearance}
        />
      )}
      <ReCAPTCHA ref={recaptchaRef} />
    </Root>
  );
};

export default NoCardEntry;
