import { Color } from 'style/types';

export const THRESHOLD_COLORS: { [key: string]: Color } = {
  veryLow: 'var(--c-score-veryLow)',
  low: 'var(--c-score-low)',
  mediumLow: 'var(--c-score-mediumLow)',
  medium: 'var(--c-score-medium)',
  mediumHigh: 'var(--c-score-mediumHigh)',
  high: 'var(--c-score-high)',
};

export const thresholds = [
  [4.5, 'veryLow'],
  [14.5, 'low'],
  [24.5, 'mediumLow'],
  [34.5, 'medium'],
  [49.5, 'mediumHigh'],
] as const;

export const findThreshold = (score: number) => {
  const threshold = thresholds.find(t => Number(t[0]) > score);

  return threshold ? threshold[1] : 'high';
};
