import { ReactNode } from 'react';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { useConnectionContext } from 'contexts/connection';
import { useIntlContext } from 'contexts/intl';
import useScreenSize from 'hooks/device/useScreenSize';
import { glossary } from 'lib/glossary';
import { laptopAndAbove, tabletAndAbove } from 'style/mediaQuery';

import playNowBannerDefault from './assets/playNowBanner.jpg';
import playNowBannerTabletDefault from './assets/playNowBannerTablet.jpg';
import rivalsLogo from './assets/rivalsLogo.svg';

const PlayNowWrapper = styled(ContentContainer)`
  position: relative;
  margin-top: var(--quadruple-unit);

  @media ${tabletAndAbove} {
    margin-top: var(--double-unit);
  }
`;

const PlayNowBanner = styled.img`
  max-width: 100%;
  border-radius: var(--unit);
`;

const PlayNowCTAs = styled(Vertical).attrs({ gap: 2 })`
  top: 50%;
  left: 50%;
  position: absolute;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const RivalsLogo = styled.img`
  height: 79px;

  @media ${tabletAndAbove} {
    height: 85px;
  }

  @media ${laptopAndAbove} {
    height: 188px;
  }
`;

interface Props {
  playNowBanner?: string;
  playNowBannerTablet?: string;
  cta?: ReactNode;
}

export const RivalsPlayNow = ({
  playNowBanner = playNowBannerDefault,
  playNowBannerTablet = playNowBannerTabletDefault,
  cta,
}: Props) => {
  const { signUp } = useConnectionContext();
  const { up: isDesktop } = useScreenSize('laptop');
  const { formatMessage } = useIntlContext();

  return (
    <PlayNowWrapper>
      <PlayNowBanner
        src={isDesktop ? playNowBanner : playNowBannerTablet}
        alt=""
      />
      <PlayNowCTAs>
        <RivalsLogo src={rivalsLogo} alt="" />
        <span>
          <Button color="secondary" size="small" onClick={signUp}>
            {cta || formatMessage(glossary.playNow)}
          </Button>
        </span>
      </PlayNowCTAs>
    </PlayNowWrapper>
  );
};
