import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { Sport } from '__generated__/globalTypes';
import idFromObject from 'gql/idFromObject';
import { useSendUserEngagementIncrement } from 'hooks/useSendUserEngagementIncrement';

import { useClaimReferralReward_referralReward } from './__generated__/useClaimReferralReward.graphql';
import useCoreClaimReferralReward from './useCoreClaimReferralReward';
import useUSSportsClaimReferralReward from './useUSSportsClaimReferralReward';

export const useClaimReferralReward = (sport: Sport | undefined) => {
  const { sendUserEngagementIncrement } = useSendUserEngagementIncrement();

  const claimCore = useCoreClaimReferralReward();
  const claimNBA = useUSSportsClaimReferralReward();

  return useCallback(
    (referralReward: useClaimReferralReward_referralReward) => {
      claimCore(referralReward.id);
      if (sport === Sport.NBA && !referralReward.conversionCredit) {
        const nbaId = idFromObject(referralReward.id);
        if (nbaId) {
          claimNBA(nbaId);
        }
      }
      sendUserEngagementIncrement(80);
    },
    [claimCore, claimNBA, sendUserEngagementIncrement, sport]
  );
};

useClaimReferralReward.fragments = {
  referralReward: gql`
    fragment useClaimReferralReward_referralReward on ReferralReward {
      id
      conversionCredit {
        id
      }
      card: anyCard {
        slug
        sport
      }
    }
  ` as TypedDocumentNode<useClaimReferralReward_referralReward>,
};
