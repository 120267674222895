import { TypedDocumentNode, gql } from '@apollo/client';

import {
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables,
  NBAPastWinnerQuery,
  NBAPastWinnerQueryVariables,
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables,
  Nickname_usSportsUser,
  USSportsPastWinner_LeaderboardInterface,
  USSportsPastWinner_LineupInterface,
} from './__generated__/queries.graphql';

const USSPORTS_PAST_WINNER_LINEUP_FRAGMENT = gql`
  fragment USSportsPastWinner_LineupInterface on LineupInterface {
    id
    score
    user {
      slug
      nickname
      suspended
      avatarUrl
    }
    cards {
      card {
        slug
        pictureUrl
      }
    }
  }
` as TypedDocumentNode<USSportsPastWinner_LineupInterface>;

const USSPORTS_PAST_WINNER_LEADERBOARD_FRAGMENT = gql`
  fragment USSportsPastWinner_LeaderboardInterface on LeaderboardInterface {
    slug
    displayName
    iconImageUrl
    lineupsCount
    leaderboardRarity
    ... on BaseballLeaderboard {
      slug
      lineups(first: 1) {
        nodes {
          id
          ...USSportsPastWinner_LineupInterface
        }
      }
    }
    ... on NBALeaderboard {
      slug
      lineups(first: 1) {
        nodes {
          id
          ...USSportsPastWinner_LineupInterface
        }
      }
    }
  }
  ${USSPORTS_PAST_WINNER_LINEUP_FRAGMENT}
` as TypedDocumentNode<USSportsPastWinner_LeaderboardInterface>;

export const NBA_PAST_WINNER_QUERY = gql`
  query NBAPastWinnerQuery {
    nbaPastFixtures(first: 1) {
      nodes {
        slug
        leaderboards {
          slug
          ...USSportsPastWinner_LeaderboardInterface
        }
      }
    }
  }
  ${USSPORTS_PAST_WINNER_LEADERBOARD_FRAGMENT}
` as TypedDocumentNode<NBAPastWinnerQuery, NBAPastWinnerQueryVariables>;

export const NBA_REFERRAL_REWARDS_QUERY = gql`
  query NBAReferralRewardsQuery($referralIDs: [UUID!]!) {
    usSportReferralRewards: nbaReferralRewards(referralIDs: $referralIDs) {
      id
      state
      card {
        slug
        anyPositions
        rarityTyped
        anyPlayer {
          slug
          anyPositions
        }
        anyTeam {
          slug
          pictureUrl
        }
      }
    }
  }
` as TypedDocumentNode<
  NBAReferralRewardsQuery,
  NBAReferralRewardsQueryVariables
>;

export const CLAIM_NBA_REFERRAL_REWARDS_MUTATION = gql`
  mutation ClaimNBAReferralRewardsMutation($referralIDs: [UUID!]!) {
    claimNBAReferralRewards(referralIDs: $referralIDs) {
      id
      state
    }
  }
` as TypedDocumentNode<
  ClaimNBAReferralRewardsMutation,
  ClaimNBAReferralRewardsMutationVariables
>;

export const US_SPORTS_USER_FRAGMENTS = {
  nickname: gql`
    fragment Nickname_usSportsUser on User {
      nickname
      suspended
      slug
    }
  ` as TypedDocumentNode<Nickname_usSportsUser>,
};
