import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Arena = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0L3.46899e-05 16H16V2.76864e-05L0 0ZM6.22457 3.74998L4.47278 8.12498H6.66252L8.01282 4.75267L9.36313 8.12498H11.5529L9.80107 3.74998H6.22457ZM7.97632 8.12498H5.78659L4.0348 12.5H6.22453L7.97632 8.12498ZM8.04932 8.12498H10.2391L11.9909 12.5H9.80112L8.04932 8.12498Z"
      fill="currentColor"
    />
  </svg>
);
