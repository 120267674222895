import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { SBHorizontal } from 'atoms/layout/flex';
import { HexColor, RGBColor } from 'style/types';

const Root = styled(SBHorizontal)`
  --color-o: 1;
  align-items: flex-start;
  padding: var(--double-unit);
  &::before {
    position: absolute;
    z-index: -1;
    background-color: var(--color);
    inset: 0;
    clip-path: var(
      --clip-path,
      path('M433 45L169 54L174 60L-44 71L-123 -20H6008L600 40Z')
    );
    content: '';
  }
  &.withSubtitle::before {
    clip-path: var(
      --clip-path,
      path('M433 65L169 74L174 80L-44 91L-123 0H6008L600 60Z')
    );
  }
  &.rgbBackground::before {
    background: rgba(var(--rgb-color), var(--color-o));
  }
`;

type Props = {
  withSubtitle?: boolean;
  color?: HexColor;
  rgbColor?: RGBColor;
  colorOpacity?: number;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  className?: string;
};
export const BlockHeader = ({
  withSubtitle,
  leftNode,
  rightNode,
  rgbColor,
  color,
  colorOpacity,
  className,
}: Props) => {
  return (
    <Root
      className={classNames(className, {
        withSubtitle: !!withSubtitle,
        rgbBackground: !!rgbColor,
      })}
      style={
        {
          '--color': color,
          '--rgb-color': rgbColor,
          '--color-o': colorOpacity,
        } as CSSProperties
      }
    >
      {leftNode}
      {rightNode}
    </Root>
  );
};
