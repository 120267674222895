import { useCurrentUserContext } from 'contexts/currentUser';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';

export const useShowBottomBarNav = () => {
  const { currentUser } = useCurrentUserContext();
  const isTabletAndAbove = useIsTabletAndAbove();
  return !isTabletAndAbove && !!currentUser;
};

export default useShowBottomBarNav;
