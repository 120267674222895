import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  --top-gradient-grey: rgba(33, 34, 41, 0.7);
  --bottom-gradient-grey: var(
    --collection-bottom-background,
    var(--c-static-neutral-900)
  );

  position: relative;
  & > * {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background:
      linear-gradient(
        180deg,
        var(--top-gradient-grey) 12px,
        rgba(var(--c-static-rgb-neutral-900), 0) min(25%, 125px),
        var(--bottom-gradient-grey) min(60%, 300px)
      ),
      top / auto min(60%, 300px) no-repeat var(--background-url);
    filter: grayscale(1);
  }

  &.colored:before {
    filter: grayscale(0);
  }

  &.fullBanner:before {
    background:
      linear-gradient(
        180deg,
        var(--top-gradient-grey) 12px,
        transparent 40%,
        var(--bottom-gradient-grey) 98%
      ),
      top / auto 100% no-repeat var(--background-url);
  }
`;

const CollectionBackground = ({
  bannerPictureUrl,
  className,
  fullBanner,
  colored,
  children,
}: PropsWithChildren<{
  bannerPictureUrl: string | null;
  fullBanner?: boolean;
  colored?: boolean;
  className?: string;
}>) => {
  return (
    <Root
      style={
        { '--background-url': `url(${bannerPictureUrl})` } as CSSProperties
      }
      className={classNames(className, { fullBanner, colored })}
    >
      {children}
    </Root>
  );
};

export default CollectionBackground;
