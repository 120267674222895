import { useCallback, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { MLB } from 'constants/__generated__/routes';
import { NBA_WILDCARD, catchAll } from 'constants/routes';
import { Sport } from 'protos/events/shared/events';

export const useGetProtoSport = () => {
  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  return useCallback(() => {
    if (matchPath(NBA_WILDCARD, pathnameRef.current)) {
      return Sport.NBA;
    }
    if (matchPath(catchAll(MLB), pathnameRef.current)) {
      return Sport.BASEBALL;
    }
    return Sport.FOOTBALL;
  }, []);
};
