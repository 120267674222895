import { TypedDocumentNode, gql } from '@apollo/client';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import ScarcityIcon from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { ScarcityType } from '@sorare/core/src/components/card/ScarcityBall';
import { range } from '@sorare/core/src/lib/arrays';
import { withFragments } from '@sorare/core/src/lib/gql';

import {
  CardRequirements_LeaderboardRequirementsInterface,
  getCardScarcity_LeaderboardRequirementsInterface,
} from './__generated__/index.graphql';

const Wrapper = styled.div`
  display: flex;
  gap: var(--half-unit);
`;

const Slot = styled.div`
  position: relative;
  display: flex;
`;

const Checked = styled(Horizontal).attrs({ gap: 0, center: true })`
  font-size: 10px;
  --border-size: 1px;
  background-color: var(--c-neutral-100);
  color: var(--c-green-600);
  border-radius: 1em;
  --size: calc(1em + 2 * var(--border-size));
  height: var(--size);
  width: var(--size);
  position: absolute;
  left: 100%;
  top: 100%;
  margin: calc(-0.5 * var(--size)) 0 0 calc(-0.75 * var(--size));
`;

export type OwnedCardType = {
  [Rarity.common]?: number;
  [Rarity.limited]?: number;
  [Rarity.rare]?: number;
  [Rarity.super_rare]?: number;
  [Rarity.unique]?: number;
  [Rarity.custom_series]?: number;
};

type OwnedCards = (Rarity | undefined)[];

type Props = {
  nbCards: number;
  requirements: CardRequirements_LeaderboardRequirementsInterface;
  owned?: OwnedCards;
};

export const getCardScarcity = withFragments(
  (
    nbCards: number,
    requirements: CardRequirements_LeaderboardRequirementsInterface
  ): ScarcityType[] => {
    if (requirements.allowedRarities.length === 1) {
      return range(nbCards).map(() => requirements.allowedRarities[0]);
    }

    if (requirements.allowedRarities.length === 2) {
      if (requirements.minRarity !== null) {
        const secondRarity = requirements.allowedRarities.find(
          r => r !== requirements.minRarity!.rarity
        )!;
        return range(nbCards).map((_, index) =>
          index < requirements.minRarity!.minCount
            ? requirements.minRarity!.rarity
            : secondRarity
        );
      }
      return range(nbCards).map(() => requirements.allowedRarities[0]);
    }
    return range(nbCards).map(() => 'mix');
  },
  {
    LeaderboardRequirementsInterface: gql`
      fragment getCardScarcity_LeaderboardRequirementsInterface on LeaderboardRequirementsInterface {
        allowedRarities
        minRarity {
          minCount
          rarity
        }
      }
    ` as TypedDocumentNode<getCardScarcity_LeaderboardRequirementsInterface>,
  }
);

export const CardRequirements = ({
  nbCards,
  requirements,
  owned = new Array(nbCards).fill(undefined),
}: Props) => {
  const { formatMessage } = useIntl();
  const scarcities = getCardScarcity(nbCards, requirements);

  const ownedCounts = owned.reduce<OwnedCardType>((acc, rarity) => {
    if (rarity) {
      acc[rarity] = (acc[rarity] ?? 0) + 1;
    }
    return acc;
  }, {});

  const scarcitiesCounts = scarcities.reduce<{ [K in ScarcityType]?: number }>(
    (acc, rarity) => {
      if (rarity) {
        acc[rarity] = (acc[rarity] ?? 0) + 1;
      }
      return acc;
    },
    {}
  );

  const missingRarities = (
    Object.keys(scarcitiesCounts) as Array<keyof typeof scarcitiesCounts>
  ).flatMap(rarity => {
    const value =
      (scarcitiesCounts?.[rarity] ?? 0) -
      (ownedCounts?.[rarity as keyof OwnedCardType] ?? 0);
    return Array(Math.max(value, 0)).fill(rarity);
  }, {});

  const tmpMissingRarities = [...(missingRarities || [])];
  const template = owned.map(rarity => {
    if (rarity && scarcities.includes(rarity)) {
      const index = scarcities.indexOf(rarity);
      scarcities.splice(index, 1);
      return { rarity, owned: true };
    }
    return { rarity: tmpMissingRarities.shift(), owned: false };
  });

  return (
    <Wrapper
      title={
        missingRarities
          ? `${nbCards - missingRarities.length} / ${nbCards}`
          : ''
      }
    >
      {template.map((card, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Slot key={index}>
            <ScarcityIcon scarcity={card.rarity} />
            {card.owned && (
              <Checked
                aria-label={formatMessage({
                  id: 'card.owned',
                  defaultMessage: 'Owned',
                })}
              >
                <FontAwesomeIcon icon={faCircleCheck} />
              </Checked>
            )}
          </Slot>
        );
      })}
    </Wrapper>
  );
};

CardRequirements.fragments = {
  LeaderboardRequirementsInterface: gql`
    fragment CardRequirements_LeaderboardRequirementsInterface on LeaderboardRequirementsInterface {
      ...getCardScarcity_LeaderboardRequirementsInterface
    }
    ${getCardScarcity.fragments.LeaderboardRequirementsInterface}
  ` as TypedDocumentNode<CardRequirements_LeaderboardRequirementsInterface>,
};
