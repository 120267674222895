export const LiveEvent = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7442 1.75886C10.5673 1.58201 10.2894 1.58201 10.1252 1.75886C9.94836 1.9357 9.94836 2.2136 10.1252 2.37782C11.0852 3.35047 11.6158 4.62629 11.6158 6.00316C11.6158 7.3674 11.0852 8.65585 10.1126 9.61587C9.93573 9.79272 9.93573 10.0706 10.1126 10.2348C10.201 10.3233 10.3147 10.3612 10.4284 10.3612C10.5421 10.3612 10.6557 10.3233 10.7442 10.2348C11.881 9.09797 12.5 7.59478 12.5 5.99053C12.5 4.39891 11.881 2.88309 10.7442 1.75886ZM1.38423 6.00316C1.38423 4.62628 1.91477 3.35047 2.88742 2.39044C3.06427 2.2136 3.06427 1.9357 2.88742 1.77148C2.71058 1.59464 2.43268 1.59464 2.26846 1.77148C1.11896 2.88309 0.5 4.39891 0.5 6.00316C0.5 7.6074 1.11896 9.11059 2.25583 10.2475C2.34425 10.3359 2.45794 10.3738 2.57163 10.3738C2.68531 10.3738 2.799 10.3359 2.88742 10.2475C3.06427 10.0706 3.06427 9.79271 2.88742 9.6285C1.91477 8.65585 1.38423 7.3674 1.38423 6.00316ZM8.63505 3.24941C8.79927 3.07257 9.07717 3.07257 9.25402 3.24941C9.98666 3.98206 10.3909 4.96735 10.4035 5.99053C10.4035 7.02634 9.9993 7.99899 9.26665 8.73164C9.17822 8.82007 9.06454 8.85796 8.95085 8.85796C8.83716 8.85796 8.72348 8.82007 8.63505 8.73164C8.45821 8.56743 8.45821 8.28953 8.63505 8.11268C9.20349 7.54425 9.51929 6.78634 9.51929 5.99053C9.51929 5.19472 9.20349 4.43681 8.63505 3.86838C8.45821 3.70416 8.45821 3.42626 8.63505 3.24941ZM3.49278 6.00316C3.49278 5.19472 3.80857 4.44944 4.37701 3.881C4.55385 3.70416 4.55385 3.42626 4.37701 3.26204C4.20016 3.0852 3.92226 3.0852 3.75805 3.26204C3.0254 3.99469 2.62118 4.96734 2.62118 6.01579C2.62118 7.0516 3.03803 8.04952 3.77068 8.76953C3.8591 8.85796 3.97279 8.89585 4.08647 8.89585C4.20016 8.89585 4.31385 8.85796 4.40227 8.76953C4.57912 8.59269 4.56648 8.31479 4.40227 8.15057C3.80857 7.56951 3.49278 6.79896 3.49278 6.00316ZM5.37609 4.67682C5.37609 4.36103 5.72979 4.17155 5.99506 4.34839L7.97826 5.64948C8.21826 5.81369 8.21826 6.15475 7.97826 6.31896L5.99506 7.64531C5.72979 7.82216 5.37609 7.63268 5.37609 7.31688V4.67682Z"
      fill="currentColor"
    />
  </svg>
);
