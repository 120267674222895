import { ElementType, forwardRef } from 'react';
import {
  Link as RRDLink,
  NavLink as RRDNavLink,
  NavLinkProps as RRDNavLinkProps,
  useSearchParams,
} from 'react-router-dom';

export type LinkProps = Omit<RRDNavLinkProps, 'unstable_viewTransition'> & {
  viewTransition?: boolean;
  keepSearchParams?: boolean;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function ForwardedLink(
    { to, keepSearchParams, viewTransition, ...rest },
    ref
  ) {
    const isNavLink =
      'caseSensitive' in rest || 'end' in rest || viewTransition;
    const Root: ElementType = isNavLink ? RRDNavLink : RRDLink;
    const [searchParams] = useSearchParams();

    const getTo = () => {
      if (typeof to !== 'string') {
        return to;
      }
      const toWithSearchParams = keepSearchParams
        ? `${to}?${searchParams.toString()}`
        : to;
      return toWithSearchParams;
    };

    return (
      <Root
        ref={ref}
        // WARNING to=".." behaves differently than to={{pathname: '..'}}
        to={getTo()}
        unstable_viewTransition={viewTransition}
        {...rest}
      />
    );
  }
);
