import { useState } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';

import { MonetaryAmount, Sport } from '__generated__/globalTypes';
import { LabelM, Text16 } from 'atoms/typography';
import DiscountBannerWrapper from 'components/referral/DiscountBannerWrapper';
import { AUCTION_MARKET_URL, INSTANT_BUY_URL } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlContext } from 'contexts/intl';
import { useSportContext } from 'contexts/sport';
import { Lifecycle } from 'hooks/useLifecycle';
import { glossary, sportsLabelsMessages } from 'lib/glossary';
import { MonetaryAmountCurrency } from 'lib/monetaryAmount';

import { TermsDialog } from '../TermsDialog';
import { StyledLink, StyledSpan, TermsLink } from './ui';

type Props = {
  discountCredits: MessageDescriptor;
  discountCreditsUpTo: MessageDescriptor;
  endDate: Date;
  maxDiscount: MonetaryAmount;
  percentageDiscount: number;
  sport?: Sport | null;
};

const messages = defineMessages({
  expireIn: {
    id: 'ConversionCreditBanner.expireIn',
    defaultMessage: 'Expire {expiring}',
  },
});

export const DumbConversionCreditBanner = ({
  discountCredits,
  discountCreditsUpTo,
  endDate,
  maxDiscount,
  percentageDiscount,
  sport,
}: Props) => {
  const { currentUser, fiatCurrency } = useCurrentUserContext();
  const { sport: sportFromContext } = useSportContext();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { formatNumber, formatDistanceToNowStrict } = useIntlContext();

  const expiring = formatDistanceToNowStrict(endDate);

  const currencyCode = fiatCurrency.code.toLowerCase() as Exclude<
    MonetaryAmountCurrency,
    'wei'
  >;
  const maxDiscountInFiat =
    ((currencyCode in maxDiscount && maxDiscount?.[currencyCode]) || 0) / 100;

  const actualSport =
    sport ||
    sportFromContext ||
    (currentUser?.userSettings?.lifecycle as Lifecycle)?.lastVisitedSport;

  if (!actualSport) return null;

  const text = (
    <Text16 color="var(--c-static-neutral-100)">
      <FormattedMessage
        {...(percentageDiscount === 1 ? discountCredits : discountCreditsUpTo)}
        values={{
          span: (...chunks) => <StyledSpan>{chunks}</StyledSpan>,
          link1: (...chunks) => (
            <StyledLink to={AUCTION_MARKET_URL[actualSport]}>
              {chunks}
            </StyledLink>
          ),
          link2: (...chunks) => (
            <StyledLink to={INSTANT_BUY_URL[actualSport]}>{chunks}</StyledLink>
          ),
          percentageDiscount: percentageDiscount * 100,
          maxDiscount: formatNumber(+maxDiscountInFiat, {
            style: 'currency',
            currency: fiatCurrency.code,
          }),
          sport: <FormattedMessage {...sportsLabelsMessages[actualSport]} />,
        }}
      />
    </Text16>
  );

  return (
    <DiscountBannerWrapper
      fullWidth
      rightNode={
        <LabelM color="var(--c-static-neutral-500)">
          <FormattedMessage {...messages.expireIn} values={{ expiring }} />{' '}
          <TermsLink type="button" onClick={() => setOpenDialog(true)}>
            (
            <FormattedMessage {...glossary.termsApply} />)
          </TermsLink>
        </LabelM>
      }
    >
      {text}
      {openDialog && <TermsDialog onClose={() => setOpenDialog(false)} />}
    </DiscountBannerWrapper>
  );
};
