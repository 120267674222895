import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  h2hTitle: {
    id: 'BridgeToRivalsAfterDraftDialog.h2h.title',
    defaultMessage: 'Fancy<br></br>a 1v1?',
  },
  h2hSubtitle: {
    id: 'BridgeToRivalsAfterDraftDialog.h2h.subtitle',
    defaultMessage: 'Test yourself against the best managers in the world.',
  },
  rewardTitle: {
    id: 'BridgeToRivalsAfterDraftDialog.reward.title',
    defaultMessage: 'Get<br></br>Rewarded',
  },
  rewardSubtitle: {
    id: 'BridgeToRivalsAfterDraftDialog.reward.subtitle',
    defaultMessage:
      'Fight for promotion in a weekly leaderboard and win reward boxes!',
  },
  promotionTitle: {
    id: 'BridgeToRivalsAfterDraftDialog.promotion.title',
    defaultMessage: 'Fight for<br></br>Promotion',
  },
  promotionSubtitle: {
    id: 'BridgeToRivalsAfterDraftDialog.promotion.subtitle',
    defaultMessage:
      'Climb the leaderboards every week and get promoted to the top divisions!"',
  },
});
