import { RefObject, useEffect, useState } from 'react';

const useIsOverflowing = (containerRef: RefObject<HTMLDivElement>) => {
  const [, updateState] = useState<unknown>();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleResize = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      const updatedValue = scrollWidth > clientWidth;
      if (updatedValue !== isOverflowing) {
        setIsOverflowing(updatedValue);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, []);

  handleResize();

  return isOverflowing;
};

export default useIsOverflowing;
