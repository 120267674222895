import styled, { css } from 'styled-components';

import { LinkOther, LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { text14 } from '@sorare/core/src/atoms/typography';
import ButtonWithConfirmDialog from '@sorare/core/src/components/form/ButtonWithConfirmDialog';

const button = css`
  background: var(--c-neutral-100);
  border-radius: 16px;
  ${text14}
  font-weight: var(--t-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-neutral-1000);
  height: var(--quadruple-unit);
  gap: var(--unit);
  &:disabled {
    cursor: not-allowed;
  }
  &:not(:disabled, .primary):hover,
  &:not(:disabled, .primary):focus {
    opacity: 0.7;
    color: var(--c-neutral-1000);
  }
  &.primary {
    background: var(--c-brand-600);
    color: var(--c-static-neutral-100);
    border: unset;
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: var(--c-neutral-1000);
      background: linear-gradient(
          rgba(var(--c-rgb-neutral-1000), 0.1),
          rgba(var(--c-rgb-neutral-1000), 0.2)
        )
        var(--c-brand-600);
    }
  }
  &.tertiary {
    background: rgba(var(--c-rgb-neutral-100), 0.8);
    color: var(--c-neutral-1000);
    border: 2px solid rgba(var(--c-rgb-neutral-400), 0.9);
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: rgba(var(--c-rgb-neutral-400), 0.9);
      background: linear-gradient(
          rgba(var(--c-rgb-neutral-1000), 0.05),
          rgba(var(--c-rgb-neutral-1000), 0.1)
        )
        var(--c-neutral-400);
    }
  }
`;

export const LineupActionButton = styled(LinkOther)`
  ${button}
`;

export const LineupActionButtonOverlay = styled(LinkOverlay)`
  ${button}
`;

export const LineupActionButtonWithConfirmDialog = styled(
  ButtonWithConfirmDialog
)`
  ${button}
`;
