export const MLB_CLUB_SHOP = "/mlb/club-shop";
export const MLB_COMPOSE_TEAM_LEADERBOARDSLUG = "/mlb/compose-team/:leaderboardSlug";
export const MLB = "/mlb";
export const MLB_LEAGUES_LEAGUESLUG = "/mlb/leagues/:leagueSlug";
export const MLB_MARKET = "/mlb/market";
export const MLB_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG = "/mlb/my-club/:slug/collections/:collectionSlug";
export const MLB_MY_CLUB_SLUG_HISTORY_FIXTURE = "/mlb/my-club/:slug/history/:fixture";
export const MLB_MY_CLUB_SLUG_HISTORY = "/mlb/my-club/:slug/history";
export const MLB_NO_CARD_ROUTE = "/mlb/no-card-route";
export const MLB_PLAY = "/mlb/play";
export const MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP = "/mlb/play/pro/:fixture/chase/:userGroup";
export const MLB_PLAY_PRO_FIXTURE_CHASE = "/mlb/play/pro/:fixture/chase";
export const MLB_PLAY_PRO_FIXTURE_GAMES = "/mlb/play/pro/:fixture/games";
export const MLB_PLAY_PRO_FIXTURE = "/mlb/play/pro/:fixture";
export const MLB_PLAY_PRO = "/mlb/play/pro";
export const MLB_PLAY_REWARDS_FIXTURE = "/mlb/play/rewards/:fixture";
export const MLB_PLAYERS_SLUG = "/mlb/players/:slug";
export const MLB_REWARDS_FIXTURE = "/mlb/rewards/:fixture";
export const MLB_TEAMS_SLUG = "/mlb/teams/:slug";
export const FOOTBALL_CLUB_SHOP = "/football/club-shop";
export const FOOTBALL_CLUBS_SLUG = "/football/clubs/:slug";
export const FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG = "/football/compose-team/:leaderboardSlug";
export const FOOTBALL_COUNTRIES_COUNTRYCODE = "/football/countries/:countryCode";
export const FOOTBALL = "/football";
export const FOOTBALL_LEAGUES_SLUG = "/football/leagues/:slug";
export const FOOTBALL_MARKET = "/football/market";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION = "/football/my-club/:slug/collections/:collection";
export const FOOTBALL_MY_CLUB_SLUG_HISTORY_FIXTURE = "/football/my-club/:slug/history/:fixture";
export const FOOTBALL_MY_CLUB_SLUG_HISTORY = "/football/my-club/:slug/history";
export const FOOTBALL_NO_CARD_ROUTE = "/football/no-card-route";
export const FOOTBALL_PLAY_INVENTORY = "/football/play/inventory";
export const FOOTBALL_PLAY = "/football/play";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION = "/football/play/pro/:fixture/enter/:seasonality/:competition";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY = "/football/play/pro/:fixture/enter/:seasonality";
export const FOOTBALL_PLAY_PRO_FIXTURE = "/football/play/pro/:fixture";
export const FOOTBALL_PLAY_PRO = "/football/play/pro";
export const FOOTBALL_PLAY_REWARDS_FIXTURE = "/football/play/rewards/:fixture";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG = "/football/play/rivals/leaderboard/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD = "/football/play/rivals/leaderboard";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG = "/football/play/rivals/leagues/:slug";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_LEADERBOARDS = "/football/play/rivals/leagues/:slug/leaderboards";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MATCHES_MATCH = "/football/play/rivals/leagues/:slug/matches/:match";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MATCHES = "/football/play/rivals/leagues/:slug/matches";
export const FOOTBALL_PLAY_RIVALS_LEAGUES = "/football/play/rivals/leagues";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_LEADERBOARD = "/football/play/rivals/leagues/leaderboard";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD = "/football/play/rivals/squad-leaderboard";
export const FOOTBALL_PLAY_RIVALS = "/football/play/rivals";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG = "/football/play/rivals/matches/:date/:slug";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE = "/football/play/rivals/matches/:date";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG = "/football/play/rivals/my-lineups/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_CREATE = "/football/play/rivals/my-squad/create";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD = "/football/play/rivals/my-squad";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug";
export const FOOTBALL_PLAY_RIVALS_RIVALRIES_SELECTEDUSERSLUG = "/football/play/rivals/rivalries/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_RIVALRIES = "/football/play/rivals/rivalries";
export const FOOTBALL_PLAY_RIVALS_SQUADS_INVITE_JOINSECRET = "/football/play/rivals/squads/invite/:joinSecret";
export const FOOTBALL_PLAYERS_SLUG = "/football/players/:slug";
export const FOOTBALL_REWARDS_FIXTURE = "/football/rewards/:fixture";
export const FOOTBALL_REWARDS_ESSENCE = "/football/rewards/essence";
export const HELP = "/help";
export const NBA_COMPOSE_TEAM_LEADERBOARDSLUG = "/nba/compose-team/:leaderboardSlug";
export const NBA_GAMES_ID = "/nba/games/:id";
export const NBA = "/nba";
export const NBA_LEAGUES_LEAGUESLUG = "/nba/leagues/:leagueSlug";
export const NBA_LEAGUES = "/nba/leagues";
export const NBA_LIVE = "/nba/live";
export const NBA_LIVE_PRO_COMPETITIONS_LEADERBOARDSLUG = "/nba/live/pro/competitions/:leaderboardSlug";
export const NBA_LIVE_PRO_COMPETITIONS_LEADERBOARDSLUG_LEADERBOARD = "/nba/live/pro/competitions/:leaderboardSlug/leaderboard";
export const NBA_LIVE_PRO_COMPETITIONS = "/nba/live/pro/competitions";
export const NBA_LIVE_PRO_GAMES = "/nba/live/pro/games";
export const NBA_LIVE_PRO = "/nba/live/pro";
export const NBA_MARKET = "/nba/market";
export const NBA_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG = "/nba/my-club/:slug/collections/:collectionSlug";
export const NBA_MY_CLUB_SLUG = "/nba/my-club/:slug";
export const NBA_NO_CARD_ROUTE = "/nba/no-card-route";
export const NBA_PLAY_POSTSEASON_FIXTURE = "/nba/play/postseason/:fixture";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG = "/nba/play/pro/competition-details/:leaderboardSlug";
export const NBA_PLAY_PRO_GAMES = "/nba/play/pro/games";
export const NBA_PLAY_PRO = "/nba/play/pro";
export const NBA_PLAY_PRO_MY_LINEUPS_ID = "/nba/play/pro/my-lineups/:id";
export const NBA_PLAY_PRO_MY_LINEUPS = "/nba/play/pro/my-lineups";
export const NBA_PLAYERS_SLUG = "/nba/players/:slug";
export const NBA_TEAMS_SLUG = "/nba/teams/:slug";
export const SPORT_MY_OWN_CLUB = "/$sport/my-own-club";
export const CONTENT_CREATORS = "/content-creators";
export const INVITE = "/invite";
export const LICENSED_PARTNERS = "/licensed-partners";
export const SETTINGS = "/settings";
export const MLB_ALL_STAR_COLLECTION_EVENT = "/mlb/all-star-collection-event";
export const MLB_CARDS_SLUG = "/mlb/cards/:slug";
export const MLB_CLUB_SHOP_MERCH = "/mlb/club-shop/merch";
export const MLB_CLUB_SHOP_MY_ITEMS = "/mlb/club-shop/my-items";
export const MLB_CLUB_SHOP_UTILITY = "/mlb/club-shop/utility";
export const MLB_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID = "/mlb/compose-team/:leaderboardSlug/:lineupId";
export const MLB_DRAFT = "/mlb/draft";
export const MLB_GALLERY_SLUG = "/mlb/gallery/:slug";
export const MLB_HOWTOPLAY = "/mlb/howToPlay";
export const MLB_INVITE = "/mlb/invite";
export const MLB_LEAGUES_LEAGUESLUG_MEMBERS = "/mlb/leagues/:leagueSlug/members";
export const MLB_LEAGUES_JOIN_LEAGUESLUG = "/mlb/leagues/join/:leagueSlug";
export const MLB_MARKET_INSTANT_BUY = "/mlb/market/instant-buy";
export const MLB_MARKET_PRIMARY = "/mlb/market/primary";
export const MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY = "/mlb/market/secondary/:playerSlug/:rarity";
export const MLB_MARKET_SECONDARY = "/mlb/market/secondary";
export const MLB_MY_CLUB_SLUG_CARDS = "/mlb/my-club/:slug/cards";
export const MLB_MY_CLUB_SLUG_CHALLENGES = "/mlb/my-club/:slug/challenges";
export const MLB_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG_LEADERBOARD = "/mlb/my-club/:slug/collections/:collectionSlug/leaderboard";
export const MLB_MY_CLUB_SLUG_COLLECTIONS = "/mlb/my-club/:slug/collections";
export const MLB_MY_CLUB_SLUG_EDIT = "/mlb/my-club/:slug/edit";
export const MLB_MY_CLUB_SLUG = "/mlb/my-club/:slug";
export const MLB_MY_CLUB = "/mlb/my-club";
export const MLB_NO_CARD_ROUTE_ACCEPT = "/mlb/no-card-route/accept";
export const MLB_NO_CARD_ROUTE_CONFIRM = "/mlb/no-card-route/confirm";
export const MLB_NO_CARD_ROUTE_DECLINE = "/mlb/no-card-route/decline";
export const MLB_NO_CARD_ROUTE_ELIGIBLE_LEADERBOARDS = "/mlb/no-card-route/eligible-leaderboards";
export const MLB_NO_CARD_ROUTE_REQUEST = "/mlb/no-card-route/request";
export const MLB_PLAY_PRO_FIXTURE_COMPETITION = "/mlb/play/pro/:fixture/:competition";
export const MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP_USER = "/mlb/play/pro/:fixture/chase/:userGroup/:user";
export const MLB_PLAY_PRO_FIXTURE_COMPETITIONS_LEADERBOARD = "/mlb/play/pro/:fixture/competitions/:leaderboard";
export const MLB_PLAY_PRO_FIXTURE_COMPETITIONS = "/mlb/play/pro/:fixture/competitions";
export const MLB_PLAY_PRO_FIXTURE_GAMES_ID = "/mlb/play/pro/:fixture/games/:id";
export const MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP = "/mlb/play/pro/:fixture/my-lineups/:lineup";
export const MLB_PLAY_PRO_FIXTURE_MY_LINEUPS = "/mlb/play/pro/:fixture/my-lineups";
export const MLB_PLAY_PRO_CHASE_USERGROUP = "/mlb/play/pro/chase/:userGroup";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD = "/mlb/play/pro/leaderboard/:leaderboard";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/mlb/play/pro/leaderboard/:leaderboard/prize-pool/:contender";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/mlb/play/pro/leaderboard/:leaderboard/prize-pool";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/mlb/play/pro/leaderboard/:leaderboard/rankings/:contender";
export const MLB_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = "/mlb/play/pro/leaderboard/:leaderboard/rankings";
export const MLB_PLAY_PRO_LEADERBOARD = "/mlb/play/pro/leaderboard";
export const MLB_PLAY_PRO_LINEUPS_ID = "/mlb/play/pro/lineups/:id";
export const MLB_PLAY_REWARDS = "/mlb/play/rewards";
export const MLB_PLAYERS_SLUG_CARDS = "/mlb/players/:slug/cards";
export const MLB_PLAYERS_SLUG_GAMES = "/mlb/players/:slug/games";
export const MLB_PLAYERS_SLUG_TRANSACTIONS = "/mlb/players/:slug/transactions";
export const MLB_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/mlb/rewards/:fixture/:league/:quality/:rarity";
export const MLB_TEAMS_SLUG_CARDS = "/mlb/teams/:slug/cards";
export const MLB_TEAMS_SLUG_PLAYERS = "/mlb/teams/:slug/players";
export const BLOG_SLUG = "/blog/$slug";
export const BLOG_P_SLUG = "/blog/p/:slug";
export const BLOG = "/blog";
export const FOOTBALL_AFFILIATES = "/football/affiliates";
export const FOOTBALL_BUNDESLIGA = "/football/bundesliga";
export const FOOTBALL_BUNDLED_AUCTIONS_ID = "/football/bundled-auctions/:id";
export const FOOTBALL_CARDS_SLUG = "/football/cards/:slug";
export const FOOTBALL_CLUB_SHOP_TAB = "/football/club-shop/:tab";
export const FOOTBALL_CLUBS_SLUG_CARDS = "/football/clubs/:slug/cards";
export const FOOTBALL_CLUBS_SLUG_FIXTURES = "/football/clubs/:slug/fixtures";
export const FOOTBALL_CLUBS_SLUG_STANDINGS = "/football/clubs/:slug/standings";
export const FOOTBALL_CLUBS_SLUG_TEAM = "/football/clubs/:slug/team";
export const FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_MANAGERTEAMID = "/football/compose-team/:leaderboardSlug/:managerTeamId";
export const FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID = "/football/compose-team/:leaderboardSlug/lineup/:lineupId";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS = "/football/countries/:countryCode/cards";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_FIXTURES = "/football/countries/:countryCode/fixtures";
export const FOOTBALL_COUNTRIES_COUNTRYCODE_TEAM = "/football/countries/:countryCode/team";
export const FOOTBALL_EPL = "/football/epl";
export const FOOTBALL_INVITE_E_JOINSECRET = "/football/invite/e/:joinSecret";
export const FOOTBALL_INVITE = "/football/invite";
export const FOOTBALL_LALIGA = "/football/laliga";
export const FOOTBALL_LALIGA_SANTANDER = "/football/laliga-santander";
export const FOOTBALL_LEAGUES_SLUG_CARDS = "/football/leagues/:slug/cards";
export const FOOTBALL_LEAGUES_SLUG_FIXTURES = "/football/leagues/:slug/fixtures";
export const FOOTBALL_MAJOR_LEAGUE_SOCCER = "/football/major-league-soccer";
export const FOOTBALL_MARKET_INSTANT_BUY = "/football/market/instant-buy";
export const FOOTBALL_MARKET_MANAGER_SALES_PLAYERSLUG_RARITY = "/football/market/manager-sales/:playerSlug/:rarity";
export const FOOTBALL_MARKET_MANAGER_SALES = "/football/market/manager-sales";
export const FOOTBALL_MARKET_MOBILE_INSTANT_BUY = "/football/market/mobile-instant-buy";
export const FOOTBALL_MARKET_MOBILE_NEW_SIGNINGS = "/football/market/mobile-new-signings";
export const FOOTBALL_MARKET_NEW_SIGNINGS = "/football/market/new-signings";
export const FOOTBALL_MLS = "/football/mls";
export const FOOTBALL_MY_CLUB_SLUG_CARDS = "/football/my-club/:slug/cards";
export const FOOTBALL_MY_CLUB_SLUG_CHALLENGES = "/football/my-club/:slug/challenges";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_CARDS = "/football/my-club/:slug/collections/:collection/cards";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_LEADERBOARD = "/football/my-club/:slug/collections/:collection/leaderboard";
export const FOOTBALL_MY_CLUB_SLUG_COLLECTIONS = "/football/my-club/:slug/collections";
export const FOOTBALL_MY_CLUB_SLUG_EDIT = "/football/my-club/:slug/edit";
export const FOOTBALL_MY_CLUB_SLUG = "/football/my-club/:slug";
export const FOOTBALL_MY_CLUB = "/football/my-club";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_ACCEPT = "/football/no-card-route/:so5FixtureId/:so5LineupId/accept";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CANCEL = "/football/no-card-route/:so5FixtureId/:so5LineupId/cancel";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_CONFIRM = "/football/no-card-route/:so5FixtureId/:so5LineupId/confirm";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID_SO5LINEUPID_LEADERBOARDS = "/football/no-card-route/:so5FixtureId/:so5LineupId/leaderboards";
export const FOOTBALL_NO_CARD_ROUTE_SO5FIXTUREID = "/football/no-card-route/:so5FixtureId";
export const FOOTBALL_PL = "/football/pl";
export const FOOTBALL_PLAY_INVENTORY_BOXES_TYPE = "/football/play/inventory/boxes/:type";
export const FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE = "/football/play/inventory/revealable-cards/:type";
export const FOOTBALL_PLAY_INVENTORY_SHARDS_TYPE = "/football/play/inventory/shards/:type";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES = "/football/play/pro/:fixture/enter/:seasonality/:competition/:trck/games";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK = "/football/play/pro/:fixture/enter/:seasonality/:competition/:trck";
export const FOOTBALL_PLAY_PRO_FIXTURE_ENTER = "/football/play/pro/:fixture/enter";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_ADD = "/football/play/pro/:fixture/:competition/add";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER = "/football/play/pro/:fixture/:competition/contender/:contender";
export const FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION = "/football/play/pro/:fixture/:competition";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD = "/football/play/pro/leaderboard/:leaderboard";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER = "/football/play/pro/leaderboard/:leaderboard/prize-pool/:contender";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL = "/football/play/pro/leaderboard/:leaderboard/prize-pool";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER = "/football/play/pro/leaderboard/:leaderboard/rankings/:contender";
export const FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS = "/football/play/pro/leaderboard/:leaderboard/rankings";
export const FOOTBALL_PLAY_PRO_LEADERBOARD = "/football/play/pro/leaderboard";
export const FOOTBALL_PLAY_PRO_LINEUPS_ID = "/football/play/pro/lineups/:id";
export const FOOTBALL_PLAY_PRO_MY_LINEUPS_ID = "/football/play/pro/my-lineups/:id";
export const FOOTBALL_PLAY_PRO_ONBOARDING_MOBILE = "/football/play/pro/onboarding/mobile";
export const FOOTBALL_PLAY_PRO_ONBOARDING = "/football/play/pro/onboarding";
export const FOOTBALL_PLAY_REWARDS = "/football/play/rewards";
export const FOOTBALL_PLAY_RIVALS_INSTANT_BUY_SLUG = "/football/play/rivals/instant-buy/:slug";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_ARENA = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/arena";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_BETWEEN_YOU = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/between-you";
export const FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_MATCHES = "/football/play/rivals/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/matches";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_CREATE = "/football/play/rivals/leagues/create";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_SETTINGS_AVATAR = "/football/play/rivals/leagues/:slug/settings/avatar";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_INVITATION_CODE = "/football/play/rivals/leagues/:slug/invitation-code";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_LEADERBOARDS_USER = "/football/play/rivals/leagues/:slug/leaderboards/:user";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MATCHES_MATCH_LINEUPS = "/football/play/rivals/leagues/:slug/matches/:match/lineups";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MEMBERS = "/football/play/rivals/leagues/:slug/members";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_ONBOARDING = "/football/play/rivals/leagues/:slug/onboarding";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_SETTINGS = "/football/play/rivals/leagues/:slug/settings";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_INVITE_JOINSECRET_USERSLUG = "/football/play/rivals/leagues/invite/:joinSecret/:userSlug";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_INVITE_JOINSECRET = "/football/play/rivals/leagues/invite/:joinSecret";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_JOIN_JOINSECRET = "/football/play/rivals/leagues/join/:joinSecret";
export const FOOTBALL_PLAY_RIVALS_LEAGUES_LEADERBOARD_PRIZE_POOL = "/football/play/rivals/leagues/leaderboard/prize-pool";
export const FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG = "/football/play/rivals/squad-leaderboard/:seasonSlug/:userSlug/:selectedUserSlug";
export const FOOTBALL_PLAY_RIVALS_ARENA = "/football/play/rivals/arena";
export const FOOTBALL_PLAY_RIVALS_DRAFT_SLUG = "/football/play/rivals/draft/:slug";
export const FOOTBALL_PLAY_RIVALS_DRAFT_SUBSTITUTES_SLUG = "/football/play/rivals/draft/substitutes/:slug";
export const FOOTBALL_PLAY_RIVALS_FEATURED_INVITE_INVITEID = "/football/play/rivals/featured-invite/:inviteId";
export const FOOTBALL_PLAY_RIVALS_GAMES_ID = "/football/play/rivals/games/:id";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_ARENA = "/football/play/rivals/matches/:date/:slug/arena";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_CHALLENGERS = "/football/play/rivals/matches/:date/:slug/challengers";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_FRIENDLIES = "/football/play/rivals/matches/:date/:slug/friendlies";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_LINEUPS = "/football/play/rivals/matches/:date/:slug/lineups";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_MY_SQUAD = "/football/play/rivals/matches/:date/:slug/my-squad";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_OVERVIEW = "/football/play/rivals/matches/:date/:slug/overview";
export const FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_SETUP = "/football/play/rivals/matches/:date/:slug/setup";
export const FOOTBALL_PLAY_RIVALS_MATCHES = "/football/play/rivals/matches";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/arena";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_LINEUPS = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/lineups";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_MY_SQUAD = "/football/play/rivals/my-lineups/:seasonSlug/:gameSlug/my-squad";
export const FOOTBALL_PLAY_RIVALS_MY_LINEUPS = "/football/play/rivals/my-lineups";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_ARENA = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/arena";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_BETWEEN_YOU = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/between-you";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG_SELECTEDUSERSLUG_MATCHES = "/football/play/rivals/my-squad/leaderboard/:seasonSlug/:userSlug/:selectedUserSlug/matches";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG = "/football/play/rivals/my-squad/leaderboard/:seasonSlug";
export const FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD = "/football/play/rivals/my-squad/leaderboard";
export const FOOTBALL_PLAY_RIVALS_RIVALRIES_SELECTEDUSERSLUG_ARENA = "/football/play/rivals/rivalries/:selectedUserSlug/arena";
export const FOOTBALL_PLAY_RIVALS_RIVALRIES_SELECTEDUSERSLUG_BETWEEN_YOU = "/football/play/rivals/rivalries/:selectedUserSlug/between-you";
export const FOOTBALL_PLAY_RIVALS_RIVALRIES_SELECTEDUSERSLUG_MATCHES = "/football/play/rivals/rivalries/:selectedUserSlug/matches";
export const FOOTBALL_PLAY_RIVALS_SQUADS_INVITE_JOINSECRET_USERSLUG = "/football/play/rivals/squads/invite/:joinSecret/:userSlug";
export const FOOTBALL_PLAYERS_SLUG_CARDS = "/football/players/:slug/cards";
export const FOOTBALL_PLAYERS_SLUG_FIXTURES = "/football/players/:slug/fixtures";
export const FOOTBALL_PLAYERS_SLUG_TRANSACTIONS = "/football/players/:slug/transactions";
export const FOOTBALL_PREMIER_LEAGUE = "/football/premier-league";
export const FOOTBALL_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY = "/football/rewards/:fixture/:league/:quality/:rarity";
export const FOOTBALL_REWARDS_ESSENCE_QUALITY_RARITY = "/football/rewards/essence/:quality/:rarity";
export const FOOTBALL_RIVALS_LEAGUE_PRIZE_POOL = "/football/rivals-league-prize-pool";
export const FOOTBALL_RIVALS_INVITES_CODE = "/football/rivals/invites/:code";
export const FOOTBALL_SERIE_A = "/football/serie-a";
export const HELP_A_ARTICLEID_SLUG = "/help/a/:articleId/:slug";
export const HELP_C_CATEGORYID_SLUG = "/help/c/:categoryId/:slug";
export const HELP_S_SECTIONID_SLUG = "/help/s/:sectionId/:slug";
export const NBA_CARDS_SLUG = "/nba/cards/:slug";
export const NBA_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUPID = "/nba/compose-team/:leaderboardSlug/:lineupId";
export const NBA_GALLERY_SLUG = "/nba/gallery/:slug";
export const NBA_INVITE = "/nba/invite";
export const NBA_LEAGUES_LEAGUESLUG_LEADERBOARDS = "/nba/leagues/:leagueSlug/leaderboards";
export const NBA_LEAGUES_LEAGUESLUG_MEMBERS = "/nba/leagues/:leagueSlug/members";
export const NBA_LEAGUES_JOIN_LEAGUESLUG = "/nba/leagues/join/:leagueSlug";
export const NBA_LIVE_PRO_COMPETITIONS_LEADERBOARDSLUG_LEADERBOARD_FULL = "/nba/live/pro/competitions/:leaderboardSlug/leaderboard/full";
export const NBA_LIVE_PRO_COMPETITIONS_LEADERBOARDSLUG_TEAM = "/nba/live/pro/competitions/:leaderboardSlug/team";
export const NBA_LIVE_PRO_GAMES_ID = "/nba/live/pro/games/:id";
export const NBA_LOBBY = "/nba/lobby";
export const NBA_MARKET_INSTANT_BUY = "/nba/market/instant-buy";
export const NBA_MARKET_MOBILE_INSTANT_BUY = "/nba/market/mobile-instant-buy";
export const NBA_MARKET_PRIMARY = "/nba/market/primary";
export const NBA_MARKET_SECONDARY_PLAYERSLUG_RARITY = "/nba/market/secondary/:playerSlug/:rarity";
export const NBA_MARKET_SECONDARY = "/nba/market/secondary";
export const NBA_MY_CLUB_SLUG_CARDS = "/nba/my-club/:slug/cards";
export const NBA_MY_CLUB_SLUG_CHALLENGES = "/nba/my-club/:slug/challenges";
export const NBA_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG_LEADERBOARD = "/nba/my-club/:slug/collections/:collectionSlug/leaderboard";
export const NBA_MY_CLUB_SLUG_COLLECTIONS = "/nba/my-club/:slug/collections";
export const NBA_MY_CLUB_SLUG_HISTORY = "/nba/my-club/:slug/history";
export const NBA_MY_CLUB = "/nba/my-club";
export const NBA_NO_CARD_ROUTE_ACCEPT = "/nba/no-card-route/accept";
export const NBA_NO_CARD_ROUTE_CONFIRM = "/nba/no-card-route/confirm";
export const NBA_NO_CARD_ROUTE_DECLINE = "/nba/no-card-route/decline";
export const NBA_NO_CARD_ROUTE_ELIGIBLE_LEADERBOARDS = "/nba/no-card-route/eligible-leaderboards";
export const NBA_NO_CARD_ROUTE_REQUEST = "/nba/no-card-route/request";
export const NBA_ONBOARDING = "/nba/onboarding";
export const NBA_PLAY = "/nba/play";
export const NBA_PLAY_POSTSEASON_FIXTURE_COMPETITION = "/nba/play/postseason/:fixture/:competition";
export const NBA_PLAY_POSTSEASON_FIXTURE_COMPETITION_REWARDS = "/nba/play/postseason/:fixture/:competition/rewards";
export const NBA_PLAY_POSTSEASON_FIXTURE_TRAINING = "/nba/play/postseason/:fixture/training";
export const NBA_PLAY_POSTSEASON = "/nba/play/postseason";
export const NBA_PLAY_PRO_TRAINING_TEAMS = "/nba/play/pro/training-teams";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_GAMEPLAY = "/nba/play/pro/competition-details/:leaderboardSlug/gameplay";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEADERBOARD = "/nba/play/pro/competition-details/:leaderboardSlug/leaderboard";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG_GAMEPLAY = "/nba/play/pro/competition-details/:leaderboardSlug/league/:leagueSlug/gameplay";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG_LEADERBOARD = "/nba/play/pro/competition-details/:leaderboardSlug/league/:leagueSlug/leaderboard";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG_LINEUPS_LINEUPID = "/nba/play/pro/competition-details/:leaderboardSlug/league/:leagueSlug/lineups/:lineupId";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LEAGUE_LEAGUESLUG = "/nba/play/pro/competition-details/:leaderboardSlug/league/:leagueSlug";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_LINEUPS_LINEUPID = "/nba/play/pro/competition-details/:leaderboardSlug/lineups/:lineupId";
export const NBA_PLAY_PRO_COMPETITION_DETAILS_LEADERBOARDSLUG_REWARDS = "/nba/play/pro/competition-details/:leaderboardSlug/rewards";
export const NBA_PLAY_PRO_GAMES_ID = "/nba/play/pro/games/:id";
export const NBA_PLAY_PRO_MY_LINEUPS_ID_LEADERBOARD_SLUG = "/nba/play/pro/my-lineups/:id/leaderboard/:slug";
export const NBA_PLAY_PRO_MY_LINEUPS_ID_TEAM = "/nba/play/pro/my-lineups/:id/team";
export const NBA_PLAY_REWARDS = "/nba/play/rewards";
export const NBA_PLAYERS_SLUG_CARDS = "/nba/players/:slug/cards";
export const NBA_PLAYERS_SLUG_GAMES = "/nba/players/:slug/games";
export const NBA_PLAYERS_SLUG_TRANSACTIONS = "/nba/players/:slug/transactions";
export const NBA_SHARE_USERSLUG_LINEUPS_LINEUPID_SHARETEMPLATE = "/nba/share/:userSlug/lineups/:lineupId/:shareTemplate";
export const NBA_SHARE_USERSLUG_LINEUPS_LINEUPID_LEAGUES_LEAGUESLUG_SHARETEMPLATE = "/nba/share/:userSlug/lineups/:lineupId/leagues/:leagueSlug/:shareTemplate";
export const NBA_SHARE_USERSLUG_LINEUPS_LINEUPID_LEAGUES_LEAGUESLUG = "/nba/share/:userSlug/lineups/:lineupId/leagues/:leagueSlug";
export const NBA_SHARE_USERSLUG_LINEUPS_LINEUPID = "/nba/share/:userSlug/lineups/:lineupId";
export const NBA_TEAMS_SLUG_CARDS = "/nba/teams/:slug/cards";
export const NBA_TEAMS_SLUG_PLAYERS = "/nba/teams/:slug/players";
export const ACCEPT_INVITATION = "/accept-invitation";
export const ACTIVITY_NEWS_ID = "/activity/news/:id";
export const ACTIVITY_NEWS = "/activity/news";
export const AFFILIATE_PROGRAM = "/affiliate-program";
export const CAREERS = "/careers";
export const CLAIM_STRK = "/claim-strk";
export const CONFIRM_EMAIL = "/confirm-email";
export const COOKIE_POLICY = "/cookie-policy";
export const COVERAGE = "/coverage";
export const DEBUG_DEVICE = "/debug-device";
export const FAQ = "/faq";
export const GAME_RULES = "/game-rules";
export const LEGAL_NOTICE = "/legal-notice";
export const LICENSED_PARTNERS_SPORT_TAB = "/licensed-partners/:sport/:tab";
export const LICENSED_PARTNERS_SPORT = "/licensed-partners/:sport";
export const MARKETPLACE_TERMS = "/marketplace-terms";
export const NETWORK_SLUG = "/network/:slug";
export const LANDING = "/";
export const PRESS = "/press";
export const PRIVACY_POLICY = "/privacy-policy";
export const R_USERSLUG = "/r/:userSlug";
export const SETTINGS_ACCOUNT = "/settings/account";
export const SETTINGS_GLOBAL_PREFERENCES = "/settings/global-preferences";
export const SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const SETTINGS_PAYMENT = "/settings/payment";
export const SETTINGS_PRIVACY_AND_SAFETY = "/settings/privacy-and-safety";
export const SETTINGS_SECURITY = "/settings/security";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const VERIFY_IDENTITY_ID = "/verify-identity/:id";
