import { Sport } from '__generated__/globalTypes';
import { NotIOsAppFeature } from 'components/BuyableFeature';
import { useSportContext } from 'contexts/sport';
import { Balances } from 'routing/MultiSportAppBar/Balances';

import { CoinBalance } from '../CoinBalance';
import { InventoryBalance } from '../InventoryBalance';

interface Props {
  compact?: boolean;
  onClick?: () => void;
}

export const CoinsAndBalances = ({ compact, onClick }: Props) => {
  const { sport } = useSportContext();

  return (
    <>
      {!compact && (
        <>
          {sport === Sport.FOOTBALL && <InventoryBalance onClick={onClick} />}
          <CoinBalance onClick={onClick} />
        </>
      )}
      <NotIOsAppFeature>
        <Balances compact={compact} onClick={onClick} />
      </NotIOsAppFeature>
    </>
  );
};
