import {
  AvatarDisplayProps,
  DisabledAvatar,
  PictureAvatar,
  PlaceHolderAvatar,
} from '@sorare/core/src/components/user/Avatar';

type Props = {
  user?: {
    nickname: string;
    avatarUrl?: string | null;
    suspended?: boolean;
  } | null;
} & AvatarDisplayProps;

export const DumbAvatar = ({ user: userProp, ...rest }: Props) => {
  const user = userProp || { nickname: '' };
  if (user?.suspended) return <DisabledAvatar {...rest} />;
  const { avatarUrl } = user;
  if (!avatarUrl) return <PlaceHolderAvatar {...rest} user={user} />;
  return <PictureAvatar {...rest} user={user} pictureUrl={avatarUrl} />;
};
