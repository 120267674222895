import { defineMessages, useIntl } from 'react-intl';

import NonComponentWithTooltip from '@sorare/core/src/atoms/tooltip/NonComponentWithTooltip';
import { Color } from '@sorare/core/src/style/types';

import Icon from './Icon';

const messages = defineMessages({
  title: {
    id: 'transfers.pack.title',
    defaultMessage: 'Pack',
  },
});

export const Pack = ({ color }: { color?: Color }) => {
  const { formatMessage } = useIntl();
  return (
    <NonComponentWithTooltip title={formatMessage(messages.title)}>
      <Icon color={color} />
    </NonComponentWithTooltip>
  );
};

export default Pack;
