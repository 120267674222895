import { useEffect, useState } from 'react';

type Props = React.JSX.IntrinsicElements['group'];

export const Hoverable = ({ ...props }: Props) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [hovered]);

  return (
    <group
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      {...props}
    />
  );
};

export default Hoverable;
