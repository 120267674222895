import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import { EarlyAccessSpecialOfferBannerContent } from 'components/market/EarlyAccessSpecialOfferBannerContent';

import { EarlyAccessSpecialOfferBanner_anyCard } from './__generated__/index.graphql';
import { useGetCardSpecialEventConfig } from './useGetCardSpecialEventConfig';

export const EarlyAccessSpecialOfferBanner = ({
  cards,
}: {
  cards: EarlyAccessSpecialOfferBanner_anyCard[];
}) => {
  const mainCard = cards[0];
  const {
    flags: { useEnableEarlyAccessFlows },
  } = useFeatureFlags();
  const event = useGetCardSpecialEventConfig({ card: mainCard });
  if (
    !useEnableEarlyAccessFlows ||
    !mainCard.blueprint ||
    (event && isPast(event.endDate))
  )
    return null;

  const rarities = cards
    .map(c => c.rarityTyped)
    .filter(r => r !== Rarity.unique)
    .filter((item, key, self) => {
      return self.indexOf(item) === key;
    });

  return (
    <EarlyAccessSpecialOfferBannerContent event={event} rarities={rarities} />
  );
};

EarlyAccessSpecialOfferBanner.fragments = {
  anyCard: gql`
    fragment EarlyAccessSpecialOfferBanner_anyCard on AnyCardInterface {
      slug
      rarityTyped
      blueprint {
        id
      }
      ...useGetCardSpecialEventConfig_anyCard
    }
    ${useGetCardSpecialEventConfig.fragments.anyCard}
  ` as TypedDocumentNode<EarlyAccessSpecialOfferBanner_anyCard>,
};

export default EarlyAccessSpecialOfferBanner;
