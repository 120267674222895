import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineM, LabelM } from 'atoms/typography';
import { tabletAndAbove } from 'style/mediaQuery';

import defaultImage from './assets/matches.svg';

const Wrapper = styled(Vertical).attrs({ center: true, gap: 1 })`
  text-align: center;
  margin: auto;
  &.fullHeight {
    height: 100%;
  }
  padding: calc(10 * var(--unit)) var(--triple-unit) var(--triple-unit);
`;

const Message = styled(LabelM).attrs({ as: 'div' })`
  @media ${tabletAndAbove} {
    max-width: calc(60 * var(--unit));
  }
`;

const StyledButton = styled(Button)`
  margin-top: var(--unit);
`;

const Ctas = styled(Horizontal)`
  flex-wrap: wrap;
  justify-content: center;
`;

export type Props = {
  className?: string;
  fullHeight?: boolean;
  cta?: Nullable<ReactNode>;
  title: ReactNode;
  ctaLink?: Nullable<string>;
  onCtaClick?: () => void;
  secondaryCta?: Nullable<ReactNode>;
  secondaryCtaLink?: Nullable<string>;
  onSecondaryCtaClick?: () => void;
  message?: ReactNode;
  image?: ReactNode;
  imageUrl?: string;
};

export const EmptyState = ({
  className,
  secondaryCta,
  secondaryCtaLink,
  onSecondaryCtaClick,
  fullHeight = true,
  cta,
  onCtaClick,
  title,
  message,
  ctaLink,
  image,
  imageUrl = defaultImage,
}: Props) => {
  return (
    <Wrapper
      className={classNames(className, {
        fullHeight,
      })}
    >
      {image || <img src={imageUrl} alt="" height={100} />}
      {title && <HeadlineM as="div">{title}</HeadlineM>}
      {message && <Message color="var(--c-neutral-600)">{message}</Message>}
      <Ctas>
        {secondaryCtaLink && secondaryCta && (
          <StyledButton
            color="tertiary"
            size="small"
            to={secondaryCtaLink}
            onClick={onSecondaryCtaClick}
          >
            {secondaryCta}
          </StyledButton>
        )}
        {ctaLink && cta && (
          <StyledButton
            color="primary"
            size="small"
            to={ctaLink}
            onClick={onCtaClick}
          >
            {cta}
          </StyledButton>
        )}
      </Ctas>
    </Wrapper>
  );
};
