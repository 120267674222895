import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { TeamLogo_SportsTeamInterface } from './__generated__/index.graphql';

type Props = {
  team?: TeamLogo_SportsTeamInterface | null;
  className?: string;
  style?: React.CSSProperties;
  dark?: boolean;
};
const LogoWrapper = styled.span`
  display: flex;
  --size: var(--quadruple-unit);
  height: var(--size);
  width: var(--size);
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const TeamLogo = ({ team, className, style, dark = false }: Props) => {
  const { svgUrl = '' } = team || {};
  const url = dark ? svgUrl.replace('headwear-mark', 'dark') : svgUrl;
  return (
    <LogoWrapper className={className} style={style}>
      {team && <img src={url} title={team.name} alt={`${team.name} logo`} />}
    </LogoWrapper>
  );
};

TeamLogo.fragments = {
  SportsTeamInterface: gql`
    fragment TeamLogo_SportsTeamInterface on SportsTeamInterface {
      slug
      name
      svgUrl
    }
  ` as TypedDocumentNode<TeamLogo_SportsTeamInterface>,
};
