import { Sport } from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';

import useFeatureFlags from './useFeatureFlags';

export const useInSeasonReady = () => {
  const { sport = Sport.FOOTBALL } = useSportContext();
  const {
    flags: { useNbaMarketplaceInSeason = false },
  } = useFeatureFlags();

  const inSeasonSports = [
    Sport.BASEBALL,
    Sport.FOOTBALL,
    ...(useNbaMarketplaceInSeason ? [Sport.NBA] : []),
  ];

  return inSeasonSports.includes(sport);
};
