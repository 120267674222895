import { Navigate, generatePath } from 'react-router-dom';

import { FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_CARDS } from '@sorare/core/src/constants/__generated__/routes';
import { useTypedParams } from '@sorare/core/src/lib/routing';

import { PageParams } from './__generated__/routeParams';

const CollectionPage = () => {
  const { slug, collection } = useTypedParams<PageParams>();

  return (
    <Navigate
      to={generatePath(FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION_CARDS, {
        slug,
        collection,
      })}
      replace
    />
  );
};
export default CollectionPage;
