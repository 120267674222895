import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { absCenter } from 'lib/style';
import { Color } from 'style/types';

import { Shape } from './Shape';

const Wrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  width: 32px;
  height: 32px;
  color: white;
  isolation: isolate;
  &.disabled {
    color: var(--c-neutral-100);
  }
  &.invalid {
    color: var(--c-red-600);
  }
`;
const Hexagon = styled(Shape)`
  .disabled & path {
    fill: #ededed;
  }
  .invalid & path {
    fill: var(--c-red-800);
    opacity: 0.2;
  }
`;
const Score = styled(Horizontal).attrs({ gap: 0, center: true })`
  ${absCenter}
  font-weight: var(--t-bold);
  font-size: 14px;
  font-style: normal;
  letter-spacing: -0.03em;
  z-index: 1;
`;

type Props = {
  score: ReactNode;
  disabled?: boolean;
  invalid?: boolean;
  title?: string;
  className?: string;
  style?: CSSProperties;
  color?: Color;
};
export const Cost = ({
  score,
  disabled = false,
  invalid = false,
  title,
  className,
  style,
  color,
}: Props) => {
  const roundedScore = typeof score === 'number' ? Math.round(score) : score;

  return (
    <Wrapper
      className={classNames(className, { disabled, invalid })}
      style={style}
      title={title}
    >
      <Score>{roundedScore}</Score>
      <Hexagon fill={color} />
    </Wrapper>
  );
};
