import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

import { SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking } from './__generated__/index.graphql';

const BoxPreview = styled.img`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  object-fit: contain;
`;

type Props = {
  squadRanking: SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking;
};
export const SquadRewardsPreview = ({ squadRanking }: Props) => {
  const { eligibleRewards } = squadRanking;

  if (!eligibleRewards.length) {
    return null;
  }

  return (
    <Horizontal>
      {eligibleRewards.map(({ probabilisticBundleConfiguration }, index) => {
        return (
          <BoxPreview
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            src={probabilisticBundleConfiguration?.pictureUrl}
            alt={probabilisticBundleConfiguration?.name}
          />
        );
      })}
    </Horizontal>
  );
};

SquadRewardsPreview.fragments = {
  footballRivalsSquadsLeaderboardRanking: gql`
    fragment SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking on FootballRivalsSquadsLeaderboardRanking {
      id
      eligibleRewards {
        probabilisticBundleConfiguration {
          id
          pictureUrl
          name
        }
      }
    }
  ` as TypedDocumentNode<SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking>,
};
