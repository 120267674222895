import { faLink, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from 'atoms/icons';
import { Snackbar } from 'atoms/snackbar/Snackbar';
import { glossary } from 'lib/glossary';

import { Level, SnackNotificationOptions } from '..';
import { Message } from '../SnackMessage';

type Props = {
  notification: string | null;
  onClose: () => void;
  opts?: SnackNotificationOptions;
  className?: string;
  anchorOrigin?: ComponentProps<typeof Snackbar>['anchorOrigin'];
};

export const SnackNotification = ({
  onClose,
  notification,
  opts = {},
  className,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
}: Props) => {
  const { formatMessage } = useIntl();
  const { link, internalLink, autoHideDuration, level = Level.INFO } = opts;
  const defaultAutoHideDuration = link || internalLink ? null : 5000;

  return (
    <Snackbar
      key={notification!}
      anchorOrigin={anchorOrigin}
      open={Boolean(notification)}
      autoHideDuration={
        typeof autoHideDuration !== 'undefined'
          ? autoHideDuration
          : defaultAutoHideDuration
      }
      onClose={onClose}
      className={className}
      message={
        <Message id="message-id" notification={notification} level={level} />
      }
      renderActionButton={ActionButton => (
        <>
          {link && (
            <ActionButton
              key="details"
              aria-label="Details"
              href={link}
              rel="noopener noreferrer"
              color="tertiary"
            >
              <FontAwesomeIcon icon={faSearch} />
            </ActionButton>
          )}
          {internalLink && (
            <ActionButton
              key="details"
              aria-label="Details"
              to={internalLink}
              color="tertiary"
            >
              <FontAwesomeIcon icon={faLink} />
            </ActionButton>
          )}
          <ActionButton
            key="close"
            aria-label={formatMessage(glossary.close)}
            onClick={onClose}
            color="tertiary"
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </ActionButton>
        </>
      )}
    />
  );
};

export default SnackNotification;
