import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import ButtonBase from 'atoms/buttons/ButtonBase';
import { Text14 } from 'atoms/typography';

const ButtonsBar = styled.div`
  display: flex;
  gap: var(--double-unit);
  flex-wrap: wrap;
`;
const StyledButtonBase = styled(ButtonBase)`
  color: var(--c-link);
  text-decoration: underline;
`;

const PhoneNumberVerificationButton = ({
  message,
  onClick,
}: {
  message: MessageDescriptor;
  onClick: () => void;
}) => {
  return (
    <ButtonsBar>
      <StyledButtonBase onClick={onClick} color="var(--c-link)">
        <Text14 bold>
          <FormattedMessage {...message} />
        </Text14>
      </StyledButtonBase>
    </ButtonsBar>
  );
};

export default PhoneNumberVerificationButton;
