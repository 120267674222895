import styled from 'styled-components';

import Lightning from 'atoms/icons/Lightning';
import { Horizontal } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';

const Wrapper = styled(Horizontal).attrs({ gap: 0.5 })``;

const ImgWrapper = styled.span`
  width: var(--intermediate-unit);
  display: flex;
  justify-content: center;
`;

type Props = { score: number; suffix?: string | null };

export const Score = ({ score, suffix }: Props) => {
  return (
    <Wrapper>
      <Text16 bold>
        {score}
        {suffix}
      </Text16>
      <ImgWrapper>
        <Lightning />
      </ImgWrapper>
    </Wrapper>
  );
};
