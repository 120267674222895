import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { SEARCH_PARAMS } from '@sorare/core/src/components/search/InstantSearch/types';
import {
  ANY_SPORT_MARKET_INSTANT_BUY,
  ANY_SPORT_MARKET_SECONDARY,
} from '@sorare/core/src/constants/routes';
import { withFragments } from '@sorare/core/src/lib/gql';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import { getMarketUrl_so5Leaderboard } from './__generated__/getMarketUrl.graphql';

export const getMarketUrl = withFragments(
  (
    so5Leaderboard: getMarketUrl_so5Leaderboard,
    sport: Sport,
    instantBuy?: boolean,
    positions?: string[]
  ) => {
    if (instantBuy) {
      const rarities = so5Leaderboard?.rules?.rarities;
      const stringParams = [
        qs.stringify({
          [SEARCH_PARAMS.ACTIVE_LEAGUE]: so5Leaderboard.so5League.competitions
            .map(({ displayName }) => displayName)
            .join(','),
        }),
        qs.stringify({
          [SEARCH_PARAMS.GAMEWEEK_FILTER]: so5Leaderboard.gameWeek,
        }),
        rarities &&
          qs.stringify({
            [SEARCH_PARAMS.RARITY]: rarities.join(','),
          }),
        positions &&
          qs.stringify({ [SEARCH_PARAMS.POSITION]: positions.join(',') }),
      ].filter(Boolean);

      return `${generateSportPath(ANY_SPORT_MARKET_INSTANT_BUY, {
        sport,
      })}?${stringParams.join('&')}`;
    }
    return `${generateSportPath(ANY_SPORT_MARKET_SECONDARY, {
      sport,
    })}?${qs.stringify({
      leaderboard: so5Leaderboard.slug,
    })}&${so5Leaderboard.canCompose.transferMarketFilters}`;
  },
  {
    so5Leaderboard: gql`
      fragment getMarketUrl_so5Leaderboard on So5Leaderboard {
        slug
        gameWeek
        rules {
          id
          rarities
        }
        so5League {
          slug
          competitions {
            slug
            displayName
          }
        }
        canCompose {
          transferMarketFilters
        }
      }
    ` as TypedDocumentNode<getMarketUrl_so5Leaderboard>,
  }
);
