import { generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { Navigate } from '@sorare/core/src/routing/Navigate';

const PlayProPage = () => {
  const {
    flags: { useComposeTeamRightBar = false },
  } = useFeatureFlags();
  const { currentUser } = useCurrentUserContext();

  const fixture =
    useComposeTeamRightBar && currentUser?.forcedIntoOnboarding
      ? 'onboarding'
      : 'ongoing';

  return (
    <Navigate
      to={generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
        fixture,
      })}
      replace
    />
  );
};

export default PlayProPage;
