import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  DeleteLineup,
  DeleteLineupVariables,
} from './__generated__/useDeleteNBALineup.graphql';

const DELETE_NBA_LINEUPS_MUTATION = gql`
  mutation DeleteLineup($id: UUID!) {
    deleteNBALineup(id: $id)
  }
` as TypedDocumentNode<DeleteLineup, DeleteLineupVariables>;

export const useDeleteNBALineup = (refetchQueries: string[] = []) => {
  const [deleteLineupMutation] = useMutation(DELETE_NBA_LINEUPS_MUTATION);

  const { showNotification } = useSnackNotificationContext();

  return useCallback(
    async (lineupId: string) => {
      await deleteLineupMutation({
        variables: {
          id: lineupId,
        },
        refetchQueries,
        update: cache => {
          cache.evict({
            id: cache.identify({
              id: lineupId,
              __typename: 'NBALineup',
            }),
          });
          cache.gc();
        },
      });
      showNotification('so5FixtureDelete');
      return null;
    },
    [deleteLineupMutation, showNotification, refetchQueries]
  );
};
