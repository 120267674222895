import { generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_RIVALS_MATCHES_DATE } from '@sorare/core/src/constants/__generated__/routes';
import { formatDateForUrl } from '@sorare/core/src/lib/routing';
import { Navigate } from '@sorare/core/src/routing/Navigate';

const MatchesPage = () => {
  const today = new Date();

  return (
    <Navigate
      keepSearchParams
      to={generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE, {
        date: formatDateForUrl(today),
      })}
      replace
    />
  );
};

export default MatchesPage;
