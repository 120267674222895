import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ButtonBase from '@sorare/core/src/atoms/buttons/ButtonBase';
import Collapse from '@sorare/core/src/atoms/layout/Collapse';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14, Title5 } from '@sorare/core/src/atoms/typography';
import { TinyCardsPreviewContainer } from '@sorare/core/src/components/bundled/CardsPreviewContainer';
import { supportedScarcities } from '@sorare/core/src/components/card/ScarcityBall';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { scarcityNames } from '@sorare/core/src/lib/cards';

import CardProperties from 'components/card';
import { PrimaryOfferTokensPreview } from 'components/primaryOffer/PrimaryOfferTokensPreview';
import CardDescription from 'components/token/CardDescription';
import FlexCard from 'components/token/FlexCard';

import TokenDrawerSummary from '../TokenDrawerSummary';
import { TokensSummary_anyCard } from './__generated__/index.graphql';

const Summary = styled(Vertical).attrs({ gap: 2 })``;
const Row = styled.div`
  display: flex;
  gap: var(--unit);
`;

const Image = styled.div`
  flex-shrink: 0;
  width: calc(6 * var(--unit));
`;
const Title = styled(Horizontal).attrs({ gap: 0.5 })``;

const Preview = styled.div`
  flex-shrink: 0;
  width: calc(10 * var(--unit));
`;

const Metas = styled(Vertical).attrs({ gap: 0 })`
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`;

const TokensInPack = styled(Vertical)`
  justify-content: flex-start;
`;

const Line = styled.div`
  display: flex;
  gap: var(--intermediate-unit);
  padding: var(--unit);
  border-radius: var(--unit);
  background-color: var(--c-neutral-200);
`;

const Button = styled(ButtonBase)`
  display: inline-flex;
`;

type Props = {
  cards: TokensSummary_anyCard[];
  title?: ReactNode;
  packTitle: ReactNode;
  price?: ReactNode;
};

const TokenInPack = ({ card }: { card: TokensSummary_anyCard }) => (
  <Line>
    <Image>
      <FlexCard width={80} card={card} withLink />
    </Image>
    <Metas>
      <CardProperties card={card} withTransferMalus={false} />
      <CardDescription card={card} />
    </Metas>
  </Line>
);

export const TokensSummary = ({ cards, title, packTitle, price }: Props) => {
  const [viewPlayerInPack, setViewPlayerInPack] = useState<boolean>(false);

  const { up: isLaptop } = useScreenSize('laptop');

  const countByRarity = cards.reduce<Record<string, number>>(
    (acc, card) => ({
      ...acc,
      [card.rarityTyped]: (acc[card.rarityTyped] || 0) + 1,
    }),
    {}
  );
  const packDetails = supportedScarcities
    .map(
      scarcity =>
        countByRarity[scarcity] &&
        `${countByRarity[scarcity]} ${scarcityNames[scarcity]}`
    )
    .filter(Boolean);

  return (
    <div>
      <Summary>
        {title}
        <Row>
          <Preview>
            <TinyCardsPreviewContainer>
              <PrimaryOfferTokensPreview cards={cards} />
            </TinyCardsPreviewContainer>
          </Preview>
          <Metas>
            <Title>{packTitle}</Title>
            <Text14 color="var(--c-neutral-600)">
              {packDetails.join(' – ')}
            </Text14>
            <Button
              type="button"
              onClick={() => setViewPlayerInPack(!viewPlayerInPack)}
            >
              <Text14 as="span" color="var(--c-brand-600)">
                <FormattedMessage
                  id="tokensSummary.cardsInPack.cta"
                  defaultMessage="View cards in pack"
                />
              </Text14>
            </Button>
          </Metas>
          {price}
        </Row>
      </Summary>
      {isLaptop ? (
        <Collapse open={viewPlayerInPack}>
          <TokensInPack>
            {cards.map(card => (
              <TokenInPack key={card.slug} card={card} />
            ))}
          </TokensInPack>
        </Collapse>
      ) : (
        <TokenDrawerSummary
          open={viewPlayerInPack}
          onBackdropClick={() => setViewPlayerInPack(false)}
          title={
            <Title5>
              <FormattedMessage
                id="tokensSummary.cardsInPack.title"
                defaultMessage="Cards in pack"
              />
            </Title5>
          }
        >
          <TokensInPack>
            {cards.map(card => (
              <TokenInPack key={card.slug} card={card} />
            ))}
          </TokensInPack>
        </TokenDrawerSummary>
      )}
    </div>
  );
};

TokensSummary.fragments = {
  anyCard: gql`
    fragment TokensSummary_anyCard on AnyCardInterface {
      slug
      rarityTyped
      ...FlexCard_anyCard
      ...CardDescription_anyCard
      ...CardProperties_anyCard
      ...PrimaryOfferTokensPreview_anyCard
    }
    ${FlexCard.fragments.anyCard}
    ${CardDescription.fragments.anyCard}
    ${CardProperties.fragments.anyCard}
    ${PrimaryOfferTokensPreview.fragments.anyCard}
  ` as TypedDocumentNode<TokensSummary_anyCard>,
};

export default TokensSummary;
