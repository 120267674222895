import { faBell, faClose } from '@fortawesome/pro-solid-svg-icons';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import { Vertical } from 'atoms/layout/flex';
import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import { Text14, Text16 } from 'atoms/typography';
import { MUIBadge } from 'atoms/ui/MUIBadge';
import Notification from 'components/activity/Notification';
import DialogContentWithNavigation from 'components/dialog/DialogContentWithNavigation';
import { ACTIVITY } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useInGameNotificationContext } from 'contexts/inGameNotification';
import { useLocationChanged } from 'hooks/useLocationChanged';
import useToggle from 'hooks/useToggle';
import MenuIconButton from 'routing/MultiSportAppBar/MenuIconButton';
import { tabletAndAbove } from 'style/mediaQuery';
import { OverrideClasses } from 'style/utils';

const [Drawer, classes] = OverrideClasses(MuiDrawer, null, {
  paper: css`
    width: 100%;
    background-color: var(--c-neutral-200);
    @media ${tabletAndAbove} {
      width: 370px;
    }
  `,
});

const InnerContainer = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
`;
const NotificationsContainer = styled.div`
  flex: 1;
`;
const StyledLink = styled(Link)`
  padding: var(--double-unit);
`;
const StyledText14 = styled(Text14)`
  display: inline-flex;
  gap: var(--half-unit);
`;

export const Notifications = () => {
  const [open, toggleOpen] = useToggle(false);
  const { currentUser } = useCurrentUserContext();
  const {
    notifications,
    loading: notificationLoading,
    markNotificationsAsRead,
    unreadNotifications,
  } = useInGameNotificationContext();
  const locationChanged = useLocationChanged();

  useEffect(() => {
    if (locationChanged && open) toggleOpen();
  }, [locationChanged, open, toggleOpen]);

  if (!currentUser) {
    return null;
  }

  const onClose = () => {
    toggleOpen();
    markNotificationsAsRead(notifications);
  };

  const nbNotificationsUnreadNotShown =
    unreadNotifications -
    notifications.filter(notification => !notification.read).length;

  return (
    <>
      <MUIBadge badgeContent={unreadNotifications} overlap="circular">
        <MenuIconButton
          icon={faBell}
          aria-haspopup="true"
          onClick={toggleOpen}
          active={open}
        />
      </MUIBadge>
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <InnerContainer>
          <DialogContentWithNavigation
            title={
              <Text16 bold>
                <FormattedMessage
                  id="Notification.Notifications.notifications"
                  defaultMessage="Notifications"
                />
              </Text16>
            }
            right={
              <IconButton
                color="quaternary"
                onClick={onClose}
                icon={faClose}
                small
              />
            }
            footer={
              <StyledLink to={ACTIVITY} onClick={onClose}>
                <StyledText14 color="var(--c-brand-300)" bold>
                  <FormattedMessage
                    id="Notifications.viewAll"
                    defaultMessage="View all incoming activity"
                  />
                  {!!nbNotificationsUnreadNotShown && (
                    <span>({nbNotificationsUnreadNotShown})</span>
                  )}
                </StyledText14>
              </StyledLink>
            }
            stickyHeader
            noPadding
          >
            <NotificationsContainer>
              {notificationLoading && <LoadingIndicator small />}
              {notifications!.map(notification => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onClick={() => {
                    toggleOpen();
                    markNotificationsAsRead([notification]);
                  }}
                  inModale
                />
              ))}
            </NotificationsContainer>
          </DialogContentWithNavigation>
        </InnerContainer>
      </Drawer>
    </>
  );
};

export default Notifications;
