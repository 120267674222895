import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import styled from 'styled-components';

import ButtonBase from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { Score } from 'components/collections/Score';

const Wrapper = styled(Horizontal).attrs({ gap: 0.5, center: true })`
  padding: var(--half-unit) var(--intermediate-unit);
  color: var(--c-static-neutral-100);
  background-color: var(--c-static-neutral-700);
  border-radius: var(--double-unit);
  transition: background-color 0.1s ease-in-out;
  &.listed {
    background: linear-gradient(
        0deg,
        rgba(var(--c-rgb-yellow-300), 0.4) 0%,
        rgba(var(--c-rgb-yellow-300), 0.4) 100%
      ),
      var(--c-static-neutral-700);
  }
`;

const ScoreButton = styled(ButtonBase)`
  &:hover,
  &:focus {
    ${Wrapper} {
      background-color: var(--c-static-neutral-600);
      box-shadow: var(--shadow-300);
    }
  }
`;

type ContentProps = {
  score: number;
  listed?: boolean;
};

type Props = {
  onClick?: (() => void) | false;
} & ContentProps;

const Content = ({ score, listed }: ContentProps) => {
  return (
    <Wrapper className={classnames({ listed })}>
      {listed && (
        <FontAwesomeIcon
          color="var(--c-yellow-600)"
          icon={faExclamationCircle}
        />
      )}
      <Score score={score} />
    </Wrapper>
  );
};

export const CardScore = (props: Props) => {
  const { onClick } = props;
  if (onClick)
    return (
      <ScoreButton onClick={onClick}>
        <Content {...props} />
      </ScoreButton>
    );
  return <Content {...props} />;
};
