import { TypedDocumentNode, gql } from '@apollo/client';
import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { ValidWidths } from 'atoms/ui/ResponsiveImg';
import { CardImg, CardImgLoadingWrapper } from 'components/card/CardImg';
import { CardMeta } from 'components/card/CardMeta';

import { UninteractiveToken_anyCard } from './__generated__/index.graphql';

interface ClickHandlerProps {
  onClick?: () => void;
}

interface IProps {
  card: Omit<UninteractiveToken_anyCard, 'rarityTyped'> & {
    rarityTyped?: Rarity;
  };
  width?: ValidWidths;
}

const InnerContainer = styled(CardImgLoadingWrapper)`
  transform-origin: bottom center;
  position: relative;
  width: 100%;
  text-align: center;
`;

const FrontPicture = styled(CardImg)<{ $loaded: boolean }>`
  opacity: ${({ $loaded }) => ($loaded ? '1' : '0')};
  display: inline-block;
  width: 100%;
  height: 100%;
`;

export const UninteractiveToken: {
  fragments?: any;
} & ForwardRefExoticComponent<
  IProps & ClickHandlerProps & RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, IProps & ClickHandlerProps>((props, ref) => {
  const [loaded, setLoaded] = useState(false);
  const { card, width = 320, onClick } = props;
  const { pictureUrl, anyPlayer, rarityTyped } = card;

  return (
    <InnerContainer ref={ref} loaded={loaded}>
      {!loaded && <CardMeta card={card} />}
      <FrontPicture
        loading="lazy"
        src={pictureUrl || undefined}
        alt={`${anyPlayer.displayName}${rarityTyped ? ` - ${rarityTyped}` : ''}`}
        draggable={false}
        onLoad={() => {
          setLoaded(true);
        }}
        $loaded={loaded}
        onClick={onClick}
        width={width}
      />
    </InnerContainer>
  );
});
UninteractiveToken.displayName = 'UninteractiveToken';

UninteractiveToken.fragments = {
  anyCard: gql`
    fragment UninteractiveToken_anyCard on AnyCardInterface {
      slug
      pictureUrl
      rarityTyped
      anyPlayer {
        slug
        displayName
      }
      ...CardMeta_AnyCardInterface
    }
    ${CardMeta.fragments.AnyCardInterface}
  ` as TypedDocumentNode<UninteractiveToken_anyCard>,
};

export default UninteractiveToken;
