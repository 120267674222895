import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { Text16, Title3 } from 'atoms/typography';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { refusedReasonsMessages } from 'lib/mangopay';

import { LEARN_MORE_ABOUT_DOCUMENT_CHECK } from '../externalLinks';
import { CreateFiatWalletSteps } from '../type';

const Content = styled(Vertical).attrs({ gap: 2 })``;

const StyledButtonBase = styled.a`
  color: var(--c-link);
  text-decoration: underline;
`;

type Props = {
  setStep: (step: CreateFiatWalletSteps) => void;
};

export const HandleIdReviewError = ({ setStep }: Props) => {
  const { refusedReason } = useFiatBalance();

  return (
    <Content>
      <Title3>
        <FormattedMessage
          id="createFiatWallet.handleIdReviewError.title"
          defaultMessage="Let’s try again"
        />
      </Title3>
      <Text16>
        {refusedReason && (
          <FormattedMessage {...refusedReasonsMessages[refusedReason]} />
        )}
      </Text16>
      <Text16 bold>
        <StyledButtonBase
          href={LEARN_MORE_ABOUT_DOCUMENT_CHECK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="createFiatWallet.handleIdReviewError.learnHowIdentityVerificationWorks"
            defaultMessage="Learn how identity verification works."
          />
        </StyledButtonBase>
      </Text16>
      <Button
        color="primary"
        size="medium"
        onClick={() =>
          setStep(CreateFiatWalletSteps.REVIEW_INFO_BEFORE_ADDING_ID)
        }
      >
        <FormattedMessage
          id="createFiatWallet.handleIdReviewError.button"
          defaultMessage="Resubmit ID"
        />
      </Button>
    </Content>
  );
};
