import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { LabelM } from '@sorare/core/src/atoms/typography';

import {
  USSportsTeam_CardInterface,
  USSportsTeam_CardInterface_BaseballCard_,
  USSportsTeam_CardInterface_NBACard_,
} from './__generated__/index.graphql';

type Props = {
  card:
    | USSportsTeam_CardInterface_BaseballCard_
    | USSportsTeam_CardInterface_NBACard_;
};

const Icon = styled.img`
  height: var(--intermediate-unit);
  vertical-align: baseline;
`;
const Team = styled.span`
  display: inline-flex;
  align-items: baseline;
  gap: var(--half-unit);
`;

const USSportsTeam = ({ card }: Props) => {
  const { usSportsTeam, player } = card;

  const slug = usSportsTeam?.slug;
  const name = usSportsTeam?.name;
  const svgUrl = usSportsTeam?.svgUrl;

  const activeClubSlug = player?.team?.slug;
  const activeClubName = player?.team?.name;
  const activeClubSvgUrl = player?.team?.svgUrl;

  const hasBeenTransferred = slug && activeClubSlug !== slug;

  return (
    <>
      <LabelM color="var(--c-neutral-600)">
        <Team>
          <Icon src={svgUrl} alt={name} />
          {name}
        </Team>
      </LabelM>
      {hasBeenTransferred && (
        <LabelM color="var(--c-neutral-600)">
          <FormattedMessage
            id="USSportsTeam.currentTeam"
            defaultMessage="Current team: {activeClubName}"
            values={{
              activeClubName: (
                <Team>
                  <Icon src={activeClubSvgUrl} alt={activeClubName} />
                  {activeClubName}
                </Team>
              ),
            }}
          />
        </LabelM>
      )}
    </>
  );
};

USSportsTeam.fragments = {
  cardInterface: gql`
    fragment USSportsTeam_CardInterface on CardInterface {
      slug
      usSportsTeam: team {
        id
        slug
        name
        svgUrl
      }
      player {
        slug
        team {
          id
          slug
          name
          svgUrl
        }
      }
    }
  ` as TypedDocumentNode<USSportsTeam_CardInterface>,
};

export default USSportsTeam;
