import { TypedDocumentNode, gql } from '@apollo/client';

import { acceptSo5NoCardDraftableAppearanceInput } from '@sorare/core/src/__generated__/globalTypes';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  AcceptNoCardDraftableAppearanceMutation,
  AcceptNoCardDraftableAppearanceMutationVariables,
} from './__generated__/useAcceptNoCardDraftableAppearance.graphql';

export const ACCEPT_DRAFTABLE_APPEARANCE_MUTATION = gql`
  mutation AcceptNoCardDraftableAppearanceMutation(
    $input: acceptSo5NoCardDraftableAppearanceInput!
  ) {
    acceptSo5NoCardDraftableAppearance(input: $input) {
      draftableAppearance {
        id
        player {
          ... on AnyPlayerInterface {
            slug
            displayName
          }
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  AcceptNoCardDraftableAppearanceMutation,
  AcceptNoCardDraftableAppearanceMutationVariables
>;

const useAcceptNoCardDraftableAppearance = () => {
  const [mutate] = useMutation(ACCEPT_DRAFTABLE_APPEARANCE_MUTATION, {
    showErrorsWithSnackNotification: true,
  });

  return async (input: acceptSo5NoCardDraftableAppearanceInput) =>
    mutate({ variables: { input } });
};

export default useAcceptNoCardDraftableAppearance;
