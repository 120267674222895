import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineM, Text16 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { FOOTBALL_MARKET } from '@sorare/core/src/constants/__generated__/routes';
import { useAuthContext } from '@sorare/core/src/contexts/auth';
import { useConnectionContext } from '@sorare/core/src/contexts/connection';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import useQueryString from '@sorare/core/src/hooks/useQueryString';
import { glossary } from '@sorare/core/src/lib/glossary';
import { mustAcceptTermsOfServiceFlag } from '@sorare/core/src/lib/mustAcceptTermsOfServiceFlag';

const messages = defineMessages({
  title: {
    id: 'NewDeviceConfirmationError.title',
    defaultMessage: 'New Device Confirmation',
  },
  description: {
    id: 'NewDeviceConfirmationError.description',
    defaultMessage:
      'It looks like the link you are using is no longer valid. Please use the last link sent to you when logging in.',
  },
  openLinkInSameDevice: {
    id: 'NewDeviceConfirmationError.openLinkInSameDevice',
    defaultMessage:
      'Please open the link with the same device you are trying to log in with.',
  },
});

const Body = styled(Vertical)`
  padding: 0 var(--triple-unit) var(--triple-unit) var(--triple-unit);
`;

export const ConfirmDevice = () => {
  const { confirmDevice } = useAuthContext();
  const { currentUser } = useCurrentUserContext();
  const { promptTerms } = useConnectionContext();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const confirmationToken = useQueryString('token');
  const {
    flags: { lastTermsOfServiceUpdatedAt = '' },
  } = useFeatureFlags();

  const mustAcceptTermsFlag = mustAcceptTermsOfServiceFlag(
    lastTermsOfServiceUpdatedAt
  );

  const handleClose = useCallback(() => {
    navigate(FOOTBALL_MARKET);
  }, [navigate]);

  useEffect(() => {
    if (confirmationToken) {
      confirmDevice(confirmationToken).then(response => {
        if (response.error) {
          setError(response.error);
        } else {
          // FIXME: @gbreux find out why we need it (SO5-1267)
          setTimeout(() => {
            navigate(FOOTBALL_MARKET);
            if (currentUser?.mustAcceptTcus && mustAcceptTermsFlag) {
              promptTerms({
                closable: true,
              });
            }
          }, 250);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No dependency added because we want it to be called only once

  if (error) {
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open
        onClose={handleClose}
        title={
          <HeadlineM as="h2" className="text-center">
            <FormattedMessage {...messages.title} />
          </HeadlineM>
        }
      >
        <Body gap={3}>
          <Vertical>
            <Text16>
              <FormattedMessage {...messages.description} />
            </Text16>
            <Text16>
              <FormattedMessage {...messages.openLinkInSameDevice} />
            </Text16>
          </Vertical>
          <Button onClick={handleClose} color="primary" size="medium" fullWidth>
            <FormattedMessage {...glossary.ok} />
          </Button>
        </Body>
      </Dialog>
    );
  }

  return null;
};

export default ConfirmDevice;
