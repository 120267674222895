import styled from 'styled-components';

import CloseButton from 'atoms/buttons/CloseButton';
import { SBHorizontal } from 'atoms/layout/flex';
import BackButton from 'components/dialog/BackButton';

const Root = styled(SBHorizontal)`
  width: 100%;
`;

type Props = {
  onClose?: () => void;
  onBack?: () => void;
};
const Header = ({ onClose, onBack }: Props) => {
  return (
    <Root>
      <div>{onBack && <BackButton onBack={onBack} />}</div>
      <div>{onClose && <CloseButton onClose={onClose} />}</div>
    </Root>
  );
};

export default Header;
