export const YellowShirt = () => {
  return (
    <svg
      width="160"
      height="128"
      viewBox="0 0 160 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.654 34.344L125.655 2.34503C124.155 0.84372 122.121 0.00133712 119.999 0H95.9998C93.2182 0.124551 90.6654 1.5696 89.1281 3.89067C87.1593 6.86785 83.8111 8.63709 80.2421 8.58601C76.6716 8.57931 73.3556 6.73516 71.4644 3.70587C69.9952 1.39965 67.4506 0.0013714 64.7143 0H39.9996C37.8782 0.00133952 35.8439 0.84372 34.344 2.34503L2.34503 34.344C0.843723 35.8439 0 37.8784 0 39.9996C0 42.1208 0.843723 44.1553 2.34503 45.6553L24.9852 68.2954H24.9838C26.8213 70.1342 29.4381 70.9646 32.0003 70.5226V119.996C32.0003 122.119 32.8427 124.153 34.344 125.653C35.8439 127.153 37.8784 127.997 39.9996 127.997H120C122.122 127.997 124.156 127.153 125.656 125.653C127.157 124.153 128 122.119 128 119.996V70.5226C130.56 70.9619 133.176 70.1316 135.016 68.2954L157.656 45.6553L157.655 45.6566C159.156 44.1567 160 42.1222 160 39.9996C160 37.8782 159.156 35.8439 157.655 34.3439L157.654 34.344Z"
        fill="url(#paint0_linear_320_1032)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_320_1032"
          x1="130.773"
          y1="111.283"
          x2="95.7687"
          y2="-19.1716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD135" />
          <stop offset="1" stopColor="#FFE600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default YellowShirt;
