import { TypedDocumentNode, gql } from '@apollo/client';
import { faCheck, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import Button from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  HeadlineL,
  HeadlineM,
  LabelL,
  LabelM,
} from '@sorare/core/src/atoms/typography';
import { GraphQLResult } from '@sorare/core/src/components/form/Form';
import useQuery from '@sorare/core/src/hooks/graphql/useQuery';

import {
  NoCardEntrySelectQuery,
  NoCardEntrySelectQueryVariables,
} from './__generated__/index.graphql';
import { useAcceptNoCardDraftableAppearance } from './useAcceptNoCardDraftableAppearance';
import { useRejectNoCardDraftableAppearance } from './useRejectNoCardDraftableAppearance';
import { useResetNoCardDraftableAppearance } from './useResetNoCardDraftableAppearance';
import { useSubmitNoCardDraftableAppearances } from './useSubmitNoCardDraftableAppearances';

const NO_CARD_ENTRY_SELECT_QUERY = gql`
  query NoCardEntrySelectQuery {
    currentUser {
      slug
      baseballNoCardRouteInfo {
        draftableAppearance {
          id
          player {
            slug
            displayName
          }
          team {
            slug
            name
          }
          bonus
          season
        }
      }
      nbaNoCardRouteInfo {
        draftableAppearance {
          id
          player {
            slug
            displayName
          }
          team {
            slug
            name
          }
          bonus
          season
        }
      }
    }
  }
` as TypedDocumentNode<NoCardEntrySelectQuery, NoCardEntrySelectQueryVariables>;

const Root = styled.div`
  padding: var(--double-unit);
`;

const Appearances = styled(Vertical)`
  padding: var(--double-unit);
  text-align: center;
`;
const Appearance = styled.div`
  width: 20%;
  color: var(--c-neutral-500);
  &.selected {
    color: var(--c-green-600);
  }
  &.proposed {
    color: var(--c-neutral-1000);
  }
`;

type Appearance = {
  id: string;
  player: {
    displayName: string;
  };
  team: {
    name: string;
  };
  bonus: number;
  season: string;
};

const formatAppearance = (appearance: Appearance | undefined) =>
  appearance
    ? `${[appearance.player.displayName, appearance.team.name]
        .filter(Boolean)
        .join(' - ')} (Season: ${appearance.season}, Bonus: ${
        appearance.bonus
      })`
    : '---';

const AppearanceSlot = ({
  appearance,
}: {
  appearance: Appearance | undefined;
}) => {
  return (
    <LabelL as="div">
      <LabelM>{formatAppearance(appearance)}</LabelM>
    </LabelL>
  );
};

type Props = {
  userID: string;
  fixtureID: string;
  onSubmit: () => void;
};
export const ComposeTeam = ({ userID, fixtureID, onSubmit }: Props) => {
  const sport = Sport.NBA; // TODO(MLB-2010): Adapt for MLB
  const availableCardSlots = sport === Sport.NBA ? 5 : 7;
  const [selectedAppearances, setSelectedAppearances] = useState<Appearance[]>(
    []
  );

  const acceptAppearance = useAcceptNoCardDraftableAppearance();
  const rejectAppearance = useRejectNoCardDraftableAppearance();
  const resetAppearance = useResetNoCardDraftableAppearance();
  const submitAppearances = useSubmitNoCardDraftableAppearances();

  const { data } = useQuery(NO_CARD_ENTRY_SELECT_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [proposedAppearance, setProposedAppearance] = useState<
    Appearance | undefined
  >(undefined);

  useEffect(() => {
    setProposedAppearance(
      data?.currentUser?.[
        sport === Sport.NBA ? 'nbaNoCardRouteInfo' : 'baseballNoCardRouteInfo'
      ].draftableAppearance
    );
  }, [data, sport]);

  const resetDraftableAppearances = useCallback(async () => {
    await resetAppearance({
      userID,
      fixtureID,
    });
    setSelectedAppearances([]);
  }, [resetAppearance, userID, fixtureID]);

  const acceptDraftableAppearance = async () => {
    if (!proposedAppearance) {
      return;
    }
    setSelectedAppearances([...selectedAppearances, proposedAppearance]);
    const { data: newAppearance } = await acceptAppearance({
      userID,
      id: proposedAppearance.id,
    });
    setProposedAppearance(newAppearance?.acceptNoCardDraftableAppearance);
  };

  const rejectDraftableAppearance = async () => {
    if (!proposedAppearance) {
      return;
    }
    const { data: newAppearance } = await rejectAppearance({
      userID,
      id: proposedAppearance.id,
    });
    setProposedAppearance(newAppearance?.rejectNoCardDraftableAppearance);
  };

  const submitDraftableAppearances = async (
    onResult: (res: GraphQLResult) => void
  ) => {
    const result = await submitAppearances({
      userID,
      fixtureID,
    });
    onResult(result);
    if (result.success) {
      onSubmit();
    }
  };

  if (!userID || !fixtureID) {
    return null;
  }

  if (!proposedAppearance) {
    return <LoadingIndicator />;
  }

  const remainingSlots = availableCardSlots - selectedAppearances.length;
  const missingCards: undefined[] =
    remainingSlots > 1 ? [...Array(remainingSlots - 1)] : [];

  return (
    <Root>
      <Vertical gap={4}>
        <Appearances gap={4}>
          <HeadlineL as="p">
            <FormattedMessage
              id="noCardEntry.ComposeTeam.title"
              defaultMessage="Validate your lineup"
            />
          </HeadlineL>
          <Horizontal>
            {selectedAppearances.map(appearance => (
              <Appearance key={appearance.id} className="selected">
                <HeadlineM as="div">
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </HeadlineM>
                <AppearanceSlot appearance={appearance} />
              </Appearance>
            ))}
            {remainingSlots > 0 && (
              <Appearance className="proposed">
                <HeadlineM as="div">
                  <FontAwesomeIcon icon={faQuestion} size="xs" />
                </HeadlineM>
                <AppearanceSlot appearance={proposedAppearance} />
              </Appearance>
            )}
            {missingCards.map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Appearance key={index}>
                <AppearanceSlot appearance={undefined} />
              </Appearance>
            ))}
          </Horizontal>
        </Appearances>
        <Vertical gap={4}>
          <Vertical gap={2}>
            <Horizontal>
              <Button
                fullWidth
                size="medium"
                color="tertiary"
                onClick={rejectDraftableAppearance}
                disabled={!remainingSlots}
              >
                <FormattedMessage
                  id="noCardEntry.ComposeTeam.deny"
                  defaultMessage="Deny"
                />
              </Button>
              <Button
                fullWidth
                size="medium"
                color="primary"
                onClick={acceptDraftableAppearance}
                disabled={!remainingSlots}
              >
                <FormattedMessage
                  id="noCardEntry.ComposeTeam.accept"
                  defaultMessage="Accept"
                />
              </Button>
            </Horizontal>
            <Button
              fullWidth
              color="tertiary"
              size="medium"
              onClick={resetDraftableAppearances}
            >
              <FormattedMessage
                id="noCardEntry.ComposeTeam.reset"
                defaultMessage="Reset"
              />
            </Button>
          </Vertical>
          <Button
            fullWidth
            size="medium"
            onClick={() => submitDraftableAppearances}
            disabled={selectedAppearances.length !== availableCardSlots}
          >
            <FormattedMessage
              id="noCardEntry.ComposeTeam.submit"
              defaultMessage="Submit"
            />
          </Button>
        </Vertical>
      </Vertical>
    </Root>
  );
};
