import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import { openWidget } from 'components/Form';
import Layout from 'components/Layout';
import { NEW_REQUEST_HASH } from 'constants/routes';

const HelpCenterLayout = () => {
  const {
    flags: { embeddedHelpCenter = false },
  } = useFeatureFlags();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash === NEW_REQUEST_HASH) {
      openWidget();
      navigate(location.pathname, { replace: true });
    }
  }, [location.hash, location.pathname, navigate]);

  if (!embeddedHelpCenter) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Suspense fallback={<LoadingIndicator fullHeight />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};

export default HelpCenterLayout;
