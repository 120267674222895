import { FC, ReactElement } from 'react';

import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import VerifyPhoneNumber from '@sorare/core/src/components/user/VerifyPhoneNumber';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useRedirectToSignup } from '@sorare/core/src/hooks/auth/useRedirectToLandingWithAction';

interface Props {
  element: ReactElement;
  withLayout?: boolean;
  requireVerifiedPhoneNumber?: boolean;
}

const WithLayout: FC<React.PropsWithChildren<{ withLayout?: boolean }>> = ({
  withLayout,
  children,
}) => {
  return withLayout ? <AppLayout>{children}</AppLayout> : <>{children}</>;
};

export const PrivateRoute = ({
  element: Component,
  withLayout,
  requireVerifiedPhoneNumber,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const redirectToSignup = useRedirectToSignup();

  if (!Component) {
    throw new Error('Missing component to render');
  }
  if (!currentUser) {
    return redirectToSignup();
  }
  return (
    <WithLayout withLayout={withLayout}>
      <>
        {requireVerifiedPhoneNumber && <VerifyPhoneNumber />}
        {Component}
      </>
    </WithLayout>
  );
};

export default PrivateRoute;
