import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath } from 'react-router-dom';

import { GameStatus } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { DumbNotification } from 'components/activity/DumbNotification';
import {
  FOOTBALL_PLAY_RIVALS_FEATURED_INVITE_INVITEID,
  FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_ARENA,
  FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_FRIENDLIES,
  FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_OVERVIEW,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { formatDateForUrl } from 'lib/routing';

import { CommonNotificationProps } from '../types';
import {
  FootballRivalsGameNotification_footballRivalsGameNotification,
  FootballRivalsGameNotification_user,
} from './__generated__/index.graphql';

const linkFromStatus = (
  notification: FootballRivalsGameNotification_footballRivalsGameNotification
) => {
  const { game, name, invite } = notification;

  if (name === 'bot_invite_created' && invite) {
    return generatePath(FOOTBALL_PLAY_RIVALS_FEATURED_INVITE_INVITEID, {
      inviteId: invite.id,
    });
  }
  if (game.game.status === GameStatus.scheduled) {
    return generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_FRIENDLIES, {
      date: formatDateForUrl(game.game.date),
      slug: game.slug,
    });
  }
  if (game.game.status === GameStatus.playing) {
    if (game.myArenaRequest) {
      return generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_ARENA, {
        date: formatDateForUrl(game.game.date),
        slug: game.slug,
      });
    }
    return generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_FRIENDLIES, {
      date: formatDateForUrl(game.game.date),
      slug: game.slug,
    });
  }
  return generatePath(FOOTBALL_PLAY_RIVALS_MATCHES_DATE_SLUG_OVERVIEW, {
    date: formatDateForUrl(game.game.date),
    slug: game.slug,
  });
};

type Props = CommonNotificationProps & {
  notification: FootballRivalsGameNotification_footballRivalsGameNotification;
};
export const FootballRivalsGameNotification = ({
  notification,
  ...rest
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { createdAt, sport, read, invite, title, body } = notification;

  const opponent =
    invite && invite.invitedBy.user.slug === currentUser?.slug
      ? invite?.manager?.user
      : invite?.invitedBy?.user;

  const link = linkFromStatus(notification);

  return (
    <DumbNotification
      title={
        <Vertical gap={0}>
          <LabelM>{title}</LabelM>
          <LabelM>{body}</LabelM>
        </Vertical>
      }
      userAvatar={opponent}
      link={link}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

const userfragment = gql`
  fragment FootballRivalsGameNotification_user on PublicUserInfoInterface {
    slug
    ...DumbNotification_userAvatar
  }
  ${DumbNotification.fragments.avatarUser}
` as TypedDocumentNode<FootballRivalsGameNotification_user>;
FootballRivalsGameNotification.fragments = {
  footballRivalsGameNotification: gql`
    fragment FootballRivalsGameNotification_footballRivalsGameNotification on FootballRivalsGameNotification {
      id
      name
      createdAt
      read
      sport
      title
      body
      invite {
        id
        invitedBy {
          id
          user {
            slug
            ...FootballRivalsGameNotification_user
          }
        }
        manager {
          id
          user {
            slug
            ...FootballRivalsGameNotification_user
          }
        }
      }
      game {
        id
        slug
        game {
          id
          status: statusTyped
          date
        }
        myArenaRequest {
          id
        }
      }
      user {
        slug
        ...DumbNotification_userAvatar
      }
    }
    ${userfragment}
  ` as TypedDocumentNode<FootballRivalsGameNotification_footballRivalsGameNotification>,
};
