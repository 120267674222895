import { Outlet } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Dialog } from '@sorare/core/src/components/dialog';
import {
  DialogKey,
  WithDialogs,
} from '@sorare/core/src/components/navigation/WithDialogs';
import SportProvider from '@sorare/core/src/contexts/sport/Provider';
import { getRoutesFromFiles } from '@sorare/core/src/lib/routing';

import { DeliverableItemOrderFormRoutedDialog } from '@sorare/gameplay/src/components/rewards/deliverableItem/DeliverableItemOrderFormRoutedDialog';

import BundledAuctionPage from 'components/auction/BundledAuctionPage';
import PrivateRoute from 'routing/PrivateRoute';

export const WithDialogLayout = () => {
  return (
    <SportProvider sport={Sport.FOOTBALL}>
      <WithDialogs
        dialogs={[
          {
            name: DialogKey.deliveryItemOrder,
            element: (
              <PrivateRoute
                element={<DeliverableItemOrderFormRoutedDialog />}
              />
            ),
          },
          {
            name: DialogKey.bundledAuction,
            element: (
              <Dialog open fullScreen>
                <BundledAuctionPage />
              </Dialog>
            ),
          },
        ]}
      >
        <Outlet />
      </WithDialogs>
    </SportProvider>
  );
};

export const routeObjects = [
  {
    element: <WithDialogLayout />,
    children: getRoutesFromFiles(import.meta.glob('./**/(page|layout).tsx')),
  },
];
