import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';

import Backdrop from 'atoms/loader/Backdrop';

export enum DialogKey {
  news = 'news',
  claimedConversionCredit = 'claimedConversionCredit',
  deliveryItemOrder = 'deliveryItemOrder',
  card = 'card',
  bundledAuction = 'bundledAuction',
  primaryOfferBought = 'primaryOfferBought',
  paypalTransactionId = 'transactionId',
}

export const WithDialogs = ({
  children,
  dialogs,
}: {
  children: React.ReactNode;
  dialogs: {
    name: DialogKey;
    element: React.ReactNode;
  }[];
}) => {
  const [searchParams] = useSearchParams();
  const dialog = dialogs.find(({ name }) => !!searchParams.get(name));
  return (
    <>
      {children}
      <Suspense fallback={<Backdrop />}>{dialog?.element}</Suspense>
    </>
  );
};
