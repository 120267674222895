import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CurrentBaseballUserProvider_currentUser,
  CurrentBaseballUserQuery,
  CurrentBaseballUserQueryVariables,
} from './__generated__/queries.graphql';

export const currentUser = gql`
  fragment CurrentBaseballUserProvider_currentUser on CurrentUser {
    slug
    nickname
    baseballCurrentUserData {
      onboarding {
        state
      }
    }
  }
` as TypedDocumentNode<CurrentBaseballUserProvider_currentUser>;

export const CURRENT_BASEBALL_USER_QUERY = gql`
  query CurrentBaseballUserQuery {
    currentUser {
      slug
      ...CurrentBaseballUserProvider_currentUser
    }
  }
  ${currentUser}
` as TypedDocumentNode<
  CurrentBaseballUserQuery,
  CurrentBaseballUserQueryVariables
>;
