import { useOutletContext } from 'react-router-dom';

import { NBACompetitionDetailsPageUserDataQuery } from './__generated__/layout.graphql';

export type LayoutData = {
  data?: NBACompetitionDetailsPageUserDataQuery;
  loading: boolean;
};

export const useLayoutData = () => useOutletContext<LayoutData>();
