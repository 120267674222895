import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { fantasy } from 'lib/glossary';
import { unitMapping } from 'lib/style';

import draw from './assets/draw.svg';
import loss from './assets/loss.svg';
import win from './assets/win.svg';

const Icon = styled.img<{
  imgWidth: keyof typeof unitMapping;
}>`
  aspect-ratio: 1;
  width: ${({ imgWidth }) => unitMapping[imgWidth]};
`;

export type Outcome = 'DRAW' | 'LOSE' | 'WIN';

type Props = { outcome: Outcome; width?: keyof typeof unitMapping };
export const GameOutcome = ({ outcome, width = 1 }: Props) => {
  const { formatMessage } = useIntl();
  if (outcome === 'WIN') {
    return (
      <Icon
        src={win}
        alt={formatMessage(fantasy.win)}
        title={formatMessage(fantasy.win)}
        imgWidth={width}
      />
    );
  }
  if (outcome === 'LOSE') {
    return (
      <Icon
        src={loss}
        alt={formatMessage(fantasy.loss)}
        title={formatMessage(fantasy.loss)}
        imgWidth={width}
      />
    );
  }

  return (
    <Icon
      src={draw}
      alt={formatMessage(fantasy.draw)}
      title={formatMessage(fantasy.draw)}
      imgWidth={width}
    />
  );
};
