import { useIntl } from 'react-intl';

import { fantasy } from 'lib/glossary';

import { EmptyState, Props as EmptyStateProps } from '..';

export const EmptyLineups = (
  props: Partial<EmptyStateProps> & { upcoming?: boolean }
) => {
  const { formatMessage } = useIntl();
  const { upcoming = true } = props;

  const defaultProps = upcoming
    ? {
        title: formatMessage({
          id: 'emptyState.lineup.title',
          defaultMessage: 'No registered lineups',
        }),
        message: formatMessage({
          id: 'emptyState.lineup.message',
          defaultMessage:
            "Lineups will appear here once you've registered them",
        }),
        cta: formatMessage(fantasy.composeLineup),
      }
    : {
        title: formatMessage({
          id: 'emptyState.lineup.title',
          defaultMessage: 'No registered lineups',
        }),
        message: '',
      };
  return <EmptyState {...defaultProps} {...props} />;
};
