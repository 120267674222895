import { TypedDocumentNode, gql } from '@apollo/client';
import type { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';
import { JSX, ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import {
  Rarity,
  RewardState,
  ShippingState,
  Sport,
} from '__generated__/globalTypes';
import discountCardBack from 'assets/rewards/discount-card-back.png';
import { Title3 } from 'atoms/typography';
import { CardBack } from 'components/card/Back';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import idFromObject from 'gql/idFromObject';
import useQuery from 'hooks/graphql/useQuery';
import { isType } from 'lib/gql';
import { monetaryAmountFragment } from 'lib/monetaryAmount';
import { getPositionInitials } from 'lib/positions';
import { NBA_REFERRAL_REWARDS_QUERY } from 'lib/usSportsGraphql/queries';

import { useReferralRewards_referralReward } from './__generated__/useReferralRewards.graphql';
import { useClaimReferralReward } from './useClaimReferralReward';

const referralRewardsQueryBySport = {
  [Sport.FOOTBALL]: undefined,
  [Sport.BASEBALL]: undefined,
  [Sport.NBA]: NBA_REFERRAL_REWARDS_QUERY,
};

const DiscountCardBack = styled.img`
  width: var(--card-width);
  max-width: 100%;
`;

const isConversionCreditReward = (
  reward: useReferralRewards_referralReward
) => {
  return !!reward.conversionCredit;
};

const isFootballCardReward = (
  reward: useReferralRewards_referralReward
): reward is useReferralRewards_referralReward & {
  card: NonNullable<
    useReferralRewards_referralReward['card'] & {
      sport: Sport.FOOTBALL;
      __typename: 'Card';
    }
  >;
} => {
  return reward.card?.sport === Sport.FOOTBALL;
};

type ReferralRewardsQueryType = typeof NBA_REFERRAL_REWARDS_QUERY;

type ReferralRewardsQueryVariablesType = VariablesOf<
  typeof NBA_REFERRAL_REWARDS_QUERY
>;

export type Cardback = ReactElement | null;

type NBAReferralReward = NonNullable<
  ResultOf<ReferralRewardsQueryType>['usSportReferralRewards']
>[number];

const cardBackFactory = (
  reward: useReferralRewards_referralReward,
  nbaReferralReward: NBAReferralReward | undefined
): Cardback => {
  if (isConversionCreditReward(reward)) {
    return <DiscountCardBack src={discountCardBack} alt="" width={20} />;
  }
  if (isFootballCardReward(reward)) {
    const { card } = reward;
    const isCustomSeriesReward = card.rarityTyped === Rarity.custom_series;

    return (
      <CardBack
        path={
          card.backPictureUrl ||
          `${FRONTEND_ASSET_HOST}/cards/back/${card.rarityTyped}.svg`
        }
        sport={Sport.FOOTBALL}
        rarity={card.rarityTyped}
        radius={isCustomSeriesReward ? '30px' : '10px'}
      />
    );
  }

  if (isType(reward.card, 'BaseballCard')) {
    return (
      <CardBack sport={Sport.BASEBALL} rarity={reward?.card.rarityTyped} />
    );
  }

  if (isType(reward.card, 'NBACard') && nbaReferralReward) {
    return (
      <CardBack sport={Sport.NBA} rarity={nbaReferralReward.card.rarityTyped} />
    );
  }
  return null;
};

export type Teasers = JSX.Element[];

const teaserFactory = (
  reward: useReferralRewards_referralReward,
  nbaReferralReward: NBAReferralReward | undefined
): Teasers => {
  if (isConversionCreditReward(reward)) {
    return [];
  }

  if (isFootballCardReward(reward)) {
    const { card } = reward;

    return [
      <img key={0} src={card.player.activeClub?.pictureUrl || ''} alt="" />,
      <Title3 key={1}>{card.player.age}</Title3>,
      <Title3 key={2}>{card.player.cardPositions[0].slice(0, 3)}</Title3>,
    ];
  }

  if (isType(reward.card, 'BaseballCard')) {
    const initials = getPositionInitials(reward.card.player.cardPositions[0]);

    return [
      <img
        key={0}
        src={reward.card.player.activeClub?.pictureUrl || ''}
        alt=""
      />,
      <Title3 key={1}>{initials}</Title3>,
    ];
  }

  if (!nbaReferralReward) return [];

  const initials = getPositionInitials(
    nbaReferralReward.card.anyPlayer.anyPositions[0]
  );

  return [
    <img
      key={0}
      src={nbaReferralReward.card.anyTeam?.pictureUrl || ''}
      alt=""
    />,
    <Title3 key={1}>{initials}</Title3>,
  ];
};

export const useReferralRewards = (
  rewards: useReferralRewards_referralReward[],
  sport?: Sport | null
) => {
  const effectiveSport =
    sport || rewards.find(reward => !!reward.card)?.card?.sport;
  const claim = useClaimReferralReward(effectiveSport);

  const query =
    referralRewardsQueryBySport[effectiveSport || Sport.FOOTBALL] ||
    NBA_REFERRAL_REWARDS_QUERY;

  const usSportReferralIds = useMemo(
    () =>
      rewards
        .filter(reward => isType(reward.card, 'NBACard'))
        .map(reward => idFromObject(reward.id)),
    [rewards]
  );

  // The tupple is required to resolve complex type here
  const { data, loading } = useQuery<
    ResultOf<ReferralRewardsQueryType>,
    ReferralRewardsQueryVariablesType
  >(query, {
    variables: {
      referralIDs: usSportReferralIds,
    },
    skip: usSportReferralIds.length === 0 || !query,
  });

  const nbaReferralRewards = useMemo<
    Record<string, NBAReferralReward> | undefined
  >(() => {
    if (loading) {
      return undefined;
    }
    return data?.usSportReferralRewards?.reduce<
      Record<string, NBAReferralReward>
    >((result, referralSport) => {
      result[referralSport.id] = referralSport;
      return result;
    }, {});
  }, [loading, data?.usSportReferralRewards]);

  const isClaimed = (reward: useReferralRewards_referralReward) => {
    if (!reward || loading) return false;
    if (
      isFootballCardReward(reward) ||
      isConversionCreditReward(reward) ||
      isType(reward.card, 'BaseballCard')
    ) {
      return reward.shippingState === ShippingState.CLAIMED;
    }
    if (isType(reward.card, 'NBACard')) {
      return (
        !!nbaReferralRewards &&
        nbaReferralRewards[idFromObject(reward.id)]?.state ===
          RewardState.CLAIMED
      );
    }
    return false;
  };

  const makeCardBackAndTeaser = (reward: useReferralRewards_referralReward) => {
    const nbaReferralReward = nbaReferralRewards?.[idFromObject(reward.id)];
    return {
      cardback: cardBackFactory(reward, nbaReferralReward),
      teasers: teaserFactory(reward, nbaReferralReward),
    };
  };

  return {
    claim,
    isClaimed,
    makeCardBackAndTeaser,
  };
};

useReferralRewards.fragments = {
  referralReward: gql`
    fragment useReferralRewards_referralReward on ReferralReward {
      id
      shippingState
      conversionCredit {
        id
        maxDiscount {
          ...MonetaryAmountFragment_monetaryAmount
        }
        percentageDiscount
      }
      card: anyCard {
        slug
        sport
        rarityTyped
        player: anyPlayer {
          slug
          age
          cardPositions
          activeClub {
            slug
            pictureUrl
          }
        }
        ... on Card {
          slug
          backPictureUrl
        }
      }
      ...useClaimReferralReward_referralReward
    }
    ${monetaryAmountFragment}
    ${useClaimReferralReward.fragments.referralReward}
  ` as TypedDocumentNode<useReferralRewards_referralReward>,
};
