import classnames from 'classnames';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';

import Button, { Props as ButtonProps } from '../Button';

export interface Props extends Omit<ButtonProps, 'size'> {
  icon?: IconDefinition;
  shadow?: boolean;
  small?: boolean;
  smaller?: boolean;
}

const Root = styled(Button)`
  padding: 0;
  min-width: unset;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
  &.small {
    min-width: auto;
    width: var(--quadruple-unit);
    aspect-ratio: 1 / 1;
    height: auto;
  }
  &.smaller {
    min-width: auto;
    width: var(--triple-unit);
    aspect-ratio: 1 / 1;
    height: auto;
  }

  &.shadow {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 12px;
`;

export const IconButton = forwardRef<HTMLElement, Props>(
  function IconButton(props, ref) {
    const {
      children,
      icon,
      small,
      smaller,
      shadow = false,
      className,
      ...rest
    } = props;

    return (
      <Root
        ref={ref}
        {...rest}
        size="medium"
        className={classnames({ small, smaller, shadow }, className)}
      >
        <Label>{icon ? <FontAwesomeIcon icon={icon} /> : children}</Label>
      </Root>
    );
  }
);

export default IconButton;
