import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title4 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import idFromObject from '@sorare/core/src/gql/idFromObject';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  USSportsConfirmCardLineupMutation,
  USSportsConfirmCardLineupMutationVariables,
} from './__generated__/index.graphql';

const US_SPORTS_CONFIRM_NO_CARD_LINEUP_MUTATION = gql`
  mutation USSportsConfirmCardLineupMutation(
    $userID: UUID!
    $lineupID: UUID!
    $leaderboardID: UUID!
  ) {
    confirmNoCardLineupLeaderboard(
      userID: $userID
      lineupID: $lineupID
      leaderboardID: $leaderboardID
    ) {
      id
    }
  }
` as TypedDocumentNode<
  USSportsConfirmCardLineupMutation,
  USSportsConfirmCardLineupMutationVariables
>;

const Wrapper = styled(Vertical).attrs({ gap: 4, center: true })`
  padding: var(--quadruple-unit);

  font: var(--t-14);
  text-align: center;
  justify-content: stretch;
`;

type Props = {
  lobbyRoute: string;
};

export const NoCardEntryConfirm = ({ lobbyRoute }: Props) => {
  const [params] = useSearchParams();
  const { currentUser } = useCurrentUserContext();
  const [submitted, setSubmitted] = useState(false);

  const userID = idFromObject(currentUser?.id);
  const lineupID = params.get('lineup_id');
  const leaderboardID = params.get('leaderboard_id');

  const [confirmNoCardLineupMutation] = useMutation(
    US_SPORTS_CONFIRM_NO_CARD_LINEUP_MUTATION,
    {
      warnIfNoErrorHandling: false,
    }
  );

  useEffect(() => {
    const submit = async () => {
      if (!submitted && userID && lineupID && leaderboardID) {
        await confirmNoCardLineupMutation({
          variables: { userID, lineupID, leaderboardID },
        });
        setSubmitted(true);
      }
    };

    submit();
  }, [confirmNoCardLineupMutation, userID, submitted, lineupID, leaderboardID]);

  const invalid = !userID || !lineupID || !leaderboardID;

  return (
    <Wrapper>
      {submitted ? (
        <Title4>
          <FormattedMessage
            id="NoCardEntryConfirm.title"
            defaultMessage="Your team is confirmed and will be submitted in the Game Week!"
          />
        </Title4>
      ) : (
        <>
          {invalid ? (
            <Title4>
              <FormattedMessage
                id="NoCardEntryCancel.errorTitle"
                defaultMessage="An error occured"
              />
            </Title4>
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
      {(submitted || invalid) && (
        <Button to={lobbyRoute} color="primary" size="medium">
          <FormattedMessage
            id="NoCardEntryConfirm.Cta.title"
            defaultMessage="Go back to the lobby"
          />
        </Button>
      )}
    </Wrapper>
  );
};
