import { useCallback, useState } from 'react';

const useScrollTo = <T>(idFactory: (src: T) => string) => {
  const [scrolled, setScrolled] = useState<string | undefined>(undefined);

  return useCallback(
    (ref: T) => {
      const id = idFactory(ref);
      if (!scrolled || scrolled !== id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            block: 'end',
            behavior: 'smooth',
          });
          setScrolled(id);
        }
      }
    },
    [scrolled, idFactory]
  );
};

export default useScrollTo;
