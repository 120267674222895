import React from 'react';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';

const Root = styled(Horizontal)`
  width: var(--triple-unit);
  height: var(--triple-unit);
  background-color: var(--c-red-600);
  border-radius: 50%;
  margin-left: auto;
`;
const BoldLabelS = styled(LabelS)`
  font-weight: bold;
`;

type Props = { className?: string };
export const NewActionBadge = ({
  children,
  className,
}: React.PropsWithChildren<Props>) => {
  return (
    <Root className={className} center>
      <BoldLabelS as="strong" color="var(--c-neutral-1000)">
        {children}
      </BoldLabelS>
    </Root>
  );
};
