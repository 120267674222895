import classNames from 'classnames';
import styled from 'styled-components';

import { useIntlContext } from 'contexts/intl';
import { range } from 'lib/arrays';
import { Color } from 'style/types';

type StatsType = {
  score: number;
  color: Color;
}[];

const Root = styled.svg`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--quadruple-unit);
  height: var(--triple-unit);
  border-radius: var(--half-unit);
  background-color: var(--c-neutral-300);
  &[role='button']:hover {
    cursor: pointer;
    background-color: var(--c-neutral-400);
  }

  &.outlined {
    outline: 1px solid var(--c-neutral-400);
  }
`;

const getRectPosition = (score: number) => {
  const isNegative = score < 0;

  // ensure the bar is not too thin if score between -10 and 10
  const baseHeight = Math.max(Math.abs(score), 10);

  // 1.2 = calculated height - paddings-y (2 * 0%)
  const heightRelatedToViewbox = baseHeight * 1.2;
  // 160 is the 0-axis = 80% of viewBox height (- padding-bottom)
  const totalHeightWithPaddingBottom = 200 - 40;

  return {
    // subtract the height from 160 if the score is positive
    y: totalHeightWithPaddingBottom - (isNegative ? 0 : heightRelatedToViewbox),
    height: heightRelatedToViewbox,
  };
};

const toScore = (stats: StatsType) => (i: number) => stats[i]?.score ?? 0;

type Props = {
  stats: StatsType;
  outlined?: boolean;
  multiplier?: number;
  onClick?: (e?: React.MouseEvent) => void;
};

const StatsIcon = ({ stats, multiplier = 1, onClick, outlined }: Props) => {
  const { formatMessage } = useIntlContext();
  const [one, two, three, four, five] = range(5).map(toScore(stats));
  return (
    <Root
      viewBox="0 0 300 200"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={formatMessage(
        {
          id: 'StatsIcon.label',
          defaultMessage:
            'Last 5 stats: {one}, {two}, {three}, {four} and {five}',
        },
        { one, two, three, four, five }
      )}
      onClick={onClick}
      role={onClick ? 'button' : 'img'}
      className={classNames({ outlined })}
    >
      {stats.map(({ score, color }, i) => {
        const { y, height } = getRectPosition(
          Math.min(110, score * multiplier)
        );
        return (
          <rect
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            x={(i + 1) * 50 - 15}
            y={y}
            width={30}
            height={height}
            rx={5}
            fill={color}
            aria-label="stat-icon-rect"
          />
        );
      })}
    </Root>
  );
};

export default StatsIcon;
