import { useSubscription } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Currency,
  DeviceWasUpdatedEvent,
  EnabledWallet,
  Sport,
} from '__generated__/globalTypes';
import createLink from 'atoms/typography/Link';
import { SETTINGS_SECURITY } from 'constants/__generated__/routes';
import { useGraphqlContext } from 'contexts/graphql';
import { isType } from 'gql';
import idFromObject from 'gql/idFromObject';
import { useIsAuthorizedRouteForSecondFactorRecommendation } from 'hooks/isAuthorizedRouteForSecondFactorRecommendation';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { currencies } from 'lib/fiat';
import { asObject } from 'lib/json';

import { VERSION } from '../../config';
import { useConfigContext } from '../config';
import { useDeviceFingerprintContext } from '../deviceFingerprint';
import { useEventsContext } from '../events';
import { useSentryContext } from '../sentry';
import { useSnackNotificationContext } from '../snackNotification';
import {
  CurrentUserProvider_userSportProfile,
  onDeviceWasUpdated,
  onDeviceWasUpdatedVariables,
} from './__generated__/queries.graphql';
import CurrentUserContextProvider, { SignInArgs } from './index';
import { onDeviceSubscription, subscription } from './queries';
import useRedirectAfterSignIn from './useRedirectAfterSignIn';
import useSignIn from './useSignIn';

interface Props {
  children: ReactNode;
}

/**
 * Provides the current logged in user.
 */
export const CurrentUserProvider = ({ children }: Props) => {
  const { setApiKey } = useGraphqlContext();
  const { identify: identifyAnalytics } = useEventsContext();
  const { identifyUser: identifySentryUser } = useSentryContext();
  const { currentUser, refetch, updateQuery, defaultFiatCurrency } =
    useConfigContext();
  const { showNotification } = useSnackNotificationContext();
  const [currentDeviceFingerPrint, setCurrentDeviceFingerPrint] =
    useState<string>();
  const [shouldResubscribe, setShouldResubscribe] = useState(false);
  const { untrackedFlags, identify: identifyFeatureFlagsUser } =
    useFeatureFlags();
  const { deviceFingerprint } = useDeviceFingerprintContext();
  const [signInMutation] = useSignIn();
  const redirectUser = useRedirectAfterSignIn();
  const isAuthorizedRouteForSecondFactorRecommendation =
    useIsAuthorizedRouteForSecondFactorRecommendation();
  const [
    hasShownSecondFactorRecommendation,
    setHasShownSecondFactorRecommendation,
  ] = useState(false);

  useEffect(() => {
    deviceFingerprint()
      .then(v => setCurrentDeviceFingerPrint(v))
      .then(() => setShouldResubscribe(true));
  }, [deviceFingerprint]);

  useSubscription(subscription, {
    skip: !currentUser,
    shouldResubscribe,
    onComplete: () => {
      setShouldResubscribe(false);
    },
    context: {
      headers: {
        DEVICE_FINGERPRINT: currentDeviceFingerPrint,
      },
    },
  });

  const currentDeviceConfirmed = currentUser?.confirmedDevice;
  const currentDeviceId = currentUser?.currentDevice?.id;
  useSubscription<onDeviceWasUpdated, onDeviceWasUpdatedVariables>(
    onDeviceSubscription,
    {
      skip: !currentDeviceId || !!currentDeviceConfirmed,
      onData: ({ data: { data } }) => {
        if (data) {
          const { deviceWasUpdated } = data;
          if (
            deviceWasUpdated &&
            deviceWasUpdated.eventType === DeviceWasUpdatedEvent.confirmed &&
            deviceWasUpdated.id === currentDeviceId
          ) {
            showNotification('deviceSuccessfullyConfirmed');
            refetch();
          }
        }
      },
    }
  );

  const signIn = useCallback(
    async (args: SignInArgs) => {
      const result = await signInMutation(args);

      if (result?.currentUser) {
        // update the currentConfigQuery with the signed in user
        updateQuery(result.currentUser);
        redirectUser(result.currentUser);
      }
      return result;
    },
    [signInMutation, updateQuery, redirectUser]
  );

  const blockchainCardsCount = currentUser
    ? currentUser.footballCardCounts.limited +
      currentUser.footballCardCounts.rare +
      currentUser.footballCardCounts.superRare +
      currentUser.footballCardCounts.unique
    : 0;

  useEffect(() => {
    if (currentUser?.id && identifyFeatureFlagsUser) {
      identifyFeatureFlagsUser({
        key: currentUser.id,
        custom: {
          ...asObject(currentUser.featureFlagCustomAttributes),
          webVersion: +VERSION,
        },
      });
    }
  }, [
    identifyFeatureFlagsUser,
    currentUser?.id,
    currentUser?.featureFlagCustomAttributes,
  ]);

  useEffect(() => {
    if (identifySentryUser) {
      identifySentryUser(
        currentUser
          ? {
              id: idFromObject(currentUser?.id),
              username: currentUser?.slug,
            }
          : null
      );
    }
  }, [currentUser, identifySentryUser]);

  useEffect(() => {
    if (currentUser?.apiKey) {
      setApiKey(currentUser?.apiKey);
    }
  }, [currentUser?.apiKey, setApiKey]);

  useEffect(() => {
    if (currentUser?.id) {
      identifyAnalytics(idFromObject(currentUser.id)!, {
        created: currentUser?.createdAt,
        mlbOnboarded: currentUser?.baseballUserProfile?.onboarded,
        feature_flags: untrackedFlags,
      });
    }
  }, [
    currentUser?.id,
    currentUser?.createdAt,
    identifyAnalytics,
    untrackedFlags,
    currentUser?.baseballUserProfile?.onboarded,
  ]);

  const availableBalanceForWithdrawalPositive = currentUser
    ? currentUser.availableBalanceForWithdrawal > 0n
    : false;

  useEffect(() => {
    if (
      !currentUser?.otpRequiredForLogin &&
      currentUser?.confirmed &&
      (blockchainCardsCount > 0 || availableBalanceForWithdrawalPositive)
    ) {
      if (
        isAuthorizedRouteForSecondFactorRecommendation &&
        !hasShownSecondFactorRecommendation
      ) {
        setHasShownSecondFactorRecommendation(true);
        showNotification('secondFactorRecommendation', {
          link: createLink(SETTINGS_SECURITY),
        });
      }
    }
  }, [
    currentUser?.id,
    currentUser?.otpRequiredForLogin,
    currentUser?.confirmed,
    availableBalanceForWithdrawalPositive,
    blockchainCardsCount,
    showNotification,
    isAuthorizedRouteForSecondFactorRecommendation,
    hasShownSecondFactorRecommendation,
    setHasShownSecondFactorRecommendation,
  ]);

  const fiatWalletAccountable = useMemo(() => {
    if (!currentUser) return null;
    return currentUser.myAccounts
      .map(a =>
        isType(a.accountable, 'PrivateFiatWalletAccount') ? a.accountable : null
      )
      .filter(Boolean)?.[0];
  }, [currentUser]);

  const fiatCurrency = useMemo(() => {
    if (fiatWalletAccountable?.publicInfo.currency) {
      return currencies[
        fiatWalletAccountable.publicInfo.currency.toLowerCase()
      ];
    }
    return (
      currencies[
        currentUser?.userSettings.fiatCurrency?.toLowerCase() as keyof typeof currencies
      ] || defaultFiatCurrency
    );
  }, [
    currentUser?.userSettings.fiatCurrency,
    defaultFiatCurrency,
    fiatWalletAccountable,
  ]);

  const enabledWallets = currentUser?.profile.enabledWallets || undefined;

  const hasMigratedAndSetupWallets = !!enabledWallets;

  const showEthWallet = hasMigratedAndSetupWallets
    ? enabledWallets.includes(EnabledWallet.ETH)
    : true;

  const showFiatWallet = !!enabledWallets?.includes(EnabledWallet.FIAT);

  const onlyShowFiatCurrency = showFiatWallet && !showEthWallet;

  const emailConfirmationNeeded = !currentUser?.confirmed;
  const currentDeviceConfirmationNeeded = !currentDeviceConfirmed;

  const currency = useMemo(() => {
    if (onlyShowFiatCurrency) return Currency.FIAT;
    return currentUser?.userSettings.currency || Currency.FIAT;
  }, [currentUser?.userSettings.currency, onlyShowFiatCurrency]);

  const displayEth = useMemo(
    () =>
      Boolean(
        currentUser?.depositedEth ||
          (currentUser?.availableBalanceForWithdrawal &&
            currentUser?.availableBalanceForWithdrawal > 0n)
      ),
    [currentUser]
  );

  const sportProfileBySport: Record<
    Sport,
    Nullable<CurrentUserProvider_userSportProfile>
  > = useMemo(
    () => ({
      [Sport.FOOTBALL]: currentUser?.footballUserProfile,
      [Sport.NBA]: currentUser?.nbaUserProfile,
      [Sport.BASEBALL]: currentUser?.baseballUserProfile,
    }),
    [
      currentUser?.baseballUserProfile,
      currentUser?.footballUserProfile,
      currentUser?.nbaUserProfile,
    ]
  );

  const {
    footballLast30DaysLineupsCount = 0,
    footballChallengesManagerProgression,
    currentFootballRivalsManager,
  } = currentUser || {};
  const { points = 0, levelReached = 0 } =
    footballChallengesManagerProgression || {};
  const onboardedFootball =
    levelReached + points > 0 ||
    footballLast30DaysLineupsCount > 0 ||
    !!currentFootballRivalsManager?.sawKickoffWelcomeToKickoff;

  const value = useMemo(
    () => ({
      currentUser,
      onboarded: {
        FOOTBALL: onboardedFootball,
        BASEBALL: false,
        NBA: false,
      },
      currency,
      fiatCurrency,
      fiatWalletAccountable,
      displayEth,
      refetch,
      signIn,
      blockchainCardsCount,
      walletPreferences: {
        enabledWallets,
        showEthWallet,
        showFiatWallet,
        hasMigratedAndSetupWallets,
        onlyShowFiatCurrency,
      },
      emailConfirmationNeeded,
      currentDeviceConfirmationNeeded,
      sportProfileBySport,
    }),
    [
      currentUser,
      onboardedFootball,
      currency,
      fiatCurrency,
      fiatWalletAccountable,
      displayEth,
      refetch,
      signIn,
      blockchainCardsCount,
      enabledWallets,
      showEthWallet,
      showFiatWallet,
      hasMigratedAndSetupWallets,
      onlyShowFiatCurrency,
      emailConfirmationNeeded,
      currentDeviceConfirmationNeeded,
      sportProfileBySport,
    ]
  );

  return (
    <CurrentUserContextProvider value={value}>
      {children}
    </CurrentUserContextProvider>
  );
};

export default CurrentUserProvider;
