import { ReactEventHandler, useEffect, useState } from 'react';

export const useVideoAutoPlay = (
  ref: React.RefObject<HTMLVideoElement>,
  autoPlay: boolean,
  onError?: ReactEventHandler<HTMLVideoElement>
) => {
  const [videoStarted, setVideoStarted] = useState(false);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (ref.current && autoPlay && !videoStarted) {
      const playPromise = ref.current.play();

      playPromise
        ?.then(() => {
          setVideoStarted(true);
        })
        .catch(() => {
          // Autoplay not allowed!
          // Mute video and try to play again
          if (ref.current) {
            ref.current.muted = true;
            ref.current.play().catch(
              // 2nd trigger not working, call onError if provided
              onError
            );
            setMuted(true);
          }
        });
    }
    // onError change should not trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, ref, videoStarted]);

  return { muted };
};
