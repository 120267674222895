import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import { Horizontal } from 'atoms/layout/flex';
import { NewActionBadge } from 'components/NewActionBadge';
import Avatar from 'components/user/Avatar';
import { ANY_SPORT_MY_CLUB } from 'constants/routes';
import { generateSportPath } from 'lib/routing/generateSportPath';
import { Link } from 'routing/Link';

import { RivalsAvatar_footballRivalsManager } from './__generated__/index.graphql';

const RootLink = styled(Link)`
  width: fit-content;
`;
const AvatarWrapper = styled(Horizontal).attrs({ center: true })`
  position: relative;
  isolation: isolate;
  border-radius: 50%;
  border: 2px solid var(--c-neutral-100);
`;
const TopRightOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--half-unit));
  right: calc(-1 * var(--half-unit));
`;
const BottomRightOverlay = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: calc(-1 * var(--half-unit));
`;

type Props = {
  manager: RivalsAvatar_footballRivalsManager;
  url?: string;
  onClick?: () => void;
  seen?: boolean;
  hideStatus?: boolean;
  showReceivedInvitesCount?: boolean;
  bottomRightContent?: ReactNode;
} & Omit<ComponentProps<typeof Avatar>, 'user'>;
export const RivalsAvatar = ({
  manager,
  url,
  onClick,
  seen,
  hideStatus,
  showReceivedInvitesCount,
  bottomRightContent,
  ...rest
}: Props) => {
  const { user, myUpcomingReceivedInvitesCount } = manager;

  const getUrl = () => {
    return (
      url ||
      generateSportPath(ANY_SPORT_MY_CLUB, {
        params: { slug: user.slug },
        sport: Sport.FOOTBALL,
      })
    );
  };

  const content = (
    <AvatarWrapper>
      {myUpcomingReceivedInvitesCount > 0 && showReceivedInvitesCount && (
        <TopRightOverlay>
          <NewActionBadge>{myUpcomingReceivedInvitesCount}</NewActionBadge>
        </TopRightOverlay>
      )}
      {bottomRightContent && (
        <BottomRightOverlay>{bottomRightContent}</BottomRightOverlay>
      )}
      <Avatar size={8} rounded variant="responsive" user={user} {...rest} />
    </AvatarWrapper>
  );

  return (
    <RootLink to={getUrl()} onClick={onClick}>
      {content}
    </RootLink>
  );
};

RivalsAvatar.fragments = {
  footballRivalsManager: gql`
    fragment RivalsAvatar_footballRivalsManager on FootballRivalsManager {
      id
      myUpcomingReceivedInvitesCount
      user {
        slug
        ...Avatar_user
      }
    }
    ${Avatar.fragments.user}
  ` as TypedDocumentNode<RivalsAvatar_footballRivalsManager>,
};
