import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ButtonBase from 'atoms/buttons/ButtonBase';
import LoadingButton from 'atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Text16 } from 'atoms/typography';
import Bold from 'atoms/typography/Bold';
import { laptopAndAbove } from 'style/mediaQuery';

const Root = styled(ButtonBase)`
  display: flex;
  gap: var(--unit);
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 16px;
  padding: var(--double-unit);
  background: rgba(var(--c-rgb-yellow-600), 0.2);
  text-align: left;
  width: 100%;
  b {
    color: var(--c-yellow-800);
  }
  @media ${laptopAndAbove} {
    flex-wrap: nowrap;
  }
`;
const Info = styled.div`
  display: flex;
  gap: var(--unit);
`;
const ButtonWrapper = styled(LoadingButton)`
  width: 100%;
  @media ${laptopAndAbove} {
    flex-shrink: 0;
    width: auto;
  }
`;

type Props = {
  onClick?: () => Promise<void>;
  loading?: boolean;
};
const Warning = ({ onClick, loading }: Props) => {
  return (
    <Root as={onClick ? 'button' : 'div'} type="button" onClick={onClick}>
      <Info>
        <Text16 color="var(--c-yellow-600)">
          <FontAwesomeIcon icon={faInfoCircle} />
        </Text16>
        <Text16>
          <FormattedMessage
            id="collections.Warning.message"
            defaultMessage="If a card is <b>listed in the market</b> the points do not count in the collection score"
            values={{ b: Bold }}
          />
        </Text16>
      </Info>
      {onClick && (
        <ButtonWrapper
          asSpan
          color="secondary"
          size="small"
          loading={!!loading}
        >
          <FormattedMessage
            id="collections.Warning.cta"
            defaultMessage="Unlist"
          />
        </ButtonWrapper>
      )}
    </Root>
  );
};

export default Warning;
