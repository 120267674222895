import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import JerseyRewardPreview from 'components/rewards/deliverableItem/DeliverableItemFooter/jersey/JerseyPreview';

import { DeliverableItemPreview_deliverableItem } from './__generated__/index.graphql';

type Props = {
  deliverableItem: DeliverableItemPreview_deliverableItem;
  hideSignedVariant?: boolean;
};

const DeliverableItemPreview = ({
  deliverableItem,
  hideSignedVariant,
}: Props) => {
  if (isType(deliverableItem, 'JerseyDeliverableItem'))
    return (
      <JerseyRewardPreview
        jersey={deliverableItem}
        hideSignedVariant={hideSignedVariant}
      />
    );
  return null;
};

DeliverableItemPreview.fragments = {
  deliverableItem: gql`
    fragment DeliverableItemPreview_deliverableItem on DeliverableItemInterface {
      slug
      id
      ... on JerseyDeliverableItem {
        slug
        id
        ...JerseyRewardPreview_JerseyDetails
      }
      ... on WearableDeliverableItem {
        slug
        id
        signed
      }
    }
    ${JerseyRewardPreview.fragments.jersey}
  ` as TypedDocumentNode<DeliverableItemPreview_deliverableItem>,
};

export default DeliverableItemPreview;
