import { useEffect, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { So5State } from '@sorare/core/src/__generated__/globalTypes';
import Button from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import LoadingIndicator from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title3, Title5 } from '@sorare/core/src/atoms/typography';
import { FOOTBALL_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

import Header from 'pages/no-card-route/$so5FixtureId/Header';

const Root = styled(Vertical).attrs({ gap: 3 })``;
const CenteredFlexContainer = styled(Vertical).attrs({ gap: 2, center: true })`
  align-self: center;
  justify-content: stretch;
`;

type Props = {
  mutate: () => Promise<boolean>;
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  postAction?: 'lobby' | 'email';
};

const Form = ({ mutate, title, subtitle, postAction }: Props) => {
  const [mutationStarted, setMutationStarted] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (!mutationStarted) {
      setMutationStarted(true);
      mutate().then(setSuccess);
    }
  }, [mutationStarted, mutate]);

  return (
    <Root>
      <Header />
      {success === null && <LoadingIndicator />}
      <CenteredFlexContainer>
        {success && (
          <>
            {title && (
              <Title3>
                <FormattedMessage {...title} />
              </Title3>
            )}
            {subtitle && (
              <Title5>
                <FormattedMessage {...subtitle} />
              </Title5>
            )}
          </>
        )}
        {success === false && (
          <Title3>
            <FormattedMessage
              id="NoCardEntry.Form.errorTitle"
              defaultMessage="An error occured"
            />
          </Title3>
        )}
        {postAction === 'lobby' && (
          <Button
            to={generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
              fixture: So5State.UPCOMING.toLowerCase(),
            })}
            color="primary"
            size="medium"
          >
            <FormattedMessage
              id="NoCardEntry.Form.Cta.title"
              defaultMessage="Go back to the lobby"
            />
          </Button>
        )}
        {postAction === 'email' && (
          <Title3>
            <FormattedMessage
              id="NoCardEntry.Form.email"
              defaultMessage="Please check your emails"
            />
          </Title3>
        )}
      </CenteredFlexContainer>
    </Root>
  );
};

export default Form;
