import { FormattedMessage } from 'react-intl';

import { RewardType, SimpleReward } from '@sorare/core/src/lib/rewards';

type Props = {
  reward: SimpleReward;
};
export const LeagueRewardItemDescription = ({ reward }: Props) => {
  const { description, type } = reward;
  if (description) {
    return description;
  }

  if (type === RewardType.ARENA_TICKET) {
    return (
      <FormattedMessage
        id="LeagueRewardItemDescription.arenaTickets"
        defaultMessage="{count, plural, one {#x Arena Ticket} other {#x Arena Tickets}} for each member"
        values={{ count: reward.count }}
      />
    );
  }
  if (type === RewardType.SHARD) {
    return (
      <FormattedMessage
        id="LeagueRewardItemDescription.shards"
        defaultMessage="{count, plural, one {#x Card Essence} other {#x Card Essence}} for each member"
        values={{ count: reward.count }}
      />
    );
  }
  return type;
};
