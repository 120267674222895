import Switch from 'atoms/inputs/Switch';
import { SBHorizontal } from 'atoms/layout/flex';
import { Caption, Text16 } from 'atoms/typography';
import FormLabel from 'components/form/FormLabel';

import Field from '../Field';

export interface Props {
  name: string;
  defaultValue: boolean;
  label: string;
  helperText?: string;
}

export const SwitchField = ({
  name,
  defaultValue,
  label,
  helperText,
}: Props) => {
  return (
    <Field
      name={name}
      defaultValue={defaultValue ? SwitchField.ON : SwitchField.OFF}
      render={({ error, handleChange, value, disabled }) => {
        return (
          <>
            <SBHorizontal gap={0}>
              <div>
                <FormLabel id={name}>{label}</FormLabel>
                {helperText && (
                  <Caption color="var(--c-neutral-600)">{helperText}</Caption>
                )}
              </div>
              <Switch
                name={name}
                checked={value === SwitchField.ON}
                onChange={e => {
                  handleChange(
                    e.target.checked ? SwitchField.ON : SwitchField.OFF,
                    e.target
                  );
                }}
                disabled={disabled}
              />
            </SBHorizontal>

            {error && <Text16 color="var(--c-red-600)">{error}</Text16>}
          </>
        );
      }}
    />
  );
};

// The Field component is dealing with strings only, so we introduce 2 string values
// to deal with the boolean state of the switch
SwitchField.ON = 'on';
SwitchField.OFF = 'off';

export default SwitchField;
