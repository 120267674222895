import { Navigate } from 'react-router-dom';

import {
  NBA_PLAY_PRO_MY_LINEUPS_ID,
  NBA_PLAY_PRO_MY_LINEUPS_ID_TEAM,
} from '@sorare/core/src/constants/__generated__/routes';
import { relative } from '@sorare/core/src/lib/routing';

const Page = () => (
  <Navigate
    to={relative(NBA_PLAY_PRO_MY_LINEUPS_ID, NBA_PLAY_PRO_MY_LINEUPS_ID_TEAM)}
    replace
  />
);

export default Page;
