import { TypedDocumentNode, gql } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import dompurify from 'dompurify';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import IconButton from 'atoms/buttons/IconButton';
import { Vertical } from 'atoms/layout/flex';
import { Caption, Title2 } from 'atoms/typography';
import { ACTIVITY_NEWS_ID } from 'constants/__generated__/routes';
import { ACTIVITY } from 'constants/routes';
import { useIntlContext } from 'contexts/intl';
import idFromObject from 'gql/idFromObject';
import useEvents from 'lib/events/useEvents';
import { sportsLabelsMessages } from 'lib/glossary';
import getSafePreviousLocation from 'lib/navigation/getSafePreviousLocation';
import { isExternalDomain, toRelative } from 'lib/urls';
import { ClickAnnouncementLink } from 'protos/events/platform/web/events';
import { Link } from 'routing/Link';

import { Announcement_announcement } from './__generated__/index.graphql';

type Props = {
  announcement: Announcement_announcement;
};

const Root = styled(Vertical).attrs({ gap: 2 })`
  position: relative;
  img {
    max-width: 100%;
    max-height: 30vh;
    display: block;
    margin: auto;
  }
  & a {
    color: var(--c-link);
    text-decoration: underline;
  }
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle2 = styled(Title2)`
  && {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AnnouncementFigure = styled.figure`
  margin-bottom: var(--quadruple-unit);
`;

export const Announcement = ({ announcement }: Props) => {
  const { formatDistanceToNow } = useIntlContext();
  const track = useEvents();
  const safePreviousLocation = getSafePreviousLocation(ACTIVITY);
  const { id, title, content, createdAt, pictureUrl, sport } = announcement;

  const trackClickLink = useCallback(
    (a: HTMLAnchorElement) => {
      const params: ClickAnnouncementLink = {
        announcementId: id,
        announcementTitle: title,
        linkHref: a.href,
      };
      track('Click Announcement Link', params);
    },
    [track, id, title]
  );

  const preparedContent = useMemo(() => {
    const div = document.createElement('div');
    div.innerHTML = content;

    div.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
      const isExternal = isExternalDomain(a.href);

      if (isExternal) {
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
      } else {
        a.href = toRelative(a.href);
      }
    });

    return dompurify.sanitize(div.innerHTML, { ADD_ATTR: ['target'] });
  }, [content]);

  return (
    <Root id={idFromObject(announcement.id)}>
      <Header>
        <div>
          <StyledTitle2
            as={Link}
            to={generatePath(ACTIVITY_NEWS_ID, {
              id: idFromObject(announcement.id),
            })}
          >
            {title}
          </StyledTitle2>
          <Caption color="var(--c-neutral-600)">
            {sport ? (
              <FormattedMessage {...sportsLabelsMessages[sport]} />
            ) : (
              <FormattedMessage
                id="Notification.global"
                defaultMessage="Global"
              />
            )}
            {' • '}
            {formatDistanceToNow(createdAt)}
          </Caption>
        </div>
        <IconButton
          color="tertiary"
          icon={faTimes}
          to={safePreviousLocation as string}
        />
      </Header>
      {!!pictureUrl && (
        <AnnouncementFigure>
          <img src={pictureUrl} alt="" />
        </AnnouncementFigure>
      )}
      {/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: preparedContent }}
        onClick={e => {
          const target = e.target as HTMLElement;
          const link = target.closest('a');
          if (link) {
            trackClickLink(link);
          }
        }}
      />
      {/* eslint-enable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
    </Root>
  );
};

Announcement.fragments = {
  announcement: gql`
    fragment Announcement_announcement on Announcement {
      id
      pictureUrl
      title
      content
      createdAt
      sport
    }
  ` as TypedDocumentNode<Announcement_announcement>,
};

export default Announcement;
