import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';

import AffiliateProgramContent from 'AffiliateProgram';

const AffiliateProgram = () => {
  return (
    <AppLayout>
      <AffiliateProgramContent />
    </AppLayout>
  );
};

export default AffiliateProgram;
