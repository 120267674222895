import styled from 'styled-components';

import coin from 'assets/coin.png';
import { tabletAndAbove } from 'style/mediaQuery';

const Img = styled.img`
  width: var(--intermediate-unit);
  height: var(--intermediate-unit);
  @media ${tabletAndAbove} {
    width: var(--double-unit);
    height: var(--double-unit);
  }
`;
const Coin = () => {
  return <Img src={coin} alt="Coin" />;
};

export default Coin;
