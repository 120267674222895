import { generatePath } from 'react-router-dom';

import {
  FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG,
  FOOTBALL_MARKET,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_MY_CLUB_SLUG_HISTORY,
  FOOTBALL_PLAY_PRO,
  FOOTBALL_PLAY_PRO_MY_LINEUPS_ID,
  FOOTBALL_PLAY_RIVALS_ARENA,
  FOOTBALL_PLAY_RIVALS_FEATURED_INVITE_INVITEID,
  FOOTBALL_PLAY_RIVALS_GAMES_ID,
  FOOTBALL_PLAY_RIVALS_LEAGUES,
  FOOTBALL_PLAY_RIVALS_LEAGUES_JOIN_JOINSECRET,
  FOOTBALL_PLAY_RIVALS_LEAGUES_LEADERBOARD_PRIZE_POOL,
  FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG,
  FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_LEADERBOARDS,
  FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MATCHES,
  FOOTBALL_PLAY_RIVALS_MATCHES,
  FOOTBALL_RIVALS_INVITES_CODE,
  INVITE,
  LANDING,
  MLB,
  MLB_COMPOSE_TEAM_LEADERBOARDSLUG,
  MLB_MARKET,
  MLB_MY_CLUB_SLUG_HISTORY,
  MLB_PLAY_PRO,
  MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP,
  NBA_COMPOSE_TEAM_LEADERBOARDSLUG,
  NBA_MARKET,
  NBA_MY_CLUB_SLUG_HISTORY,
  NBA_PLAY_PRO,
  NBA_PLAY_PRO_MY_LINEUPS,
} from '@sorare/core/src/constants/__generated__/routes';
import { FOOTBALL_HOME, NBA_HOME } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSentryContext } from '@sorare/core/src/contexts/sentry';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import useQueryString from '@sorare/core/src/hooks/useQueryString';
import { Navigate } from '@sorare/core/src/routing/Navigate';

function parseIntent(uri: string) {
  // HACK: Firefox and Chrome currently do not parse non special urls correctly
  // https://github.com/nodejs/node/issues/37192#issuecomment-772004612
  // We work around this by setting a special scheme to extract host, pathname and searchParams
  const { host, pathname, searchParams } = new URL(
    uri.replace(/^sorare:\/\//, 'http://')
  );

  return { host, pathname, searchParams };
}

// The flutter app redirects to this page when it receives a push notification with a deeplink. This hook maps the deeplink to the right page
const useResolveDeeplink = (
  uri: string | null | undefined
): null | string | { to: string; state: any } => {
  const { currentUser } = useCurrentUserContext();
  const { sendSafeError } = useSentryContext();
  const {
    flags: { rivalsSquadsThirdIteration = false },
  } = useFeatureFlags();

  if (!uri || !currentUser) {
    sendSafeError(new Error('Deeplink without uri or currentUser'));
    return null;
  }

  const { host, pathname, searchParams } = parseIntent(uri);

  if (host === 'so5fixture' || (host === 'so5' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return FOOTBALL_PLAY_PRO;
    }
    return generatePath(FOOTBALL_MY_CLUB_SLUG_HISTORY, {
      slug: currentUser.slug,
    });
  }
  if (host === 'nbafixture' || (host === 'nba' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return NBA_PLAY_PRO;
    }
    return generatePath(NBA_MY_CLUB_SLUG_HISTORY, { slug: currentUser.slug });
  }
  if (host === 'mlbfixture' || (host === 'mlb' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return MLB_PLAY_PRO;
    }
    return generatePath(MLB_MY_CLUB_SLUG_HISTORY, { slug: currentUser.slug });
  }

  if (host === 'so5lineup' || (host === 'so5' && pathname === '/lineup')) {
    return generatePath(FOOTBALL_PLAY_PRO_MY_LINEUPS_ID, {
      id: searchParams.get('id'),
    });
  }
  if (host === 'nbalineup' || (host === 'nba' && pathname === '/lineup')) {
    return generatePath(NBA_PLAY_PRO_MY_LINEUPS, {
      id: searchParams.get('id'),
    });
  }
  if (host === 'mlblineup' || (host === 'mlb' && pathname === '/lineup')) {
    return generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP, {
      fixture: 'ongoing',
      lineup: searchParams.get('id'),
    });
  }

  if (
    host === 'so5composeteam' ||
    (host === 'so5' && pathname === '/composeteam')
  ) {
    return generatePath(FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }
  if (
    host === 'nbacomposeteam' ||
    (host === 'nba' && pathname === '/composeteam')
  ) {
    return generatePath(NBA_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }
  if (
    host === 'mlbcomposeteam' ||
    (host === 'mlb' && pathname === '/composeteam')
  ) {
    return generatePath(MLB_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }

  if (
    host === 'so5' &&
    (pathname === '/rivals/game' || pathname === '/rivals/game/story')
  ) {
    // TODO: handle &action=starting_lineup?
    return generatePath(FOOTBALL_PLAY_RIVALS_GAMES_ID, {
      id: searchParams.get('id'),
    });
  }

  if (host === 'managerprofile') {
    return generatePath(FOOTBALL_MY_CLUB_SLUG, {
      slug: searchParams.get('slug'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/matches') {
    if (rivalsSquadsThirdIteration) {
      return FOOTBALL_PLAY_RIVALS_ARENA;
    }
    return FOOTBALL_PLAY_RIVALS_MATCHES;
  }

  if (host === 'so5' && pathname === '/rivals/arena/claim') {
    return generatePath(FOOTBALL_PLAY_RIVALS_ARENA);
  }

  if (host === 'so5' && pathname === '/rivals/arena') {
    return generatePath(FOOTBALL_PLAY_RIVALS_ARENA);
  }

  if (host === 'so5' && pathname === '/rivals/invite') {
    return generatePath(FOOTBALL_RIVALS_INVITES_CODE, {
      code: searchParams.get('code'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/featured/invite') {
    return generatePath(FOOTBALL_PLAY_RIVALS_FEATURED_INVITE_INVITEID, {
      inviteId: searchParams.get('id'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/league/join') {
    return generatePath(FOOTBALL_PLAY_RIVALS_LEAGUES_JOIN_JOINSECRET, {
      joinSecret: searchParams.get('code'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/leagues') {
    return FOOTBALL_PLAY_RIVALS_LEAGUES;
  }

  if (host === 'so5' && pathname === '/rivals/leagues/create') {
    return { to: FOOTBALL_PLAY_RIVALS_LEAGUES, state: { create: true } };
  }

  if (host === 'so5' && pathname === '/rivals/leagues/join') {
    return { to: FOOTBALL_PLAY_RIVALS_LEAGUES, state: { join: true } };
  }

  if (host === 'so5' && pathname === '/rivals/leagues/rewards') {
    return FOOTBALL_PLAY_RIVALS_LEAGUES_LEADERBOARD_PRIZE_POOL;
  }

  if (host === 'so5' && pathname === '/rivals/leagues/activity') {
    return generatePath(FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG, {
      slug: searchParams.get('slug'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/leagues/matches') {
    return generatePath(FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_MATCHES, {
      slug: searchParams.get('slug'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/leagues/leaderboard') {
    return generatePath(FOOTBALL_PLAY_RIVALS_LEAGUES_SLUG_LEADERBOARDS, {
      slug: searchParams.get('slug'),
    });
  }

  if (host === 'so5' && pathname === '/market') {
    return FOOTBALL_MARKET;
  }
  if (host === 'nba' && pathname === '/market') {
    return NBA_MARKET;
  }
  if (host === 'mlb' && pathname === '/market') {
    return MLB_MARKET;
  }

  if (host === 'mlbhome' || (host === 'mlb' && pathname === '/home')) {
    return MLB;
  }

  if (host === 'nbahome' || (host === 'nba' && pathname === '/home')) {
    return NBA_HOME;
  }

  if (host === 'so5home' || (host === 'so5' && pathname === '/home')) {
    return FOOTBALL_HOME;
  }

  if (host === 'referrals') {
    return INVITE;
  }

  sendSafeError(new Error(`Unhandled deeplink: ${uri}`));
  return null;
};

export const Deeplink = () => {
  const uri = useQueryString('uri');
  const to = useResolveDeeplink(uri);

  if (to === null) {
    return <Navigate to={LANDING} replace />;
  }

  if (typeof to === 'string') {
    return <Navigate to={to} replace />;
  }

  return <Navigate to={to.to} state={to.state} replace />;
};
