import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import FootballTeam from './FootballTeam';
import USSportsTeam from './USSportTeam';
import { Team_anyCard } from './__generated__/index.graphql';

interface Props {
  card: Team_anyCard;
}

export const Team = ({ card }: Props) => {
  if (isType(card, 'Card')) {
    return <FootballTeam card={card} />;
  }
  if (isType(card, 'NBACard') || isType(card, 'BaseballCard')) {
    return <USSportsTeam card={card} />;
  }

  return null;
};

Team.fragments = {
  anyCard: gql`
    fragment Team_anyCard on AnyCardInterface {
      slug
      ... on Card {
        slug
        ...FootballTeam_card
      }
      ... on CardInterface {
        slug
        ...USSportsTeam_CardInterface
      }
    }
    ${FootballTeam.fragments.card}
    ${USSportsTeam.fragments.cardInterface}
  ` as TypedDocumentNode<Team_anyCard>,
};

export default Team;
