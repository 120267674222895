import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '__generated__/globalTypes';

import {
  ReferralProgramConfigQuery,
  ReferralProgramConfigQueryVariables,
} from './__generated__/useReferralProgramConfig.graphql';
import useQuery from './graphql/useQuery';
import useMonetaryAmount from './useMonetaryAmount';

const REFERRAL_PROGRAM_CONFIG_QUERY = gql`
  query ReferralProgramConfigQuery($sport: Sport) {
    config {
      id
      referralProgramConfig(sport: $sport) {
        id
        arenaTickets
        maxDiscount {
          referenceCurrency
          eur
          usd
          gbp
          wei
        }
      }
    }
  }
` as TypedDocumentNode<
  ReferralProgramConfigQuery,
  ReferralProgramConfigQueryVariables
>;

export const useReferralProgramConfig = (sport: Sport) => {
  const { data } = useQuery(REFERRAL_PROGRAM_CONFIG_QUERY, {
    variables: { sport },
  });
  const { referralProgramConfig } = data?.config || {};
  const { toMonetaryAmount, getUserFiatAmount } = useMonetaryAmount();

  return {
    referralProgramConfig,
    userFiatAmount: referralProgramConfig
      ? getUserFiatAmount(toMonetaryAmount(referralProgramConfig.maxDiscount)) /
        100
      : undefined,
  };
};
