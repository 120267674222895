import { useIntl } from 'react-intl';

type Props = {
  startDate: Date;
  endDate: Date;
};

const FixtureDateRange = ({ startDate, endDate }: Props) => {
  const { formatDateTimeRange } = useIntl();

  const range = formatDateTimeRange(startDate, endDate, {
    day: '2-digit',
    month: 'short',
  });
  return <>{range}</>;
};

export default FixtureDateRange;
