import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Dialog } from '@sorare/core/src/components/dialog';

const Layout = () => {
  return (
    <Suspense fallback={null}>
      <Dialog open hideHeader maxWidth="xl" fullWidth fullHeight>
        <Outlet />
      </Dialog>
    </Suspense>
  );
};

export default Layout;
