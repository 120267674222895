import isMobileWeb from 'is-mobile';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { SignupPlatform } from '__generated__/globalTypes';
import { getValue } from 'components/PersistsQueryStringParameters/storage';
import useAfterLoggedInTarget from 'hooks/useAfterLoggedInTarget';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useQueryString from 'hooks/useQueryString';

export const useOAuthParams = () => {
  const action = useQueryString('action');
  const location = useLocation();
  const afterLoggedInTarget = useAfterLoggedInTarget();
  const { isMobileApp, isAndroidApp } = useIsMobileApp();

  const signupPlatform = (() => {
    if (isMobileApp && isAndroidApp) return SignupPlatform.ANDROID;
    if (isMobileWeb()) return SignupPlatform.MOBILE_WEB;
    return SignupPlatform.WEB;
  })();

  return {
    referrer: getValue('referrer'),
    signup_platform: signupPlatform,
    impact_click_id: getValue('irclickid'),
    invitation_token: getValue('invitation_token'),
    after_logged_in_target:
      afterLoggedInTarget ||
      (action
        ? qs.stringify({ action }, { skipNulls: true, addQueryPrefix: true })
        : location.pathname),
  };
};
