import { useCallback } from 'react';

import { useEventsContext } from 'contexts/events';
import { useGetProtoSport } from 'hooks/events/useGetProtoSport';
import { useGetInteractionContext } from 'hooks/useGetInteractionContext';
import { mapKeys } from 'lib/object';
import toSnakeCase from 'lib/toSnakeCase';
import { sportToJSON } from 'protos/events/shared/events';

import { WithOptionalCommonProperties } from './EventsType';

type EventProperties = Record<string, number | string | string[] | boolean>;
const generateUseEvents = <
  EventTypes extends Record<string, EventProperties | void>,
>({
  defaultProperties,
}:
  | {
      defaultProperties?: EventProperties;
    }
  | undefined = {}) => {
  return () => {
    const { track: baseTrack } = useEventsContext();
    const getInteractionContext = useGetInteractionContext();

    const getSport = useGetProtoSport();

    const track = useCallback(
      <K extends keyof EventTypes & string>(
        event: K,
        ...rest: EventTypes[K] extends void
          ? []
          : [WithOptionalCommonProperties<EventTypes[K]>]
      ): void => {
        const snakeCasedProperties = rest[0]
          ? mapKeys(rest[0], v => {
              return toSnakeCase(v as string);
            })
          : {};
        baseTrack(event, {
          interaction_context: getInteractionContext(),
          sport: sportToJSON(getSport()),
          ...defaultProperties,
          ...snakeCasedProperties,
        });
      },
      [baseTrack, getSport, getInteractionContext]
    );
    return track;
  };
};

export default generateUseEvents;
