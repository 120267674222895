import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneNumberVerification: {
    id: 'Settings.phoneNumberVerification.title',
    defaultMessage: 'Phone Number Verification',
  },
  changePhoneNumber: {
    id: 'Settings.phoneNumberVerification.change',
    defaultMessage: 'Change Phone Number',
  },
  addPhoneNumber: {
    id: 'Settings.phoneNumberVerification.add',
    defaultMessage: 'Add a Phone Number',
  },
  noticeFirstParagraph: {
    id: 'Settings.phoneNumberVerification.noticeFirstParagraph',
    defaultMessage:
      'Sorare processes phone numbers for security and anti-fraud purposes (notably to monitor the respect of our terms for referral activities, non-casual line-up creations and credit cards payment).',
  },
  noticeSecondParagraph: {
    id: 'Settings.phoneNumberVerification.noticeSecondParagraph',
    defaultMessage:
      'Along with other information, your phone number will also allow our Support Team helping you in recovering your account in the case you would have lost your authentication device and recovery code.',
  },
});
