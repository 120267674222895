import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import RequireAuth from '@sorare/core/src/routing/RequireAuth';

import ClaimStrkContent from 'ClaimStrk';

const ClaimStrk = () => (
  <RequireAuth>
    <AppLayout>
      <ClaimStrkContent />
    </AppLayout>
  </RequireAuth>
);

export default ClaimStrk;
