import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { OAuthProvider } from '__generated__/globalTypes';
import { GOOGLE_OAUTH_CLIENT_ID } from 'config';
import { useConfigContext } from 'contexts/config';
import { useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';
import { currentUser as currentUserFragment } from 'contexts/currentUser/queries';
import useRedirectAfterSignIn from 'contexts/currentUser/useRedirectAfterSignIn';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';
import { useOAuthParams } from 'hooks/useOAuthParams';
import { formatGqlErrors } from 'lib/gql';

import {
  SignInWithOAuthMutation,
  SignInWithOAuthMutationVariables,
} from './__generated__/index.graphql';

const SRC = 'https://accounts.google.com/gsi/client';

declare global {
  interface Window {
    handleOneTapCredential?: (response: any) => void;
  }
}

export const SIGN_IN_WITH_OAUTH_MUTATION = gql`
  mutation SignInWithOAuthMutation($input: signInWithOAuthInput!) {
    signInWithOauth(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_currentUser
      }
      tcuToken
      errors {
        message
        code
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<
  SignInWithOAuthMutation,
  SignInWithOAuthMutationVariables
>;

export const GoogleOneTap = () => {
  const { updateQuery } = useConfigContext();
  const { currentUser } = useCurrentUserContext();
  const {
    flags: { useGoogleOneTap = false },
  } = useFeatureFlags();
  const params = useOAuthParams();
  const [consent] = useLocalStorage<false | true>(
    STORAGE.trackingConsent,
    false
  );
  const { isMobileApp } = useIsMobileApp();
  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH_MUTATION);
  const redirectUser = useRedirectAfterSignIn();
  const { promptTerms } = useConnectionContext();
  const { showNotification } = useSnackNotificationContext();

  const disabled = !useGoogleOneTap || !!currentUser || !consent || isMobileApp;

  useEffect(() => {
    if (disabled || document.querySelector(`script[src="${SRC}"]`)) {
      return;
    }
    const script = document.createElement('script');
    script.src = SRC;
    document.body.appendChild(script);
  }, [disabled]);

  useEffect(() => {
    if (disabled) return () => {};

    window.handleOneTapCredential = async response => {
      const result = await signInWithOAuth({
        variables: {
          input: {
            idToken: response.credential,
            provider: OAuthProvider.GOOGLE_OAUTH2,
            referrer: params.referrer,
            impactClickId: params.impact_click_id,
            signupPlatform: params.signup_platform,
          },
        },
      });
      const tcuToken = result.data?.signInWithOauth?.tcuToken;
      if (tcuToken) {
        promptTerms({ closable: false, tcuToken });
        return;
      }

      const user = result.data?.signInWithOauth?.currentUser;
      if (user) {
        updateQuery(user);
        redirectUser(user);
      } else if (result.data?.signInWithOauth?.errors) {
        showNotification('errors', {
          errors: formatGqlErrors(result.data.signInWithOauth.errors),
        });
      }
    };

    return () => delete window.handleOneTapCredential;
  }, [
    disabled,
    params.impact_click_id,
    params.referrer,
    params.signup_platform,
    promptTerms,
    redirectUser,
    showNotification,
    signInWithOAuth,
    updateQuery,
  ]);

  if (disabled) return null;

  return (
    <div
      id="g_id_onload"
      data-client_id={GOOGLE_OAUTH_CLIENT_ID}
      data-ux_mode="popup"
      data-callback="handleOneTapCredential"
    />
  );
};
