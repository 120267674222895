import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { FOOTBALL_PLAY } from '@sorare/core/src/constants/__generated__/routes';
import useIsLoggedIn from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { lazy } from '@sorare/core/src/lib/retry';

const FootballPublicHome = lazy(
  async () => import('components/landing/Public')
);
const EarlyAccess = lazy(async () => import('components/landing/EarlyAccess'));

type Props = {
  isRoot?: boolean;
};
const FootballPage = ({ isRoot }: Props) => {
  const { flags: { useEarlyAccessLanding = false } = {} } = useFeatureFlags();
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return (
      <Suspense>
        {useEarlyAccessLanding ? (
          <EarlyAccess showFAQ={!isRoot} />
        ) : (
          <FootballPublicHome />
        )}
      </Suspense>
    );
  }

  return <Navigate to={FOOTBALL_PLAY} replace />;
};

export default FootballPage;
