import bloomberg from './assets/bloomberg.svg';
import clique from './assets/clique.svg';
import financialTimes from './assets/financialTimes.svg';
import forbes from './assets/forbes.svg';
import lesEchos from './assets/lesEchos.svg';
import techCrunch from './assets/techCrunch.svg';

export const articles = [
  {
    name: 'Bloomberg',
    svgUrl: bloomberg,
    href: 'https://www.bloomberg.com/news/articles/2022-07-07/sorare-mlb-partnership-brings-nfts-to-baseball#xj4y7vzkg',
  },
  {
    name: 'Clique',
    svgUrl: clique,
    href: 'https://www.clique.tv/clique-avec-nicolas-julia-et-sophie-peters',
  },
  {
    name: 'Financial Times',
    svgUrl: financialTimes,
    href: 'https://www.ft.com/content/98229ed8-2867-40a5-a22d-b079fb563479',
  },
  {
    name: 'Forbes',
    svgUrl: forbes,
    href: 'https://www.forbes.com/sites/timcasey/2022/09/07/nba-nba-players-association-strike-deal-with-sorare-for-nft-fantasy-game',
  },
  {
    name: 'TechCrunch',
    svgUrl: techCrunch,
    href: 'https://techcrunch.com/2023/01/30/sorare-teams-up-with-the-premier-league-for-its-nft-fantasy-football-game',
  },
  {
    name: 'LesEchos',
    svgUrl: lesEchos,
    href: 'https://www.lesechos.fr/start-up/deals/sorare-va-lancer-un-jeu-consacre-a-la-nba-1786317',
  },
];
