import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Currency } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption, Text14 } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import Avatar from '@sorare/core/src/components/user/Avatar';
import { MyClubLink } from '@sorare/core/src/components/user/MyClubLink';
import UserName from '@sorare/core/src/components/user/UserName';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { isType } from '@sorare/core/src/lib/gql';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { Bid_tokenBid } from './__generated__/index.graphql';

type Props = {
  bid: Bid_tokenBid;
  displayAbsoluteDate: boolean;
};

const Root = styled(SBHorizontal).attrs({ gap: 0 })`
  background-color: var(--c-neutral-300);
  padding: var(--intermediate-unit);

  &:first-of-type {
    border-top-left-radius: var(--double-unit);
    border-top-right-radius: var(--double-unit);
  }
  &:last-of-type {
    border-bottom-left-radius: var(--double-unit);
    border-bottom-right-radius: var(--double-unit);
  }
`;
const Content = styled(Horizontal).attrs({ gap: 2 })``;

const Bid = ({
  bid: { amounts, createdAt, bidder },
  displayAbsoluteDate,
}: Props) => {
  const { formatDate, formatDistanceToNow } = useIntlContext();

  if (!bidder || !isType(bidder, 'User')) return null;

  return (
    <Root>
      <Content>
        <Avatar user={bidder} />
        <div>
          <Text14>
            <MyClubLink user={bidder}>
              <UserName user={bidder} />
            </MyClubLink>
          </Text14>
          <Caption color="var(--c-neutral-600)">
            {displayAbsoluteDate
              ? formatDate(createdAt, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : formatDistanceToNow(createdAt)}
          </Caption>
        </div>
      </Content>
      <AmountWithConversion
        primaryCurrency={Currency.ETH}
        hideExponent={false}
        monetaryAmount={amounts}
      />
    </Root>
  );
};

Bid.fragments = {
  tokenBid: gql`
    fragment Bid_tokenBid on TokenBid {
      id
      amounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      createdAt
      bidder {
        ... on User {
          slug
          ...Avatar_publicUserInfoInterface
          ...UserName_publicUserInfoInterface
          ...MyClubLink_publicUserInfoInterface
        }
      }
    }
    ${monetaryAmountFragment}
    ${Avatar.fragments.publicUserInfoInterface}
    ${UserName.fragments.user}
    ${MyClubLink.fragments.user}
  ` as TypedDocumentNode<Bid_tokenBid>,
};

export default Bid;
