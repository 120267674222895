import { Horizontal } from 'atoms/layout/flex';
import { useStoreBadge } from 'hooks/useStoreBadge';
import { useStoreUrl } from 'hooks/useStoreUrl';

export const DownloadAppButtons = () => {
  const storeUrl = useStoreUrl();
  const { googleBadge, appleBadge } = useStoreBadge();

  return (
    <Horizontal gap={0.5}>
      <a href={storeUrl} target="_blank" rel="noreferrer">
        <img src={appleBadge} alt="" />
      </a>
      <a href={storeUrl} target="_blank" rel="noreferrer">
        <img src={googleBadge} alt="" />
      </a>
    </Horizontal>
  );
};
