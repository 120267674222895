import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LinkBox, LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { Text16 } from '@sorare/core/src/atoms/typography';

const Root = styled(Vertical).attrs({ gap: 2 })`
  isolation: isolate;
  border-radius: var(--double-unit);
  background-color: var(--c-neutral-200);
  background-position: center;
  position: relative;

  &:not(.noPadding) > *:first-child {
    padding: var(--double-unit) var(--double-unit) 0 var(--double-unit);
  }
  & > *:last-child {
    padding: 0 var(--double-unit) var(--double-unit) var(--double-unit);
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--double-unit);
    background-color: rgba(var(--c-rgb-neutral-200), 0.6);
  }
`;
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;
const Group = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--unit);
`;
const Content = styled(Vertical).attrs({ gap: 1.5 })`
  position: relative;
  flex: 1;
  padding: 0 var(--double-unit);

  &.noPadding {
    padding: 0;
  }
`;
const Cta = styled.div`
  align-self: center;
  z-index: 1;
`;

type Props = {
  children: ReactNode;
  icon: ReactNode;
  title: ReactNode;
  cta?: ReactNode;
  to?: string;
  header?: ReactNode;
  stat?: {
    count: number;
    label: ReactNode;
  };
  backgroundImage?: string;
  className?: string;
  hideHeader?: boolean;
  noPadding?: boolean;
};
export const Block: React.FC<Props> = ({
  icon,
  title,
  stat,
  children,
  header,
  cta,
  to,
  backgroundImage,
  className,
  hideHeader,
  noPadding,
}: Props) => {
  const content = (
    <Content className={classNames({ noPadding })}>{children}</Content>
  );

  return (
    <Root
      as={to ? LinkBox : 'div'}
      className={classNames(className, { noPadding })}
      style={{
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
      }}
    >
      {!hideHeader && (
        <Header>
          <Group>
            {icon}
            <Text16 bold>{title}</Text16>
          </Group>
          {header || (
            <>
              {stat && (
                <Group>
                  <Text16>
                    <FormattedNumber value={stat.count} />
                  </Text16>
                  <Text16 color="var(--c-neutral-500)">{stat.label}</Text16>
                </Group>
              )}
            </>
          )}
        </Header>
      )}
      {to ? (
        <LinkOverlay as={Link} to={to}>
          {content}
        </LinkOverlay>
      ) : (
        content
      )}
      {cta && <Cta>{cta}</Cta>}
    </Root>
  );
};
