import { Sport } from '__generated__/globalTypes';
import { INVITE } from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';
import useCurrentSport from 'hooks/useCurrentSport';

export const useInviteLink = (sport?: Sport) => {
  const currentSport = useCurrentSport();
  const { generateSportContextPath } = useSportContext();

  return generateSportContextPath(INVITE, {
    sport: sport || currentSport,
  });
  return INVITE;
};
