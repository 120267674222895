import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  AcceptNoCardDraftableAppearance,
  AcceptNoCardDraftableAppearanceVariables,
} from './__generated__/useAcceptNoCardDraftableAppearance.graphql';

export const ACCEPT_NO_CARD_DRAFTABLE_APPEARANCE_MUTATION = gql`
  mutation AcceptNoCardDraftableAppearance($userID: UUID!, $id: UUID!) {
    acceptNoCardDraftableAppearance(userID: $userID, id: $id) {
      id
      player {
        slug
        displayName
      }
      team {
        slug
        name
      }
      bonus
      season
    }
  }
` as TypedDocumentNode<
  AcceptNoCardDraftableAppearance,
  AcceptNoCardDraftableAppearanceVariables
>;

type AcceptNoCardDraftableAppearanceInput = {
  userID: string;
  id: string;
};
export const useAcceptNoCardDraftableAppearance = () => {
  const [mutate] = useMutation(ACCEPT_NO_CARD_DRAFTABLE_APPEARANCE_MUTATION);

  return useCallback(
    async (input: AcceptNoCardDraftableAppearanceInput) =>
      mutate({ variables: input }),
    [mutate]
  );
};
