import styled from 'styled-components';

import { laptopAndAbove, largeDesktopAndAbove } from 'style/mediaQuery';

export const LineupsGridLayout = styled.div`
  display: grid;
  gap: var(--double-unit);
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  @media ${laptopAndAbove} {
    grid-template-columns: repeat(auto-fill, minmax(448px, 1fr));
  }

  @media ${largeDesktopAndAbove} {
    grid-template-columns: repeat(auto-fill, minmax(560px, 1fr));
  }
`;
