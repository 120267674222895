import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button, { Props as ButtonProps } from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import Dialog from 'components/dialog';
import { glossary } from 'lib/glossary';

const Root = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit) var(--triple-unit) 0;
`;
const Message = styled(Text16)`
  text-align: center;
  font-weight: normal;
`;
const Actions = styled(Horizontal).attrs({ gap: 3 })`
  padding: 0 var(--triple-unit) var(--triple-unit);
  margin-top: var(--double-and-a-half-unit);
`;

type Props = {
  onConfirm: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  message?: ReactNode;
  cancelCta?: ReactNode;
  cta?: ReactNode;
  ctaProps?: Omit<ButtonProps, 'size'> & { size?: never };
  open: boolean;
  onClose: () => void;
};
export const ConfirmDialog = ({
  title,
  subtitle,
  message,
  onConfirm,
  cancelCta,
  cta,
  ctaProps,
  open,
  onClose,
}: Props) => {
  const confirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      hideHeader
      footer={
        <Actions>
          <Button size="medium" onClick={onClose} color="quaternary" fullWidth>
            {cancelCta || <FormattedMessage {...glossary.close} />}
          </Button>
          <Button
            size="medium"
            color="primary"
            onClick={confirm}
            fullWidth
            {...ctaProps}
          >
            {cta || (
              <FormattedMessage
                id="ConfirmDialog.confirm"
                defaultMessage="Confirm"
              />
            )}
          </Button>
        </Actions>
      }
    >
      <Root>
        <div className="text-center">{title}</div>
        <Vertical>
          {subtitle}
          {message || (
            <Message>
              <FormattedMessage
                id="ConfirmDialog.message"
                defaultMessage="Are you sure?"
              />
            </Message>
          )}
        </Vertical>
      </Root>
    </Dialog>
  );
};

export default ConfirmDialog;
