import { ReactNode } from 'react';
import { Link as LinkComponent } from 'react-router-dom';

const createLink = (to: string) => {
  return function Link(...chunks: ReactNode[]) {
    return <LinkComponent to={to}>{chunks}</LinkComponent>;
  };
};

export default createLink;
