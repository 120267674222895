import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client';

import {
  Blockchain,
  WalletStatus,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';

import {
  currentBlockHeightQuery,
  currentBlockHeightQueryVariables,
  useMigrateCards_anyCard,
} from './__generated__/useMigrateCards.graphql';

const CURRENT_BLOCK_HEIGHT_QUERY = gql`
  query currentBlockHeightQuery {
    currentBlockHeight
  }
` as TypedDocumentNode<
  currentBlockHeightQuery,
  currentBlockHeightQueryVariables
>;

interface MigrationInput {
  migrateInternalCardsSignature?: string;
  migrateMappedCardsSignature?: string;
  expirationBlock: number;
}

// eslint-disable-next-line deprecation/deprecation
type Cards = Record<WalletStatus.INTERNAL | WalletStatus.MAPPED, string[]>;

const useMigrateCards = () => {
  const { currentUser } = useCurrentUserContext();
  const { signMigration } = useWalletContext();
  const client = useApolloClient();

  const getCurrentBlockNumber = async () => {
    const { data } = await client.query<currentBlockHeightQuery>({
      query: CURRENT_BLOCK_HEIGHT_QUERY,
    });

    return data.currentBlockHeight;
  };

  return async (cards: useMigrateCards_anyCard[]) => {
    if (!currentUser) return null;

    const requiredSignatures = cards
      .filter(
        ({ tokenOwner }) =>
          tokenOwner &&
          // TODO(jerome+baptiste): understand why it's lowercase
          tokenOwner.blockchain.toUpperCase() === Blockchain.ETHEREUM
      )
      .reduce<Cards>(
        (prev, { walletStatus, ethereumId }) => {
          if (!ethereumId) throw new Error('card should have a blockchainId');
          if (
            walletStatus !== WalletStatus.INTERNAL &&
            // TODO(t.des): To clean
            // eslint-disable-next-line deprecation/deprecation
            walletStatus !== WalletStatus.MAPPED
          )
            throw new Error('card should be in Sorare or mapped wallet');
          prev[walletStatus] = [...(prev[walletStatus] || []), ethereumId];
          return prev;
        },
        // eslint-disable-next-line deprecation/deprecation
        { [WalletStatus.INTERNAL]: [], [WalletStatus.MAPPED]: [] }
      );

    const { INTERNAL, MAPPED } = requiredSignatures;

    if (INTERNAL.length === 0 && MAPPED.length === 0) return null;

    const currentBlock = await getCurrentBlockNumber();
    const expirationBlock = currentBlock + 200000;

    const result: MigrationInput = {
      expirationBlock,
    };

    if (INTERNAL.length > 0) {
      const signature = await signMigration(INTERNAL, expirationBlock);
      result.migrateInternalCardsSignature = signature;
    }
    if (MAPPED.length > 0) {
      throw new Error(`unexpected cards in mapped account: ${MAPPED}`);
    }

    return result;
  };
};

useMigrateCards.fragments = {
  anyCard: gql`
    fragment useMigrateCards_anyCard on AnyCardInterface {
      slug
      walletStatus
      ethereumId
      tokenOwner {
        id
        blockchain
      }
    }
  ` as TypedDocumentNode<useMigrateCards_anyCard>,
};

export default useMigrateCards;
